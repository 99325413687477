//https://dev.to/chandrapantachhetri/responsive-react-file-upload-component-with-drag-and-drop-4ef8

import { FC, useRef, useState } from "react";
import {
  FileUploadContainer,
  FileUploadContainerDocument,
  FormField,
  // DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  // RemoveFileIcon,
  InputLabel,
  RemoveFileButton,
} from "./file-upload.styles";
import { Stack } from "@mui/material";
import Typography from "../../atoms/Typography";
import { FlexSpacer } from "../../atoms/FlexSpacer";
import { theme } from "../../../utils/theme";
import { getExtension, niceBytes, limitChars } from '../../../utils/functions';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 100 * 1000 * 1000; // 100Mb

const convertNestedObjectToArray = (nestedObj: any) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

interface FileUploadProps {
  id?: any,
  label?: any,
  updateFilesCb?: any,
  maxFileSizeInBytes?: any,
  multiple?: any,
  doc_type?: any,
  building_type_doc?: any,
  error?: any,
  accept?: any,
  acceptText?: any,
  register?: any,
  field?: any,
  name?:any,
}

/*const FileUpload = ({
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}*/

export const FileUpload: FC<FileUploadProps> = ({
  id,
  label,
  updateFilesCb,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  multiple,
  doc_type,
  building_type_doc,
  error,
  accept,
  acceptText,
  register,
  field,
  name,
}) => {
  const fileInputField: any = useRef(null);
  const [files, setFiles] = useState<any>({});

  const handleUploadBtnClick = () => {
    if(!fileInputField ||
      !fileInputField.current) return;

    fileInputField.current.click();
  };

  const addNewFiles = (newFiles: any) => {
    for (let file of newFiles) {
      if (file.size < maxFileSizeInBytes) {
        if (!multiple) {
          return { file };
        }
        files[file.name] = file;
      }
    }
    return { ...files };
  };

  //used in building-add to upload building image
  const callUpdateFilesCb = (files: any) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  //used in building-edit to upload documents
  const uploadFiles = () => {
    updateFilesCb(id, doc_type, files); 
    setFiles({});   
  }

  const handleNewFileUpload = (e: any, updateDirectly = true) => {
    const { files: newFiles } = e.target;
    
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      console.log('updatedFiles'.toUpperCase(), updatedFiles);

      setFiles(updatedFiles);
      if(updateDirectly){
        callUpdateFilesCb(updatedFiles);
      }
    }
  };

  const removeFile = (fileName: any) => {
    delete files[fileName];
    setFiles({ ...files });
    callUpdateFilesCb({ ...files });

    return files;
  };

  return (
    <>
      {building_type_doc === 'building_image'
        ? (
          <>
            <FileUploadContainer
              className={`upload-container${error ? ' error' : ''}`}
            >
              <InputLabel className="upload-label">{label}</InputLabel>

              <div className="upload-spacer-control">
                <FlexSpacer minHeight={1} />
              </div>

              <UploadFileBtn
                className="upload-btn"
                type="button"
                onClick={handleUploadBtnClick}
              >
                <img
                  width={15}
                  height={15}
                  className="upload-icon"
                  src="/svg/icons/PlusDark.svg"
                  alt="icon"
                />
                <span> Add {multiple ? "files" : " file"}</span>
              </UploadFileBtn>

              <div className="upload-spacer-control">
                <FlexSpacer minHeight={1} />
              </div>

              <Typography
                className="upload-legend"
                size="regular"
                color={theme.colors.neutral5}
              >
                Or drop file to upload
              </Typography>

              <div className="upload-spacer-control">
                <FlexSpacer minHeight={1} />
              </div>

              <Typography
                className="upload-sublegend"
                size="small"
                color={theme.colors.neutral5}
              >
                {acceptText === undefined || acceptText === ""
                  ? "Supports: .pdf, .jpg or.png"
                  : acceptText}
              </Typography>

              <FormField
                className="upload-input"
                type="file"
                style={{ height: "auto" }}
                ref={fileInputField}
                onChange={handleNewFileUpload}
                title=""
                value=""
                accept={accept}
                multiple={multiple}
                name={name}
              />
            </FileUploadContainer>

            <FilePreviewContainer className="UploadedFiles">
              <PreviewList className="Uploaded-files-list">
                {Object.keys(files).map((fileName, index) => {
                  let file = files[fileName];
                  let isImageFile = file.type.split("/")[0] === "image";
                  return (
                    <Stack direction="column" width="100%" key={index}>
                      <Typography className="Uploaded-files-heading">
                        Uploaded
                      </Typography>

                      <FlexSpacer minHeight={0.5} />

                      <PreviewContainer
                        className="Uploaded-files-container"
                        key={index}
                        style={{ width: "100%", border: "1px solid #D9D9D9" }}
                      >
                        <Stack direction="row">
                          {isImageFile && (
                            <ImagePreview
                              className="UploadedFilesImg"
                              src={URL.createObjectURL(file)}
                              alt={`file preview ${index}`}
                            />
                          )}

                          <FileMetaData className="Uploaded-files-metadata">
                            {/* <span>{file.name}</span> */}
                            <aside className="Uploaded-files-aside">
                              <Stack direction="column" sx={{ color: "black" }}>
                                {/* <IconButton onClick={() => removeFile(fileName)} >
                                      <CloseIcon />
                                    </IconButton>  */}
                                <Typography color={theme.colors.black} weight="regular" size="12px">
                                  {file.name}
                                </Typography>
                                <Typography color={theme.colors.neutral5} weight="regular" size="10px">
                                  {niceBytes(file.size)}
                                </Typography>
                                {/*<Typography>{isImageFile}</Typography>*/}

                                {/* <span>{convertBytesToKB(file.description)} kb</span> */}
                              </Stack>
                            </aside>
                            <aside className="Uploaded-files-aside-removebutton"
                              onClick={() => removeFile(fileName)}
                            >
                              <img
                                src="/svg/icons/Cross.svg"
                                className="Uploaded-files-img remove"
                                alt="delete icon"
                              />
                            </aside>
                          </FileMetaData>
                        </Stack>
                        <FlexSpacer minHeight={0.2} />
                      </PreviewContainer>
                    </Stack>
                  );
                })}
              </PreviewList>
            </FilePreviewContainer>
          </>
        )
        : (building_type_doc === 'document'
          ? (
            <>
              {Object.keys(files).length === 0
              ? (
                <FileUploadContainerDocument
                  className={`upload-container${error ? ' error' : ''}`}
                  id={`upload_container_${id}`}
                >
                  <UploadFileBtn
                    className="upload-btn"
                    type="button"
                    onClick={handleUploadBtnClick}
                    style={{
                      width: '100px'
                    }}
                  >
                    <img
                      width={15}
                      height={15}
                      className="upload-icon"
                      src="/svg/icons/PlusDark.svg"
                      alt="icon"
                    />
                    <span> Add {multiple ? "files" : " file"}</span>
                  </UploadFileBtn>

                  <div className="upload-spacer-control">
                    <FlexSpacer minHeight={1} />
                  </div>

                  <Typography
                    className="upload-legend"
                    size="regular"
                    color={theme.colors.neutral5}
                  >
                    Or drop file to upload
                  </Typography>

                  <div className="upload-spacer-control">
                    <FlexSpacer minHeight={1} />
                  </div>

                  <Typography
                    className="upload-sublegend"
                    size="small"
                    color={theme.colors.neutral5}
                  >
                    {acceptText === undefined || acceptText === ""
                      ? "Supports: .pdf, .jpg or.png"
                      : acceptText}
                  </Typography>

                  <FormField
                    className="upload-input"
                    type="file"
                    style={{ height: "auto" }}
                    ref={fileInputField}
                    onChange={(e) => handleNewFileUpload(e, false)}
                    title=""
                    value=""
                    accept={accept}
                    multiple={multiple}
                    name={name}
                  />
                </FileUploadContainerDocument>
              )
              : (
                <FileUploadContainerDocument
                  className={`upload-container${error ? ' error' : ''} small`}
                  id={`upload_container_file_${id}`}
                >
                  {Object.keys(files).map((fileName, index) => {
                      let file = files[fileName];
                      let isImageFile = file.type.split("/")[0] === "image";
                      return (
                        <Stack key={index}>
                          <Stack direction="row" spacing={1}>
                            <Stack
                              className="UploadedFileBorder"
                              direction="column"
                              sx={{
                                border: '1px solid #D9D9D9',
                                borderRadius: '4px',
                                width: '40px',
                                height: '40px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                overflow: 'hidden'
                              }}
                            >
                              {isImageFile
                                ? (
                                  <ImagePreview
                                    className="UploadedFilesImg"
                                    src={URL.createObjectURL(file)}
                                    alt={`file preview ${index}`}
                                    style={{
                                      objectFit: 'cover',
                                      maxWidth: '100px',
                                      maxHeight: '100px'
                                    }}
                                  />
                                )
                                : (
                                  <Typography
                                      size="10px"
                                      weight="medium"
                                      width={'100%'}
                                      justifyContent='center'
                                  >
                                      {'.' + getExtension(file.name)}
                                  </Typography>
                                )
                              }
                            </Stack>

                            <Stack direction="column" sx={{ width: '130px'}}>
                              <Typography id={`filename_${id}`} color={theme.colors.black} weight="regular" size="12px">
                                {limitChars(file.name, 18)}
                              </Typography>
                              <Typography color={theme.colors.neutral5} weight="regular" size="10px">
                                {niceBytes(file.size)}
                              </Typography>
                            </Stack>
                                  
                            <Stack direction="column">
                              <RemoveFileButton
                                onClick={() => removeFile(fileName)}
                              >
                                <img
                                  src="/svg/icons/Cross.svg"
                                  className="Uploaded-files-img remove"
                                  alt="delete icon"
                                />
                              </RemoveFileButton>
                            </Stack>
                          </Stack>

                          <FlexSpacer minHeight={0.75} />

                          <Stack direction="row" spacing={1.5}>
                            <UploadFileBtn
                              className="replace-btn"
                              type="button"
                              onClick={handleUploadBtnClick}
                            >
                              <FormField
                                className="upload-input"
                                type="file"
                                style={{ padding: '0', margin: '0', height: '0', width: '0' }}
                                ref={fileInputField}
                                onChange={handleNewFileUpload}
                                title=""
                                value=""
                                accept={accept}
                                multiple={multiple}
                                name={name}
                              />
                              Replace
                            </UploadFileBtn>

                            <UploadFileBtn
                              className="validate-btn"
                              type="button"
                              onClick={uploadFiles}
                              style={{'background': '#001AFF', 'color': 'white'}}
                            >
                              Add
                            </UploadFileBtn>
                          </Stack>
                        </Stack>
                      );
                    })
                  }
                </FileUploadContainerDocument>
              )}            
            </>
          )
          : (
            <>
              {Object.keys(files).length === 0
              ? (
                <FileUploadContainerDocument
                  className={`upload-container${error ? ' error' : ''}`}
                  id={`upload_container_${id}`}
                >
                  <UploadFileBtn
                    className="upload-btn"
                    type="button"
                    onClick={handleUploadBtnClick}
                    style={{
                      width: '125px'
                    }}
                  >
                    <img
                      width={15}
                      height={15}
                      className="upload-icon"
                      src="/svg/icons/PlusDark.svg"
                      alt="icon"
                    />
                    <span> Update {multiple ? "files" : " file"}</span>
                  </UploadFileBtn>

                  <div className="upload-spacer-control">
                    <FlexSpacer minHeight={1} />
                  </div>

                  <Typography
                    className="upload-legend"
                    size="regular"
                    color={theme.colors.neutral5}
                  >
                    Or drop file to upload
                  </Typography>

                  <div className="upload-spacer-control">
                    <FlexSpacer minHeight={1} />
                  </div>

                  <Typography
                    className="upload-sublegend"
                    size="small"
                    color={theme.colors.neutral5}
                  >
                    {acceptText === undefined || acceptText === ""
                      ? "Supports: .pdf, .jpg or.png"
                      : acceptText}
                  </Typography>

                  <FormField
                    className="upload-input"
                    type="file"
                    style={{ height: "auto" }}
                    ref={fileInputField}
                    onChange={(e) => handleNewFileUpload(e, true)}
                    title=""
                    value=""
                    accept={accept}
                    multiple={multiple}
                    name={name}
                  />
                </FileUploadContainerDocument>
              )
              : (
                <FileUploadContainerDocument
                  className={`upload-container${error ? ' error' : ''} small`}
                  id={`upload_container_file_${id}`}
                >
                  {Object.keys(files).map((fileName, index) => {
                      let file = files[fileName];
                      let isImageFile = file.type.split("/")[0] === "image";
                      return (
                        <Stack key={index}>
                          <Stack direction="row" spacing={1}>
                            <Stack
                              className="UploadedFileBorder"
                              direction="column"
                              sx={{
                                border: '1px solid #D9D9D9',
                                borderRadius: '4px',
                                width: '40px',
                                height: '40px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                overflow: 'hidden'
                              }}
                            >
                              {isImageFile
                                ? (
                                  <ImagePreview
                                    className="UploadedFilesImg"
                                    src={URL.createObjectURL(file)}
                                    alt={`file preview ${index}`}
                                    style={{
                                      objectFit: 'cover',
                                      maxWidth: '100px',
                                      maxHeight: '100px'
                                    }}
                                  />
                                )
                                : (
                                  <Typography
                                      size="10px"
                                      weight="medium"
                                      width={'100%'}
                                      justifyContent='center'
                                  >
                                      {'.' + getExtension(file.name)}
                                  </Typography>
                                )
                              }
                            </Stack>

                            <Stack direction="column" sx={{ width: '130px'}}>
                              <Typography id={`filename_${id}`} color={theme.colors.black} weight="regular" size="12px">
                                {limitChars(file.name, 18)}
                              </Typography>
                              <Typography color={theme.colors.neutral5} weight="regular" size="10px">
                                {niceBytes(file.size)}
                              </Typography>
                            </Stack>
                                  
                            <Stack direction="column">
                              <RemoveFileButton
                                onClick={() => removeFile(fileName)}
                              >
                                <img
                                  src="/svg/icons/Cross.svg"
                                  className="Uploaded-files-img remove"
                                  alt="delete icon"
                                />
                              </RemoveFileButton>
                            </Stack>
                          </Stack>
                        </Stack>
                      );
                    })
                  }
                </FileUploadContainerDocument>
              )}
            </>
          )
        )}
    </>
  );
};