import styled from "@emotion/styled";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useState, useEffect, FC } from "react";
import { theme } from "../../../utils/theme";
import { FlexSpacer } from "../../atoms/FlexSpacer";
import LightTooltip from "../../atoms/LightTooltip";
import LightTooltipHtml from "../../atoms/LightTooltipHtml";
import Typography from "../../atoms/Typography";
import TooltipContent from "./TooltipContent";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import axios, { AxiosRequestConfig } from "axios";
import { IUserType } from "../../../interfaces/userInfo";
import { useFieldArray } from "react-hook-form";
import ErrorMessage from "../../atoms/Warnings/ErrorMessage";
import BuildingService from "../../../services/buildingService";
import { IBuilding, IStakeholderDetail } from "../../../interfaces/building";
import { toast } from "react-toastify";
import { exampleStakeholderInvitee } from "../../../__mocks__/exampleStakeholder";
import UserService from "../../../services/userService";
import { getWalletBalance } from "../../../utils/functions";

interface AddStakeholderFormProps {
  building_ID?: any;
  control?: any;
  register?: any;
  defaultValues?: any;
  getValues?: any;
  setValue?: any;
  errors?: any;
  watch?: any;
  setIncludeUserTypeID?: any;
  handleOpenModalAddStakeholder?: any;
  setMyCallBack?: any;
  setBuildingWallet?: any;
  blockchain: any;
}

const AddStakeholdersForm: FC<AddStakeholderFormProps> = ({
  building_ID,
  control,
  register,
  defaultValues,
  getValues,
  setValue,
  errors,
  watch,
  setIncludeUserTypeID,
  handleOpenModalAddStakeholder,
  setMyCallBack,
  setBuildingWallet,
  blockchain
}) => {
  console.log("AddStakeholdersForm");

  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  //const [stakeholderList, setStakeholderList] = useState<IStakeholder[]>([defaultStakeholder]);
  const [userTypes, setUserTypes] = useState<IUserType[]>([]);

  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'stakeholder'
  })

  useEffect(() => {
    /* fix if the user is already logged in and coming from loginpage directly */
    if (!keycloak.authenticated) {
      navigate('/');
      keycloak.logout();
    }

    setMyCallBack(() => fetchUserTypes);

    fetchUserTypes();
  }, [setMyCallBack]);

  const fetchUserTypes = () => {
    const url = `${process.env.REACT_APP_API_URL}/user/types`;
    
    const data = {
      "exclude_user_type_ID": '1,2'
    }

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${keycloak.token}`
      },
      params: data
    };

    axios.get(url, config)
      .then((response) => {
        const userTypes: IUserType[] = response.data.result;
        console.log("userTypes", userTypes);
        setUserTypes(userTypes);

        fetchBuildingInfo();
      })
      .catch(error => {
        if (error.response.status === 401) {
          navigate('/');
          keycloak.logout();
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.error(`Error: ${error}`)
      });
    //setLoading(false);
  }

  function fetchBuildingInfo() {
    const buildingService = new BuildingService();
    buildingService.getBuildingInfo(keycloak, building_ID)
        .then(async(response) => {
            if (!response){
              navigate('/');
              return;
            }
            
            console.log("add stakeholdersform", response);
            const building: IBuilding = response;

            removeStakeholderForms();
            if (building.stakeholders) {
                /* exclude owner and asset manager */
                const stakeholdersObj: IStakeholderDetail[] = building.stakeholders.filter(stakeholder => !['1', '2'].includes(stakeholder.user_type_ID.toString()));
                console.log("stakeholdersObj",stakeholdersObj);
                if (stakeholdersObj !== undefined && stakeholdersObj.length > 0) {
                    console.log("stakeholdersObj FOUND",stakeholdersObj);
                    
                    stakeholdersObj.forEach((stakeholder, index) => {
                      append({
                        user_ID: stakeholder.user_ID,
                        user_type_ID: stakeholder.user_type_ID,
                        firstname: stakeholder.firstname,
                        lastname: stakeholder.lastname,
                        company: stakeholder.company,
                        email: stakeholder.email,
                        isNewStakeholder: false
                      });
                    });

                    /*setAssetManager(assetManagerObj);                        

                    setValue('user_ID', assetManagerObj.user_ID);
                    setValue('name', assetManagerObj.name);
                    setValue('company', assetManagerObj.company);
                    setValue('email', assetManagerObj.email);

                    setShowAssetManager(true);

                    setFormDisabled(true);*/
                }
                else{
                  // ADD first stakeholder
                  /*console.log("load fields 1",getValues());
                  if(getValues().stakeholder.length === 0){
                    append(defaultValues.stakeholder);
                  }
                  console.log("load fields 2",getValues());*/
                }
            }

            if(building.wallet_address !== undefined &&
              building.wallet_address !== null &&
              building.wallet_address !== '')
            {
              setBuildingWallet({
                name: building.building_name,
                address: building.wallet_address,
                balance: {
                    gasBalance: await getWalletBalance(blockchain, building.wallet_address)
                }
              });
            }
        })
        .catch((error) => {
            if (error.response.status === 401) {
                navigate('/');
                keycloak.logout();
            }
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.error(`Error: ${error}`)
        });
  }

  function removeStakeholderForms(){
    getValues().stakeholder.forEach(() => {
      //remove every cycle the first one with index 0
      remove(0);
    })
  }

  const handleRemoveClick = (index: number, user_ID: string) => {
    console.log("remove stakeholder index:", index, 'user_ID', user_ID);
    if(user_ID !== "" && parseInt(user_ID) > 0){
      removeStakeholder(index, user_ID);
    }
    else{
      remove(index);
      console.log("remove fields",fields.length,fields,getValues());
    }
  };

  function removeStakeholder (index: number, user_ID: string) {
    const url = `${process.env.REACT_APP_API_URL}/building/stakeholder`;

    const data = {
        "building_ID": building_ID,
        "stakeholders": [{
            "user_ID": user_ID
        }]
    }

    const config: AxiosRequestConfig = {
        method: 'delete',
        url: url,
        data: data,
        headers: {
            'Authorization': `Bearer ${keycloak.token}`,
            'Content-Type': 'application/json'
        }
    };

    console.log("api config", config);

    axios(config)
      .then((response) => {
          //const userInfo: IUserInfo = response.data.result;
          console.log(response);

          if (!response.data.status) {
              console.log(response.data.msg + "\n" + response.data.error);
              toast.error("Error removing stakeholder, try again!");
          }
          else {
              toast.success("Stakeholder has been removed!");
              remove(index);
              console.log("removeStakeholder 1",getValues());
              if(getValues().stakeholder.length === 0){
                append(defaultValues.stakeholder);
              }
              console.log("removeStakeholder 2",getValues());
              
              /*setValue('user_ID', '');
              setValue('name', '');
              setValue('company', '');
              setValue('email', '');
              setFormDisabled(false);*/
          }
          //getUserInfo(userInfo);
      })
      .catch(error => {
          if (error.response.status === 401) {
              navigate('/');
              keycloak.logout();
          }
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          console.error(`Error: ${error}`)
      });
  }


  console.log("defaultValues", defaultValues);
  console.log("fields", fields);
  console.log("errors", errors);

  function getUserTypeImg(user_type_ID: string){
    const userType = userTypes.find(userType => userType.ID.toString() === user_type_ID.toString());
    return userType?.img;
  }

  const fillTestData = (index: number) => {
    setValue(`stakeholder.${index}.user_type_ID`, exampleStakeholderInvitee.user_type_ID[Math.floor(Math.random() * (exampleStakeholderInvitee.user_type_ID.length) + 0)]);
    setValue(`stakeholder.${index}.firstname`, exampleStakeholderInvitee.firstname[Math.floor(Math.random() * (exampleStakeholderInvitee.firstname.length) + 0)]);
    setValue(`stakeholder.${index}.lastname`, exampleStakeholderInvitee.lastname[Math.floor(Math.random() * (exampleStakeholderInvitee.lastname.length) + 0)]);
    setValue(`stakeholder.${index}.company`, exampleStakeholderInvitee.company[Math.floor(Math.random() * (exampleStakeholderInvitee.company.length) + 0)]);
    setValue(`stakeholder.${index}.email`, Math.floor(Math.random() * (20) + 0) + '_' + exampleStakeholderInvitee.email[Math.floor(Math.random() * (exampleStakeholderInvitee.email.length) + 0)]);
  }

  async function checkDuplicateUser(email: string): Promise<any>{

    const userServiceObj = new UserService();
    const result = await userServiceObj.checkDuplicatedUserByEmail(keycloak, email);
    console.log(result);
    if (result) {
        // if user already exist
        return false;
    }
    else{
        // if user not exist
        return true;
    }
  }

  return (
    <Box maxWidth="100%">
      {fields.map((stakeholder: any, index) => {
        return (
          <Stack direction="column" key={stakeholder.id}>
            {/* Header */}
            <Stack
              direction="row"
              sx={{ placeContent: "space-between", padding: ".2rem" }}
            >
              <Stack direction="column">
                <Typography weight="medium" size="h5">
                  Stakeholder #{index + 1}
                </Typography>

                {process.env.REACT_APP_SHOW_ADD_DUMMY_DATA === "true" && stakeholder.isNewStakeholder &&
                  <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      type="button"
                      sx={{ width: '140px', 
                        padding: 0,
                        fontSize: '0.6rem',
                        maxHeight: 40
                    }}
                    onClick={() => {
                      fillTestData(index)
                    }}
                  >
                      Apply testdata
                  </Button>
                }

                <FlexSpacer minHeight={1.5} />

                <label htmlFor={`stakeholder.${index}.user_type_ID`}>
                  <Typography size="body" weight="regular">
                    {`Type & Category`}
                  </Typography>
                </label>
              </Stack>

              {
                watch(`stakeholder.${index}.user_type_ID`) !== undefined && watch(`stakeholder.${index}.user_type_ID`) !== ''
                ? (
                  <StyledImgBox>
                    <img
                      src={`/images/stakeholders/${getUserTypeImg(watch(`stakeholder.${index}.user_type_ID`))}`}
                      alt=""
                    />
                  </StyledImgBox>
                ) : (
                  <StyledImgBox>
                    <img
                      src={`/images/stakeholders/_tmp_user_type.png`}
                      alt=""
                    />
                  </StyledImgBox>
                )
              }
            </Stack>

            <FlexSpacer minHeight={0.5} />

            {/* Body */}
            <FormControl>
              <input type="hidden" name={`stakeholder.${index}.user_ID`} />
              <LightTooltipHtml
                placement={isTablet ? "top" : "right"}
                arrow
                title={<TooltipContent />}
              >
                <StyledSelect
                  {...(stakeholder.isNewStakeholder)
                    ? register(`stakeholder.${index}.user_type_ID` as const, { required: true })
                    : register(`stakeholder.${index}.user_type_ID` as const)
                  }
                  labelId={`stakeholder.${index}.user_type_ID`}
                  id={`stakeholder.${index}.user_type_ID`}
                  name={`stakeholder.${index}.user_type_ID`}
                  error={!!errors.stakeholder?.[index]?.user_type_ID}
                  value={watch(`stakeholder.${index}.user_type_ID`)}
                  disabled={!stakeholder.isNewStakeholder}
                  onChange= {(e: any) => {
                    setValue(`stakeholder.${index}.user_type_ID`, e.target.value);                    
                  }}
                  renderValue={(ID: number) => {
                    const userType = userTypes.find(userType => userType.ID === ID);
                    let value = '';

                    if(userType !== undefined) {
                      if(userType.user_type_ID === 0){
                        value += userType.category + '. ' + userType.name;
                      }
                      else{
                        const parentUserType = userTypes.find(_userType => _userType.ID === userType.user_type_ID);
                        if(parentUserType !== undefined){
                          value += parentUserType.category + '. ' + parentUserType.name + ' / ' + userType.category + '. ' + userType.name;
                        }
                      }

                      return value;
                    }
                    else{
                      return ID;
                    }
                  }}             
                >
                  {
                    userTypes
                      .filter(userType => userType.user_type_ID === 0)
                      .sort((a, b) => {
                        const categoryA = a.category.toLowerCase();
                        const categoryB = b.category.toLowerCase();
                        const letterA = categoryA[0];
                        const letterB = categoryB[0];
                        const numberA = parseInt(categoryA.substring(1));
                        const numberB = parseInt(categoryB.substring(1));
                  
                        if (letterA === letterB) {
                          return numberA - numberB;
                        }
                        return letterA.localeCompare(letterB);
                      }) // Sort by category
                      .map((userTypeHead, userTypesIndex) => [
                        /*(userTypeHead: IUserType, userTypesIndex: number) => {
                          return (
                            <MenuItem
                              key={`${index}_${userTypesIndex}_1`}
                              value={userTypeHead.ID}
                              disabled={userTypeHead.active === 0 || userTypeHead.ID === 2 ? true : false}
                            >
                              {userTypeHead.category}. {userTypeHead.name} ({userTypeHead.ID + "-" + userTypeHead.user_type_ID})
                            </MenuItem>
                          )
                        },*/
                        ...userTypes
                          .filter(userType => userType.ID === userTypeHead.ID)
                          .map((userTypeItem, userTypeItemIndex) => {
                            const isUserTypeSelected = userTypeItem.ID === getValues(`stakeholder.${index}.user_type_ID`);
                            return (
                              <MenuItem
                                key={`${index}_${userTypesIndex}_1`}
                                className='userTypes'
                                value={userTypeItem.ID}
                                disabled={userTypeItem.active === 0 || userTypeItem.ID === 2 ? true : false}
                              >
                                {userTypeItem.category}. {userTypeItem.name}
                                {isUserTypeSelected && (
                                  <img
                                    alt="v"
                                    color="white"
                                    src="/svg/icons/CheckWhite.svg"
                                    width={15}
                                    height={15}
                                    style={{ marginLeft: '5px' }}
                                  />
                                )}
                              </MenuItem>
                            );
                          }),
                        ...userTypes
                          .filter(userType => userType.user_type_ID === userTypeHead.ID)
                          .sort((a, b) => {
                            const categoryA = a.category.toLowerCase();
                            const categoryB = b.category.toLowerCase();
                            const letterA = categoryA[0];
                            const letterB = categoryB[0];
                            const numberA = parseInt(categoryA.substring(1));
                            const numberB = parseInt(categoryB.substring(1));
                      
                            if (letterA === letterB) {
                              return numberA - numberB;
                            }
                            return letterA.localeCompare(letterB);
                          }) // Sort by category
                          .map((userTypeItem, userTypeItemIndex) => {
                            const isUserTypeSelected = userTypeItem.ID === getValues(`stakeholder.${index}.user_type_ID`);
                            return (
                              <MenuItem
                                key={`${index}_${userTypesIndex}_${userTypeItemIndex}_2`}
                                className='userTypes'
                                sx={{ ml: '20px!important' }}
                                value={userTypeItem.ID}
                                disabled={userTypeItem.active === 0 || userTypeItem.ID === 2 ? true : false}
                              >
                                {userTypeItem.category}. {userTypeItem.name}
                                {isUserTypeSelected && (
                                  <img
                                    alt="v"
                                    color="white"
                                    src="/svg/icons/CheckWhite.svg"
                                    width={15}
                                    height={15}
                                    style={{ marginLeft: '5px' }}
                                  />
                                )}
                              </MenuItem>
                            );
                          })
                      ])
                  }
                  {/*
                  userTypes
                    .filter(userType => userType.user_type_ID === 0)
                    .map((userTypeHead, userTypesIndex) => (
                    <>
                      <MenuItem key={`${index}_${userTypesIndex}_1`} value={userTypeHead.ID} disabled={userTypeHead.active === 0 ? true : false}>
                        {userTypeHead.category}. {userTypeHead.name}
                      </MenuItem>
                      {...userTypes.map((userTypeItem, userTypeItemIndex) => {
                        <MenuItem key={`${index}_${userTypesIndex}_2`} className='userTypes' sx={{ml: '20px!important'}} value={userTypeItem.ID} disabled={userTypeItem.active === 0 ? true : false}>
                          {userTypeItem.category}. {userTypeItem.name}
                        </MenuItem>
                        {<MenuItem key={`${index}_${userTypesIndex}_2`} className='userTypes' sx={{ml: '20px!important'}} value={userTypeItem.ID} disabled={userTypeItem.active === 0 ? true : false}>
                          {userTypeItem.category}. {userTypeItem.name}
                          {userTypeItem.ID === getValues(`stakeholder.${index}.user_type_ID`)}
                          && (
                            <img
                              alt="v"
                              color="white"
                              src="/svg/icons/CheckWhite.svg"
                              width={15}
                              height={15}
                              style={{marginLeft: '5px'}}
                            />
                          )
                          </MenuItem>}
                      })}
                    </>
                  ))*/}
                </StyledSelect>
              </LightTooltipHtml>         
              {errors.stakeholder?.[index]?.user_type_ID && errors.stakeholder?.[index]?.user_type_ID.type === "required" && (<FormHelperText><ErrorMessage message="You have to choose a type"/></FormHelperText>)}

              <FlexSpacer minHeight={1.5} />

              <Stack
                direction="row"
                sx={{ lineHeight: 1.125, justifyContent: "space-between" }}
              >
                <label htmlFor="firstname">
                  <Typography size="body" weight="regular">
                    First name
                  </Typography>
                </label>

                {stakeholder.isNewStakeholder ? (
                  <Typography
                    onClick={() => {
                      setIncludeUserTypeID([watch(`stakeholder.${index}.user_type_ID`)]);
                      handleOpenModalAddStakeholder();
                    }}
                    size="body"
                    weight="regular"
                    color={theme.colors.primary}
                    link="true"
                  >
                    Already using Magma? Quick add
                    <img
                      width={16}
                      height={16}
                      src="/svg/icons/ExternalBlue.svg"
                      alt="icon"
                      style={{
                        margin: " 0 0 0 .388rem",
                        height: "16px",
                      }}
                    />
                  </Typography>
                ) : ''}
              </Stack>
              <FlexSpacer minHeight={0.5} />

              <LightTooltip
                title="Type the first name of the stakeholder"
                placement={isTablet ? "top" : "right"}
                arrow
              >
                <StyledInput
                  {...(stakeholder.isNewStakeholder)
                    ? register(`stakeholder.${index}.firstname` as const, { required: true })
                    : register(`stakeholder.${index}.firstname` as const)
                  }
                  id={`stakeholder.${index}.firstname`}
                  name={`stakeholder.${index}.firstname`}
                  error={!!errors.stakeholder?.[index]?.firstname}
                  disabled={!stakeholder.isNewStakeholder}
                />
              </LightTooltip>
              {errors.stakeholder?.[index]?.firstname && errors.stakeholder?.[index]?.firstname.type === "required" && (<FormHelperText><ErrorMessage /></FormHelperText>)}

              <FlexSpacer minHeight={1.5} />

              <label htmlFor="lastname">
                <Typography size="body" weight="regular">
                  Last name
                </Typography>
              </label>
              <LightTooltip
                title="Type the last name of the stakeholder"
                placement={isTablet ? "top" : "right"}
                arrow
              >
                <StyledInput
                  {...(stakeholder.isNewStakeholder)
                    ? register(`stakeholder.${index}.lastname` as const, { required: true })
                    : register(`stakeholder.${index}.lastname` as const)
                  }
                  id={`stakeholder.${index}.lastname`}
                  name={`stakeholder.${index}.lastname`}
                  error={!!errors.stakeholder?.[index]?.lastname}
                  disabled={!stakeholder.isNewStakeholder}
                />
              </LightTooltip>
              {errors.stakeholder?.[index]?.lastname && errors.stakeholder?.[index]?.lastname.type === "required" && (<FormHelperText><ErrorMessage /></FormHelperText>)}

              <FlexSpacer minHeight={1.5} />

              <label htmlFor="company">
                <Typography size="body" weight="regular">
                  Company
                </Typography>
                <FlexSpacer minHeight={0.5} />
              </label>

              <LightTooltip
                title="Type the company name of stakeholder"
                placement={isTablet ? "top" : "right"}
                arrow
              >
                <StyledInput
                  {...(stakeholder.isNewStakeholder)
                    ? register(`stakeholder.${index}.company` as const, { required: true })
                    : register(`stakeholder.${index}.company` as const)
                  }
                  id={`stakeholder.${index}.company`}
                  name={`stakeholder.${index}.company`}
                  error={!!errors.stakeholder?.[index]?.company}
                  disabled={!stakeholder.isNewStakeholder}
                ></StyledInput>
              </LightTooltip>
              {errors.stakeholder?.[index]?.company && errors.stakeholder?.[index]?.company.type === "required" && (<FormHelperText><ErrorMessage /></FormHelperText>)}

              <FlexSpacer minHeight={1.5} />

              <label htmlFor="email">
                <Typography size="body" weight="regular">
                  Email address
                </Typography>
                <FlexSpacer minHeight={0.5} />
              </label>

              <LightTooltip
                title="Type the email of stakeholder"
                placement={isTablet ? "top" : "right"}
                arrow
              >
                <StyledInput
                  {...(stakeholder.isNewStakeholder)
                    ? register(`stakeholder.${index}.email` as const, {
                      required: true,
                      pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      validate: {
                          duplicate: async(v: string) => await checkDuplicateUser(v)
                      }
                    })
                    : register(`stakeholder.${index}.email` as const)}
                  id={`stakeholder.${index}.email`}
                  name={`stakeholder.${index}.email`}
                  error={!!errors.stakeholder?.[index]?.email}
                  disabled={!stakeholder.isNewStakeholder}
                />
              </LightTooltip>
              {errors.stakeholder?.[index]?.email && errors.stakeholder?.[index]?.email.type === "required" && (<FormHelperText><ErrorMessage /></FormHelperText>)}
              {errors.stakeholder?.[index]?.email && errors.stakeholder?.[index]?.email.type === "pattern" && (<FormHelperText><ErrorMessage message='Must be a valid email address'/></FormHelperText>)}
              {errors.stakeholder?.[index]?.email && errors.stakeholder?.[index]?.email.type === "duplicate" && (<FormHelperText><ErrorMessage message='Stakeholder already uses Magma'/></FormHelperText>)}

              <FlexSpacer minHeight={1.5} />

              <Button
                variant="outlined"
                color="inherit"
                size="medium"
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  textTransform: "inherit",
                  border: "1px solid #D9D9D9",
                  height: "40px",
                  marginTop: "auto",
                  fontSize: ".7rem",
                  maxWidth: "fit-content",
                  marginLeft: "auto",
                }}
                onClick={() => {
                  handleRemoveClick(index, getValues(`stakeholder.${index}.user_ID`))
                }}
              >
                <img
                  width={16}
                  height={16}
                  src="/svg/icons/Cross.svg"
                  style={{ filter: "invert(1)" }}
                  alt="remove icon"
                />
                <Typography weight="medium" ml="6.21px">
                  Remove stakeholder
                </Typography>
              </Button>

              <FlexSpacer minHeight={2.5} />
            </FormControl>
          </Stack>
        );
      })}
      <div className="btn-box">
        <Button
          variant="outlined"
          color="inherit"
          size="large"
          sx={{ maxHeight: 40, maxWidth: 200 }}
          onClick={() => append(defaultValues.stakeholder)}
        >
          <img
            alt="+"
            color="white"
            src="/svg/icons/Plus.svg"
            width={20}
            height={20}
            style={{ filter: "invert(1)" }}
          />
          <Box
            ml={isMobile ? 0 : "10px"}
            style={{ width: "auto", textTransform: "none" }}
          >
            Add a stakeholder
          </Box>
        </Button>
      </div>
    </Box>
  );
};

export default AddStakeholdersForm;

const StyledSelect = styled(Select)`
  font-size: 14px;
  height: 38px;
`;

const StyledInput = styled(TextField)`
  padding: 0;

  .MuiOutlinedInput-input {
    padding: 10px 14px;
  }
`;

const StyledImgBox = styled(Box)`
  max-width: 62px;
  max-height: 62px;
  box-shadow: 4px -4px 0 0 #001aff;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 5px;
  margin-right: 2px;

  img {
    width: 62px;
  }
`;