export const tierData: {
    tier_id: number,
    tier: string,
    magma_index_weight: number,
}[] = [
    {
        tier_id: 0,
        tier: 'Genesis',
        magma_index_weight: 0.15,
    },
    {
        tier_id: 1,
        tier: 'Structure',
        magma_index_weight: 0.10,
    },
    {
        tier_id: 2,
        tier: 'Architecture',
        magma_index_weight: 0.25,
    },
    {
        tier_id: 3,
        tier: 'Wired',
        magma_index_weight: 0.30,
    },
    {
        tier_id: 4,
        tier: 'Filled',
        magma_index_weight: 0.20,
    }
]

export interface IDTTMetaData {
    address: string,
    hashAdministrator: string,
    hashStorageContract: string,
    level: number[],
    levelAdministrator: string,
    tokenAdministrator: string,
    uri: string,
}