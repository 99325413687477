import React, { FC, useEffect, useState } from "react";
import { Box, Grid, Link, Skeleton, useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import axios, { AxiosRequestConfig } from "axios";
import Typography from "../../../atoms/Typography";
import { IUserInfo } from "../../../../interfaces/userInfo";
import { theme } from "../../../../utils/theme";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { useSwiper } from 'swiper/react';
import { IBuilding } from '../../../../interfaces/building';
import "swiper/css/navigation";
import "swiper/css";
import 'react-toastify/dist/ReactToastify.css';
import { Image } from "@mui/icons-material";

interface GridActivityProps {
  open?: boolean
  messengerOpen?: boolean
  handleOpenModal?: any
  handleOpenModalStakeholdersDetailed?: any
  handleOpenModalStakeholders?: any
  handleOpenModalGiveAccess?: any
  handleOpenModalBuildingAdded?: any
  handleOpenModalAddStakeholder?: any
  handleOpenModalNft?: any
  handleOpenModalInvitation?: any
  setOpen?: Function
  bothNavsOpen?: boolean
  buildings?: any
  setSelectedBuildings?: any
  selectedBuildings?: any
}

const GridActivity: FC<GridActivityProps> = ({
  buildings,
  setSelectedBuildings,
  selectedBuildings
}) => {
  console.log("GridActivity");
  
  const swiperImgControl = useSwiper();

  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isLocked, setLocked] = useState(false)
  const [lastIndex, setLastIndex] = useState(false)
  // const [active, setActive] = useState(-1);
  // const [buildingInfo, getBuildingInfo] = useState<IBuildingInfo[] | undefined>(undefined);

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = FALLBACK_IMAGE;
    event.currentTarget.className = "error";
  };

  /*useEffect(() => {
    
  }, [selectedBuildings]);*/

  const getIndex = (e: any) => {
    if (e.realIndex >= 0) {
      setLastIndex(true)

      setTimeout(() => {
        setLastIndex(false)

      }, 1000);

    }

    if (e.realIndex >= 12) {
      setLastIndex(false)

    }
  }

  const selectBuilding = (building_ID: any) => {
    let newSelectedBuildings;
    newSelectedBuildings = handleSelectedBuildings(selectedBuildings, building_ID);
    setSelectedBuildings(newSelectedBuildings);
  }

  const handleSelectedBuildings = (string: any, num: any) => {
    
    let arr = [];
    if(string === undefined){
      arr.push(num.toString());
    }
    else{
      arr = string.split(",");
      console.log('arr', arr);
      const index = arr.indexOf(num.toString());
      if (index !== -1) {
        arr.splice(index, 1);
      } else {
        arr.push(num.toString());
      }
    }
    
    //sort array
    arr.sort(function(a: any, b: any) {
      return a - b;
    });

    string = arr.join(",");
    //remove leading commas
    string = string.replace(/^,+/, '');
    return string;
  }

  const isBuildingSelected = (building_ID: number) => {
    if(selectedBuildings === undefined) return false;

    const arr = selectedBuildings.split(",");
    const index = arr.indexOf(building_ID.toString());
    if(index !== -1){
      return true;
    }
    return false;
  }

  return (
    <StyledSwiper
      className="myBuildingActivitySwiper"
      onSlideChange={getIndex}
      modules={[Navigation, Pagination]}
      slidesPerView={isMobile ? 1 : 4}
      slidesPerGroup={isMobile ? 1 : 4}
      spaceBetween={10}
      loopFillGroupWithBlank={true}
      pagination={{
        clickable: true,
      }}
      navigation={{
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      }}
    >
      {buildings !== undefined && buildings.length > 0 ? buildings.map((item: any, index: any) => {
        // const getCardClassName = (index: number) => {
        //   if (active > -1 && index === active) return "showProgress";
        //   return "hideProgress";
        // };

        return (
          <div
            key={`grid-activity-${new Date().getTime() + Math.random()}`}
          >
            <SwiperSlide
              data-caption={item.building_name}
              key={item.building_name + index}
              className={`swiper-container${isBuildingSelected(item.ID) ? ' selected' : ' unselected'}`}
            >
              <StyledWrapperBox
                onClick={() => {
                  selectBuilding(item.ID)
                }}
              >
                <StyledCardLink
                  style={{ position: 'relative' }}
                >
                  <img
                    alt={item.building_name}
                    className="building-img"
                    src={item.building_img_data !== undefined && item.building_img_data !== '' ? `data:${item.building_img_mimetype};base64,${item.building_img_data}` : '/images/building/Draft.png'}
                    onError={imageOnErrorHandler}
                    width={200}
                    height={140}
                    style={{objectFit: 'cover', width: '100%', height: '100%'}}
                  />

                  {isLocked ?
                    <StyledLockedBox>
                      <img
                        className="lock"
                        alt={item.building_name}
                        color="white"
                        src="/svg/icons/Lock.svg"
                        width={50}
                        height={50}
                      />

                      <Typography size="h6" weight="medium" sx={{ margin: '.5rem auto 0', maxWidth: 200 }}>
                        Sign the NDA to unlock the building
                      </Typography>
                    </StyledLockedBox>
                    : ''
                  }
                </StyledCardLink>

                <StyledTextLink href="/building">
                  <Typography size="h5" weight="bold" my={1}>{item.building_name}</Typography>
                </StyledTextLink>
              </StyledWrapperBox>

            </SwiperSlide>
          </div>
        )
      }) :
        <Grid
          container
          key={`wallet-${new Date().getTime() + Math.random()}`}
          sx={{ width: '100% !important', maxHeight: '253px' }}
          direction="row"
        >
          {[{}, {}, {}, {}].map(() =>
            <Grid
              item
              xs={3}
              sx={{ maxHeight: '250px', padding: '0 .5rem', width: '184px !important' }}
              key={`grid-activity-skeleton${new Date().getTime() + Math.random()}`}
            >
              <div>
                <Skeleton
                  animation="wave"
                  width="100%"
                  height={157}
                  sx={{ borderRadius: 2 }}
                />
                <Skeleton
                  animation="wave"
                  height={40}
                  width="50%"
                  sx={{ borderRadius: 2 }}
                />
              </div>
            </Grid>
          )}
        </Grid>
      }

      {lastIndex ? '' :
        <div className="with-overlay"></div>
      }
      <StyledImgStack>

        <div
          onClick={() => swiperImgControl?.slidePrev()}
          className="swiper-button-prev"
        >
        </div>

        <div
          onClick={() => swiperImgControl?.slideNext()}
          className="swiper-button-next"
        >
        </div>
      </StyledImgStack>
    </StyledSwiper>

  );
}

export default GridActivity;

const StyledSwiper = styled(Swiper)`    
  .with-overlay:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    //background-image: linear-gradient(to right, rgba(0, 0, 0, 0),rgba(255, 255, 255, .8));
  }

  .unselected {
    filter: opacity(0.5);
  }

  .selected {
    filter: opacity(1);
  }
`;



const StyledImgStack = styled(Box)`    
  z-index: 3;

  .swiper-slide { 
    width: auto;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
  }
  
  /*.swiper-button-prev,
  .swiper-button-next {
      margin-top: -41px;
      height: 24px;        
      background: #F7F7F8;
      border-radius: 5px;
      color: black;
      
      &:after {
          font-size: 10px;
      }
  }*/

  .swiper-button-prev,
  .swiper-button-next {
      margin: 0px;
      top: 32px;
      width: 32px; 
      height: 32px;        
      background:#001AFF;
      border-radius: 4px;
      color: white;
      
      &:after {
          font-size: 10px;
      }
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
      opacity: 0;
  }
`;

const StyledTextLink = styled(Link)`
  color: black;
`;

const StyledCardLink = styled(Link)`
  overflow: hidden;
  border-radius: 4px;
  height: 100px;
  display: block;

  :hover {
    text-decoration: none;
  }
`;

const StyledWrapperBox = styled(Box)`
  position: relative;
  overflow: hidden;

  .showProgress {
    display: block !important;
  }
`;

const StyledLockedBox = styled(Box)`
  position: absolute;
  color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #00000080;
  padding: 2rem;
  z-index: 2;
`;

const FALLBACK_IMAGE =
  "/images/Logo_Transparent_White.png";
