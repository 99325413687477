import { Box, Button, FormControlLabel, Grid, ListItemText, MenuItem, Stack, useMediaQuery } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Typography from "../design-system/atoms/Typography";
import { theme } from "../utils/theme";
import { FlexSpacer } from "../design-system/atoms/FlexSpacer";
import { useKeycloak } from "@react-keycloak/web";
import styled from "@emotion/styled";
import { Logout } from "@mui/icons-material";
import SearchListBuildings from "../design-system/molecules/Searches/SearchListBuildings";
import { ISearchBuilding } from "../interfaces/building";
import CustomMenu from "../design-system/atoms/CustomMenu";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { WalletTable } from "../design-system/organisms/Tables/WalletTable";
import WalletCard from "../design-system/molecules/Cards/WalletCard";
import GridSliderWallet from "../design-system/organisms/Grids/GridSliderWallet";
import { TransitionGroup } from 'react-transition-group';
import { IOSSwitch } from "../design-system/atoms/IOSSwitch/IOSSwitch";
import ProgressSpinner from "../design-system/atoms/Buttons/ProgressSpinner";
import { getWalletBalance } from "../utils/functions";
import UserService from "../services/userService";
import { toast } from "react-toastify";

interface WalletProps {
    messengerOpen?: any;
    handleOpenModalBuyMUT?: any; 
    handleOpenModalAllowPopup?: any;
    handleOpenModalWalletSelector: any;
    stakeholderWallet?: any;
    setStakeholderWallet?: any;
    setBuildingWallet?: any;
    setWallets?: any;
    setWalletType: Function;
    blockchain: any;
    setUserInfo: any;
    userInfo: any;
}

const optionsMenu = [
    'Last event',
    'Next events',
    'Newest to oldest',
    'Oldest to newest',
    'Last modification'
];

const dataText = [
    { image: '/svg/images/wallet-img1.svg', headline: 'Manage your funds', text: 'Receive MUT from all your buildings in one place.' },
    { image: '/svg/images/wallet-img2.svg', headline: 'One click payment', text: 'Easily pay stakeholders for their services, without leaving the platform.' },
    { image: '/svg/images/wallet-img3.svg', headline: 'Earn money by vesting', text: 'Each MUT you earn on Magma will increase in value over time.' },
];

const Wallet: FC<WalletProps> = ({
    stakeholderWallet,
    setStakeholderWallet,
    messengerOpen,
    handleOpenModalBuyMUT,
    handleOpenModalAllowPopup,
    handleOpenModalWalletSelector,
    setBuildingWallet,
    setWallets,
    setWalletType,
    blockchain,
    setUserInfo,
    userInfo,
}) => {

    // const [cookie, updateCookie] = useCookie(true, 'isConnected');
    console.log("Wallet Page");
    const { keycloak } = useKeycloak();

    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [searchBuildingInput, setSearchBuildingInput] = useState("");
    const [buildingSearchList, setBuildingSearchList] = useState<ISearchBuilding[] | undefined>([]);
    const [searchBuilding, setSearchBuilding] = useState<ISearchBuilding>();

    const [isVisible, setIsVisible] = useState(false);
    const [loaded, setLoaded] = useState(false);
    // const [isSaved, setIsSaved] = useState(false);

    useEffect(() => {
        setBuildingWallet(undefined);
        fetchUserInfo();
        
    }, []);

    useEffect(() => {

    }, [userInfo]);

    const fetchUserInfo = () => {
        const userService = new UserService();
        userService.getUser(keycloak)
          .then(async(response) => {
            const userInfo = response;
            console.log("userInfo", userInfo);
            setUserInfo(userInfo);

            setStakeholderWallet({
                name: '',
                address: userInfo.wallet_address || '',
                balance: {
                    gasBalance: await getWalletBalance(blockchain, userInfo.wallet_address)
                }
            })
            setLoaded(true);
          })
          .catch(error => {
            console.log('error', error);
            if (error.response?.status === 401) {
              navigate('/');
              keycloak.logout();
            }
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.error(`Error: ${error}`)
          });
        //setLoading(false);
    }

    // Functions
    // Select Menu
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const selectOpen = Boolean(anchorEl);

    const handleSelectClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleSelectClose = () => {
        setAnchorEl(null);
    };

    const [inProp, setInProp] = useState(false);

    const handleConnectStakeholderWallet = async() => {
        console.log("connect stakeholder wallet", blockchain);

        /*if (!window.localStorage.getItem("hideModalAllowPopup")) {
            handleOpenModalAllowPopup();
        }*/

        const wallets = await blockchain.askPermissionForAccount();
        console.log("connectStakeholderWallet wallets", wallets);
    
        /* FORCE SHOWING THE WALLETSELECTOR, UNCOMMENT THE CODE BELOW  */
        if(wallets.length > 0){
            setWallets(wallets);
            if(setWalletType){
                setWalletType({
                    func: setStakeholderWallet,
                    type: 'stakeholder'    
                });
            }
            handleOpenModalWalletSelector();
        }
        /*
        if (wallets.length === 1) {
            setStakeholderWallet({
                name: '',
                address: wallets[0].address,
                balance: {
                    gasBalance: await getWalletBalance(blockchain, wallets[0].address)
                }
            })

            const data = {
                "user_ID": userInfo.ID,
                "wallet_chain": chain,
                "wallet_address": wallets[0].address
            }

            const userService = new UserService();
            userService.updateUserWallet(keycloak, data)
            .then((response) => {
                console.log(response);
                if(response.data.status){
                //setBuilding(building);
                toast.success("Stakeholder wallet has been updated!");
                }
                else {
                toast.error("Stakeholder wallet not updated, try again");
                }          
            })
            .catch((error) => {
                toast.error("Error updating stakeholder wallet, try again!")
    
                if (error.response.status === 401) {
                    navigate('/');
                    keycloak.logout();
                }            
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                console.error(`Error: ${error}`)
            });
        }
        else if(wallets.length > 1){
            setWallets(wallets);
            if(setWalletType){
                setWalletType(() => setStakeholderWallet);
            }
            handleOpenModalWalletSelector();
        }
        */
    }

    const handleDisconnectClick = async() => {
        setStakeholderWallet({
            name: '',
            address: '',
            balance: {
                gasBalance: 0
            }
        })

        const data = {
            "user_ID": userInfo.ID,
            "wallet_chain": '',
            "wallet_address": ''
        }

        const userService = new UserService();
        userService.updateUserWallet(keycloak, data)
        .then((response) => {
            console.log(response);
            if(response.data.status){
            //setBuilding(building);
            toast.success("Stakeholder wallet has been disconnected!");
            }
            else {
            toast.error("Stakeholder wallet not updated, try again");
            }          
        })
        .catch((error) => {
            toast.error("Error updating stakeholder wallet, try again!")

            if (error.response.status === 401) {
                navigate('/');
                keycloak.logout();
            }            
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.error(`Error: ${error}`)
        });
    };

    return (
        <>
        {loaded && (
            <StyledBox className="wallet-page" sx={{ width: '100%', maxWidth: 740 }}>
                {/* ===== HEADER ===== */}
                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    sx={{ paddingLeft: '.5rem' }}
                >
                    <Stack direction="row">
                        <Link to={`/`}
                            style={{
                                margin: '5px 0px -6px -10px',
                                color: theme.colors.text,
                                fontWeight: 'regular'
                            }}
                        >
                            <img src="/svg/icons/ArrowLeft.svg" alt="go back" width="32px" height="32px" />
                        </Link>
                        <Typography sx={{ paddingLeft: '.5rem' }} size='h2'>
                            Your Wallet
                        </Typography>
                    </Stack>

                    <FlexSpacer minWidth={1} />

                    {/* // TODO: Send connected State to application */}

                    {stakeholderWallet.address !== '' &&
                        <Stack
                            direction={isMobile ? 'column' : 'row'}
                            mt={isMobile ? 2 : 0}
                        >
                            <Button
                                variant="outlined"
                                sx={{
                                    marginTop: "auto",
                                    fontSize: ".7rem",
                                    borderColor: theme.colors.neutral4,
                                    color: theme.colors.neutral6,
                                }}
                                onClick={handleDisconnectClick}
                            >
                                <Logout fontSize="small" style={{ height: 22, margin: 6 }} />
                                Disconnect wallet
                            </Button>

                            <Button
                                variant="contained"
                                sx={{ margin: isMobile ? "8px 0" : "auto 0 0 .75rem", fontSize: ".7rem" }}
                                onClick={handleOpenModalBuyMUT}
                            >
                                <img
                                    src="/svg/icons/Wallet.svg"
                                    height="16px" width="16px"
                                    style={{ height: 22, margin: 6, filter: 'invert(1)' }}
                                    alt="bell icon"
                                />
                                Buy MUT
                            </Button>
                        </Stack>
                    }
                </Stack>
                {/* HEADER END */}

                <FlexSpacer minHeight={2} />

                {!stakeholderWallet.address ?
                    // WALLET DISCONNECTED STATE 
                    <Box width='100%'>
                        <Typography size="h3" mb={1}>
                            Connection to Magma Wallet
                        </Typography>

                        <Typography width={'60%'} mb={1.5}>
                            Magma works with Venly to safely and reliably manage stakeholders wallets on the platform.
                        </Typography>

                        <FlexSpacer minHeight={2} />

                        <Grid container
                            ml={0.5}
                            spacing={2}
                            sx={{
                                background: '#F2F4FF',
                                borderRadius: 4
                            }}
                        >
                            {dataText.map((item, index) =>
                                <Grid item xs={4} mt={5} key={index}>
                                    <img src={item.image} alt="pictogram" style={{ display: 'flex', margin: '0 auto' }} />

                                    <FlexSpacer minHeight={2} />
                                    <Stack direction="column" alignItems="center">

                                        <Typography size="h5" weight="medium">
                                            {item.headline}
                                        </Typography>

                                        <FlexSpacer minHeight={.5} />

                                        <Typography textAlign="center" maxWidth="95%">
                                            {item.text}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Stack alignContent="center" mt={5} width={'100%'}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        sx={{
                                            margin: "auto",
                                            fontSize: ".7rem",
                                            width: isMobile ? '100%' : 'auto',
                                            "&.MuiTouchRipple-root": {
                                                display: 'none'
                                            }
                                        }}
                                        onClick={handleConnectStakeholderWallet}
                                    >
                                        <img style={{ filter: 'invert(1)' }} src="/svg/icons/Wallet.svg" alt="" />

                                        <FlexSpacer minWidth={.5} />

                                        <Box>Connect my wallet</Box>

                                        {isVisible &&
                                            <ProgressSpinner />
                                        }
                                    </Button>
                                </Stack>
                            </Grid>

                            <Grid item
                                width='90%'
                                margin='auto'
                                mt={5}
                                mb={5}>
                                <Grid
                                    xs={12}
                                    container
                                    direction={isMobile ? 'column' : 'row'}
                                    rowSpacing={0}
                                    alignItems="center"
                                    spacing={1}
                                    style={{
                                    border: '1px solid #FFA500',
                                    borderRadius: '4px',
                                    backgroundColor: '#FFF7E6',
                                    padding: '12px',
                                    }}
                                >
                                    <Grid item xs={12} md={1}>
                                    <img
                                        alt="+"
                                        color="white"
                                        src="/svg/icons/Warning.svg"
                                        width={30}
                                        height={30}
                                        style={{
                                        filter:
                                            'brightness(0) saturate(100%) invert(58%) sepia(91%) saturate(799%) hue-rotate(359deg) brightness(101%) contrast(104%)',
                                        }}
                                    />
                                    </Grid>
                                    <Grid item xs={12} md={11} style={{ flex: 1 }}>
                                    <Typography weight="medium" style={{ color: '#FFA500' }}>
                                        Please be aware that when clicking the button, a Venly popup will open. If the popup does not appear, it is possible that your browser has blocked it. Please make sure to allow popups in your browser settings to proceed.
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    // WALLET CONNECTED STATE 
                    <TransitionGroup in={inProp} timeout={duration}>
                        <Box
                            sx={{
                                width: '100%',
                                maxWidth: '744px'
                            }}
                        >
                            <Grid container direction={isMobile ? 'column' : 'row'} mt={2}>
                                <Grid item sm={6}>
                                    <WalletCard
                                        wallet={stakeholderWallet}
                                        walletType='stakeholderWallet'
                                    />
                                </Grid>

                                <Grid item sm={6} hidden>
                                    <Box sx={{
                                        border: '1px solid #EBECEF',
                                        borderRadius: '4px',
                                        boxShadow: 'none',
                                        padding: '15px 10px',
                                        position: 'relative'
                                    }}
                                    >
                                        {/* TODO: Add applicable chart component  <Chart /> */}
                                    </Box>
                                </Grid>
                            </Grid>

                            <FlexSpacer minHeight={4} />

                            <label htmlFor="size">
                                <Stack direction="row"
                                    sx={{
                                        justifyContent: 'space-between'
                                    }}>
                                    <Typography sx={{ paddingLeft: '.2rem' }} size='h3' weight='regular'>
                                        Digital Twin Tokens
                                    </Typography>

                                    <Stack direction="row">
                                        {!isMobile &&

                                            <SearchListBuildings
                                                placeholder='Search through buildings'
                                                list={buildingSearchList}
                                                value={searchBuilding?.value}
                                                handleSearch={setSearchBuildingInput}
                                                setSearchBuilding={setSearchBuilding}
                                            />
                                        }
                                    </Stack>
                                </Stack>
                            </label>

                            <FlexSpacer minHeight={1} />

                            <Box sx={{
                                overflow: 'hidden'
                            }}>   
                                <GridSliderWallet
                                    userInfo={userInfo}
                                    blockchain={blockchain}
                                />
                            </Box>

                            <FlexSpacer minHeight={2} />

                            {/* //Table */}
                            <Box hidden>
                                <Stack direction="row" >
                                    <Typography size='h3'>
                                        Vesting
                                    </Typography>

                                    <FlexSpacer minWidth={1} />

                                    <Stack direction="row">

                                        {!isMobile &&
                                            <SearchListBuildings
                                                placeholder='Search by building, amount, date...'
                                                list={buildingSearchList}
                                                value={searchBuilding?.value}
                                                handleSearch={setSearchBuildingInput}
                                                setSearchBuilding={setSearchBuilding}
                                            />
                                        }
                                    </Stack>

                                    <Button
                                        size='small'
                                        id="basic-button"
                                        aria-controls={selectOpen ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={selectOpen ? 'true' : undefined}
                                        onClick={handleSelectClick}
                                        variant="outlined"
                                        sx={{
                                            borderColor: theme.colors.neutral4,
                                            color: theme.colors.neutral6,
                                            textTransform: 'inherit',
                                            fontWeight: '500',
                                            height: 36,
                                            marginLeft: '8px',
                                            "&:hover": {
                                                borderColor: theme.colors.neutral4,
                                            }
                                        }}
                                    >
                                        <ExpandMoreIcon />

                                        <ListItemText
                                            secondary={
                                                <Typography size="body" weight="medium">{optionsMenu[selectedIndex]}</Typography>
                                            }
                                        />

                                    </Button>

                                    <CustomMenu
                                        id="filter-menu-wallet"
                                        anchorEl={anchorEl}
                                        open={selectOpen}
                                        onClose={handleSelectClose}
                                    >
                                        {optionsMenu.map((item, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    // disabled={index === selectedIndex}
                                                    selected={index === selectedIndex}
                                                    onClick={(event: any) => handleMenuItemClick(event, index)}
                                                >
                                                    {item}
                                                </MenuItem>
                                            )
                                        })}
                                    </CustomMenu>
                                </Stack>

                                <FlexSpacer minHeight={2} />

                                <WalletTable />
                            </Box>
                        </Box>
                    </TransitionGroup>
                }
            </StyledBox>
        )}
        </>
    );
}

export default Wallet;

function customizeText(arg: any) {
    return `${arg.valueText} (${arg.percentText})`;
}

const StyledBox = styled(Box)`
   &.wallet-page {
    .swiper-button-prev,
    .swiper-button-next {
        background: #001AFF;
        
        &::after {
            color: white;
        }
    }
   }
`;

const duration = 300;
const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    padding: 20,
    display: "inline-block",
    backgroundColor: "#b3d0ff"
};
const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0 },
};