//https://blog.logrocket.com/implement-keycloak-authentication-react/
import Keycloak, { KeycloakConfig, KeycloakInstance } from "keycloak-js";

/*const config: KeycloakConfig = {
    url: 'https://magmaid.safetech.io/auth/',
    //url: 'http://localhost:8080/auth/',
    realm: 'master',
    clientId: 'magma'
}*/

const config: KeycloakConfig = {
    url: process.env.REACT_APP_KEYCLOAK_URL,
    //url: 'http://localhost:8080/auth/',
    realm: process.env.REACT_APP_KEYCLOAK_REALM!,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID!
}

const keycloak: KeycloakInstance = new (Keycloak as any)(config);

export default keycloak;