import React, { FC, useEffect, useState } from "react";
import { Box, Grid, Skeleton, useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import Typography from "../../../atoms/Typography";
import { IBuilding } from '../../../../interfaces/building';
import { theme } from "../../../../utils/theme";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { useSwiper } from 'swiper/react';
import "swiper/css/navigation";
import "swiper/css";
import 'react-toastify/dist/ReactToastify.css';
import BuildingService from "../../../../services/buildingService";
import { IUserInfo } from "../../../../interfaces/userInfo";
import { Blockchain } from "magma-sdk";

interface GridSliderWalletProps {
  userInfo: any
  blockchain: Blockchain
}

const GridSliderWallet: FC<GridSliderWalletProps> = ({
  userInfo,
  blockchain
}) => {
  
  console.log("GridSliderWallet2");
  const swiperImgControl = useSwiper();
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [buildings, setBuildings] = useState<IBuilding[] | undefined>(undefined);

  const [isLocked, setLocked] = useState(false)

  useEffect(() => {
    /* fix if the user is already logged in and coming from loginpage directly */
    if (!keycloak.authenticated) {
      navigate('/');
      keycloak.logout();
    }

    console.log("test userInfo", userInfo);

    fetchBuildingInfo(userInfo);
  }, [userInfo]);

  const fetchBuildingInfo = (userInfo: IUserInfo) => {
    console.log("DTT userInfo", userInfo);
    if (userInfo === undefined) {
        return;
    }

    const buildingService = new BuildingService();
    buildingService.getBuildings(keycloak, blockchain, userInfo)
    .then(async(response) => {
        const buildings: IBuilding[] = response;

        setBuildings(buildings);
    })
    .catch((error) => {
        if (error.response?.status === 401) {
            navigate('/');
            keycloak.logout();
        }
        console.log(error.response?.data);
        console.log(error.response?.status);
        console.log(error.response?.headers);
        console.error(`Error: ${error}`)
    });                
  }

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = FALLBACK_IMAGE;
    event.currentTarget.className = "error";
  };
  
  /*
  const fetchBuildingInfo = (userInfo: IUserInfo) => {
    console.log("fetchBuildingInfo");
    if (userInfo === undefined) {
      console.log("userInfo undefined");
      return
    }
    if (userInfo.permissions === undefined) {
      console.log("permissions undefined");
      const buildings: IBuilding[] = [];
      setBuildings(buildings);
      return;
    }

    var permissions = userInfo.permissions;
    var values = permissions.map(permission => permission.building_ID);

    const data = {
      "building_ID": values
    }

    const url = `${process.env.REACT_APP_API_URL}/building`;

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${keycloak.token}`
      },
      params: data
    };

    axios.get(url, config)
      .then((response) => {
        const buildings: IBuilding[] = response.data.result;
        for (var i = 0; i < buildings.length; i++) {
          const building: IBuilding = buildings[i];
          if (building.media) {
            const building_imgObj = building.media.find(media => media.mediatype === 'BUILDING_IMG');
            if (building_imgObj) {
              building.building_img_data = building_imgObj.mediabase64;
              building.building_img_mimetype = building_imgObj.mimetype;
            }
          }
        }
        console.log('buildings', buildings);
        setBuildings(buildings);
      })
      .catch(error => {
        if (error.response.status === 401) {
          navigate('/');
          keycloak.logout();
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.error(`Error: ${error}`)
      });
    //setLoading(false);
  }*/

  const [lastIndex, setLastIndex] = useState(false)

  const getIndex = (e: any) => {
    console.log('HERE INDEX', e.realIndex)

    if (e.realIndex >= 0) {
      setLastIndex(true)

      setTimeout(() => {
        setLastIndex(false)

      }, 1000);

    }

    if (e.realIndex >= 12) {
      setLastIndex(false)

    }
    console.log('TRUE INDEX', lastIndex)
  }
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [isActive, setIsActive] = useState(null);
  const toggleIsActive = (i: any) => {
    setIsActive(i);
  };

  return (
      <StyledSwiper
        className="mySwiper"
        onSlideChange={getIndex}
        modules={[Navigation, Pagination]}
        slidesPerView={isMobile ? 1 : 4}
        slidesPerGroup={isMobile ? 1 : 4}
        spaceBetween={10}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
      >

        {buildings !== undefined && buildings.length > 0 ? buildings.filter(building => building.dtt_address).map((item, index) => {
          return (
            <div key={`grid-dtt-${new Date().getTime() + Math.random()}`}>
              <SwiperSlide
                data-caption={item.building_name}
                key={item.building_name + index}
              >
                <StyledBox
                  onClick={() => setSelectedIndex(index)}
                  style={{ position: 'relative' }}
                  className={`key-div ${selectedIndex === index ? "active" : ""}`}
                >
                  <StyledWrapperBox>
                    <img
                      alt={item.building_name}
                      color="white"
                      className="building-img"
                      src={item.building_img_data !== undefined && item.building_img_data !== '' ? `data:${item.building_img_mimetype};base64,${item.building_img_data}` : '/images/building/Draft.png'}
                      onError={imageOnErrorHandler}
                      width={200}
                      height={140}
                    />

                    {isLocked ?
                      <StyledLockedBox>
                        <img
                          className="lock"
                          alt={item.building_name}
                          color="white"
                          src="/svg/icons/Lock.svg"
                          width={50}
                          height={50}
                        />

                        <Typography size="h6" weight="medium" sx={{ margin: '.5rem auto 0', maxWidth: 200 }}>
                          Sign the NDA to unlock the building
                        </Typography>
                      </StyledLockedBox>
                      : ''
                    }
                  </StyledWrapperBox>
                  <Typography size="h5" weight="bold" my={1}>{item.building_name}</Typography>
                  <Typography size="small" my={1}>Magma index: {Math.round(item.magma_index * 10) / 10}%</Typography>
                </StyledBox>

              </SwiperSlide>
            </div>
          )
        }) :
          <Grid
            container
            key={`wallet-${new Date().getTime() + Math.random()}`}
            sx={{ width: '100% !important', maxHeight: '253px' }}
            direction="row"
          >
            {[{}, {}, {}, {}].map((item,index) =>
              <Grid
                item
                key={index}
                sx={{ maxHeight: '250px', padding: '0 .5rem', width: '184px !important' }}
              >
                <>
                  <Skeleton
                    animation="wave"
                    width="100%"
                    height={167}
                    sx={{ borderRadius: 2 }}
                  />
                  <Skeleton
                    animation="wave"
                    height={40}
                    width="50%"
                    sx={{ borderRadius: 2 }}
                  />
                </>
              </Grid>
            )}
          </Grid>
        }


        {lastIndex ? '' :
          <div className="with-overlay"></div>
        }
        <StyledImgStack>

          <div
            onClick={() => swiperImgControl?.slidePrev()}
            className="swiper-button-prev"
          >
          </div>

          <div
            onClick={() => swiperImgControl?.slideNext()}
            className="swiper-button-next"
          >
          </div>
        </StyledImgStack>

      </StyledSwiper>
  );
}

export default GridSliderWallet;

/*const SwiperWrapper = styled(Box)`
  .swiper-slide {
    height: auto;
    cursor: pointer;
  }
`;*/

const StyledSwiper = styled(Swiper)`    
  .with-overlay:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    //background-image: linear-gradient(to right, rgba(0, 0, 0, 0),rgba(255, 255, 255, .8));
  }
`;

const StyledBox = styled(Box)`
  img {
    border-radius: 4px;
    object-fit: cover;
  }

  &.active {
    /*background: #F2F4FF;*/
    border-radius: 4px;
    height: 100%;
    margin: 0 -10px;
    padding: 0 10px;
  } 

  > .MuiBox-root {
    pointer-events: none; 
    overflow: hidden;
    border-radius: 4px;
    height: 140px;
  }
`;

const StyledWrapperBox = styled(Box)`
  margin-top: 10px;
  position: relative;
  overflow: hidden;

  .showProgress {
    display: block !important;
  }
`;

const StyledLockedBox = styled(Box)`
      position: absolute;
      color: white;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      background: #00000080;
      padding: 2rem;
      z-index: 2;
`;

const StyledImgStack = styled(Box)`    
  z-index: 3;

  .swiper-slide { 
    width: auto;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
    }
  
  /*.swiper-button-prev,
  .swiper-button-next {
      margin-top: -66px;
      height: 24px;        
      background: #F7F7F8;
      top: 54%;
      border-radius: 5px;
      color: black;
      
      &:after {
          font-size: 10px;
      }
  }*/

  .swiper-button-prev,
  .swiper-button-next {
      margin: 0px;
      top: 60px;
      width: 32px; 
      height: 32px;        
      background:#001AFF;
      border-radius: 4px;
      color: white;
      
      &:after {
          font-size: 10px;
      }
  }

  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
      opacity: 0;
  }
`;

const FALLBACK_IMAGE = "/images/Logo_Transparent_White.png"; 