import { Box, Grid, Stack } from '@mui/material'
import { FC } from 'react'
import { FlexSpacer } from '../../design-system/atoms/FlexSpacer'
import Typography from '../../design-system/atoms/Typography'

interface PrivacyPolicyProps {
    source?: any
}

const PrivacyPolicy: FC<PrivacyPolicyProps> = ({
    source,
}) => {
    const styles = {
        container: {
            width: source === 'public' ? 'calc(100vw - 20%)' : '100%', /* set the width using calc() and vw units */
            margin: source === 'public' ? '50px 10% 50px 10%' : '0', /* set equal margins on left and right using % units */ 
        },
        gridStyle: {
            borderTop: '1px solid #000',
            borderLeft: '1px solid #000',
        },
        gridItemStyle: {
            padding: '0px 15px 0px 15px',
            borderBottom: '1px solid #000',
            borderRight: '1px solid #000',
        }
    };

    return (
        <Stack>
            <Box style={styles.container}>
                <Typography size='h1'>MAGMA</Typography>
                <FlexSpacer minHeight={2} />
                <Typography size='h2'>Privacy Policy</Typography>
                <FlexSpacer minHeight={2} />

                <Box>
                    <Typography size='h4'>LAST UPDATED: October 19, 2023</Typography>
                    <FlexSpacer minHeight={2} />

                    <ol className='styledOrderList'>
                        <li>
                            <Typography paragraph weight='bold'>INTRODUCTION</Typography>
                            
                            <p>
                                MAGMA, a French société par actions simplifiée having its registered office at 127 rue Castagnary, 75015 Paris, registered with the Paris Trade and Companies Register under number 907 687 107, and represented by Matthieu Merchadou acting as Chief Executive Officer (Président), MAGMA contact email address: <a href='mailto: support@mymagma.com' target='_blank'>support@mymagma.com</a>, and its affiliates (hereinafter referred to as "<b>MAGMA</b>", the "<b>Company</b>", "<b>we</b>", "<b>us</b>" or "<b>our</b>") are committed to protecting and respecting your privacy (hereinafter referred to as "Users", "you" or "your").
                            </p>
                            <p>
                                This privacy policy (hereinafter referred to as "<b>Privacy Policy</b>") together with our legal notice, and general terms and conditions explain how MAGMA operates and how it may use your personal information (hereinafter referred to as "<b>Personal Information</b>" or "<b>Personal Data</b>") when Users access the website: <a href='https://app.mymagma.com' target='_blank'>https://app.mymagma.com</a> (hereinafter referred to as the "Site").
                            </p>
                            <p>   
                                Magma is a building enhancement tool designed to optimize your property's operations and generate value for your asset. For more information about the Company, the User is invited to consult the <a href='/terms-and-conditions' target='_blank'>Terms and Conditions</a>.
                            </p>
                            <p>
                                MAGMA, through the Services (defined in the general terms and conditions), may assist you in performing various tasks relating to real property, such as the creation of a digital asset, the Digital Twin Token (“<b>DTT®</b>”), representing a real estate asset.
                            </p>
                            <p>    
                                In this context, this Privacy Policy describes why and how we may collect and use Personal Information and provides information about individuals’ rights in relation to Personal Information.
                            </p>
                        </li>
                        <li>
                            <Typography paragraph weight='bold'>PURPOSE OF PRIVACY POLICY</Typography>
                            
                            <p>
                                The purpose of this Privacy Policy is to comply with General Data Protection Regulation (“<b>GDPR</b>”) and applicable regulation and to set out in an accountable and transparent way the collection and use of information by MAGMA.
                            </p>
                            <p>
                                MAGMA ensures and implements strong guarantees of both technological security and regulatory safety, especially regarding anti-money laundering, know-your-client procedures (“<b>KYC</b>”), Know Your Business procedures (“<b>KYB</b>”) and ID verification of accredited persons.
                            </p>
                        </li>
                        <li>
                            <Typography paragraph weight='bold'>ELIGIBILITY</Typography>
                            
                            <p>
                                MAGMA does not allow persons under the age of 18 to use the MAGMA Services and does not knowingly collect personal data from children under the age of 18.
                            </p>
                        </li>
                        <li>
                            <Typography paragraph weight='bold'>PERSONAL INFORMATION COLLECTION - PURPOSE - LEGAL BASIS</Typography>
                            
                            <p>
                                Personal Information is information which identifies you personally or by which your identity can reasonably be ascertained. The Personal Information which may be processed is listed bellow.
                            </p>
                            <p>
                                <FlexSpacer minHeight={1} />
                                
                                <Grid container style={styles.gridStyle} className=''>
                                    <Grid container item xs={12}>
                                        <Grid item xs={4} style={styles.gridItemStyle}>
                                            <p>
                                                <b>What personal data does MAGMA collect and process?</b>
                                            </p>
                                        </Grid>
                                        <Grid item xs={4} style={styles.gridItemStyle}>
                                            <p>
                                                <b>Why does MAGMA process my personal data?</b>
                                            </p>
                                        </Grid>
                                        <Grid item xs={4} style={styles.gridItemStyle}>
                                            <p>
                                                <b>Legal basis for our use of personal data</b>
                                            </p>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12}>
                                        <Grid item xs={4} style={styles.gridItemStyle}>
                                            <p>
                                                <ul>
                                                    <li>
                                                        First, last name and username,
                                                    </li>
                                                    <li>
                                                        email address,
                                                    </li>
                                                    <li>
                                                        telephone number,
                                                    </li>
                                                    <li>
                                                        gender,
                                                    </li>
                                                    <li>
                                                        nationality,
                                                    </li>
                                                    <li>
                                                        transactional information,
                                                    </li>
                                                    <li>
                                                        government-issued identification,
                                                    </li>
                                                    <li>
                                                        personal address,
                                                    </li>
                                                    <li>
                                                        date of birth,
                                                    </li>
                                                    <li>
                                                        and other information collected during MAGMA account registration,
                                                    </li>
                                                    <li>
                                                        information and documents on your building,
                                                    </li>
                                                    <li>
                                                        content, within any messages you send to us via our contact address: <a href='mailto: support@mymagma.com' target='_blank'>support@mymagma.com</a> (such as feedback, questions, job applications, etc.).
                                                    </li>
                                                </ul>
                                            </p>
                                        </Grid>
                                        <Grid item xs={4} style={styles.gridItemStyle}>
                                            <p>
                                                Transaction Services. We use your personal information to process your orders and to communicate with you about orders and services.
                                            </p>
                                            <p>
                                                Communicating with you. We use your personal information to communicate with you regarding MAGMA Services.
                                            </p>
                                            <p>
                                                We collect and process identity data to comply with our know-yourcustomer obligations.
                                            </p>
                                        </Grid>
                                        <Grid item xs={4} style={styles.gridItemStyle}>
                                            <p>
                                                <b>Fulfillment of a contract</b> when we provide products or services to you, or communicate with you about them. This includes where we use your personal data to take and process orders, and to process payments.
                                            </p>
                                            <p>
                                                <b>Legal obligation</b>, to comply with our legal obligations under applicable laws and regulations, as well as anti-money laundering laws and regulations.                                                
                                            </p>
                                            <p>
                                                <b>Your consent</b> where we ask for your consent to process your Personal Data for a specific purpose that we communicate to you. Where you consent to MAGMA processing your Personal Data for a specific purpose, you may withdraw your consent at any time and we will stop processing your Personal Data for that purpose. Withdrawal of consent does not affect the lawfulness of the processing based on the consent prior to its withdrawal.
                                            </p>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12}>
                                        <Grid item xs={4} style={styles.gridItemStyle}>
                                            <p>
                                                <ul>
                                                    <li>
                                                        the Internet Protocol (IP) address used to connect your computer to the Internet,
                                                    </li>
                                                    <li>
                                                        login, email address, password and location of your device or computer,
                                                    </li>
                                                    <li>
                                                        audience and technical measurements of the MAGMA Services (e.g., occurrences of technical errors, your interactions with the features and content of the Services, and your preferences for settings),
                                                    </li>
                                                    <li>
                                                        version and time zone settings,
                                                    </li>
                                                    <li>
                                                        transaction history,
                                                    </li>
                                                    <li>
                                                        information from other sources.
                                                    </li>
                                                </ul>
                                            </p>
                                        </Grid>
                                        <Grid item xs={4} style={styles.gridItemStyle}>
                                            <p>
                                                To provide, troubleshoot and improve the MAGMA Services. We use your personal data to provide functionality, analyze performance, correct errors, and improve the usability and efficiency of the MAGMA Services.
                                            </p>
                                            <p>
                                                Fraud prevention and credit risks. We process personal data to prevent and detect fraud and abuse in order to protect the safety of our Users, the MAGMA Services and others. We may also use scoring methods to assess and manage credit risk.
                                            </p>
                                        </Grid>
                                        <Grid item xs={4} style={styles.gridItemStyle}>
                                            <p>
                                                <b>Legitimate interest</b> and the interests of our Users when, for example, we detect and prevent fraud and abuse to protect the safety of our Users, ourselves or others.
                                            </p>
                                            <p>
                                                <b>Fulfillment of a contract</b> when we provide products or services to you, or communicate with you about them. This includes where we use your personal data to take and process orders, and to process payments.
                                            </p>
                                            <p>
                                                <b>Legal obligation</b> to comply with our legal obligations under applicable laws and regulations, as well as anti-money laundering laws and regulations.
                                            </p>
                                            <p>
                                                <b>Legitimate interest</b> and the interests of our Users when, for example, we detect and prevent fraud and abuse in order to protect the safety of our Users, ourselves or others;
                                            </p>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12}>
                                        <Grid item xs={4} style={styles.gridItemStyle}>
                                            <p>
                                                <ul>
                                                    <li>
                                                        information about your activity: we may process information about your behavior and activity for marketing and advertising purposes.
                                                    </li>
                                                </ul>
                                            </p>
                                        </Grid>
                                        <Grid item xs={4} style={styles.gridItemStyle}>
                                            <p>
                                                To improve our services. We process Personal Data in order to improve our services and provide you with a better User experience. 
                                            </p>
                                            <p>
                                                Recommendations and Personalization. We use your Personal Information to recommend features and services that may be of interest to you, to identify your preferences and to personalize your experience with the MAGMA Services.
                                            </p>
                                        </Grid>
                                        <Grid item xs={4} style={styles.gridItemStyle}>
                                            <p>
                                                <b>Our legitimate interest</b> in improving our services. 
                                            </p>
                                            <p>
                                                <b>Your consent</b> where we ask for your agreement to process your personal information for a specific purpose that we communicate to you. Where you consent to process your personal information for a specific purpose, you may withdraw your consent at any time and we will stop processing your personal information for that purpose. Withdrawal of consent does not affect the lawfulness of the processing based on the consent prior to its withdrawal.
                                            </p>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </p>
                            <p>
                                <b>Blockchains provide transparency into transactions and MAGMA is not responsible for preventing or managing information broadcasted on a blockchain.</b>
                            </p>
                            <p>
                                <u>Important notice:</u> Given the use of services offered via the Site, the Personal Information of <a href='https://app.mymagma.com' target='_blank'>https://app.mymagma.com</a> may be processed and stored within the blockchains used for the processing of digital asset transactions. Therefore, the User is informed that, in any event, such processing is not the responsibility of MAGMA, which does not manage nor control said blockchains, and as such does not have any power of determination nor control over the possible processing of Personal Information which may be implemented in connection with said blockchains.
                            </p>
                        </li>
                        <li>
                            <Typography paragraph weight='bold'>PROTECTION AND USE OF PERSONAL INFORMATION</Typography>
                            
                            <p>
                                MAGMA is committed to protecting your privacy. Internally, only a specified number of employees within our business have access to your Personal Information.
                            </p>
                            <p>
                                MAGMA’s systems and data are constantly under review to ensure that you are getting the best access and that market borrowing and lending security features are in place.
                            </p>
                            <p>
                                MAGMA reserves the right to retain and share certain Personal Information in order to meet our regulatory and statutory requirements. In addition, MAGMA reserves the right to retain and share certain Personal Information with our corporate partners, and third parties acting on behalf of MAGMA.
                            </p>
                            <p>
                                Personal Information and other related data may be exported outside of the jurisdiction in which you reside. Your Personal Information may be processed and stored in a foreign country or countries. Under those circumstances, the governments, courts, law enforcement or regulatory agencies of that country or those countries may be able to obtain access to your Personal Information through foreign laws. You need to be aware that the privacy standards of those countries may be lower than those of the jurisdiction in which you reside.
                            </p>
                        </li>
                        <li>
                            <Typography paragraph weight='bold'>ACCESS AND CHANGING OF PERSONAL INFORMATION</Typography>
                            
                            <p>
                                You have the right to access the Personal Information we hold about you, and to require the correction, updating and blocking of inaccurate and/or incorrect data by sending an email to us. We will aim respond to your request within 14 days. You may also request the deletion or destruction of your Personal Information, your account details, etc.
                            </p>
                            <p>
                                MAGMA will act on your request only when it is not inconsistent with its legal and regulatory obligations and compliance procedures. Upon your written request, we will inform you of the use and general disclosure of your Personal Information. 
                            </p>
                            <p>
                                <u>Important notice:</u> The exercise of these rights is nevertheless limited, with regard to Personal Information that may be necessary for the operation of a blockchain. Indeed, this Data required for the operation of blockchains cannot be modified or erased during the lifetime of the blockchain in question. In addition, the retention / storage of said Data in said blockchains is not the responsibility of MAGMA, which does not manage nor control said blockchains, and therefore has no power to determine or control the processing of Personal Information which may be implemented in connection with said blockchains, nor to pass on the exercise by Users of their rights to said blockchains.
                            </p>
                        </li>
                        <li>
                            <Typography paragraph weight='bold'>RIGHTS</Typography>
                            
                            <p>
                                You benefit, under the terms and conditions and within the limits defined by the legal and regulatory provisions on the protection of Personal Information, the following rights with regard to your Data:
                            </p>
                            <p>
                                <ul className='styledUnorderedList'>
                                    <li>
                                        <p>
                                            <b>Right of access:</b> you can obtain confirmation whether or not Personal Information concerning you is processed by the Company and, when they are, access to said Personal Information, as well as certain information relating to the processing of your Personal Information and the characteristics of such processing;
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Right of rectification:</b> you can request the correction of your Personal Information that you consider incomplete or inaccurate;
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Right to erasure:</b> you can, in certain cases, request the erasure of your Personal Information (except for example if they are necessary for the execution of your contractual relations with the Company where applicable, or if they are necessary for the Company to comply with its legal or regulatory obligations or to ascertain or exercise its rights);
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Right to limitation of processing:</b> you can request limitation of processing of your Personal Information, allowing you to request in certain cases the marking of your Personal Information in order to limit future processing;
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Right to the portability of your Personal Information:</b> you have the right in certain cases and under certain conditions to request to receive the Personal Information concerning you that you have provided to us or, when technically possible, to they are transferred to a third party, in a machine-readable form (it being specified that this right to data portability only applies to processing based on the consent of the Persons concerned or on the execution of contractual relations, and this provided that the Data processing is carried out using automated processes);
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Right to withdraw your consent:</b> you can withdraw your consent if the processing is carried out on the basis of your consent, without the withdrawal of such consent prejudicing the lawfulness of the processing based on the consent made before the withdrawal of it;
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b>Right to define guidelines for the retention, erasure or communication of your Personal Information after your death:</b> in this regard, in the event of death which would be brought to our attention, please be aware that your Personal Information will in principle be deleted (unless it is necessary to keep it for a specified period for reasons relating to our legal and regulatory obligations), after having been communicated to a third party possibly designated by you.
                                        </p>
                                    </li>
                                </ul>
                            </p>
                            <p>
                                Regarding the processing of your Personal Information implemented by the Company, these rights are exercised:
                            </p>
                            <p style={{marginLeft: '20px'}}>
                                <ol type='i'>
                                    <li>
                                        by email to the following email address: <a href='mailto: support@mymagma.com' target='_blank'>support@mymagma.com</a> or,
                                    </li>
                                    <li>
                                        by post to the address next: 127 rue Castagnary, 75015 Paris.
                                    </li>
                                </ol>
                            </p>
                        </li>
                    </ol>

                </Box>
            </Box>
        </Stack>
    )
}

export default PrivacyPolicy;