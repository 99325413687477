import { FC, useEffect, useState } from 'react'
import { Button, Grid, Stack, useMediaQuery } from '@mui/material'
import { theme } from '../../../../utils/theme';
import { FlexSpacer } from '../../../atoms/FlexSpacer';
import Typography from '../../../atoms/Typography';
import styled from '@emotion/styled';
import { DttAccordion } from './DttAccordion';
import DttSliderLevels from './DttSliderLevels';
import { BigNumber } from 'magma-sdk';
import BuildingService from '../../../../services/buildingService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { DOCUMENT_STATUS_ADDED, DOCUMENT_STATUS_UPDATED, DOCUMENT_STATUS_VALIDATED, IMedia } from '../../../../interfaces/media';
import UserService from '../../../../services/userService';
import { etherToBigNumber, getWalletBalance } from '../../../../utils/functions';
import { IDTTMetaData, tierData } from '../../../../interfaces/dtt';

interface DttMintReviewProps {
    handleOpenModalNft?: any,
    handleOpenModalNftLevel?: any,
    setIsMinted?: any,
    isMinted?: any,
    isUpgraded?: any,
    setIsUpgraded?: any,
    blockchain?: any,
    building?: any,
    keycloak?:any,
    userTypeDocs: any,
    buildingDocs: IMedia[] | undefined,
    userInfo: any,
    myCallBack: any,
    userTypes: any,
    setBuildingID: any,
    setBuilding: any,
    buildingWallet: any,
    setBuildingWallet: any,
}

export const DttMintReview: FC<DttMintReviewProps> = ({
    handleOpenModalNft,
    handleOpenModalNftLevel,
    setIsMinted,
    isMinted,
    isUpgraded,
    setIsUpgraded,
    blockchain,
    building,
    keycloak,
    userTypeDocs,
    buildingDocs,
    userInfo,
    myCallBack,
    userTypes,
    setBuildingID,
    setBuilding,
    buildingWallet,
    setBuildingWallet
}) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const [mintingCostData, setMintingCostData] = useState<{
        cost: number,
        amountOfStakeholders: number,
        gasFeeCost: number,
        gasFeeMaxCost: number,
    }>({
        cost: 0,
        amountOfStakeholders: 0, 
        gasFeeCost: 0,
        gasFeeMaxCost: 0,
    });
    const [buildingDTT, setBuildingDTT] = useState();

    const [dttMetaData, setDTTMetaData] = useState<IDTTMetaData | undefined>(undefined);
    const [indexLevels, setIndexLevels] = useState<Array<{
        percentageValidate: number,
        percentageValidated: number,
        upToDate?: boolean
    }>>(Array(5).fill({
        percentageValidate: 0,
        percentageValidated: 0,
        upToDate: true,
    }));
    /*
    DTT MetaData: {
        "address": "0x5f24ea9954f31447ffd94e0f8b30aa3f3c942cb6",
        "hashAdministrator": "0xcb2721d5f1e88ea0872550046fe4a1f15e40624c",
        "hashStorageContract": "0x6f6203bf45c4174052e6958e572f1d6232001b70",
        "level": "0",
        "levelAdministrator": "0xcb2721d5f1e88ea0872550046fe4a1f15e40624c",
        "tokenAdministrator": "0xcb2721d5f1e88ea0872550046fe4a1f15e40624c",
        "uri": "https://dtt.mymagma.com/"
    }
    */

    const [processing, setProcessing] = useState(false);
    const [process_name, setProcessName] = useState('');

    useEffect(() => {        
        const fetchData = async () => {
            console.log('BUILDING', building);
            if (building !== undefined) {
                console.log('setBuildingDTT in useEffect', building.dtt_address);
                setBuildingDTT(building.dtt_address);
            }
        };
    
        fetchData();
    }, [building]);

    useEffect(() => {
        if(!buildingDTT)
            return;
        getDTTMetaData(buildingDTT);
    }, [buildingDTT])

    useEffect(() => {
        if(!buildingDocs || !userTypeDocs)
            return;
        calculateLevels();
    }, [dttMetaData, buildingDocs, userTypeDocs])

    useEffect(() => {
        calculateMintingCostData();
    },[buildingDocs])

    useEffect(() => {
        console.log("update indexLevels");
    }, [indexLevels])

    async function handleStakeholdersPayments() {
        console.log('handleStakeholdersPayments');

        if(buildingDocs === undefined) return true;

        let allValidatedDocsPaid = true;
        const buildingDocsToPay = buildingDocs.filter(b => b.versions[0].status === DOCUMENT_STATUS_VALIDATED && b.versions[0].payment === undefined);

        for(let i=0; i<buildingDocsToPay.length; i++){
            setProcessName(`Paying stakeholders (${i+1}/${buildingDocsToPay.length})`);

            /*if(i > 0){
                console.log("before promise", new Date(Date.now()).toLocaleString());
                await new Promise((resolve) => setTimeout(resolve, 15000));
                console.log("after promise", new Date(Date.now()).toLocaleString());
            }*/            
            
            const buildingDoc: IMedia = buildingDocsToPay[i];

            const buildingDocAddedActivity = buildingDoc?.versions[0].activity?.find(a => [DOCUMENT_STATUS_ADDED, DOCUMENT_STATUS_UPDATED].includes(a.action));
            const stakeholder = {
                ID: buildingDocAddedActivity?.user_ID,
                name: buildingDocAddedActivity?.firstname + ' ' + buildingDocAddedActivity?.lastname,
                wallet_address: buildingDocAddedActivity?.wallet_address,
            }
            console.log(`Payment for buildingDoc #${buildingDoc.versions[0].media_ID} for 5 MUT tokens to ${stakeholder.name} #${stakeholder.ID} with wallet_address ${stakeholder.wallet_address}`);

            if(!stakeholder.wallet_address){
                allValidatedDocsPaid = false;
                toast.error(`Stakeholder (${stakeholder.name}) not have a connected wallet`);
                return;
            }

            const magmaToken = process.env.REACT_APP_VECHAIN_MUT_ADDRESS;
            const magmaTokenInstance = await blockchain.getInstanceOfMagmaToken(magmaToken);
            const paymentFee = 5;
            
            console.log('transfer buildingWallet.address', buildingWallet.address);
            console.log('transfer stakeholder.wallet_address', stakeholder.wallet_address);
            console.log('transfer etherToBigNumber(paymentFee)', etherToBigNumber(paymentFee));

            const magmaTokenTransferTx = await magmaTokenInstance.transfer(
                buildingWallet.address,
                stakeholder.wallet_address,
                etherToBigNumber(paymentFee)
            );

            const magmaTokenTransferReceipt = await blockchain.getReceipt(magmaTokenTransferTx);

            console.log("magmaTokenTransferReceipt:", magmaTokenTransferReceipt);

            if(magmaTokenTransferReceipt.reverted === true){
                console.log("TRANSACTION FAILED");
                throw new Error('Stakeholder payment failed');
            }
            else {
                console.log("TRANSACTION SUCCESS");
            }

            console.log("after receipt check");

            if(building.wallet_address !== undefined &&
                building.wallet_address !== null &&
                building.wallet_address !== '')
            {
                setBuildingWallet({
                  name: building.building_name,
                  address: building.wallet_address,
                  balance: {
                      gasBalance: await getWalletBalance(blockchain, building.wallet_address)
                  }                      
                });
            }

            const data = {
                "user_ID": userInfo.ID,
                "ref": `USER#${stakeholder.ID}_MEDIA_VERSION#${buildingDoc.versions[0].ID}`,
                "amount": paymentFee,
                "token": 'MUT',
                "tx": magmaTokenTransferTx,
                "status": magmaTokenTransferReceipt.reverted ? 2 : 1,
            }
    
            const userServiceObj = await new UserService();
            await userServiceObj.createUserPayment(keycloak, data)
            // eslint-disable-next-line no-loop-func
            .then((response) => {
                console.log(response);
                if(response.data.status){
                    buildingDoc.versions[0].payment = [data];
                    toast.success("Stakeholder payment has been created!");
                }
                else {
                    allValidatedDocsPaid = false;
                    toast.error("Stakeholder payment not created, try again");
                }          
            })
            // eslint-disable-next-line no-loop-func
            .catch((error) => {
                allValidatedDocsPaid = false;
                //toast.error("Error creating building payment, try again!")
                if (error.response.status === 401) {
                    navigate('/');
                    keycloak.logout();
                }            
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                console.error(`Error: ${error}`)
            });

            /*const data2 = {
                "ID": 1,
                "tx": 'tx',
                "status": 2,
            }
    
            userServiceObj.updateUserPayment(keycloak, data2)
            .then((response) => {
                console.log(response);
                if(response.data.status){
                    toast.success("User payment has been created!");
                }
                else {
                    toast.error("User payment not created, try again");
                }          
            })
            .catch((error) => {
                //toast.error("Error creating building payment, try again!")
    
                if (error.response.status === 401) {
                    navigate('/');
                    keycloak.logout();
                }            
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                console.error(`Error: ${error}`)
            });*/

            //return true;
        }

        return allValidatedDocsPaid;
    }

    async function checkAllStakeholdersPaid() {
        console.log('checkAllStakeholdersPaid');

        let allValidatedDocsPaid = true;

        if(buildingDocs === undefined) return true;

        for(let i=0; i<buildingDocs.length; i++){
            console.log('check buildingDocs[i]', buildingDocs[i]);

            console.log('buildingDocs[i].versions[0].status', buildingDocs[i].versions[0].status, 'DOCUMENT_STATUS_VALIDATED', DOCUMENT_STATUS_VALIDATED);

            if(buildingDocs[i].versions[0].status !== DOCUMENT_STATUS_VALIDATED) continue;

            console.log('buildingDocs[i] 1 allValidatedDocsPaid', allValidatedDocsPaid);

            //test if validated doc have a payment
            if(buildingDocs[i].versions[0].payment === undefined){
                allValidatedDocsPaid = false;
            }

            console.log('buildingDocs[i] 2 allValidatedDocsPaid', allValidatedDocsPaid);
        }

        console.log('allValidatedDocsPaid', allValidatedDocsPaid);

        if(!allValidatedDocsPaid) {
            return await handleStakeholdersPayments();
        }

        return true;
    }

    async function checkHashesToDTT(_buildingDTT: string){
        if(buildingDocs === undefined) return true;

        console.log('checkHashesToDTT 1');

        const buildingDocsHash = buildingDocs.filter(b => b.versions[0].status === DOCUMENT_STATUS_VALIDATED && b.versions[0].hash !== null && b.versions[0].hash !== '');

        console.log('checkHashesToDTT 2');

        if(buildingDocsHash.length === 0){
            console.log('no hashes to add');
            return;
        }

        console.log('checkHashesToDTT 3');

        const hashStorageResult = await getHashStorage(_buildingDTT);
        console.log('hashStorageResult', hashStorageResult);
        const hashesArr = hashStorageResult.hashes;
        
        console.log('checkHashesToDTT 4');

        for(let i=0; i<buildingDocsHash.length; i++){
            setProcessName(`Add document hashes (${i+1}/${buildingDocsHash.length})`);
            
            const buildingDoc: IMedia = buildingDocsHash[i];
            const hash = buildingDoc.versions[0].hash;

            const hashExists = hashesArr.find((h: any) => h.hash === hash);

            if (hash !== undefined && !hashExists) {
                console.log(`${hash} is not in the array`);
                console.log('addHash', buildingDoc.ID, hash);
                await addHashToDTT(_buildingDTT, hash);
            } else {
                console.log(`${hash} is in the array`);
            }
        }

        console.log('end of addHashesToDTT');
    }

    async function getHashStorage(_buildingDTT: string){
        console.log('getHashStorage', _buildingDTT)
        
        const dtt = await blockchain.getInstanceOfDigitalTwinToken(_buildingDTT);

        const hashStorage = await blockchain.getInstanceOfHashStorage(await dtt.hashStorageContract());

        const hashStorageResult = await hashStorage.getMetaData(1);
        
        return hashStorageResult;
    }

    async function addHashToDTT(_buildingDTT: string, _hash: string){
        console.log('handleAddHash');

        if(!askPermissionForAccount()){
            return;
        }
        
        const dtt = await blockchain.getInstanceOfDigitalTwinToken(_buildingDTT);

        const hashStorage = await blockchain.getInstanceOfHashStorage(await dtt.hashStorageContract());

        const newTokenId = BigNumber.from("1");

        const response = await hashStorage.addHash(buildingWallet.address, newTokenId, _hash);

        console.log('addHash', response);

        //getHashStorageHashMetaData(newTokenId);
    }

    async function askPermissionForAccount(): Promise<any>{
        console.log("askPermissionForAccount");
        return new Promise(async (resolve) => {
            const wallets = await blockchain.askPermissionForAccount();
        
            console.log('handleMint', wallets);

            const addressFound = wallets.find((wallet: any) => wallet.address === buildingWallet.address);

            if(addressFound){
                console.log('address found');

                resolve(true);
            }
            else{
                console.log("address not found");
                toast.error("Building wallet not found, try again");
                await blockchain.logout();

                resolve(false);
            }
        })
    }

    async function handleMint() {
        setProcessing(true);
        setProcessName('Initiating');

        try{
            if(!await askPermissionForAccount()){
                console.log('cancel mint');
                setProcessing(false);
                return;
            }
            else{
                console.log('start mint');
            }

            setProcessName('Paying stakeholders');
            if(!await checkAllStakeholdersPaid()){
                setProcessing(false);
                toast.error("Building DTT® not minted, solve the stakeholder payments first");
                return false;
            }

            if(buildingDTT !== undefined && buildingDTT !== null){
                setProcessName('Add document hashes');
                await checkHashesToDTT(buildingDTT!);

                setProcessName('Set DTT® Level');
                await handleSetLevel(buildingDTT, indexLevels[0].percentageValidated + indexLevels[0].percentageValidate);

                await getDTTMetaData(buildingDTT);

                if (myCallBack){
                    myCallBack();
                }

                setProcessing(false);
            }
            else{
                console.log("handleMint");

                const factoryAddress = process.env.REACT_APP_VECHAIN_DTT_FACTORY_ADDRESS;
                console.log('factoryAddress',factoryAddress);

                setProcessName('Minting DTT®');
                const factory = await blockchain.getInstanceOfDigitalTwinTokenFactory(factoryAddress);

                console.log('factory.getContractCount', await factory.getContractCount());

                const dtt = await factory.newToken(buildingWallet.address, "https://dtt.mymagma.com/", buildingWallet.address, buildingWallet.address, buildingWallet.address, buildingWallet.address);

                console.log('dtt', dtt);
                building.dtt_address = dtt.address;
                setBuildingDTT(dtt.address);
                console.log('BuildingDTT', buildingDTT);
                console.log('building',building);
                console.log('setBuildingDTT in handleMint building.dtt_address',building.dtt_address);
                setIsMinted(true);

                const data = {
                    "building_ID": building.ID,
                    "dtt_address": dtt.address,
                    "status": 3
                }

                const buildingService = new BuildingService();
                buildingService.updateBuildingFields(keycloak, data)
                    .then(async(response) => {
                        console.log(response);
                        if(response.data.status){
                            //setBuilding(building);
                            toast.success("Building DTT® address has been updated!");
                            setIsMinted(true);

                            console.log('before addHashes');
                            setProcessName('Hashing documents');
                            await checkHashesToDTT(dtt.address);
                            console.log('after addHashes');

                            //set Genesis level TODO
                            console.log('before handleSetLevel');
                            setProcessName('Set DTT® Level');
                            await handleSetLevel(dtt.address, indexLevels[0].percentageValidate);
                            console.log('after handleSetLevel');
                            
                            setBuildingID(building.ID);
                            setBuilding(building);
                            handleOpenModalNftLevel();

                            await getDTTMetaData(dtt.address);

                            if (myCallBack){
                                myCallBack();
                            }

                            setProcessing(false);
                        }
                        else {
                            toast.error("Building DTT® address not updated, try again");
                        }          
                    })
                    .catch((error) => {
                        setProcessing(false);
                        toast.error("Error updating building wallet, try again!")

                        if(error.response){
                            if (error.response.status === 401) {
                                navigate('/');
                                keycloak.logout();
                            }            
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        }
                        console.error(`Error: ${error}`)
                    });
            }
        }
        catch(e){
            console.log('error',e);
            
            if (myCallBack){
                myCallBack();
            }

            setProcessing(false);
        }

        //getDTTMetaData();
    }

    async function openModalNftLevel(){
        handleOpenModalNftLevel();
    }

    async function getDTTMetaData(_buildingDTT: string){
        console.log("GET DTTMETADATA", _buildingDTT);
        if(!_buildingDTT)
            return;
        
        const dtt = await blockchain.getInstanceOfDigitalTwinToken(_buildingDTT);
        const tmpDTTMetaData: any = await dtt.getMetaData();
        const dttMetaData: IDTTMetaData = {
            address: tmpDTTMetaData.address,
            hashAdministrator: tmpDTTMetaData.hashAdministrator,
            hashStorageContract: tmpDTTMetaData.hashStorageContract,
            level: [tmpDTTMetaData.level, 0, 0, 0, 0],
            levelAdministrator: tmpDTTMetaData.levelAdministrator,
            tokenAdministrator: tmpDTTMetaData.tokenAdministrator,
            uri: tmpDTTMetaData.uri,
        };

        console.log('DTT MetaData', dttMetaData);
        setDTTMetaData(dttMetaData);
    }

    async function handleSetLevel(_buildingDTT: string, dttNewLevel: number){
        console.log('handleSetLevel dttNewLevel:', _buildingDTT, dttNewLevel);

        if(!askPermissionForAccount()){
            return;
        }

        const dtt = await blockchain.getInstanceOfDigitalTwinToken(_buildingDTT);
        const dttCurrentLevel = await dtt.level();

        console.log('get current level', dttCurrentLevel);

        //const dttNewLevel: number = parseInt(dttCurrentLevel) + 1;
        const tempDttNewLevel = Math.round(dttNewLevel);

        const response = await dtt.setLevel(buildingWallet.address, tempDttNewLevel.toString());

        console.log('setLevel response', response);

        console.log('dtt new level', dttNewLevel);

        //getDTTMetaData();
    }

    /*async function handleMintHashContract(){
        console.log('handleMintHashContract');

        if(!askPermissionForAccount()){
            return;
        }

        const dtt = await blockchain.getInstanceOfDigitalTwinToken(buildingDTT);

        const newTokenId = BigNumber.from("1"); // Incremented number based on the child tokens.
        const amount = BigNumber.from("1"); // This is the supply of child tokens, so if it is meant to be an NFT, use a quantity of 1. If it is meant to be like an ERC20, use a big number for total supply.

        const response = await dtt.mint(buildingWallet.address, buildingWallet.address, newTokenId, amount);

        console.log('mint HashStorage', response);

        //getHashStorageMetaData();
    }*/

    /*async function handleAddHash(hash: string){
        if(buildingDTT !== undefined && buildingDTT !== null) {
            addHashToDTT(buildingDTT, hash);
        }
    }*/

    /*async function getHashStorageMetaData(){
        const dtt = await blockchain.getInstanceOfDigitalTwinToken(buildingDTT);

        const hashStorage = await blockchain.getInstanceOfHashStorage(await dtt.hashStorageContract());

        console.log('HashStorage MetaData', await hashStorage.getMetaData());
    }*/

    /*async function getHashStorageHashMetaData(newTokenId: BigNumber, index?: number){
        const dtt = await blockchain.getInstanceOfDigitalTwinToken(buildingDTT);

        const hashStorage = await blockchain.getInstanceOfHashStorage(await dtt.hashStorageContract());

        if(index === undefined){
            console.log('HashStorage MetaData', await hashStorage.getMetaData(newTokenId));
        }
        else{
            console.log('HashStorage MetaData', await hashStorage.getMetaData(newTokenId, index));
        }        
    }*/

    /*async function handleReadHashes(){
        if(buildingDTT !== undefined && buildingDTT !== null) {
            const hashStorageResult = await getHashStorage(buildingDTT);

            setDTTMetaData(hashStorageResult);
        }
    }*/


    function handleUpgrade() {
        //setIsUpgraded(true)
        console.log('upgrade DTT');
        //handleSetLevel();

        handleMint();
    }

    /*function handleSee() {
        window.open('https://forge-rcdb.autodesk.io/configurator?id=5a7185a92a0a7aeb152725d5', '_blank')
    }*/

    /*console.log('blockchain', blockchain);
    console.log('TEST');
    let magmatokenPromise = blockchain.getInstanceOfMagmaToken('0x0a7815E74b9E92Bb2F74814c07473763672dF2f3');
    console.log('0x0a7815E74b9E92Bb2F74814c07473763672dF2f3', magmatokenPromise);
    magmatokenPromise.then(
        async (magmatokenInstance: any) => {
            const magmaTokenSupply = await magmatokenInstance.totalSupply();
            console.log('magmaTokenSupply', magmaTokenSupply.toString());

            let magmaTokenBalance = await magmatokenInstance.balanceOf('0x0F486936cC33559fB4Dc5cD50DD48804938010Cd');
            console.log('magmaTokenBalance 0x0F486936cC33559fB4Dc5cD50DD48804938010Cd', magmaTokenBalance.toString());

            magmaTokenBalance = await magmatokenInstance.balanceOf('0xCB2721d5F1E88Ea0872550046fe4a1f15E40624C');
            console.log('magmaTokenBalance 0xCB2721d5F1E88Ea0872550046fe4a1f15E40624C', magmaTokenBalance.toString());
        }
    )*/

    const allowMint = () => {
        if(buildingDocs === undefined || userTypeDocs === undefined) return false;
        
        /*let percentageValidated = buildingDocs.reduce((total_weight: number, buildingDoc: IMedia) => {
            const userTypeDoc = userTypeDocs.find(
                (utd: IUserTypeDoc) =>
                    utd.ID === parseInt(buildingDoc.mediatype.substring(buildingDoc.mediatype.lastIndexOf('_') + 1)) &&
                    buildingDoc.validated === true
            );
            return total_weight + (userTypeDoc === undefined ? 0 : userTypeDoc.level_weight);
        }, 0);
        
        percentageValidated = percentageValidated * 100;
    
        return percentageValidated > 10;*/

        const buildingDocsValidated = buildingDocs.find(docs => docs.versions[0].status === DOCUMENT_STATUS_VALIDATED);
        return !!buildingDocsValidated;
    }

    function calculateMintingCostData() {
        if(!buildingDocs){
            return undefined;
        }

        console.log("buildingDocs", buildingDocs);

        const documentsNeedToBePaid = buildingDocs.filter(doc => 
            doc.versions[0].status === DOCUMENT_STATUS_VALIDATED && !doc.versions[0].payment
        );

        console.log('documentsNeedToBePaid', documentsNeedToBePaid);

        /*for(let i=0; i<buildingDocs.length; i++){
            if(buildingDocs[i].versions[0].status !== DOCUMENT_STATUS_VALIDATED) continue;

            //test if validated doc have a payment
            if(buildingDocs[i].versions[0].payment === undefined){
                documentsNeedToBePaid++;
            }
        }*/

        const filteredActivities = documentsNeedToBePaid.map((doc: IMedia) => doc!.versions![0].activity![0])
            .filter(activity => activity.action === 3);

        const uniqueUserIDs = new Set(filteredActivities.map(activity => activity.user_ID));
        const uniqueUserIDCount = uniqueUserIDs.size;

        const transferCost = documentsNeedToBePaid.length * 0.99;
        const transferMaxCost = documentsNeedToBePaid.length * 1.17;
        const newTokenCost = !buildingDTT ? 34.97 : 0;
        const newTokenMaxCost = !buildingDTT ? 41.9 : 0;
        const addHashCost = !buildingDTT ? 1.08 + (documentsNeedToBePaid.length - 1) * 0.93 : documentsNeedToBePaid.length * 0.93;
        const addHashMaxCost = !buildingDTT ? 1.29 + (documentsNeedToBePaid.length - 1) * 1.29 : documentsNeedToBePaid.length * 1.29;
        const setLevelCost = 0.27;
        const setLevelMaxCost = 0.32;

        console.log("mintingCostData detail", {
            buildingDTT: buildingDTT,
            documentsNeedToBePaid: documentsNeedToBePaid,
            transferCost: transferCost,
            transferMaxCost: transferMaxCost,
            newTokenCost: newTokenCost,
            newTokenMaxCost: newTokenMaxCost,
            addHashCost: addHashCost,
            addHashMaxCost: addHashMaxCost,
            setLevelCost: setLevelCost,
            setLevelMaxCost: setLevelMaxCost,
        })

        const mintingCostData = {
            cost: documentsNeedToBePaid.length * 5,
            amountOfStakeholders: uniqueUserIDCount,
            gasFeeCost: transferCost + newTokenCost + addHashCost + setLevelCost,
            gasFeeMaxCost: transferMaxCost + newTokenMaxCost + addHashMaxCost + setLevelMaxCost,
        };

        console.log("mintingCostData total", mintingCostData);

        setMintingCostData(mintingCostData);
    }

    function calculateLevels() {
        console.log("CALCULATELEVELS _dttMetaData", dttMetaData);
        console.log("CALCULATELEVELS buildingDocs", buildingDocs);
        console.log("CALCULATELEVELS userTypeDocs", userTypeDocs);

        const percentage = {
            percentageValidate: 0 * 100,
            percentageValidated: 0 * 100,
        };
    
        if(buildingDocs === undefined || userTypeDocs === undefined){
            setIndexLevels([]);
            return;
        }
    
        const updateIndexLevels = [...indexLevels];

        tierData.forEach(tier => {
            let percentageValidated = buildingDocs
                .filter(buildingDoc => buildingDoc.level === tier.tier_id)
                .reduce((total_weight: number, buildingDoc: IMedia) => {

                console.log('buildingDoc',buildingDoc);

                let index_weight = 0;
                if (buildingDoc.versions[0].status === 3){
                    index_weight = (buildingDoc.index_weight || 0);
                }
                /*const userTypeDoc: IUserTypeDoc = userTypeDocs.find(
                    (utd: IUserTypeDoc) =>
                        utd.ID === parseInt(buildingDoc.mediatype.substring(buildingDoc.mediatype.lastIndexOf('_') + 1)) &&
                        buildingDoc.versions[0].status === 3
                );
                return total_weight + (userTypeDoc === undefined ? 0 : userTypeDoc.index_weight);*/
                return total_weight + index_weight;
            }, 0);
            
            const currentDTTLevel = dttMetaData ? parseFloat(dttMetaData.level[tier.tier_id].toString()) : 0;
            percentageValidated = percentageValidated * 100;

            // TODO TEMPORARY FIX FOR SMART CONTRACT LIMITED UINT8
            console.log('before percentageValidated',percentageValidated);
            const tmpPercentageValidated = Math.round(percentageValidated);
            console.log('after percentageValidated', tmpPercentageValidated);

            percentage.percentageValidate = tmpPercentageValidated - currentDTTLevel;
            percentage.percentageValidated = currentDTTLevel;
        
            console.log("check DTT upToDate", parseFloat(currentDTTLevel.toString()), parseFloat(tmpPercentageValidated.toString()));

            updateIndexLevels[tier.tier_id] = {
                percentageValidate: percentage.percentageValidate,
                percentageValidated: percentage.percentageValidated,
                upToDate: dttMetaData && (parseFloat(currentDTTLevel.toString()) === parseFloat(tmpPercentageValidated.toString())) ? true : false
            };
            //newIndexLevels[tier.tier_id] = newIndexLevel;
            
        });

        console.log("updateIndexLevels", updateIndexLevels, dttMetaData);
        setIndexLevels(updateIndexLevels);

        const isUpgraded = updateIndexLevels.some(item => item.upToDate === false);
        setIsUpgraded(!isUpgraded);
    }

    return (
        <>
            <StyledContainerGrid
                container
                direction="row"
                sx={{ minWidth: '760px', width: '100%', marginTop: 0, justifyContent: 'space-between' }}
            >
                <StyledGrid
                    item
                    xs={12}
                    md={7.5}
                >
                    <Stack direction="row" mb={2} sx={{ justifyContent: 'space-between' }}>
                        <Typography size="h2" weight="medium">
                            DTT® Minting Review
                        </Typography>
                    </Stack>

                    {building !== undefined &&
                        buildingDTT !== undefined &&
                        buildingDTT !== null &&
                        buildingDTT !== ''
                        &&
                            <Stack direction="row" mb={2} sx={{ justifyContent: 'space-between' }}>
                                <Typography size="body" sx={{ fontSize: '10px !important' }} weight="regular" color={theme.colors.neutral5}>
                                    Unique ID:&nbsp;<a style={{color:'blue'}} href={`${process.env.REACT_APP_VECHAIN_EXPLORER}/accounts/${buildingDTT}`} target='_blank' rel='noreferrer'>{buildingDTT}</a>
                                </Typography>
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                        getDTTMetaData(buildingDTT)
                                    }}
                                    sx={{fontSize: '10px!important'}}
                                >
                                    Get DTT® MetaData
                                </Button>
                            </Stack>
                    }

                    <Typography size="h3" weight="medium">
                        Level evolution
                    </Typography>
                    {
                    /*<div
                            style={{
                                width: '450px',
                                wordWrap: 'break-word',
                                fontFamily: 'Courier New',
                                fontSize: '12px'
                            }}
                        >
                        {JSON.stringify(indexLevels)}
                    </div>
                    */}
                    <FlexSpacer minHeight={2} />

                    <DttSliderLevels 
                        userTypeDocs={userTypeDocs}
                        buildingDocs={buildingDocs}
                        dttMetaData={dttMetaData}
                        indexLevels={indexLevels}
                    />

                    {
                    /*<div
                            style={{
                                width: '450px',
                                wordWrap: 'break-word',
                                fontFamily: 'Courier New',
                                fontSize: '12px'
                            }}
                        >
                        {JSON.stringify(dttMetaData)}
                    </div>

                    <div
                            style={{
                                width: '450px',
                                wordWrap: 'break-word',
                                fontFamily: 'Courier New',
                                fontSize: '12px'
                            }}
                        >
                        isMinted: {isMinted ? 'true' : 'false'}
                    </div>

                    <div
                            style={{
                                width: '450px',
                                wordWrap: 'break-word',
                                fontFamily: 'Courier New',
                                fontSize: '12px'
                            }}
                        >
                        isUpgraded: {isUpgraded ? 'true' : 'false'}
                    </div>*/
                    }

                    {mintingCostData?.cost === 0 && isUpgraded === true
                        ? building && building.dtt_address && <>
                            <FlexSpacer minHeight={1.5} />
                            
                            <Stack direction="row" sx={{ justifyContent: 'end' }}>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    onClick={openModalNftLevel}
                                    sx={{ minWidth: 130 }}
                                >
                                    See the DTT®
                                </Button>
                            </Stack> 
                        </>
                        : <>
                            {allowMint() && (
                                <>
                                    <FlexSpacer minHeight={1.5} />
                                    
                                    <Typography size="h3" weight="medium">
                                        Minting cost:
                                    </Typography>

                                    <FlexSpacer minHeight={2} />

                                    <Typography size="body" weight="regular" sx={{ textAlign: 'start' }}>
                                        {mintingCostData?.cost > 0 &&
                                            `The mint will cost ${Math.round(mintingCostData?.cost * 100) / 100} MUT, shared between ${mintingCostData?.amountOfStakeholders} stakeholder(s).`                                            
                                        }
                                    </Typography>
                                    <Typography size="body" weight="regular" sx={{ textAlign: 'start' }}>
                                        {mintingCostData?.gasFeeCost > 0 &&
                                            `The gas fees will cost ~${Math.round(mintingCostData?.gasFeeCost * 100) / 100} (max: ${Math.round(mintingCostData?.gasFeeMaxCost * 100) / 100}) VTHO.`
                                        }
                                    </Typography>
                                </>
                            )}

                            <FlexSpacer minHeight={1.5} />

                            <Stack direction="row" sx={{ justifyContent: 'end' }}>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    disabled={!allowMint()}
                                    onClick={
                                        !processing
                                            ? !isMinted
                                                ? handleMint
                                                : isMinted
                                                    ? handleUpgrade
                                                    : () => {}
                                            : () => {}
                                    }
                                    sx={{
                                        minWidth: 130,
                                        cursor: processing ? 'default' : 'pointer',
                                    }}
                                >
                                    {!isMinted
                                        ? (processing
                                            ? <>
                                                {process_name} <img src="/images/loader.gif" alt="magma loader" width={20} height={20} style={{marginLeft: '5px'}} />
                                            </>
                                            : 'Mint the DTT®'
                                        )
                                        : isMinted && !isUpgraded
                                            ? (processing
                                                ? <>
                                                    {process_name} <img src="/images/loader.gif" alt="magma loader" width={20} height={20} style={{marginLeft: '5px'}} />
                                                </>
                                                : 'Upgrade the DTT®'
                                            )
                                            : 'See the DTT®'
                                    }
                                </Button>
                            </Stack>   
                        </>
                    }

                    <FlexSpacer minHeight={1.5} />

                    {/*isMinted && (
                        <>
                            <Stack direction="row">
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={handleSetLevel}
                                >
                                    Increase Level
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={handleMintHashContract}
                                >
                                    Mint HashStorage
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => handleAddHash(Date.now().toString(36))}
                                >
                                    Add Hash
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                        //setBuildingID(building.ID);
                                        setBuilding(building);
                                        openModalNftLevel();
                                    }}
                                >
                                    openModalNftLevel
                                </Button>
                            </Stack>

                            <Stack direction="row">
                                {/*
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={handleSetLevel}
                                >
                                    Increase Level
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={handleMintHashContract}
                                >
                                    Mint HashStorage
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => handleAddHash(Date.now().toString(36))}
                                >
                                    Add Hash
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={handleReadHashes}
                                >
                                    Read hashes
                                </Button>
                            </Stack>
                        </>
                    )*/}

                    {/*dttMetaData && (
                        <div
                            style={{
                                width: '450px',
                                wordWrap: 'break-word',
                                fontFamily: 'Courier New',
                                fontSize: '12px'
                            }}
                        >
                            DTT MetaData: {JSON.stringify(dttMetaData, null, 3)}
                        </div>
                        )*/
                    }
                </StyledGrid>                

                <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                        marginTop: '1rem',
                        borderRadius: '5px',
                        bgcolor: theme.colors.lighter,
                        maxWidth: 240,
                    }}
                >
                    <Stack
                        direction="column"
                        sx={{
                            padding: '1rem 1rem .5rem',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Stack
                            direction="row"
                            sx={{ justifyContent: 'space-between' }}
                        >
                            <Typography
                                size="h5"
                                weight="medium"
                                sx={{ textAlign: 'end', marginBottom: 1 }}>
                                Documents reviewed
                            </Typography>
                        </Stack>

                        <DttAccordion 
                            userTypeDocs={userTypeDocs}
                            buildingDocs={buildingDocs}
                            userTypes={userTypes}
                        />

                    </Stack>
                </Grid>
            </StyledContainerGrid>
        </>
    )
}

const StyledContainerGrid = styled(Grid)`
    padding: 1.5rem ;
    border-radius: 12px;
    border: 1px solid #D9D9D9;
    overflow: scroll;
    box-shadow: 5px 5px 0 0 #001aff;
`;

const StyledGrid = styled(Grid)`
    .progress {
      background-color: rgba(0, 26, 255, 0.25) !important;
    }
`;