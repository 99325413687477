import axios, { AxiosRequestConfig } from "axios";
import { IBuilding, IBuildingType } from "../interfaces/building";
import { INotification } from "../interfaces/notification";
import { IUserInfo } from "../interfaces/userInfo";
import { tierData } from "../interfaces/dtt";
import { Blockchain } from "magma-sdk";

const url = `${process.env.REACT_APP_API_URL}/building`;

export default class BuildingService {

  getBuildings (keycloak: any, blockchain: Blockchain, userInfo: IUserInfo){
    if (userInfo === undefined) {
      throw new Error('user empty');
    }
    if (userInfo.permissions === undefined) {
      //throw new Error('user permissions empty');
      return Promise.resolve([]);
    }

    var permissions = userInfo.permissions;
    var values = permissions.map((permission: any) => permission.building_ID);

    const data = {
      'load_documents': false,
      'building_ID': values
    }

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${keycloak.token}`
      },
      params: data
    };

    return axios.get(url, config)
    .then(async(response) => {
      const buildings: IBuilding[] = response.data.result;

      for (var i = 0; i < buildings.length; i++) {
        const building: IBuilding = buildings[i];
        if (building.media) {
          const building_imgObj = building.media.find(media => media.mediatype === 'BUILDING_IMG');
          if (building_imgObj) {
            building.building_img_data = building_imgObj.versions[0].mediabase64!;
            building.building_img_mimetype = building_imgObj.versions[0].mimetype;
          }
        }

        building.level = Array(5).fill(0);
        console.log('building.level', building.level);
        building.magma_index = 0;
        if (building.dtt_address?.trim()) {
          const dtt = await blockchain.getInstanceOfDigitalTwinToken(building.dtt_address);
          const tmpDTTMetaData: any = await dtt.getMetaData();
          building.level[0] = tmpDTTMetaData.level;
          building.level[1] = 0;
          building.level[2] = 0;
          building.level[3] = 0;
          building.level[4] = 0;
          
          for (let i = 0; i < tierData.length; i++) {
            building.magma_index += building.level[i] * tierData.sort((a, b) => a.tier_id - b.tier_id)[i].magma_index_weight;
          }
        }
      }

      return buildings;
    })
    .catch(error => {
      throw error; 
      //return error;
    });
  }

  getBuildingTypes (keycloak: any) {

    const urlBuildingTypes = url + '/types';

    const config: AxiosRequestConfig = {
    headers: {
        Authorization: `Bearer ${keycloak.token}`
    }};

    return axios.get(urlBuildingTypes, config)
    .then((response) => {
        const buildingTypes: IBuildingType[] = response.data.result;

        return buildingTypes;
    })
    .catch(error => {
        throw error;
    });
  };

  getBuildingInfo (keycloak: any, id: any) {

    const data = {
      "building_ID": id
    }

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${keycloak.token}`
      },
      params: data
    };

    return axios.get(url, config)
    .then((response) => {
        const building: IBuilding = response.data.result[0];

        return building;
    })
    .catch(error => {
        throw error; 
        //return error;
    });
  };

  saveBuildingInfo (keycloak: any, bodyFormData: any) {
    const config: AxiosRequestConfig = {
      method: 'post',
      url: url,
      data: bodyFormData,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
        'Content-Type': 'multipart/form-data'
      }
    };

    return axios(config)
    .then((response) => {
      return response;
    })
    .catch(error => {
      throw error; 
    });
  }

  addMedia (keycloak: any, bodyFormData: any) {
    const urlAddMedia = url + '/media';

    const config: AxiosRequestConfig = {
      method: 'post',
      url: url,
      data: bodyFormData,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
        'Content-Type': 'multipart/form-data'
      }
    };

    return axios(urlAddMedia, config)
    .then((response) => {
      return response;
    })
    .catch(error => {
      throw error; 
    });
  }

  createMediaActivity (keycloak: any, data: any) {
    const urlCreateMediaActivity = url + '/media/activity';

    const config: AxiosRequestConfig = {
      method: 'post',
      url: urlCreateMediaActivity,
      data: data,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
        'Content-Type': 'application/json'
      }
    };

    return axios(config)
    .then((response) => {
      return response;
    })
    .catch(error => {
      throw error; 
    });
  }

  deleteMedia (keycloak: any, data: any) {
    const urlDeleteMedia = url + '/media';

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${keycloak.token}`
      },
      params: data
    };

    return axios.delete(urlDeleteMedia, config)
    .then((response) => {
      return response;
    })
    .catch(error => {
      throw error; 
    });
  }

  updateBuildingFields (keycloak: any, data: any) {
    const urlUpdateBuildingFields = url + '/fields';

    const config: AxiosRequestConfig = {
      method: 'post',
      url: urlUpdateBuildingFields,
      data: data,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
        'Content-Type': 'application/json'
      }
    };

    return axios(config)
    .then((response) => {
      return response;
    })
    .catch(error => {
      throw error; 
    });
  }

  createBuildingPayment (keycloak: any, data: any) {
    const urlUpdateBuildingWallet = url + '/payment';

    const config: AxiosRequestConfig = {
      method: 'post',
      url: urlUpdateBuildingWallet,
      data: data,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
        'Content-Type': 'application/json'
      }
    };

    return axios(config)
    .then((response) => {
      return response;
    })
    .catch(error => {
      throw error; 
    });
  }

  getBuildingNotifications (keycloak: any, building_IDs: any){
    const urlBuildingNotifications = url + '/notifications';

    const data = {
      'building_IDs': !building_IDs ? '' : building_IDs 
    }

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${keycloak.token}`
      },
      params: data
    };

    console.log('getBuildingNotifications', config);

    return axios.get(urlBuildingNotifications, config)
    .then((response) => {
      const notifications: INotification[] = response.data.result;

      return notifications;
    })
    .catch(error => {
      throw error; 
      //return error;
    });
  }
}