import { Box, Stack } from "@mui/material";
import styled from '@emotion/styled';
import { FlexSpacer } from '../../../atoms/FlexSpacer';
import Typography from '../../../atoms/Typography';
import { theme } from '../../../../utils/theme';
import { FC } from "react";
import BackArrowIcon from "../../../atoms/Buttons/BackArrowIcon"
import { NumericFormat } from 'react-number-format';
import { formatNumber, getMUTEURValue } from "../../../../utils/functions";

type walletCardProps = {
    isModal?: any
    inputAmount?: any
    wallet?: any
    width?: any;
    height?: any;
    walletType: string;
}

const StyledCard = styled(Box)`
    /*background: linear-gradient(123.78deg, rgba(255, 11, 60, 0.75) 0%, rgba(0, 42, 255, 0.75) 82.17%);     */
    /*border: 2px solid #EBECEF;*/
    border: 2px solid #E9E9F1;
    border-radius: 8px;

    &.buildingWallet {
        background: linear-gradient(73.58deg, #0F0F17 -0.98%, #424252 121.18%);
    }
    &.stakeholderWallet {
        background: linear-gradient(270deg, #0F4BFD 0%, #0F0F17 100%);
    }
`;

const WalletCard: FC<walletCardProps> = ({
    inputAmount,
    isModal,
    wallet,
    width,
    height,
    walletType }) => {

    console.log('wallet', wallet);

    return (
        <StyledCard className={walletType}
            sx={{
                padding: "16px 24px",
                width: `${width === undefined ? '312' : width}px`,
                height: `${height === undefined ? '192' : height}px`
            }}>
            <Stack direction="row">
                <img src="/svg/icons/walletLogo.svg" width="108px" alt="logo" />

                <FlexSpacer minWidth={1} />

                {wallet.address !== '' &&
                    <Box
                        style={{ cursor: 'pointer', marginTop: 7, height: 22 }}
                        onClick={
                            () => window.open(`${process.env.REACT_APP_VECHAIN_EXPLORER}/accounts/${wallet.address}`)
                        }
                    >
                        <Stack direction="row" sx={{mt: '5px'}}>
                            <Typography color={theme.colors.white} size="14px"  >
                                Explorer
                            </Typography>

                            <FlexSpacer minWidth={.25} />

                            <img src="/svg/icons/ExternalBlue.svg" style={{ filter: ' brightness(0) invert(1)', width: '16px' }} alt="link" />
                        </Stack>
                    </Box>
                    }
            </Stack>

            <Stack direction="column" mt={1.7}>
                <Typography color={theme.colors.white} size="12px"  >
                    Balance
                </Typography>

                <Stack direction="row" sx={{
                    alignItems: 'center',
                    lineHeight: '27px'
                }} >
                    <Typography color={theme.colors.white} weight="medium"
                        sx={{
                            fontSize: '16px',
                            lineHeigt: '18px',
                            marginRight: .5
                        }} >
                        {`${formatNumber((wallet.balance.gasBalance.toFixed(4)))} MUT`}
                    </Typography>


                    {inputAmount &&
                        <>
                            <Box sx={{
                                height: 18,
                                "& img": {
                                    maxWidth: '20px',
                                    mt: '-2px',
                                    height: 18,
                                },
                                transform: 'rotate(180deg)', filter: "invert(1)",
                            }}>
                                <BackArrowIcon />
                            </Box>
                            <Typography color={theme.colors.white} weight="regular"
                                sx={{
                                    fontSize: '16px',
                                    lineHeigt: '18px',
                                    marginLeft: .5
                                }} >
                                <NumericFormat
                                    value={`${parseInt(inputAmount) + parseInt(wallet.balance.gasBalance)}`}
                                    thousandSeparator={true}
                                    maxLength={10}
                                    //{separators.thousandSeparator}
                                    // decimalSeparator={separators.decimalSeparator}
                                    displayType='text'
                                />
                                <Box sx={{ marginLeft: .5 }}>{' MUT'}  </Box>
                            </Typography>
                        </>
                    }

                    {/*
                    DISABLE FIAT VALUATION FOR LEGAL REASON
                    !isModal &&
                        <Typography
                            color={theme.colors.white}
                            size="10px"
                        >
                            {`= ${getMUTEURValue(wallet.balance.gasBalance).toFixed(4)} EUR`}
                        </Typography>
                    */}
                </Stack>
            </Stack>

            <FlexSpacer minHeight={1.125} />

            <Stack direction="column">
                <Typography color={theme.colors.white} size="12px"  >
                    {wallet.name === '' ? 'Address' : wallet.name}
                </Typography>

                <Typography color={theme.colors.white} size="8px" >
                    {wallet.address === '' ? 'not connected' : wallet.address}
                </Typography>
            </Stack>
        </StyledCard >
    )
}

export default WalletCard