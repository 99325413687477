import BigNumber from 'bignumber.js';
import { CONST_SQM_SQFT } from './constants';

export function formatNumber(num: number){
    //const localPrice = (_price).toLocaleString('en').replace(',',' ');
    //return _price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    // get the integer and decimal parts of the number
    let [int, decimal] = num.toString().split('.')
    // add space as thousand separator to the integer part
    int = int.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    
    // add space as thousand separator to the decimal part
    //decimal = decimal.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    // return the formatted number
    if(decimal === undefined){
        return `${int}`;
    }
    else {
        return `${int}.${decimal}`;
    }
}

export function removeTrailingZeros(number: number | undefined): number {
    if(!number){
        return 0;
    }
    let numberStr = number.toString();
    if (numberStr.includes(',')) {
      const commaIndex = numberStr.indexOf(',');
      for (let i = numberStr.length - 1; i > commaIndex; i--) {
        if (numberStr[i] !== '0') {
          break;
        }
        numberStr = numberStr.slice(0, i);
      }
  
      if (numberStr.endsWith(',')) {
        numberStr = numberStr.slice(0, -1);
      }
    }
  
    return parseFloat(numberStr);
}

export function getExtension(filename: string){
    return filename.substring(filename.lastIndexOf('.') + 1).toUpperCase();
}

export function limitChars(text: string | undefined | null, maxChars: number){
    if(text !== undefined && text !== null && text.length > maxChars){
        return text.substring(0, maxChars) + '...';
    }
    if(text === undefined || text === null){
        return '';
    }
    return text;
}

export function isDocument(filename: string){
    const extension = getExtension(filename).toLowerCase();
    const arrDocumentExtensions = ['pdf','rvt','xslx'];
    return arrDocumentExtensions.includes(extension);
}

export function isImage(mimetype: string){
    return mimetype.includes('image');
}

export function niceBytes(x: string | number) {
    if(x === undefined) return '';

    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(x.toString(), 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

/**
 interface DateTimeFormatOptions {
        localeMatcher?: "best fit" | "lookup" | undefined;
        weekday?: "long" | "short" | "narrow" | undefined;
        era?: "long" | "short" | "narrow" | undefined;
        year?: "numeric" | "2-digit" | undefined;
        month?: "numeric" | "2-digit" | "long" | "short" | "narrow" | undefined;
        day?: "numeric" | "2-digit" | undefined;
        hour?: "numeric" | "2-digit" | undefined;
        minute?: "numeric" | "2-digit" | undefined;
        second?: "numeric" | "2-digit" | undefined;
        timeZoneName?: "short" | "long" | "shortOffset" | "longOffset" | "shortGeneric" | "longGeneric" | undefined;
        formatMatcher?: "best fit" | "basic" | undefined;
        hour12?: boolean | undefined;
        timeZone?: string | undefined;
    }
    //const options = { month: 'long', day: 'numeric', year: 'numeric' };
     
 */
export function formatDateTime(_datetime: undefined | string, _format?: string){
    if(_datetime === undefined) return '';

    const date = new Date(_datetime);
    
    if (!date || isNaN(date.getTime())) {
        return '';
    }

    let formattedDate = _datetime;
    let options: Intl.DateTimeFormatOptions; 
    if(_format !== ''){
        switch(_format){
            case 'MMMM d, yyyy':
                options = { month: 'long', day: 'numeric', year: 'numeric' };
                formattedDate = date.toLocaleDateString('en-US', options);
                //const [month, day, year] = formattedDate.split('/');
                //formattedDate = `${month} ${addDaySuffix(Number(day))}, ${year}`;
                break;
        }
    }

    return formattedDate;
}

function addDaySuffix(day: number) {
    if (day > 3 && day < 21) return `${day}th`;
    switch (day % 10) {
      case 1:  return `${day}st`;
      case 2:  return `${day}nd`;
      case 3:  return `${day}rd`;
      default: return `${day}th`;
    }
}

export async function getWalletBalance(_blockchain: any, _wallet_address: string) {
    if(_wallet_address === null || _wallet_address === '')
        return 0;
        
    const magmaToken = process.env.REACT_APP_VECHAIN_MUT_ADDRESS;    
    const magmaTokenInstance = await _blockchain.getInstanceOfMagmaToken(magmaToken);
    console.log('getWalletBalance', await magmaTokenInstance.balanceOf(_wallet_address));
    return parseFloat((await magmaTokenInstance.balanceOf(_wallet_address)).toString())
}

export function getMUTEURValue(_MUT_amount: number): number {
    const MUT_EUR = 1.00;
    return _MUT_amount * MUT_EUR; 
}

export function etherToBigNumber(num: number) {
    let etherValue = new BigNumber(num);
    let weiValue = etherValue.times(new BigNumber(10).pow(18));
    return weiValue.toString();
}

export function calcDiffTime(_dateTime1: any, _dateTime2: any){
    const dt1 = new Date(_dateTime1);
    const dt1_time = dt1.getTime() / 1000;
    const dt2 = new Date(_dateTime2);
    const dt2_time = dt2.getTime() / 1000;
    console.log('dt1 dt2', dt1, dt2);
    console.log('dt1_time dt2_time', dt1_time, dt2_time);

    let diff = dt2_time - dt1_time;

    console.log('diff', diff, ' seconden');

    if(diff < 60) {
        console.log(diff, 'sec');
        return parseInt(diff.toString()) + 'sec';
    }
    diff = diff / 60;
    if(diff < 60){
        console.log(diff, 'min');
        return parseInt(diff.toString()) + 'min';
    }
    diff = diff / 60;
    if(diff < 24){
        console.log(diff, 'h');
        return parseInt(diff.toString()) + 'h';
    }
    diff = diff / 24;
    if(diff < 7){
        console.log(diff, 'd');
        return parseInt(diff.toString()) + 'd';
    }
    diff = diff / 7;
    if(diff < 52){
        console.log(diff, 'w');
        return parseInt(diff.toString()) + 'w';
    }
    diff = diff / 4;
    if(diff < 12){
        console.log(diff, 'm');
        return parseInt(diff.toString()) + 'm';
    }
    console.log(diff,'> 12? => y')
    diff = diff / 12;
    console.log(diff, 'y');
    return parseInt(diff.toString()) + ' y';
}

export function convertSQM_SQFT(unitOfMeasure: string, amount: string | undefined) {
    var size: number | undefined;

    if(amount === undefined){
      return '';
    }

    size = undefined;
    switch(unitOfMeasure){
      case 'sqm':
        size = parseFloat(amount) / CONST_SQM_SQFT;
        break;
      case 'sqft':
        size = parseFloat(amount) * CONST_SQM_SQFT;
        break;
    }

    if(size === undefined || isNaN(size)){
      return '';
    }
    else{
      return size.toString();
    }
  }