export const exampleBuildings = {
    building_name: [
      'Hagia Sophia',
      'The Guggenheim',
      'Taj Mahal',
      'Dancing House',
      'Château de Chenonceau',
      'Niterói Contemporary Art Museum',
      'The Pyramids of Giza',
      'Acropolis of Athens',
      'Le Centre Pompidou',
      'The Gateway Arch'
    ],
    building_type_ID: [
      '1',
      '2',
      '3'
    ],
    construction_year: [
      '1900',
      '1910',
      '1920',
      '1930',
      '1940',
      '1950',
      '1960',
      '1970',
      '1980',
      '1990',
      '2000'
    ],
    size: [
      '1000.15',
      '5000',
      '10615.1585',
      '25000',
      '50000.5',
      '100000',
      '150000.5555',
      '250000'
    ],
    price: [
      '100000',
      '200000',
      '300000',
      '400000',
      '500000',
      '600000',
      '700000',
      '800000'
    ],
    address: [
      {
        label: 'Sultan Ahmet, Ayasofya Meydanı No:1, 34122 Fatih/İstanbul, Turkije',
        coords: {
          lat: 41.008050,
          lng: 28.978920
        }
      },
      {
        label: '1071 5th Ave, New York, NY 10128, Verenigde Staten',
        coords: {
          lat: 40.783020,
          lng: -73.959130
        }
      },
      {
        label: 'Dharmapuri, Forest Colony, Tajganj, Agra, Uttar Pradesh 282001, India',
        coords: {
          lat: 27.211121,
          lng: 78.005127
        }
      },
      {
        label: 'Jiráskovo nám. 1981/6, 120 00 Nové Město, Tsjechië',
        coords: {
          lat: 50.075450,
          lng: 14.414190
        }
      },
      {
        label: '37150 Chenonceaux, Frankrijk',
        coords: {
          lat: 47.331430,
          lng: 1.068030
        }
      },
      {
        label: 'Mirante da Boa Viagem, s/nº - Boa Viagem, Niterói - RJ, 24210-390, Brazilië',
        coords: {
          lat: -22.87527,
          lng: -43.49839
        }
      },
      {
        label: 'Al Haram, Giza Governorate, Egypte',
        coords: {
          lat: 29.44021,
          lng: 31.18682
        }
      },
      {
        label: 'Athens 105 58, Griekenland',
        coords: {
          lat: 37.98129,
          lng: 23.72683
        }
      },
      {
        label: 'Place Georges-Pompidou, 75004 Paris, Frankrijk',
        coords: {
          lat: 48.86078,
          lng: 2.35174
        }
      },
      {
        label: 'Gateway Arch, St. Louis, MO 63102, Verenigde Staten',
        coords: {
          lat: 38.62455,
          lng: -90.18500
        }
      }
    ],
    owner: [
      'DeAgostini',
      'Stan Honda',
      'Julian Finney',
      'Insights/UIG',
      'Chesnot',
      'Patrick Altmann',
      'Sean Gallup',
      'Milos Bicanski',
      'DEA/C. SAPPA/De Agostini',
      'Raymond Boyd/Michael Ochs Archives'
    ],
    owner_address: [
      'Istanbul, Turkey',
      'New York City, USA',
      'Agra, India',
      'Prague, Czech Republic',
      'Chenonceaux, France',
      'Niterói, Rio de Janeiro, Brazil',
      'Giza, Egypt',
      'Athens, Greece',
      'Paris, France',
      'St. Louis, Missouri, USA',
    ]
  }
