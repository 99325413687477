import styled from '@emotion/styled';
import { Button, Dialog, Stack, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '../design-system/atoms/Typography';
import { theme } from '../utils/theme';
import { Outlet } from "react-router-dom";

const DivLoading = styled(Stack)`
    background-color: #F2F4FF; /* For browsers that do not support gradients */
    height: 100vh;
    align-items: center;
    padding: 2rem 10rem;
    color: white;
`;

const CenterVertical = styled(Stack)`
    margin: auto 0;
`;

const CenterTitle = styled(Stack)`
    align-items: center;
    justify-content: space-between;
`;


export const NoMatch: FC = () => {
  console.log("No match");
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();


  const handleCloseModal = () => {

  }


  const handleClick = () => {
    navigate('/')
  }

  return (
    <>
      <Dialog
        open={true}
        fullScreen
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DivLoading direction="column" sx={{ background: '#F2F4FF' }}>
          <CenterVertical>
            <img src="/svg/3DLogo.svg"
              alt="magma brand home"
              width={isMobile ? '100%' : 248}
              height={isMobile ? '100%' : 248}
              style={{ filter: 'invert(1)', margin: 'auto' }}
            />

            <Typography size="h2" weight='regular' mb={3} sx={{ justifyContent: 'center', fontSize: isMobile ? 'auto' : 48 }} color={theme.colors.black}>
              404 - Page not found
            </Typography>


            <Typography size="p" weight='regular' mb={3} sx={{ justifyContent: 'center', textAlign: 'center', fontSize: isMobile ? 'auto' : '34px !important' }} color={theme.colors.black}>
              We’re sorry, we didn’t find  what  you <br />  were looking for.
            </Typography>


            <CenterTitle>

              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ fontWeight: 'medium', width: isMobile ? '100% !important' : 'auto' }}
                onClick={handleClick}
              >
                Back to the dashboard
              </Button>

            </CenterTitle>
          </CenterVertical>
        </DivLoading>
      </Dialog>
      <Outlet />
    </>
  );
};