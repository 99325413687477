import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { useMediaQuery } from '@mui/material';
import { theme } from '../../../utils/theme';

export default function ResponsiveDatePickers() {
  // const [value, setValue] = React.useState<Dayjs | null>(dayjs());
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [value, setValue] = React.useState(null);

  function DateIcon(props: any) {
    return (
      <img
        width={20}
        height={20}
        src="/svg/icons/Calendar.svg"
        alt="icon"
        style={{ marginRight: ".1rem" }}
      />
    );
  }


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        {isMobile ?
          <MobileDatePicker
            value={value}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            InputAdornmentProps={{ position: 'start' }}
            // renderInput={(params) => <TextField {...params} />}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "Since Forever"
                  }}
                />
              );
            }}
          // renderInput={(params) => <TextField {...params} />}
          />
          :
          <DatePicker
            disableFuture
            openTo="year"
            views={['year', 'month', 'day']}
            value={value}
            components={{
              OpenPickerIcon: DateIcon,
            }}
            InputAdornmentProps={{ position: 'start' }}
            onChange={(newValue) => {
              setValue(newValue);
            }}
            // renderInput={(params) => <TextField {...params} />}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "Since Forever"
                  }}
                />
              );
            }}
          />
        }
      </Stack>
    </LocalizationProvider>
  );
}
