export default class ApiService {
    //_baseUrl = "https://nominatim.openstreetmap.org/search/";
    //_baseUrl = `https://nominatim.openstreetmap.org/search.php?q=brussel&format=jsonv2`;
  
    getData = async (adress: any) => {
      //const data = await fetch(`${this._baseUrl}${adress}?format=json`);
      const data = await fetch (`https://nominatim.openstreetmap.org/search.php?q=${adress}&format=jsonv2`);
      if (!data.ok) {
        throw new Error(`Error: ${data.status}`);
      } else {
        return data.json();
      }
    };
  }
  