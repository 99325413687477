import { LatLngExpression } from "leaflet";
import { FC } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'


interface MapBlockProps {
  position?: any
  markerText?: any
  zoom?: any
}

const HandleOnFlyTo: FC<MapBlockProps> = ({position}) => {
  const map = useMap()
  const posString: string = position.toString();

  const lat = parseFloat(posString.split(',')[0]);
  const lng = parseFloat(posString.split(',')[1]);
  if(isNaN(lat) || lat === undefined || isNaN(lng) || lng === undefined) {
    console.log('Location error');
    return null;
  }
  const latLng: LatLngExpression = {lat: lat, lng: lng};
  
  // TODO calculate zoom based on boundary
  const zoom = 13;

  map.flyTo(latLng, zoom /*map.getZoom()*/);

  return null;
}

const MapBlock: FC<MapBlockProps> = ({ position, markerText }) => {
  
  return (
      <MapContainer 
        scrollWheelZoom={false}
        id="mapId"
        className="map__container"
        center={position}
        zoom={13}        
      >
        <HandleOnFlyTo position={position} />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup>
            {markerText}
          </Popup>
        </Marker>
        
      </MapContainer>
   );
};

export default MapBlock;
 