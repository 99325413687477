import { IMedia } from "./media";

export const BUILDING_STATUS_DRAFT = 1;
export const BUILDING_STATUS_NOT_MINTED = 2;
export const BUILDING_STATUS_ACTIVE = 3;

export interface IBuilding {
    ID: string;
    building_name: string;
    building_type_ID: number;
    building_type?: string;
    construction_year?: string;
    size: number;
    price: string;
    address: string;
    lat: number;
    lng: number;
    owner: string;
    owner_address: string;
    wallet_chain: string;
    wallet_address: string;
    dtt_address: string;
    level: number[];
    magma_index: number;
    media?: IMedia[];
    stakeholders?: IStakeholderDetail[];
    building_img_data: string;
    building_img_mimetype: string;
    status: number;
    updated?: string;
}

export interface IStakeholderDetail {
    user_ID: number;
    firstname: string;
    lastname: string;
    company: string;
    email: string;
    img: string;
    user_type_ID: number;
    user_type_img: string;
    user_type_name: string;
}

export interface IBuildingType {
    ID: number;
    name: string;
    description: string;
}

export interface ISearchBuilding {
    key: string;
    value: string;
}