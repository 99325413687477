import React, { FC, useState } from 'react'
import { Button, Checkbox, FormControlLabel, FormGroup, ListItemText, MenuItem, Stack, useMediaQuery } from '@mui/material'
import { theme } from '../../../../utils/theme';
import CustomMenu from '../../../atoms/CustomMenu';
import Typography from '../../../atoms/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@emotion/styled';
import ResponsiveDatePickers from '../../../atoms/ResponsiveDatePickers';


const FilterActivity: FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const selectOpen = Boolean(anchorEl);


    const handleSelectClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);

    };

    const [selectedIndex, setSelectedIndex] = useState(4);

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleSelectClose = () => {
        setAnchorEl(null);
    };


    const optionsMenu = [
        'Alphabetical (A-Z)',
        'Alphabetical (Z-A)',
        'Newest to oldest',
        'Oldest to newest',
        'Last modification'
    ];

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <CustomStack direction={isMobile ? 'column' : 'row'} className="CustomPageFilters">
            <ResponsiveDatePickers />

            <StyledFormGroup row sx={{ marginLeft: 2, marginTop: isMobile ? 3 : 0 }}>
                <FormControlLabel control={<Checkbox defaultChecked />} label="Announcement" />
                <FormControlLabel control={<Checkbox />} label="Reminder" />
                <FormControlLabel control={<Checkbox />} label="Document" />
            </StyledFormGroup>

            {/* // BUTTON MENU */}

            <Button
                size='small'
                id="basic-button"
                aria-controls={selectOpen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={selectOpen ? 'true' : undefined}
                onClick={handleSelectClick}
                variant="outlined"
                sx={{
                    borderColor: theme.colors.neutral4,
                    color: theme.colors.neutral6,
                    textTransform: 'inherit',
                    fontWeight: '500',
                    marginLeft: 'auto',
                    "&:hover": {
                        borderColor: theme.colors.neutral4,
                    }
                }}
            >
                <ExpandMoreIcon />
                <ListItemText
                    secondary={
                        <Typography size="body" weight="medium">{optionsMenu[selectedIndex]}</Typography>
                    }
                />

            </Button>

            <CustomMenu
                id="filter-menu"
                anchorEl={anchorEl}
                open={selectOpen}
                onClose={handleSelectClose}
            >
                {optionsMenu.map((item, index) => {
                    return (
                        <MenuItem
                            // disabled={index === selectedIndex}
                            selected={index === selectedIndex}
                            onClick={(event: any) => handleMenuItemClick(event, index)}
                            key={index}
                        >
                            {item}
                        </MenuItem>
                    )
                })}
            </CustomMenu>
        </CustomStack>
    )
}

export default FilterActivity

const StyledFormGroup = styled(FormGroup)`
.MuiFormControlLabel-root {
    & span {
        padding: 3px;
    }
}
`;

const CustomStack = styled(Stack)`

.MuiInputBase-adornedStart {
    height: 38px !important;
}

.MuiOutlinedInput-input {
    width: 100px;

    &::placeholder {
        color: black;
        opacity: 1;
    }
}
`;