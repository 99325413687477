import { FC, useState } from 'react'
import { Box, CircularProgress, FormControlLabel, Stack } from '@mui/material';
import { theme } from '../../../utils/theme';
import { FlexSpacer } from '../../atoms/FlexSpacer';
import { IOSSwitch } from '../../atoms/IOSSwitch/IOSSwitch';
import Typography from '../../atoms/Typography'
import { CustomizedAccordions } from '../Accordion';
import { IMedia } from '../../../interfaces/media';
import { IUserTypeDoc } from '../../../interfaces/userInfo';
import CircularBar from 'react-multicolor-circular-progress-bar';
import { tierData } from '../../../interfaces/dtt';

interface MagmaLevelProps {
    userTypeDocs: any,
    buildingDocs: any,
    userTypes: any,
}

export const MagmaLevel: FC<MagmaLevelProps> = ({
    userTypeDocs,
    buildingDocs,
    userTypes,
}) => {
    const [isToggleLevel, setIsToggleLevel] = useState(false);

    const handleToggleLevel = () => {
        setIsToggleLevel(!isToggleLevel)

        return isToggleLevel
    }

    function CircularProgressWithLabel(props: any) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                {/*<CircularProgress variant="determinate" size={100} {...props} />*/}
                <CircularBar
                    // title={{
                    // name: `${item.percentage} %`,
                    // position: { X: 10, Y: 1 }
                    // }}
                    // angleTransition={[180]}
                    fontFamily={'magma regular'}
                    scale={1}
                    angleTransition={[Math.max(0.001,Math.round((props.data.percentageValidated) / 100 * 360) - 0.001)]}
                    colors={['#001AFF', '#FFA800']}
                    stroke={{ color: '#BFC6FF', width: 7 }}
                    percent={{
                        showValue: false,
                        value: Math.round(props.data.percentageValidated + props.data.percentageValidate),
                        fontSize: 16,
                        fontWeight: 'bold',
                        align: 'middle',
                        position: {
                            X: 0,
                            Y: 0,
                            /*item: {
                                tier: item.tier,
                                percentage: item.percentage,
                                percentage2: item.percentage2
                            }*/
                        }
                        // color: ['#001AFF', '#83ff00'],
                    }}
                />
                {<Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography weight="bold" size="body" >
                        {Math.round(props.data.percentageValidated * 1) / 1}%
                    </Typography>
                    {props.data.percentageValidate > 0 &&
                        <Typography weight="regular" size="10px" >
                            &nbsp; (+{Math.round(props.data.percentageValidate * 1) / 1}%)
                        </Typography>
                    }
                </Box>}
            </Box>
        );
    }

    const getPercentage = (tier_id: number) => {
        const percentage = {
            percentageValidate: 0 * 100,
            percentageValidated: 0 * 100,
        };

        if(buildingDocs === undefined || userTypeDocs === undefined) return percentage;
        if(tier_id > 0) return percentage;

        let percentageValidate = buildingDocs.reduce((total_weight: number, buildingDoc: IMedia) => {
            let index_weight = 0;
            if (buildingDoc.versions[0].status === 2){
                index_weight = (buildingDoc.index_weight || 0);
            }
            /*const userTypeDoc: IUserTypeDoc = userTypeDocs.find(
                (utd: IUserTypeDoc) =>
                    utd.ID === parseInt(buildingDoc.mediatype.substring(buildingDoc.mediatype.lastIndexOf('_') + 1)) &&
                    buildingDoc.versions[0].status === 2
            );*/
            //return total_weight + (userTypeDoc === undefined ? 0 : userTypeDoc.index_weight);
            return total_weight + index_weight;
        }, 0);

        let percentageValidated = buildingDocs.reduce((total_weight: number, buildingDoc: IMedia) => {
            let index_weight = 0;
            if (buildingDoc.versions[0].status === 3){
                index_weight = (buildingDoc.index_weight || 0);
            }
            /*const userTypeDoc: IUserTypeDoc = userTypeDocs.find(
                (utd: IUserTypeDoc) =>
                    utd.ID === parseInt(buildingDoc.mediatype.substring(buildingDoc.mediatype.lastIndexOf('_') + 1)) &&
                    buildingDoc.versions[0].status === 3
            );
            return total_weight + (userTypeDoc === undefined ? 0 : userTypeDoc.index_weight);*/
            return total_weight + index_weight;
        }, 0);
        
        percentage.percentageValidate = percentageValidate * 100;
        percentage.percentageValidated = percentageValidated * 100;

        return percentage;
    }

    return (
        <>
            <Box sx={{ padding: '1rem 2rem 2rem', borderRadius: '4px', border: `1px solid ${theme.colors.neutral3}`, overflow: 'scroll', boxShadow: '5px 5px 0 0 #001AFF' }}>
                <Stack direction="row" marginBottom={5} sx={{ justifyContent: 'space-between' }}>
                    <Typography size='h4' weight="medium">
                        Magma level
                    </Typography>

                    <Stack direction="row">

                        <Typography size='body' sx={{ margin: '0 1rem', }}>Simple</Typography>

                        <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label="Detailed"
                            onChange={handleToggleLevel}
                        // onChange={isAssetManager}
                        />
                    </Stack>
                </Stack>

                <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                    {tierData && tierData
                    .sort((a, b) => a.tier_id - b.tier_id)
                    .map((item, index) => {
                        return (
                            <Stack direction="column" key={index}>
                                <CircularProgressWithLabel data={getPercentage(item.tier_id)} /*value={getPercentage(item.tier_id)}*/ />
                                <Typography sx={{ margin: '.5rem auto' }}>
                                    {item.tier}
                                </Typography>
                                {index > 0 && 
                                    <Typography sx={{ margin: '0rem auto' }}>
                                        (coming soon)
                                    </Typography>
                                }
                            </Stack>
                        )
                    })}
                </Stack>
            </Box>

            {isToggleLevel &&
                <>
                    <FlexSpacer minHeight={2} />
                    <CustomizedAccordions 
                        userTypeDocs={userTypeDocs}
                        buildingDocs={buildingDocs}
                        userTypes={userTypes}
                    />
                    <FlexSpacer minHeight={3} />
                </>
            }
        </>
    )
}
