import { Select, Checkbox, FormControlLabel, FormGroup, Grid, MenuItem, Stack, useMediaQuery, OutlinedInput, InputAdornment, IconButton } from "@mui/material"
import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"
import TextField from "@mui/material/TextField"
import { useKeycloak } from "@react-keycloak/web"
import { KeycloakTokenParsed } from "keycloak-js"
import { FC, useEffect, useState } from "react"
import { FlexSpacer } from "../design-system/atoms/FlexSpacer"
import LightTooltip from "../design-system/atoms/LightTooltip"
import Typography from "../design-system/atoms/Typography"
import { theme } from "../utils/theme"
import { FileUpload } from "../design-system/molecules/FileUpload";
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { Link } from "react-router-dom"
import BackArrowIcon from "../design-system/atoms/Buttons/BackArrowIcon"

type ParsedToken = KeycloakTokenParsed & {
  email?: string

  preferred_username?: string

  given_name?: string

  family_name?: string

  phone?: string
}

interface State {
  amount: string;
  password: string;
  weight: string;
  weightRange: string;
  showPassword: boolean;
}


const StyledStack = styled(Stack)`
  &.settings-page {

  .upload-container {
    padding: 17px 20px
  }

    .upload-spacer-control {
      display: none;
    }

    .upload-icon {
      margin-right: .8rem;
    }

    .upload-container {
      flex-direction: row;
    }

    .upload-btn {
      width: 112px;
      height: 32px;
    }

    .upload-legend {
      display: none;
    }
    
    .upload-sublegend {
      margin-left: .8rem;
    }
  }
`;

const StyldOutlinedInput = styled(OutlinedInput)`
padding: 0  14px 0 0;

.MuiOutlinedInput-input {
  padding: 10px 14px;
}
 
  & legend { display: none;}
  & fieldset {  top: 0;}
`

const StyledInput = styled(TextField)`
    padding: 0;

    .MuiOutlinedInput-input {
      padding: 10px 14px;
    }
`;

const StyledFormGroup = styled(FormGroup)`
  .MuiFormControlLabel-root {
      & span {
          padding: 3px;
      }
  }
`;

const StyledSelect = styled(Select)`
    font-size: 14px;
    height: 38px;
`;

interface SettingsProps {
  setBuildingWallet?: any
}


// ================= START PAGE STRUCTURE
const SettingsPage: FC<SettingsProps> = ({
  setBuildingWallet
}) => {

  console.log("Settings");
  const { keycloak } = useKeycloak();
  const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [type, setType] = useState('');

  const [values, setValues] = useState<State>({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  useEffect(() => {
    setBuildingWallet(undefined);
  }, []);


  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleChangeSelect = (event: any) => {
    setType(event.target.value as string);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };


  const [newUserInfo, setNewUserInfo] = useState({
    profileImages: []
  });

  const updateUploadedFiles = (files: any) =>
    setNewUserInfo({ ...newUserInfo, profileImages: files });

  const handleSubmit = (event: any) => {
    event.preventDefault();
    //logic to create new user...
  };

  const elipse = (
    <img src="/svg/icons/elipse.svg" alt="elipse" style={{ marginRight: '.5rem' }} />
  );

  const legend = <>
    <Grid container>
      <Grid item xs={6}>
        <Typography size="small">
          {elipse}
          One lowercase characters
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography size="small">
          {elipse}
          One uppercase characters
        </Typography>
      </Grid>

      <FlexSpacer minHeight={.625} />

      <Grid item xs={6}>
        <Typography size="small">
          {elipse}
          One special characters</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography size="small">
          {elipse}
          8 characters minimum</Typography>
      </Grid>
    </Grid>

    <FlexSpacer minHeight={1.5} />

  </>


  const profile = keycloak.authenticated ? (
    <>
      <label htmlFor="firstname">
        <Typography size='body'> First name</Typography>
      </label>
      <FlexSpacer minHeight={.625} />

      <StyledInput id="firstname" value={parsedToken?.given_name ?? ''} disabled></StyledInput>
      <FlexSpacer minHeight={1.5} />

      <label htmlFor="lastname">
        <Typography size='body'> Last name</Typography>
      </label>
      <FlexSpacer minHeight={.625} />

      <StyledInput id="lastname" value={parsedToken?.family_name ?? ''} disabled></StyledInput>
      <FlexSpacer minHeight={1.5} />

      <label htmlFor="organization_name">
        <Typography size='body'> Organization Name</Typography>
      </label>
      <FlexSpacer minHeight={.625} />

      <StyledInput id="organization_name" value={parsedToken?.organization_name ?? ''} disabled></StyledInput>
      <FlexSpacer minHeight={1.5} />



      <label htmlFor="email">
        <Typography size='body'> Email:</Typography>
      </label>
      <FlexSpacer minHeight={.625} />
      <StyledInput id="email" value={parsedToken?.email ?? ''} disabled></StyledInput>
      <FlexSpacer minHeight={1.5} />



      <label htmlFor="password">
        <Typography size='body'> Password</Typography>
      </label>
      <FlexSpacer minHeight={.625} />

      <StyldOutlinedInput
        id="password"
        sx={{ '& TextField legend': { display: 'none' }, '& TextField fieldset': { top: 0 }, }}
        type={values.showPassword ? 'text' : 'password'}
        value={values.password}
        // value={parsedToken?.password ?? ''} 
        // disabled
        onChange={handleChange('password')}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              value={parsedToken?.password ?? ''}
            >
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Password"
      />

      <FlexSpacer minHeight={.625} />


      {legend}


      <label htmlFor="phone">
        <Typography size='body'> Phone number</Typography>
      </label>
      <FlexSpacer minHeight={.625} />
      <StyledInput id="phone" type="tel" value={parsedToken?.phone ?? ''} disabled></StyledInput>

      <FlexSpacer minHeight={1.5} />



      <label htmlFor="last_name">
        <Typography size='body'> Profile picture</Typography>
      </label>
      <FlexSpacer minHeight={.625} />

      <form onSubmit={handleSubmit}>
        <FileUpload
          accept=".jpg,.png,.jpeg,.pdf"
          label=""
          multiple
          updateFilesCb={updateUploadedFiles}
        />
        {/* <button type="submit">Create New User</button> */}
      </form>

      <FlexSpacer minHeight={1.5} />
    </>
  ) : (
    <span>Please login to view profile.</span>
  );


  const languages = {
    type: [
      'English',
      'French',
      'German'
    ],
  }

  const general = keycloak.authenticated ? (
    <>
      <FlexSpacer minHeight={1.5} />

      <label htmlFor="language">
        <Typography size='body'> Language</Typography>
      </label>
      <FlexSpacer minHeight={.5} />

      <LightTooltip
        title="select language"
        placement={isTablet ? "top" : 'right'} arrow >
        <StyledSelect
          labelId="type-select-label"
          id="language"
          name="language"
          defaultValue="language"
          value={type}
          onChange={handleChangeSelect}
          sx={{
            "& .MuiMenuItem-root": {
              minHeight: 40
            },
            "& .MuiMenuItem-root.Mui-selected": {
              backgroundColor: theme.colors.white,
              fontWeight: 'bold'
            },
          }}
        >
          {languages?.type.map((item, index) => (
            <MenuItem key={index} value={item}>{item}</MenuItem>
          ))
          }
        </StyledSelect>
      </LightTooltip>

      <FlexSpacer minHeight={1.5} />

      <StyledFormGroup sx={{ marginLeft: 2 }}>
        <FormControlLabel control={<Checkbox />} label="I want to receive news about the product by e-mail." />
        <FormControlLabel control={<Checkbox defaultChecked />} label="I want to be e-mailed when a building has activity." />
      </StyledFormGroup>

      <FlexSpacer minHeight={2} />
    </>
  ) : (
    <span>Please login to view general.</span>
  );



  return (
    <StyledStack
      direction="column"
      className="settings-page"
      sx={{ width: isTablet ? '100%' : '60%' }}
    >

      <Stack direction="row" sx={{ paddingLeft: '.5rem' }}>
        <Link to="/buildings"
          style={{
            margin: '5px 0px -6px -10px',
            color: theme.colors.text,
            fontWeight: 'regular'
          }}
        >
          <BackArrowIcon />
        </Link>
        <Typography sx={{ paddingLeft: '.5rem' }} size='h2'>
          Settings
        </Typography>
      </Stack>


      <FlexSpacer minHeight={3} />

      <Typography
        size='h3'
        weight="medium"
      >
        General
      </Typography>

      {general}

      <Typography
        size='h3'
        weight="medium"
        sx={{ margin: '20px 0px 20px 0px' }}>
        Account settings
      </Typography>


      {profile}


      {/* To change your account data, click on the following button: */}
      {/* <br></br> */}

      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ textTransform: 'none' }}
        sx={{ width: isMobile ? '100%' : '200px' }}
        onClick={() => {
          keycloak.redirectUri = window.location.origin + '/settings'
          keycloak.accountManagement()
        }}
      >
        Update information
      </Button>
    </StyledStack>
  )
}

export default SettingsPage;
