import React, { FC, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Avatar, Box, Button, Card, CardActionArea, CardContent, CardMedia, Divider, Grid, Menu, MenuItem, Popover, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@mui/material'
import { theme } from '../../../utils/theme';
import Typography from '../../atoms/Typography';
import OptionsButton from '../../atoms/Buttons/OptionsButton';
import styled from '@emotion/styled';
import { FlexSpacer } from '../../atoms/FlexSpacer';
import { formatNumber, getExtension, isDocument, isImage, limitChars, removeTrailingZeros } from '../../../utils/functions';
import { DOCUMENT_STATUS_ADDED, DOCUMENT_STATUS_UPDATED, IMedia, documentStatus } from '../../../interfaces/media';

interface IDocument{
    name: string;
    thumbnail: string;
    base64: string;
    filename: string;
    mimetype: string;
    paid: number;
    owner: string;
    status: number;
    doc?: IMedia;
    show?: boolean;
    access?: boolean;
}

interface DocumentsProps {
    keyprefix: any,
    documentData?: any,
    showDetails?: any,
    handleOpenModalEstimate?: any,
    setDocument?: any,
}

const Documents: FC<DocumentsProps> = ({
    keyprefix,
    documentData,    
    showDetails,
    handleOpenModalEstimate,
    setDocument,
}) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const open = Boolean(anchorEl);

    const popoverList = [
        {
            label: 'Open the document',
            src: '/svg/icons/External.svg',
            color: 'inherit',
            onClick: () => {
                console.log('Open the document clicked')
                handleOpenModalEstimate();
            }
        },
        {
            label: 'Share to others',
            src: '/svg/icons/Share.svg',
            color: 'inherit',
            onClick: () => {
                console.log('Share to others clicked')
            },
            disabled: true
        },
        /*{ label: 'Print', src: '/svg/icons/Printer.svg', color: 'inherit', onClick: () => console.log('Print clicked') },*/
        {
            label: '',
            src: 'divider',
            color: 'black'
        },
        {
            label: 'Delete',
            src: '/svg/icons/Trash.svg',
            color: 'error',
            onClick: () => {
                console.log('Delete clicked')
            },
            disabled: true
        }
    ];

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        console.log('popoverList[index].label', popoverList[index].label);
        popoverList[index]?.onClick?.();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    console.log('documentData', documentData);

    return (
        <>
            {documentData.filter((document: IDocument) => document.show === true).length === 0 && 
                <Stack>
                    No documents
                </Stack>                
            }
                
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                <Grid container direction={isMobile ? 'column' : 'row'} spacing={3}>
                    {documentData.map((document: IDocument, index: number) => {
                        if(!document.show) return;

                        return (
                            <Grid item xs={4} key={`${keyprefix}-document-${index}`}>
                                <Card
                                    key={`${keyprefix}-documentcard-${index}`}
                                    sx={{
                                        maxWidth: isMobile ? '100%' : 345,                                
                                        border: `1px solid ${theme.colors.neutral3}`,
                                        borderRadius: '4px',
                                        boxShadow: 'none'
                                    }}
                                    onClick={() => {
                                        setDocument(document.doc)
                                        handleOpenModalEstimate();
                                    }}
                                >
                                    <CardActionArea>
                                        {document.thumbnail !== ''
                                            ? 
                                                <CardMedia
                                                    component="img"
                                                    height="140"
                                                    image={document.thumbnail && `data:image/jpg;base64,${document.thumbnail}`}
                                                    alt={document.name}
                                                    sx={{
                                                        backgroundColor: theme.colors.neutral2,
                                                        margin: '0px 0px -12px',
                                                        padding: isImage(document.mimetype) ? '' : '12px 12px 0px',
                                                        borderRadius: '4px',
                                                    }}
                                                />
                                            :
                                                ''
                                        }
                                        <CardContent>
                                            {document.thumbnail === ''
                                                ? (
                                                    <>
                                                        <Stack
                                                            direction="row"
                                                            sx={{
                                                                height: "140px",
                                                                margin: "-16px -16px 0px -16px",
                                                                backgroundColor: theme.colors.neutral2,
                                                            }}
                                                        >
                                                            <Stack
                                                                direction="row"
                                                                sx={{
                                                                    width: '100%',
                                                                    margin: !isImage(document.mimetype) ? '12px 12px 0px' : '',
                                                                    backgroundColor: theme.colors.white,
                                                                    borderRadius: '4px',
                                                                    border: `1px solid ${theme.colors.neutral3}`,
                                                                    borderBottom: 'none',
                                                                }}
                                                                alignContent={'center'}
                                                                alignItems={'center'}
                                                            >
                                                                <Typography
                                                                    size="14px"
                                                                    weight="medium"
                                                                    width={'100%'}
                                                                    justifyContent='center'
                                                                >
                                                                    {'.' + getExtension(document.filename)}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </>
                                                )
                                                : ''
                                            }
                                            <Stack direction="row" sx={{ justifyContent: 'space-between', paddingTop: '1rem' }}>
                                                <Typography size="14px" weight="medium">
                                                    {limitChars(document.name, 20)}
                                                </Typography>

                                                <Typography size="14px" color={theme.colors.neutral3}>
                                                    {getExtension(document.filename)}
                                                </Typography>
                                            </Stack>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            </Stack>
            
            {showDetails && documentData.length > 0 && (
                <>
                    <FlexSpacer minHeight={1.5} />

                    <Stack>
                        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>

                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell align="left">Amount paid</StyledTableCell>
                                            <StyledTableCell align="left">Uploader</StyledTableCell>
                                            <StyledTableCell align="left">Status</StyledTableCell>
                                            <StyledTableCell align="right" style={{minWidth: '20px'}}></StyledTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {documentData.map((document: IDocument, index: number) => (
                                            <TableRow
                                                key={`${keyprefix}-documents-table-${index}`}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <Typography
                                                        weight='regular'
                                                        sx={{
                                                            display: 'initial !important',
                                                            margin: '0',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            cursor: 'pointer',
                                                            maxWidth: '13ch',
                                                            letterSpacing: '0',
                                                            alignItems: 'center',
                                                            boxAlign: 'center',
                                                        }}>
                                                        {limitChars(document.name, 50)}
                                                    </Typography>
                                                    <br />
                                                    <Typography
                                                        weight='regular'
                                                        sx={{
                                                            display: 'initial !important',
                                                            margin: '0',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            cursor: 'pointer',
                                                            maxWidth: '13ch',
                                                            letterSpacing: '0',
                                                            alignItems: 'center',
                                                            boxAlign: 'center',
                                                            fontSize: '10px!important'
                                                        }}>
                                                        {/*
                                                        'miw: ' + document.doc!.magma_index_weight! * 100 + '% iw: ' + document.doc!.index_weight! * 100 + '% hash: ' + limitChars(document.doc!.versions[0].hash!, 40)
                                                        */}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Typography weight='regular'>
                                                        {!document.paid ? '/' : `${formatNumber(removeTrailingZeros(document.paid))} MUT`}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell align="right">
                                                    <Avatar
                                                        sx={{ width: '24px', height: '24px', marginRight: 'auto' }}
                                                        alt="avatar"
                                                        title={document?.doc?.versions[0].activity?.find(a => [DOCUMENT_STATUS_ADDED, DOCUMENT_STATUS_UPDATED].includes(a.action))?.firstname + ' ' + document?.doc?.versions[0].activity?.find(a => [DOCUMENT_STATUS_ADDED, DOCUMENT_STATUS_UPDATED].includes(a.action))?.lastname}
                                                        src={`/../images/stakeholders/${document.owner}`}
                                                    />
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Button
                                                        variant="outlined"
                                                        size='small'
                                                        sx={{
                                                            pointerEvents: 'none',
                                                            maxHeight: '24px',
                                                            fontSize: '12px!important',
                                                            borderColor: documentStatus.find(stat => stat.ID === document.status)!.borderColor,
                                                            color: documentStatus.find(stat => stat.ID === document.status)!.color,
                                                            bgcolor: documentStatus.find(stat => stat.ID === document.status)!.bgcolor,
                                                            width: isMobile ? '100%' : 'auto',
                                                            marginRight: 'auto',

                                                        }}
                                                    >
                                                        {documentStatus.find(stat => stat.ID === document.status)!.desc}
                                                    </Button>
                                                </TableCell>

                                                <TableCell align="right" style={{ position: 'relative' }}>
                                                        
                                                    {document.access &&
                                                        <OptionsButton
                                                            top={16}
                                                            right={2}
                                                            id="document-options-menu-button"
                                                            aria-controls={open ? 'demo-positioned-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open ? 'true' : undefined}
                                                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                                                setDocument(document.doc);
                                                                handleClick(event);
                                                            }}
                                                            sx={{width: '24px', height: '24px'}}
                                                        />
                                                    }

                                                </TableCell>
                                            </TableRow>
                                        ))}
                                            
                                        <Popover
                                            id="documents-options-menu"
                                            open={open}
                                            anchorEl={anchorEl}
                                            disableScrollLock={false}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            PaperProps={{
                                                elevation: 1
                                            }}
                                            sx={{
                                                ml: '8px',
                                                mt: '0px'
                                            }}
                                        >
                                            {popoverList.map((item, index) => (
                                                <Stack
                                                    key={`${keyprefix}-stakeholderMenuItem-${index}`}
                                                >
                                                    {item.src === "divider"
                                                        ?
                                                            <Divider sx={{marginX: '16px'}} />
                                                        : (
                                                            <StyledMenuItem
                                                                key={index}
                                                                onClick={(event) => handleMenuItemClick(event, index)}
                                                                sx={{
                                                                    height: '42px',
                                                                    margin: '4px!important'
                                                                }}
                                                                disabled={item.disabled ? item.disabled : false}
                                                            >
                                                                <Stack direction="row">
                                                                    <img src={item.src} className={`icon-${item.color}`} alt="icon" />
                                                                    <Typography color={item.color} sx={{ ml: 1 }}>{item.label}</Typography>
                                                                </Stack>
                                                            </StyledMenuItem>
                                                        )
                                                    }
                                                </Stack>
                                            ))}
                                        </Popover>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Stack>
                </>
            )}
        </>
    )
}


{/*<StyledMenuItem
    key={index}
    onClick={(event) => handleMenuItemClick(event, index)}
    className={`${selectedIndex}`}
    sx={{height: '42px'}}
>
    <Box
        onClick={item.onCLick}
        color="inherit"
        style={{ padding: 0, textTransform: 'inherit', width: '100%', justifyContent: "flex-start" }}>
        <Stack direction="row">
            <img src={item.src} className={`icon-${item.color}`} alt="icon" />
            <Typography color={item.color} sx={{ ml: 1 }}>{item.label}</Typography>
        </Stack>
    </Box>
</StyledMenuItem>*/}

export default Documents

const StyledTableCell = styled(TableCell)`
    color: ${theme.colors.neutral4};
`

const StyledMenuItem = styled(MenuItem)`
    :hover{
        div{
            transform: translateX(-4px);
        }

        img{
            filter: invert(1);
        }
        span {
            color: white;
        }
    }
`;