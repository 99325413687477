/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */
import { Stripe, loadStripe } from '@stripe/stripe-js'

const STRIPE_PK = process.env.REACT_APP_STRIPE_PK || ''; 

let stripePromise: Promise<Stripe | null>
const getStripe = () => {
  if(STRIPE_PK === undefined || STRIPE_PK === ''){
    throw Error('STRIPE_PK is empty');
  }
  else{
    console.log('STRIPE_PK', STRIPE_PK);
  }

  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_PK)
  }
  return stripePromise
}
 
export default getStripe