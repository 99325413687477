
import { Menu, menuClasses, MenuProps, Tooltip, } from '@mui/material';
import { styled } from "@mui/material/styles";
import { theme } from '../../../utils/theme';

const StyledMenu = styled(Menu)`
    & > ul {
        list-style: disc !important;
        list-style-type: circle !important;
    }
`

const CustomMenu = styled(({ className, ...props }: MenuProps) => (
    <StyledMenu
        {...props}
        className={className}
        disableScrollLock={true}
        disablePortal={true}
        MenuListProps={{
            'aria-labelledby': 'basic-button',
        }}
        sx={{
            "& .MuiMenuItem-root": {
                minHeight: 40
            },
            "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: theme.colors.white,
                fontWeight: 'bold'
            },
        }} />
))(({ theme }) => ({
   
}));



export default CustomMenu