import { Box, Button, Card, CardContent, Divider, Grid, List, ListItem, ListItemText, Stack, useMediaQuery } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Typography } from '../../design-system/atoms/Typography/Typography';
import { Link, useNavigate, useParams } from "react-router-dom";
import { FlexSpacer } from "../../design-system/atoms/FlexSpacer";
import { useKeycloak } from "@react-keycloak/web";
import { styled, useTheme } from "@mui/material/styles";
import { IBuilding } from "../../interfaces/building";
import BuildingService from "../../services/buildingService";
import WalletCard from "../../design-system/molecules/Cards/WalletCard";
import { toast } from "react-toastify";
import { formatNumber, getWalletBalance, removeTrailingZeros } from "../../utils/functions";


//============= TODO: CLEANUP UPNUSED FUNCTIONS =============//

interface BuildingReviewProps {
  //handleOpenModalStakeholders?: any
  buildingWallet: any;
  setBuildingWallet: any;
  setWallets: any;
  setWalletType: Function;
  blockchain: any;
  handleOpenModalAllowPopup?: any
  handleOpenModalWalletSelector: any
  building?: any;
  setBuilding?: any;
}

const BuildingReviewPage: FC<BuildingReviewProps> = ({
  buildingWallet,
  setBuildingWallet,
  setWallets,
  setWalletType,
  blockchain,
  handleOpenModalAllowPopup,
  handleOpenModalWalletSelector,
  building,
  setBuilding,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  let { id } = useParams();
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  useEffect(() => {
    /* fix if the user is already logged in and coming from loginpage directly */
    if (!keycloak.authenticated) {
      navigate('/');
      keycloak.logout();
    }

    fetchBuildingInfo();
  },[]);

  function fetchBuildingInfo() {
    const buildingService = new BuildingService();
    buildingService.getBuildingInfo(keycloak, id)
        .then(async(response) => {
            if (!response){
              navigate('/');
              return;
            }
            
            const building: IBuilding = response;
            setBuilding(building);

            if(building.wallet_address !== undefined &&
              building.wallet_address !== null &&
              building.wallet_address !== '')
            {
              setBuildingWallet({
                name: building.building_name,
                address: building.wallet_address,
                balance: {
                    gasBalance: await getWalletBalance(blockchain, building.wallet_address)
                }
              });
            }
            else{
              setBuildingWallet(undefined);
            }
        })
        .catch((error) => {
            if (error.response.status === 401) {
                navigate('/');
                keycloak.logout();
            }
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.error(`Error: ${error}`)
        });
  }

  const handleConnectBuildingWallet = async() => {
    console.log("connect building wallet");

    /*if (!window.localStorage.getItem("hideModalAllowPopup")) {
      handleOpenModalAllowPopup();
    }*/

    const wallets = await blockchain.askPermissionForAccount();
    console.log("connectBuildingWallet wallets", wallets);

    /* FORCE SHOWING THE WALLETSELECTOR, UNCOMMENT THE CODE BELOW  */
    if(wallets.length > 0){
      setWallets(wallets);
      if(setWalletType){
          setWalletType({
              func: setBuildingWallet,
              type: 'building'    
          });
      }
      
      handleOpenModalWalletSelector();
    }
    /*
    if (wallets.length === 1) {
      setBuildingWallet({
        name: building?.building_name,
        address: wallets[0].address,
        balance: {
            gasBalance: await getWalletBalance(blockchain, wallets[0].address)
        }
      });

      const data = {
        "building_ID": id,
        "wallet_chain": chain,
        "wallet_address": wallets[0].address
      }

      const buildingService = new BuildingService();
      buildingService.updateBuildingFields(keycloak, data)
        .then((response) => {
          console.log(response);
          if(response.data.status){
            //setBuilding(building);
            toast.success("Building wallet has been updated!");
          }
          else {
            toast.error("Building wallet not updated, try again");
          }          
        })
        .catch((error) => {
          toast.error("Error updating building wallet, try again!")

          if (error.response.status === 401) {
                navigate('/');
                keycloak.logout();
            }            
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.error(`Error: ${error}`)
        });
    }
    else if(wallets.length > 1){
      setWallets(wallets);
      if(setWalletType){
        setWalletType(() => setBuildingWallet);
      }
      handleOpenModalWalletSelector();
    }
    */
  }

  const handlePayment = async() => {
    console.log('pay with Stripe');
    navigate(`/building/${id}/checkout`);
  }

  return (
    <>
      <Stack direction="row" sx={{ paddingLeft: '.5rem' }}>
        <Link to={`/building/${id}/stakeholders`}
          style={{
            margin: '5px 0px -6px -10px',
            color: theme.colors.text,
            fontWeight: 'regular'
          }}
        >
          <img src="/svg/icons/ArrowLeft.svg" alt="go back" width="32px" height="32px" />
        </Link>
        <Typography sx={{ paddingLeft: '.5rem' }} size='h2'>
          Add a new building
        </Typography>
      </Stack>

      <FlexSpacer minHeight={2} />

      <Box
        sx={{
          width: isTablet ? '100%' : '100%', maxWidth: 670,
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '100%' },
          gap: 2,
        }}
      >

        <Typography size='h3' weight='medium'>
          Review
        </Typography>

        {/* Building */}

        <StyledBox>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Typography size='h4' weight='medium'>
              Building information
            </Typography>

            <Button
              variant="outlined"
              color="inherit"
              size="medium"
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                textTransform: 'inherit',
                border: '1px solid #D9D9D9',
                height: '40px',
                marginTop: "auto",
                fontSize: ".7rem"
              }}
              onClick={() => navigate(`/building/${id}/add`)}
            >
              <img width={16} height={16} src="/svg/icons/Pencil.svg" alt="edit icon" />
              <Typography weight="medium" ml="6.21px">Edit</Typography>
            </Button>

          </Stack>

          <FlexSpacer minHeight={2} />

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>

              <Typography weight="medium" size="h5">Name</Typography>
              <FlexSpacer minHeight={.25} />
              <Typography weight="regular" size="body">{building?.building_name}</Typography>

            </Grid>
            <Grid item xs={12} md={4}>

              <Typography weight="medium" size="h5">Type</Typography>
              <FlexSpacer minHeight={.25} />
              <Typography weight="regular" size="body">{building?.building_type} </Typography>

            </Grid>
            <Grid item xs={12} md={4}>

              <Typography weight="medium" size="h5">Construction year</Typography>
              <FlexSpacer minHeight={.25} />
              <Typography weight="regular" size="body">{building?.construction_year} </Typography>

            </Grid>
          </Grid>

          <FlexSpacer minHeight={1.875} />

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>

              <Typography weight="medium" size="h5">Size</Typography>
              <FlexSpacer minHeight={.25} />
              <Typography weight="regular" size="body">{formatNumber(removeTrailingZeros(building?.size))} sqm</Typography>

            </Grid>
            <Grid item xs={12} md={4}>

              <Typography weight="medium" size="h5">Address</Typography>
              <FlexSpacer minHeight={.25} />

              <Typography weight="regular" size="body">{building?.address}</Typography>

            </Grid>
            <Grid item xs={12} md={4}>

              <Typography weight="medium" size="h5">Owner information</Typography>
              <FlexSpacer minHeight={.25} />
              <Typography weight="regular" size="body">{building?.owner}</Typography>
              <Typography weight="regular" size="body">{building?.owner_address}</Typography>

            </Grid>
          </Grid>


        </StyledBox>

        {/* Stakeholders */}

        <StyledBox>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Typography size='h4' weight='medium'>
              Stakeholders
            </Typography>

            <Button
              variant="outlined"
              color="inherit"
              size="medium"
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                textTransform: 'inherit',
                border: '1px solid #D9D9D9',
                height: '40px',
                marginTop: "auto",
                fontSize: ".7rem"
              }}
              onClick={() => navigate(`/building/${id}/stakeholders`)}
            >
              <img width={16} height={16} src="/svg/icons/Pencil.svg" alt="edit icon" />
              <Typography weight="medium" ml="6.21px">Edit</Typography>
            </Button>

          </Stack>

          <FlexSpacer minHeight={2} />

          {
            building !== undefined &&
            building.stakeholders !== undefined && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} key={"review_owner"}>
                <StyledCard variant="outlined">
                  <StyledCardContent>
                    <Typography weight="medium" size="h5">Owner</Typography>
                    <FlexSpacer minHeight={.25} />
                    <Typography weight="regular" size="body">{building.owner}</Typography>
                    <Typography weight="regular" size="body">{building.owner_address}</Typography>
                  </StyledCardContent>
                </StyledCard>
              </Grid>
              {building.stakeholders.filter((stakeholder: any) => stakeholder.user_type_ID.toString() === '2').map( (stakeholder: any, index: number) => {
                return (
                  <Grid item xs={12} md={6} key={"review_assetmanager"}>
                    <StyledCard variant="outlined">
                      <StyledCardContent>
                        <Typography weight="medium" size="h5">{stakeholder.user_type_name}</Typography>
                        <FlexSpacer minHeight={.25} />
                        <Typography weight="regular" size="body">{stakeholder.firstname + ' ' + stakeholder.lastname}</Typography>
                        <Typography weight="regular" size="body">{stakeholder.company}</Typography>
                        <Typography weight="regular" size="body">{stakeholder.email}</Typography>
                      </StyledCardContent>
                    </StyledCard>
                  </Grid>
                )
              })}               
            </Grid>
            )
          }

          <FlexSpacer minHeight={1.875} />

          {
            building !== undefined &&
            building.stakeholders !== undefined && (
            <Grid container spacing={2}>
              {building.stakeholders.filter((stakeholder: any) => !['1','2'].includes(stakeholder.user_type_ID.toString())).map( (stakeholder: any, index: number) => {
                return (
                  <Grid item xs={12} md={6} key={index}>
                    <StyledCard variant="outlined" sx={{ pb: '15px' }}>
                      <StyledCardContent>
                        <Typography weight="medium" size="h5">{stakeholder.user_type_name}</Typography>
                        <FlexSpacer minHeight={.25} />
                        <Typography weight="regular" size="body">{stakeholder.firstname + ' ' + stakeholder.lastname}</Typography>
                        <Typography weight="regular" size="body">{stakeholder.company}</Typography>
                        <Typography weight="regular" size="body">{stakeholder.email}</Typography>
                      </StyledCardContent>
                    </StyledCard>
                  </Grid>
                )
              })}
            </Grid>
            )
          }
        </StyledBox>

        <StyledBox sx={{ backgroundColor: '#F2F4FF', border: 'none' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} key={"review_walletLeft"}>
              <Typography size='h4' weight='medium' sx={{ lineHeight: '25px' }}>
                Create a building wallet
              </Typography>
              <FlexSpacer minHeight={1} />
              <Typography size='body' weight='regular' sx={{ fontSize: '14px', lineHeight: '19px'}}>
                Magma works with Venly to safely manage Magma wallets.<br />
                <br/>
                Pay stakeholders with the building wallet
              </Typography>
              <FlexSpacer minHeight={2} />
              {buildingWallet !== undefined && buildingWallet.address !== ''
                ? (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{ fontSize: ".7rem", minWidth: isMobile ? 'auto' : "auto", marginLeft: 'auto', cursor: 'default' }}
                  >
                    <img
                      alt="v"
                      color="white"
                      src="/svg/icons/CheckWhite.svg"
                      width={20}
                      height={20}
                    />
                    <Box ml={isMobile ? 0 : '10px'} style={{ width: 'auto', textTransform: 'none' }}>
                      <Typography weight="medium">
                        Wallet connected
                      </Typography>
                    </Box>                  
                  </Button>
                )
                : (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      sx={{ fontSize: ".7rem", minWidth: isMobile ? 'auto' : "auto", marginLeft: 'auto' }}
                      onClick={handleConnectBuildingWallet}
                    >
                      <img
                        alt="+"
                        color="white"
                        src="/svg/icons/Plus.svg"
                        width={20}
                        height={20}
                      />
                      <Box ml={isMobile ? 0 : '10px'} style={{ width: 'auto', textTransform: 'none' }}>
                        <Typography weight="medium">
                          Create or connect<br />building wallet
                        </Typography>
                      </Box>                  
                    </Button>
                  </>
                )
              }
              
            </Grid>
            <Grid item xs={12} md={7} key={"review_walletRight"}>
              <Box sx={{ margin: '10px 20px 0' }}>
                <WalletCard
                  isModal={true}
                  wallet={
                    buildingWallet !== undefined
                    ? buildingWallet
                    : { name: building?.building_name, address: '', balance: { gasBalance: 0}}}
                  walletType='buildingWallet'
                />
              </Box>
            </Grid>
          </Grid>

          {!buildingWallet && (
            <>
              <FlexSpacer minHeight={2} />

              <Grid container alignItems="center" spacing={1}
                style={{ border: '1px solid #FFA500', borderRadius: '4px', backgroundColor: '#FFF7E6', padding: '12px' }}
              >
                <Grid item xs={12} md={1}>
                  <img
                    alt="+"
                    color="white"
                    src="/svg/icons/Warning.svg"
                    width={30}
                    height={30}
                    style={{filter: "brightness(0) saturate(100%) invert(58%) sepia(91%) saturate(799%) hue-rotate(359deg) brightness(101%) contrast(104%)"}}
                  />
                </Grid>
                <Grid item xs={12} md={11}>
                  <Typography weight="medium" style={{ color: '#FFA500' }}>
                    Please be aware that when clicking the button, a Venly popup will open. If the popup does not appear, it is possible that your browser has blocked it. Please make sure to allow popups in your browser settings to proceed.
                  </Typography>
                </Grid>
              </Grid> 
            </>
          )}
        </StyledBox>

        {/* Due */}
        <StyledBox sx={{ boxShadow: '4px 4px 0 0 #001AFF' }}>
          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Typography size='h4' weight='medium'>
              Your quote
            </Typography>

            <Button
              variant="outlined"
              color="inherit"
              size="medium"
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                textTransform: 'inherit',
                border: '1px solid #D9D9D9',
                height: '40px',
                marginTop: "auto",
                fontSize: ".7rem"
              }}
            >
              <Typography weight="medium" ml="6.21px" onClick={() => navigate('/privacy-policy')}>
                See our pricing policy
              </Typography>
            </Button>

          </Stack>


          {/*
          <Typography size='body' weight='regular'>
            Based on your current needs.
          </Typography>
          */}

          <FlexSpacer minHeight={2} />

          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Typography weight="medium" size="h5">Setup fees</Typography>
            <Typography weight="medium" size="body">5 000 €</Typography>
          </Stack>
          <FlexSpacer minHeight={.5} />

          <Typography weight="regular" size="body">
            The setup fees are due one time per building and includes:
          </Typography>

          <ul style={{
            marginLeft: '20px',
            listStyle: 'disc',
            fontSize: '14px'
          }} className='styledUnorderedList'>
            <li>
              <p>
                Creation of the Digital Twin Token (DTT®) on Level Genesis
              </p>
            </li>
            <li>
              <p>
                Building wallet creation
              </p>
            </li>
            <li>
              <p>
                Stakeholder wallet creation
              </p>
            </li>
            <li>
              <p>
                Onboarding of up to ten (10) Stakeholders
              </p>
            </li>
            <li>
              <p>
                Credit of 2,500 $MUT for rewards in the Building’s wallet
              </p>
            </li>
            <li>
              <p>
                Hashing of up to 1,000 documents
              </p>
            </li>
            <li>
              <p>
                Gas fees for transactions
              </p>
            </li>
            <li>
              <p>
                One workshop to learn how to create the DTT® and use wallets
              </p>
            </li>
            <li>
              <p>
                1 year membership
              </p>
            </li>
            <li>
              <p>
                1 year support
              </p>
            </li>
          </ul>

          <FlexSpacer minHeight={1.875} />
          <Divider />
          <FlexSpacer minHeight={1.875} />

          <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            <Typography weight="medium" size="h4">Due </Typography>
            <Typography weight="medium" size="body">5 000 €</Typography>
          </Stack>
        </StyledBox>

        <FlexSpacer minHeight={1.875} />

        {/* Footer */}
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
          }}>

          {!isMobile && <FlexSpacer minWidth={2} />}

          <Stack direction={isMobile ? 'column' : 'row'} sx={{
            alignItems: 'right',
            justifyContent: 'end',
            width: !isMobile ? '70% !important' /*'-webkit-fill-available'*/ : '100% !important'
          }}>
            
            {false && (
            <Box
              color="inherit"
              style={{
                textTransform: 'inherit',
                justifyContent: 'space-evenly',
                marginRight: "1rem",
                minHeight: '46px',
                lineHeight: '17.71px',
                padding: '14px 9px',
                fontSize: "8757rem",
                minWidth: '130px',
                maxWidth: '170px'
              }}
            >
              <Stack direction="row">
                <img
                  width={16}
                  height={16}
                  src="/svg/icons/Save.svg"
                  alt="save icon"
                // style={{ margin: '.1rem 0' }}
                />
                <Typography ml={1}>Draft Saved.</Typography>
              </Stack>
            </Box>
            )}

            {isMobile && <FlexSpacer minHeight={.5} />}

            <Button
              variant="outlined"
              color="inherit"
              fullWidth
              size="large"
              sx={{ textTransform: 'inherit', fontSize: ".7rem", minWidth: isMobile ? '100%' : 150, marginLeft: 'auto', marginRight: '.75rem' }}
              onClick={() => navigate(`/building/${id}/stakeholders`)}
            >
              Previous Step
            </Button>

            {isMobile && <FlexSpacer minHeight={.5} />}

            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              sx={{ fontSize: ".7rem", minWidth: isMobile ? 'auto' : "auto", marginLeft: 'auto' }}
              onClick={handlePayment}
              disabled={buildingWallet === undefined || buildingWallet.address === ''}
            >
              Proceed to payment
            </Button>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

export default BuildingReviewPage;

const StyledBox = styled(Box)`  
   margin: 1rem 0 0 0;
   padding: 2rem;
   border: 1px solid #EBECEF;
   border-radius: 4px;
`;

const StyledCard = styled(Card)`  
   background-color: transparent;
   border: none;
`;

const StyledCardContent = styled(CardContent)`  
  padding: 0px!important;
`;

const StyledList = styled(List)``;
const StyledListItem = styled(ListItem)``;
const StyledListItemText = styled(ListItemText)``;