import { FC } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import { Router } from './Router';
import { theme } from './utils/theme';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';
import { ThemeProvider } from '@mui/material';
import './styles/stripe.css';
import './styles/globals.css';
import './styles/mui-overrides.css';
interface AppProps {
  name?: any
}

const App: FC<AppProps> = () => {
  return (
    <div className="App">
      <ReactKeycloakProvider authClient={keycloak}>
        <ThemeProvider theme={theme}>          
          <Router />
        </ThemeProvider>
      </ReactKeycloakProvider>
    </div>
  );
}

export default App;
