import axios, { AxiosRequestConfig } from "axios";
import { IUserInfo, IUserType } from "../interfaces/userInfo";

const url = `${process.env.REACT_APP_API_URL}/user`;

export default class UserService {

  getUser(keycloak: any) {
    const urlUserInfo = url ;

    const config: AxiosRequestConfig = {
      headers: {
          Authorization: `Bearer ${keycloak.token}`
      }
    };

    return axios.get(urlUserInfo, config)
    .then((response) => {
        const userInfo: IUserInfo = response.data.result;

        return userInfo;
    })
    .catch(error => {
        throw error; 
        //return error;
    });
  };
    
  getUserTypes (keycloak: any, _data?: any) {

    const urlUserTypes = url + '/types';

    if(_data === undefined){
      _data = {};
    }

    const config: AxiosRequestConfig = {
    headers: {
        Authorization: `Bearer ${keycloak.token}`
    },
    params: _data
    };

    return axios.get(urlUserTypes, config)
    .then((response) => {
        const userTypes: IUserType[] = response.data.result;

        return userTypes;
    })
    .catch(error => {
        throw error; 
        //return error;
    });
  };

  checkDuplicatedUserByEmail( keycloak: any, _email: string){

    const urlUserTypes = url + '/searchByEmail';

    const data = {
      "email": _email,
    }

    const config: AxiosRequestConfig = {
    headers: {
        Authorization: `Bearer ${keycloak.token}`
    },
    params: data
    };

    return axios.get(urlUserTypes, config)
    .then((response) => {
        const userFoundResult: any = response.data;
        console.log('checkDuplicatedUserByEmail',userFoundResult);
        
        if(userFoundResult === true || userFoundResult === false){
          return userFoundResult;
        }
        else{
          return false;
        }
        //return (user.length !== 0);
    })
    .catch(error => {
        throw error; 
        //return error;
    });
  }

  createNDA (keycloak: any, data: any) {
    const urlCreateNDA = url + '/nda';

    const config: AxiosRequestConfig = {
      method: 'post',
      url: urlCreateNDA,
      data: data,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
        'Content-Type': 'application/json'
      }
    };

    return axios(config)
    .then((response) => {
      return response;
    })
    .catch(error => {
      throw error; 
    });
  }

  updateUserWallet (keycloak: any, data: any) {
    const urlUpdateUserWallet = url + '/wallet';

    const config: AxiosRequestConfig = {
      method: 'post',
      url: urlUpdateUserWallet,
      data: data,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
        'Content-Type': 'application/json'
      }
    };

    console.log("updateUserWallet", config);

    return axios(config)
    .then((response) => {
      return response;
    })
    .catch(error => {
      throw error; 
    });
  }

  createUserPayment (keycloak: any, data: any) {
    const urlCreateUserPayment = url + '/payment';

    const config: AxiosRequestConfig = {
      method: 'post',
      url: urlCreateUserPayment,
      data: data,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
        'Content-Type': 'application/json'
      }
    };

    console.log("createUserPayment", config);

    return axios(config)
    .then((response) => {
      return response;
    })
    .catch(error => {
      throw error; 
    });
  }

  updateUserPayment (keycloak: any, data: any) {
    const urlUpdateUserPayment = url + '/payment';

    const config: AxiosRequestConfig = {
      method: 'put',
      url: urlUpdateUserPayment,
      data: data,
      headers: {
        'Authorization': `Bearer ${keycloak.token}`,
        'Content-Type': 'application/json'
      }
    };

    console.log("updateUserPayment", config);

    return axios(config)
    .then((response) => {
      return response;
    })
    .catch(error => {
      throw error; 
    });
  }
}