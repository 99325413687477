import { Box, Button, Stack, useMediaQuery } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Typography from "../../design-system/atoms/Typography";
import { theme } from "../../utils/theme";
import { FlexSpacer } from "../../design-system/atoms/FlexSpacer";
import AddStakeholdersForm from "../../design-system/organisms/AddStakeholdersForm";
import { useKeycloak } from "@react-keycloak/web";
import { useForm } from "react-hook-form";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";

interface BuildingStakeholdersPageProps {
    handleOpenModalAddStakeholder?: any;
    setBuildingID?: any;
    setIncludeUserTypeID?: any;
    setExcludeUserTypeID?: any;
    setMaxStakeholders?: any;
    setMyCallBack?: any;
    setBuildingWallet?: any;
    blockchain: any;
}

const defaultValues: {stakeholder: {
    user_ID: string,
    user_type_ID: string,
    firstname: string,
    lastname: string,
    company: string,
    email: string,
    isNewStakeholder: boolean
}[]} = {
    stakeholder: [{
        user_ID: '',
        user_type_ID: '',
        firstname: '',
        lastname: '',
        company: '',
        email: '',
        isNewStakeholder: true
    }]
}

const BuildingStakeholdersPage: FC<BuildingStakeholdersPageProps> = ({
    handleOpenModalAddStakeholder,
    setBuildingID,
    setIncludeUserTypeID,
    setExcludeUserTypeID,
    setMaxStakeholders,
    setMyCallBack,
    setBuildingWallet,
    blockchain,
}) => {
    console.log("BuildingStakeholdersPage");
    let { id } = useParams();
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();
    const [isSaved, setIsSaved] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        console.log("setBuildingID");
        setBuildingID(id);
        setIncludeUserTypeID();
        setExcludeUserTypeID([1,2]);
        setMaxStakeholders(-1);
    }, [setBuildingID, setIncludeUserTypeID, setExcludeUserTypeID, setMaxStakeholders]);

    const {
        control,
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { errors }
    } = useForm({
        mode: "onChange"
    });

    const onSubmit = (data: any) => {
        setTimeout(() => {
            setIsSaved(true);
        }, 2000);

        const stakeholderToSave = data.stakeholder.filter((stakeholder: any) => stakeholder.isNewStakeholder === true);

        addStakeholderInvitees(stakeholderToSave);
    }

    /*const addStakeholders = () => {
        // we will store our user data via logic

        setTimeout(() => {
            setIsSaved(true);

            navigate(`/building/${id}/review`);
            console.log(isSaved)
        }, 500);

    };*/

    const addStakeholderInvitees = (stakeholderToSave: any) => {
        if (stakeholderToSave === undefined || stakeholderToSave.length === 0) {
            console.log("not saving a stakeholder");
            navigate(`/building/${id}/review`);
            return;
        }
    
        const stakeholders = stakeholderToSave.map((stakeholder: any) => {
            return {
                "firstname": stakeholder.firstname,
                "lastname": stakeholder.lastname,
                "company": stakeholder.company,
                "email": stakeholder.email,
                "user_type_ID": stakeholder.user_type_ID
            }
        })

        const url = `${process.env.REACT_APP_API_URL}/building/stakeholder`;
    
        const data = {
            "building_ID": id,
            "stakeholders": stakeholders
        }
    
        console.log("data",data);

        const config: AxiosRequestConfig = {
            method: 'post',
            url: url,
            data: data,
            headers: {
                'Authorization': `Bearer ${keycloak.token}`,
                'Content-Type': 'application/json'
            }
        };
    
        console.log("api config", config);
    
        axios(config)
            .then((response) => {
                //const userInfo: IUserInfo = response.data.result;
                console.log("building-stakeholders", response);
    
                if (!response.data.status) {
                    console.log(response.data.msg + "\n" + response.data.error);
                    toast.error(`Error inviting ${stakeholderToSave.length === 1 ? 'stakeholder' : 'stakeholders'}, try again!`);
                }
                else {
                    toast.success(`${stakeholderToSave.length === 1 ? 'Stakeholder has been invited' : 'Stakeholders are invited'}!`);
                    //handleOpenModalDelegate();
                    navigate(`/building/${id}/review`);
                }
                //getUserInfo(userInfo);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    navigate('/');
                    keycloak.logout();
                }
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                console.error(`Error: ${error}`)
            });
    }

    return (
        <Box sx={{ width: '100%', maxWidth: 420 }}>
            <Stack direction="row" sx={{ paddingLeft: '.5rem' }}>
                <Link to={`/building/${id}/asset-manager`}
                    style={{
                        margin: '5px 0px -6px -10px',
                        color: theme.colors.text,
                        fontWeight: 'regular'
                    }}
                >
                    <img src="/svg/icons/ArrowLeft.svg" alt="go back" width="32px" height="32px" />
                </Link>
                <Typography sx={{ paddingLeft: '.5rem' }} size='h2'>
                    Add a new building
                </Typography>
            </Stack>

            <FlexSpacer minHeight={2} />

            <Typography sx={{ paddingLeft: '.2rem' }} size='h3' weight='regular'>
                Stakeholders (Optional)
            </Typography>

            <FlexSpacer minHeight={1.875} />

            <Box
                component="form"
                /*noValidate*/
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    display: 'grid',
                    gridTemplateColumns: { xs: '100%' },
                }}
            >
                <AddStakeholdersForm
                    building_ID={id}
                    control={control}
                    register={register}
                    defaultValues={defaultValues}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    watch={watch}
                    setIncludeUserTypeID={setIncludeUserTypeID}
                    handleOpenModalAddStakeholder={handleOpenModalAddStakeholder}
                    setMyCallBack={setMyCallBack}
                    setBuildingWallet={setBuildingWallet}
                    blockchain={blockchain}
                />

                <FlexSpacer minHeight={2} />

                {/* Footer */}
                <Stack
                    direction="row"
                    sx={{
                        justifyContent: 'space-between',
                        width: !isMobile ? '-webkit-fill-available' : '100% !important'
                    }}>

                    {!isMobile && <FlexSpacer minWidth={2} />}

                    <Stack
                        direction={isMobile ? 'column' : 'row'}
                        sx={{
                            justifyContent: 'end',
                            alignItems: 'center',                           
                            width: !isMobile ? '-webkit-fill-available' : '100% !important'
                        }}>
                        {false &&
                            <Box
                                color="inherit"
                                style={{
                                    textTransform: 'inherit',
                                    justifyContent: 'space-evenly',
                                    marginRight: "1rem",
                                    minHeight: '46px',
                                    lineHeight: '17.71px',
                                    padding: '14px 9px',
                                    fontSize: "8757rem",
                                    minWidth: '130px',
                                    maxWidth: '170px'
                                }}
                            >
                                <Stack direction="row">
                                    <img
                                        width={16}
                                        height={16}
                                        src="/svg/icons/Save.svg"
                                        alt="save icon"
                                    // style={{ margin: '.1rem 0' }}
                                    />
                                    <Typography ml={1}>Draft Saved.</Typography>
                                </Stack>
                            </Box>
                        }

                        {isMobile && <FlexSpacer minHeight={1} />}

                        <Button
                            variant="outlined"
                            color="inherit"
                            fullWidth
                            size="large"
                            sx={{ textTransform: 'inherit', fontSize: ".7rem", minWidth: isMobile ? '100%' : 150, marginLeft: 'auto', marginRight: '.75rem' }}
                            onClick={() => navigate(`/building/${id}/asset-manager`)}
                        >
                            Previous Step
                        </Button>

                        {isMobile && <FlexSpacer minHeight={1} />}

                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            sx={{ fontSize: ".7rem", minWidth: isMobile ? 'auto' : "auto", marginLeft: 'auto' }}
                            type="submit"
                        >
                            Next Step
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );

}

export default BuildingStakeholdersPage;