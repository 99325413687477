import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Button, Stack } from '@mui/material';
import Typography from '../../../atoms/Typography';
import { theme } from '../../../../utils/theme';
import { FlexSpacer } from '../../../atoms/FlexSpacer';
import { useNavigate } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import BuildingService from '../../../../services/buildingService';
import { INotification } from '../../../../interfaces/notification';
import { calcDiffTime } from '../../../../utils/functions';



type ActivityTableProps = {
    handleOpenModalReminderSent?: any;
    selectedBuildings?: any;
}
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
}

const FALLBACK_IMAGE =
  "/images/Logo_Transparent_White.png";

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

const notification_cat = [{
    ID: 1,
    name: 'Announcement'
},{
    ID: 2,
    name: 'Reminder'
},{
    ID: 3,
    name: 'Document'
}]

const notification_type = [{
    ID: 1,  notification_cat_ID: 1, name: 'Invitation'
},{
    ID: 2,  notification_cat_ID: 1, name: 'Access request'
},{
    ID: 3,  notification_cat_ID: 1, name: 'Access request'
},{
    ID: 4,  notification_cat_ID: 1, name: ''
},{
    ID: 5,  notification_cat_ID: 1, name: ''
},{
    ID: 6,  notification_cat_ID: 1, name: 'DTT®'
},{
    ID: 7,  notification_cat_ID: 1, name: 'DTT®'
},{
    ID: 8,  notification_cat_ID: 1, name: 'Announcement'
},{
    ID: 9,  notification_cat_ID: 3, name: 'Document'
},{
    ID: 10, notification_cat_ID: 3, name: 'Document'
},{
    ID: 11, notification_cat_ID: 3, name: 'Document'
},{
    ID: 12, notification_cat_ID: 2, name: 'Reminder'
},{
    ID: 13, notification_cat_ID: 2, name: 'Reminder'
}]

function createData(
    img: string, 
    building_name: string, 
    notification_type_ID: any, 
    time: any, 
    description: any, 
    buttonText: any,
    buttonIcon: any,
    buttondDisabled: any = false) {
    return { 
        img, 
        building_name, 
        notification_type_ID, 
        time, 
        description, 
        buttonIcon, 
        buttonText,
        buttondDisabled
    };
}

const ctaButton = [{
        id: 1, /*text: 'Accept', icon: 'Check'*/
    },{
        id: 2, button: {
            text: 'Grant access', icon: 'LockOpenSmall'
        }
    },{
        id: 3,
    },{
        id: 4,
    },{
        id: 5,
    },{
        id: 6, button: {
            text: 'Mint DTT®', icon: 'Buildings'
        }
    },{
        id: 7, button: {
            text: 'See DTT®', icon: 'Buildings'
        }
    },{
        id: 8, button: {
            text: 'See what’s new', icon: 'File'
        }
    },{
        id: 9, /*button: {
            text: 'See document', icon: 'LockOpenSmall'
        }*/
    },{
        id: 10,
    },{
        id: 11,
    },{
        id: 12,
    },{
        id: 13, button: {
            text: 'Send reminder', icon: 'BellRinging'
        }
    }
]

/*const notificationRows = [
    createData('building1', 'Deloitte Amsterdam', 2, '2d', 'James U. is requesting access to the building as an Observer', 'Grant access', 'LockOpenSmall'),
    createData('', 'Magma Team', 8, '1min', 'Magma 2.0 just dropped! ', 'See what’s new', 'Confetti'),
    createData('building1', 'Deloitte Amsterdam', 9, '3min', 'Gus Fring, Notary added "Building estimates"', 'See document', 'File'),
    createData('building1', 'Deloitte Amsterdam', 13, '12h', 'You sent a reminder for "Building estimates" a week ago', 'Reminder sent', 'Check', true),
    createData('building2', 'Bank of America Tower', 13, '18h', 'Yoan Gross still hasn\'t accepted the invitation', 'Send reminder', 'BellRinging'),
    createData('building1', 'Deloitte Amsterdam', 7, '2d', 'The DTT has been minted!', 'See DTT', 'Buildings'),
    createData('building1', 'Deloitte Amsterdam', 6, '2d', 'The DTT is ready to be minted.', 'DTT Minted', 'Buildings', true),
    createData('', 'Magma Team', 8, '7d', 'Magma 1.0 just dropped! ', 'See what’s new', 'Confetti'),
    createData('building2', 'Bank of America Tower', 13, '8d', 'No document added by the stakeholders yet', 'Send reminder', 'BellRinging'),
    createData('building1', 'Deloitte Amsterdam', 2, '2d', 'James U. is requesting access to the building as an Observer', 'Grant access', 'LockOpenSmall'),
    createData('', 'Magma Team', 8, '1min', 'Magma 2.0 just dropped! ', 'See what’s new', 'Confetti'),
    createData('building1', 'Deloitte Amsterdam', 9, '3min', 'Gus Fring, Notary added "Building estimates"', 'See document', 'File'),
    createData('building1', 'Deloitte Amsterdam', 13, '12h', 'You sent a reminder for "Building estimates" a week ago', 'Reminder sent', 'Check'),
    createData('building2', 'Bank of America Tower', 13, '18h', 'Yoan Gross still hasn\'t accepted the invitation', 'Send reminder', 'BellRinging'),
    createData('building1', 'Deloitte Amsterdam', 7, '2d', 'The DTT has been minted!', 'See DTT', 'Buildings'),
    createData('building1', 'Deloitte Amsterdam', 6, '2d', 'The DTT is ready to be minted.', 'DTT Minted', 'Buildings'),
    createData('', 'Magma Team', 8, '7d', 'Magma 1.0 just dropped! ', 'See what’s new', 'Confetti'),
    createData('building2', 'Bank of America Tower', 13, '8d', 'No document added by the stakeholders yet', 'Send reminder', 'BellRinging'),
];*/

export const ActivityTable: FC<ActivityTableProps> = ({
    handleOpenModalReminderSent,
    selectedBuildings
}) => {
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();
    const [notifications, setNotifications] = useState<INotification[]>([]);

    useEffect(() => {
        if (!keycloak.authenticated) {
          navigate('/');
          keycloak.logout();
        }
    
        /*if(selectedBuildings !== undefined && selectedBuildings !== ''){
            const buildingService = new BuildingService();
            buildingService.getBuildingNotifications(keycloak, selectedBuildings).then((response) => {
                let notificationsResult: INotification[] = response;
                if(notificationsResult === undefined)
                    notificationsResult = [];
                
                setNotifications(notificationsResult);
                console.log('notificationsResult',notificationsResult);
            })
            .catch(error => {
                console.log('error',error);
                if (error.response.status === 401) {
                    navigate('/');
                    keycloak.logout();
                }
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                console.error(`Error: ${error}`)

                return {
                status: false
                };
            });
        }
        else{
            setNotifications([]);
        }*/

        loadNotifications();
        
    }, [selectedBuildings]);

    const imageOnErrorHandler = (
        event: React.SyntheticEvent<HTMLImageElement, Event>
    ) => {
        event.currentTarget.src = FALLBACK_IMAGE;
        event.currentTarget.className = "error";
    };

    const getCTAButton = (_notification_type_ID: number) => {
        return ctaButton.find(button => button.id === _notification_type_ID);
    }

    const [page, setPage] = useState(0);
    const [isActive, setActive] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const Navigate = useNavigate();
    const [newText, setNewText] = useState("Reminder Sent")

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - notifications.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const searchNotificationType = (notification_type_ID: number) => {
        return notification_type.find(nt => nt.ID === notification_type_ID);
    }

    function loadNotifications() {
        console.log("loadNotifications");
        
        const buildingService = new BuildingService();
        buildingService.getBuildingNotifications(keycloak, selectedBuildings).then((response) => {
            let notificationsResult: INotification[] = response;
            if(notificationsResult === undefined)
                notificationsResult = [];
            
            setNotifications(notificationsResult);
            console.log('notificationsResult',notificationsResult);
        })
        .catch(error => {
            console.log('error',error);
            if (error.response.status === 401) {
                navigate('/');
                keycloak.logout();
            }
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.error(`Error: ${error}`)

            return {
            status: false
            };
        });
    }

    return (
        <>
        {/*'Show activity for buildings: ' + (selectedBuildings !== undefined ? selectedBuildings : '')*/}
        
        {notifications.length === 0
        ? <>
            <FlexSpacer minHeight={2} />
            <Stack direction="row" sx={{ justifyContent: 'center' }}>
                <Typography size="h5" weight='regular'>
                    No activity yet.
                </Typography>
            </Stack>
        </>
        :
        <TableContainer component={Paper}>
            <Table sx={{ backgroundColor: 'rgb(252, 252, 253) !important' }} aria-label="custom pagination table">
                <TableBody>
                    {(rowsPerPage > 0
                        ? notifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : notifications
                    ).map((notification: INotification, index: any) => {
                        const handleClick = (
                            event: React.MouseEvent<HTMLElement>,
                            index: number,
                        ) => {
                            console.log('Notification Type',notification.notification_type_ID);
                            /*switch(row.notification_type_ID){
                                case 1:
                                
                            }*/
                            /*if (row.type === "Announcement") {
                                console.log("Announcement HERE")

                                Navigate('/buildings/add/stakeholders');
                            }

                            if (row.type === "Document") {
                                console.log("Document HERE")

                                Navigate('/buildings/');
                            }

                            if (row.type === "NFT") {
                                console.log("NFT HERE")

                                Navigate('/building/5');
                            }
                            
                            if (row.type === "Reminder") {
                                setActive(true)
                                
                                setTimeout(() => {
                                    
                                    setActive(false)
                                }, 1500);    
                                
                                handleOpenModalReminderSent()

                                return newText
                            }*/
                        };

                        return (
                            <TableRow key={index} sx={{
                                margin: '0px',
                                padding: '0px',
                                //marginBottom: ".75rem",
                                //overflow: 'hidden',
                                borderRadius: '4px',
                                "&:hover": {
                                    backgroundColor: theme.colors.lighter
                                }
                            }}>
                                <TableCell
                                    className='activitytablecell'
                                >
                                    <Stack
                                        direction="column"
                                    >
                                        <img
                                            src={
                                                notification.building_ID === null
                                                ? `/images/building_magma.png`
                                                : notification.building_img_data !== undefined &&
                                                  notification.building_img_data !== ''
                                                  ? `data:${notification.building_img_mimetype};base64,${notification.building_img_data}`
                                                  : '/images/building/Draft.png'
                                            }
                                            onError={imageOnErrorHandler}
                                            //src={notification.building_img.img !== '' ? `/images/${row.img}.png` : `/images/building_magma.png`}
                                            alt="building"
                                            width="56"
                                            height="56"
                                            style={{
                                                borderRadius: '4px',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    </Stack>                                    
                                </TableCell>
                                <TableCell align="left"
                                    className='activitytablecell'
                                >
                                    <Stack direction="column">

                                        <Stack direction="row">
                                            <Typography size='h5' weight='medium'>
                                                {notification.building_name === '' ? 'Magma Team' : notification.building_name}
                                            </Typography>

                                            <Paper variant="outlined" sx={{
                                                background: theme.colors.neutral1,
                                                border: 'none',
                                                borderRadius: '4px',
                                                padding: '.1rem .5rem',
                                                marginLeft: 1
                                            }}>
                                                <Typography size='small'>
                                                    {searchNotificationType(notification.notification_type_ID)?.name}
                                                </Typography>
                                            </Paper>
                                        </Stack>

                                        <FlexSpacer minHeight={.2} />

                                        <Stack direction="row">
                                            <Typography size='small' color={theme.colors.neutral5}
                                                sx={{whiteSpace: 'nowrap'}}
                                                mr={'10px'}
                                            >
                                                {calcDiffTime(notification.created, new Date())} ago
                                            </Typography>
                                            <Typography size='14px'>
                                                {notification.description}
                                            </Typography>
                                        </Stack>
                                    </Stack>

                                </TableCell>
                                <TableCell style={{ width: 'min-content' }} align="right"
                                    className='activitytablecell'
                                >
                                    {getCTAButton(notification.notification_type_ID)?.button &&
                                        <Button
                                            size='small'
                                            id="basic-button"
                                            aria-haspopup="true"
                                            variant="outlined"
                                            onClick={(event: any) => {
                                                handleClick(event, index)
                                            }}
                                            //disabled={row.buttondDisabled}
                                            sx={{
                                                borderColor: theme.colors.neutral4,
                                                backgroundColor: false /*row.buttondDisabled*/ ? theme.colors.neutral2 : theme.colors.white,
                                                color: theme.colors.neutral6,
                                                textTransform: 'inherit',
                                                fontWeight: '500',
                                                margin: 'auto 0 auto auto',
                                                maxHeight: '30px',
                                                width: 'max-content',
                                                "&:hover": {
                                                    borderColor: theme.colors.neutral4,
                                                    backgroundColor: false /*row.buttondDisabled*/ ? theme.colors.neutral2 : theme.colors.white
                                                },
                                                cursor: `${false /*row.buttondDisabled*/ ? 'default' : 'pointer'}`
                                            }}
                                        >
                                            <img
                                                src={getCTAButton(notification.notification_type_ID)?.button!.icon !== ''
                                                    ? `/svg/icons/${getCTAButton(notification.notification_type_ID)?.button!.icon}.svg`
                                                    : `/svg/icons/Check.svg`
                                                }
                                                alt={getCTAButton(notification.notification_type_ID)?.button!.text}
                                                style={{ marginRight: '.3rem' }}
                                            />
                                            {getCTAButton(notification.notification_type_ID)?.button!.text}
                                        </Button>
                                    }
                                </TableCell>
                            </TableRow>
                        )

                        /*return (
                            <TableRow key={index} sx={{
                                //marginBottom: ".75rem",
                                //overflow: 'hidden',
                                borderRadius: '4px',
                                "&:hover": {
                                    backgroundColor: theme.colors.lighter
                                }
                            }}>
                                <TableCell style={{ width: 70 }} scope="row">
                                    <img
                                        src={row.img !== '' ? `/images/${row.img}.png` : `/images/building_magma.png`}
                                        alt="building"
                                        width="56"
                                        height="56"
                                        style={{
                                            borderRadius: '10px',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="left">
                                    <Stack direction="column">

                                        <Stack direction="row">
                                            <Typography size='h5' weight='medium'>
                                                {row.building_name}
                                            </Typography>

                                            <Paper variant="outlined" sx={{
                                                background: theme.colors.neutral1,
                                                border: 'none',
                                                borderRadius: '4px',
                                                padding: '.1rem .5rem',
                                                marginLeft: 1
                                            }}>
                                                <Typography size='small'>
                                                    {searchNotificationType(row.notification_type_ID)?.name}
                                                </Typography>
                                            </Paper>
                                        </Stack>

                                        <FlexSpacer minHeight={.2} />

                                        <Stack direction="row">

                                            <Typography size='small' mr="5px" color={theme.colors.neutral5}>
                                                {row.time} ago
                                            </Typography>
                                            <Typography>{row.description}</Typography>
                                        </Stack>
                                    </Stack>

                                </TableCell>
                                <TableCell style={{ width: 'min-content' }} align="right">

                                    <Button
                                        size='small'
                                        id="basic-button"
                                        aria-haspopup="true"
                                        variant="outlined"
                                        onClick={(event: any) => handleClick(event, index)}
                                        disabled={isActive ? true : false}
                                        sx={{
                                            borderColor: row.buttonIcon === 'CheckIcon' ? 'transparent' : theme.colors.neutral4,
                                            background: row.buttonIcon === 'CheckIcon' ? theme.colors.neutral2 : theme.colors.white,
                                            color: theme.colors.neutral6,
                                            textTransform: 'inherit',
                                            fontWeight: '500',
                                            margin: 'auto 0 auto auto',
                                            maxHeight: '30px',
                                            width: 'max-content',
                                            "&:hover": {
                                                borderColor: theme.colors.neutral4,
                                            }
                                        }}
                                    >
                                        <img src={!isActive ? `/svg/icons/${row.buttonIcon}.svg` : `/svg/icons/Check.svg`} alt={row.buttonIcon} style={{ marginRight: '.3rem' }} />
                                        {isActive && row.buttonText ===  "Send reminder" ? newText : row.buttonText  }
                                     </Button>
                                </TableCell>
                            </TableRow>
                        )*/
                    })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                        </TableRow>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={notifications.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: {
                                    'aria-label': 'rows per page',
                                },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        }
        </>
    );
}
