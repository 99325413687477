export const exampleAssetManagerInvitee = {
    firstname: [
        'ROGER',
        'EMMITT',
        'PATRICK',
        'JENNIFER',
        'ELIZABETH'
    ],
    lastname: [
        'STAUBACH',
        'SMITH',
        'DEMPSEY',
        'ANISTON',
        'BANKS'
    ],
    company: [
        'Big Block',
        'Courtland Partners',
        'Eco Agency',
        'Equity Point Real Estate',
        'FirstClose',
        'Full Circle',
        'Highrises Realty'
    ],
    email: [
        'ROGER.STAUBACH@GMAIL.COM',
        'EMMITT.SMITH@GMAIL.COM',
        'PATRICK.DEMPSEY@GMAIL.COM',
        'JENNIFER.ANISTON@GMAIL.COM',
        'ELIZABETH.BANKS@GMAIL.COM'
    ]
}

export const exampleStakeholderInvitee = {
    user_type_ID: [
        7,
        23
    ],
    firstname: [
        'ROGER',
        'EMMITT',
        'PATRICK',
        'JENNIFER',
        'ELIZABETH'
    ],
    lastname: [
        'STAUBACH',
        'SMITH',
        'DEMPSEY',
        'ANISTON',
        'BANKS'
    ],
    company: [
        'Big Block',
        'Courtland Partners',
        'Eco Agency',
        'Equity Point Real Estate',
        'FirstClose',
        'Full Circle',
        'Highrises Realty'
    ],
    email: [
        'ROGER.STAUBACH@GMAIL.COM',
        'EMMITT.SMITH@GMAIL.COM',
        'PATRICK.DEMPSEY@GMAIL.COM',
        'JENNIFER.ANISTON@GMAIL.COM',
        'ELIZABETH.BANKS@GMAIL.COM'
    ]
}
