import { Button, Divider, Menu, MenuItem, Popover, Stack, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { IBuilding, IStakeholderDetail } from '../../../interfaces/building';
import { theme } from '../../../utils/theme';
import OptionsButton from '../../atoms/Buttons/OptionsButton';
import { FlexSpacer } from '../../atoms/FlexSpacer';
import Typography from '../../atoms/Typography';
import { IUserInfo } from '../../../interfaces/userInfo';
import styled from '@emotion/styled';
import axios, { AxiosRequestConfig } from 'axios';
import { useKeycloak } from '@react-keycloak/web';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

interface StakeholdersProps {
    handleOpenModalAddStakeholder?: any,
    stakeholdersData?: IStakeholderDetail[],
    building: IBuilding | undefined,
    userInfo: IUserInfo,
    setIncludeUserTypeID: any,
    setExcludeUserTypeID: any,
    setMaxStakeholders?: any,
}

export const Stakeholders: FC<StakeholdersProps> = ({
    handleOpenModalAddStakeholder,
    stakeholdersData,
    building,
    userInfo,
    setIncludeUserTypeID,
    setExcludeUserTypeID,
    setMaxStakeholders,
}) => {
    const popoverList = [
        // { label: 'Edit building information', src: '/svg/icons/Pencil.svg', color: 'inherit', onCLick: handleOpenModalAddStakeholder },
        // { label: 'Invite a stakeholder', src: '/svg/icons/PlusDark.svg', color: 'inherit', onCLick: handleOpenModalAddStakeholder },
        // { label: 'Change picture', src: '/svg/icons/Image.svg', color: 'inherit', onCLick: handleOpenModalAddStakeholder },
        {
            id: 1,
            label: 'Start a private chat',
            src: '/svg/icons/Chats.svg',
            color: 'inherit',
            onClick: () => {
                handleStartPrivateChat();
            },
            disabled: true,
        },{
            id: 2,
            label: 'Remove access',
            src: '/svg/icons/Trash.svg',
            color: 'error',
            onClick: () => {
                handleRemoveAccess();
            },
        }
    ]
    const navigate = useNavigate();
    const { keycloak } = useKeycloak();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [stakeholder, setStakeholder] = useState<IStakeholderDetail | undefined>();
    const [stakeholders, setStakeholders] = useState<IStakeholderDetail[] | undefined>();

    useEffect(() => {
        setStakeholders(stakeholdersData);
    }, [stakeholdersData]);

    useEffect(() => {
        console.log("reload stakeholders", stakeholders);
    }, [stakeholders]);

    const handleClick = (
        event: React.MouseEvent<HTMLElement>,
        _stakeholder: IStakeholderDetail
        ) => {
        setStakeholder(_stakeholder);
        setAnchorEl(event.currentTarget);
    };
    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        popoverList[index]?.onClick?.();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleStartPrivateChat() {
        console.log('handleStartPrivateChat');
    }

    function handleRemoveAccess() {
        removeStakeholder(stakeholder!);
    }

    function removeStakeholder (_stakeholder: IStakeholderDetail) {
        if(!building){
            console.log("error building not available");
        }

        const url = `${process.env.REACT_APP_API_URL}/building/stakeholder`;
    
        const data = {
            "building_ID": building!.ID,
            "stakeholders": [{
                "user_ID": _stakeholder.user_ID
            }]
        }
    
        const config: AxiosRequestConfig = {
            method: 'delete',
            url: url,
            data: data,
            headers: {
                'Authorization': `Bearer ${keycloak.token}`,
                'Content-Type': 'application/json'
            }
        };
    
        console.log("api config", config);
    
        axios(config)
        .then((response) => {
            //const userInfo: IUserInfo = response.data.result;
            console.log(response);

            if (!response.data.status) {
                console.log(response.data.msg + "\n" + response.data.error);
                toast.error("Error removing stakeholder, try again!");
            }
            else {
                const index = stakeholders?.findIndex((stakeholder: IStakeholderDetail) => stakeholder.user_ID === _stakeholder.user_ID);

                const updatedStakeholders = [...stakeholders!];
                if (updatedStakeholders && index && index !== -1) {
                    updatedStakeholders.splice(index, 1);
                }
                setStakeholders(updatedStakeholders);

                toast.success("Stakeholder has been removed!");
                
                //remove(index);
                //console.log("removeStakeholder 1",getValues());
                //if(getValues().stakeholder.length === 0){
                    //append(defaultValues.stakeholder);
                //}
                //console.log("removeStakeholder 2",getValues());
            }
            //getUserInfo(userInfo);
        })
        .catch(error => {
            if (error.response.status === 401) {
                navigate('/');
                keycloak.logout();
            }
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.error(`Error: ${error}`)
        });
    }

    console.log('userInfo',userInfo);
    console.log("stakeholdersData", stakeholdersData);

    return (
        <div>
            <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                <Typography size="h3" weight='medium'>
                    Stakeholders
                </Typography>

                <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                        borderColor: theme.colors.neutral5,
                        color: theme.colors.neutral5,
                        maxWidth: 200,
                        maxHeight: 40, width: isMobile ? '100%' : '220px'
                    }}
                    onClick={() => {
                        setIncludeUserTypeID();
                        setExcludeUserTypeID([1]);
                        setMaxStakeholders(-1);
                        handleOpenModalAddStakeholder();
                    }}
                >
                    <img
                        alt="+"
                        color="black"
                        src="/svg/icons/PlusDark.svg"
                        width={20}
                        height={20}
                    />
                    <span style={{ marginLeft: 10, textTransform: 'none' }}>Add stakeholder</span>
                </Button>
            </Stack>

            <FlexSpacer minHeight={1.5} />

            <Stack direction="row" sx={{ justifyContent: 'flex-start', overflow: 'scroll' }}>
                {stakeholders === undefined
                    ? 'No stakeholders added'
                    : ''
                }
                {stakeholders !== undefined && stakeholders!.map((stakeholder: IStakeholderDetail, index) => {
                    return (
                        <Stack
                            direction="column"
                            key={`stakeholder-${stakeholder.user_ID}`}
                            sx={{ marginRight: '1rem', maxWidth: '128px' }}
                        >
                            <Box sx={{ width: '128px', overflow: 'hidden', marginRight: '1rem' }}>
                                <img
                                    alt={stakeholder.firstname + ' ' + stakeholder.lastname}
                                    color="white"
                                    src={(stakeholder.img !== undefined && stakeholder.img !== '') ? stakeholder.img : `/../images/stakeholders/${stakeholder.user_type_img}`}
                                    width={128}
                                    height={128}
                                />

                                {parseInt(userInfo.ID) !== stakeholder.user_ID &&
                                    <OptionsButton
                                        aria-describedby={id}
                                        size="small"
                                        id={"stakeholderOptionsMenu-" + stakeholder.user_ID}
                                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={(event: any) => handleClick(event, stakeholder)}
                                        maxHeightImg={17}
                                        maxWidthImg={20}
                                        sx={{
                                            position: "relative !important",
                                            width: '24px',
                                            height: '24px',
                                            top: '-105px',
                                            marginLeft: '-30px'
                                        }}
                                    />
                                }
                            </Box>

                            <FlexSpacer minHeight={.5} />

                            <Typography className="stakeholder-name" size="16px" weight="bold">
                                {stakeholder.firstname + ' ' + stakeholder.lastname}
                            </Typography>

                            <Typography className="stakeholder-user-type" size="12px" weight="bold">
                                {stakeholder.user_type_name}
                            </Typography>
                            <Typography className="stakeholder-company" size="small" sx={{ height: '1.5rem' }}>
                                {stakeholder.company ? stakeholder.company : ' '}
                            </Typography>
                        </Stack>
                    );
                })}
            </Stack>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                disableScrollLock={false}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    elevation: 1
                }}
                sx={{
                    ml: '20px',
                    mt: '-8px'
                }}
            >
                {userInfo !== undefined &&
                    popoverList.map((item, index) => (
                    <Stack
                        key={`stakeholderMenuItem-${index}`}
                    >
                        {(item.id === 1 || (item.id === 2 && 
                            [1,2].includes(userInfo.permissions![0].user_type_ID) &&
                            stakeholder?.user_type_ID !== 1
                            ))
                            && (
                                <>
                                    {item.id > 1 && <Divider sx={{marginX: '16px'}} />}
                                    <StyledMenuItem
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                        className={`${selectedIndex}`}
                                        sx={{
                                            height: '42px',
                                            margin: '4px!important',
                                        }}
                                        disabled={
                                            !item.disabled
                                                ? [1,2].includes(userInfo.permissions![0].user_type_ID)
                                                    ? false
                                                    : true
                                                : item.disabled
                                        }
                                    >
                                        <Box
                                            /*onClick={item.onClick}*/
                                            color="inherit"
                                            style={{ padding: 0, textTransform: 'inherit', width: '100%', justifyContent: "flex-start" }}>
                                            <Stack direction="row">
                                                <img src={item.src} className={`icon-${item.color}`} alt="icon" />
                                                <Typography color={item.color} sx={{ ml: 1 }}>{item.label}</Typography>
                                            </Stack>
                                        </Box>
                                    </StyledMenuItem>
                                </>
                            )
                        }
                    </Stack>
                ))}
            </Popover>
        </div>
    )
}

const StyledMenuItem = styled(MenuItem)`
    :hover{
        div{
            transform: translateX(-4px);
        }

        img{
            filter: invert(1);
        }
        span {
            color: white;
        }
    }
`;
