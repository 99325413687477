import { Box } from '@mui/material'
import ProgressSpinner from '../../design-system/atoms/Buttons/ProgressSpinner'
import { theme } from '../theme'

function renderLoader() {
    return (
        <>
            <Box
                sx={{
                    position: 'absolute',                                
                    top: '54%',
                    height: '80px',
                    width: '63px',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    right: '50%',
                    zIndex: '999'            
                }}
            >
                <ProgressSpinner stroke="#001AFF" size="40px" />
            </Box>
        </>
    )
}

export default renderLoader