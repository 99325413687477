import { FC, useState } from "react";
import { Autocomplete, Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormHelperText, MenuItem, Modal, Paper, Slider, Stack, TextField, useMediaQuery } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from "@emotion/styled";
import CloseIcon from '@mui/icons-material/Close';
import { theme } from "../../../utils/theme";
import { FlexSpacer } from "../../atoms/FlexSpacer";
import Typography from "../../atoms/Typography";
import CustomMenu from "../../atoms/CustomMenu";
import { IOSSwitch } from '../../atoms/IOSSwitch/IOSSwitch';
import { IBuildingType } from "../../../interfaces/building";
import { convertSQM_SQFT, formatNumber } from "../../../utils/functions";
import { MAX_BUILDING_SIZE } from "../../../utils/constants";


interface FiltersProps {
    open?: boolean
    setOpen?: Function
    setFilters: any
    setSort: any
    filters: {
        locations: string[] | undefined,
        building_types: {
            ID: number
        }[] | undefined,
        building_size: {
            from: number,
            to: number | undefined
        } | undefined
    } | any
    buildingTypes: any
}

// Consts

const optionsSortMenu = [
    {
        ID: 0,
        desc: 'Alphabetical (A-Z)'
    },{
        ID: 1,
        desc: 'Alphabetical (Z-A)'
    },{
        ID: 2,
        desc: 'Newest to oldest'
    },{
        ID: 3,
        desc: 'Oldest to newest'
    },{
        ID: 4,
        desc: 'Last modification'
    }
];

/*const cities = [
    {
        id: 0,
        title: 'Paris',
    },
    {
        id: 1,
        title: 'Barcelona',
    },
    {
        id: 2,
        title: 'Rio',
    },
    {
        id: 3,
        title: 'Berlin',
    },
    {
        id: 4,
        title: 'Dublin',
    },
];*/

//Search Location Component
interface SearchTagsProps {
    locations: string[],
    setLocations: any,
}

const SearchTags: FC<SearchTagsProps> = ({locations, setLocations}) => {
    return (
        <>
            <Autocomplete
                multiple
                id="tags-filled"
                className="filters"
                freeSolo
                disableClearable={true}
                options={[]}
                value={locations}
                onChange={(event: any, value: string[]) => {
                    console.log("tags", value);
                    setLocations(value);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                        }}
                        sx={{
                            height: '100% !important',
                            "& input": {
                                height: 20,
                                minWidth: '98% !important',
                                border: '1px solid #D9D9D9',
                                padding: '.5rem !important',
                            }
                        }}
                        variant="standard"
                    />
                )}
                renderTags={(value: readonly string[], getTagProps) => {
                    return (
                        <Stack
                            direction="row"
                            width="100%"
                            padding=".5rem 0 0"
                            marginLeft="-15px"
                            sx={{
                                flexFlow: "wrap"
                            }}
                        >
                            {value.map((option: string, index: number) => (
                                <Chip
                                    deleteIcon={
                                        <img src="/svg/icons/Cross.svg"
                                            alt="Remove"
                                            width="12px"
                                            height="12px"
                                            style={{ marginRight: '10px' }}
                                        />}
                                    sx={{
                                        minWidth: 'min-content !important',
                                        justifyContent: 'space-between !important',
                                        height: '30px !important',
                                        padding: '0px !important',
                                        "& span": {
                                            width: 'max-content',
                                            display: 'inline-table',
                                            marginRight: '0'
                                        }
                                    }}
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                />
                            ))}
                        </Stack>
                    )
                }}
            />
        </>
    )
}


/*function valuetext(value: number) {
    return `${value} SQM`;
}*/

// Component

const Filters: FC<FiltersProps> = ({ open, setOpen, setFilters, setSort, filters, buildingTypes }) => {
    console.log("Filters");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const selectOpen = Boolean(anchorEl);

    // const [openModal, setOpenModal] = useState(false);
    // const handleOpenModal = () => setOpenModal(true);
    // const handleCloseModal = () => setOpenModal(false);

    const [openFilterModal, setOpenFilterModal] = useState(false);
    const handleOpenFilterModal = () => {
        if(filters !== undefined){
            if(filters.building_types !== undefined) {
                setFilterBuildingTypes(filters.building_types);
            }
            if(filters.building_size !== undefined) {
                setBuildingSizeFilter([(filters.building_size.from / MAX_BUILDING_SIZE) * 100, (filters.building_size.to / MAX_BUILDING_SIZE) * 100]);
                calculateMarks([(filters.building_size.from / MAX_BUILDING_SIZE) * 100, (filters.building_size.to / MAX_BUILDING_SIZE) * 100]);
            }
        }
        setOpenFilterModal(true);
    }
    const handleCloseFilterModal = () => setOpenFilterModal(false);

    const [locations, setLocations] = useState<string[]>([]);

    const [unitOfMeasure, setUnitOfMeasure] = useState('sqm');

    const [buildingSizeFilter, setBuildingSizeFilter] = useState<number[]>([]);
    const [marks, setMarks] = useState<{value: number, size: number, label: string}[]>([]);

    const handleBuildingSizeChangeRange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number
    ) => {
        console.log(newValue);
        const minDistance = 10;

        if (!Array.isArray(newValue)) {
            return;
        }

        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
              const clamped = Math.min(newValue[0], 100 - minDistance);
              setBuildingSizeFilter([clamped, clamped + minDistance]);
              calculateMarks([clamped, clamped + minDistance]);
            } else {
              const clamped = Math.max(newValue[1], minDistance);
              setBuildingSizeFilter([clamped - minDistance, clamped]);
              calculateMarks([clamped - minDistance, clamped]);
            }
        } else {
            setBuildingSizeFilter(newValue as number[]);
            calculateMarks(newValue as number[]);
        }        
    };

    function calculateMarks(value: number[]) {
        const newMarks: {value: number, size: number, label: string}[] = value.map((element: number) => {
            return {
                value: element,
                size: calculateBuildingSize(element),
                label: `${formatNumber(calculateBuildingSize(element))} ${unitOfMeasure}`
            };
        });
        setMarks(newMarks);
    }

    function calculateBuildingSize(percentage: number) {
        console.log('percentage slider', percentage);
        if (percentage < 0 || percentage > 100) {
            throw new Error("Percentage must be between 0 and 100");
        }
        
        const value = percentage / 100 * MAX_BUILDING_SIZE;
        
        return Math.round(value);
    }

    const handleSelectClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);

    };

    const [selectedIndex, setSelectedIndex] = useState(4);

    const handleOptionsSortMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setSort(index);
        setAnchorEl(null);
    };

    const handleSelectClose = () => {
        setAnchorEl(null);
    };

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [filterBuildingTypes, setFilterBuildingTypes] = useState<{ID: number}[]>([]);
    useState([filterBuildingTypes]);
   
    const handleFilterBuildingTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log('handleFilterBuildingTypeChange',event.target.name,event.target.checked);
        let updatedFilterBuildingTypes: {ID: number}[] = [...filterBuildingTypes];
        const filterBuildingType: {ID: number} = {
            ID: parseInt(event.target.name)
        };

        if(event.target.checked){
            if(!filterBuildingTypes.find(fbt => fbt.ID === filterBuildingType.ID)){
                updatedFilterBuildingTypes.push(filterBuildingType);
            }
        }
        else if(!event.target.checked){
            updatedFilterBuildingTypes = updatedFilterBuildingTypes.filter(fbt => fbt.ID !== filterBuildingType.ID);
        }

        setFilterBuildingTypes(updatedFilterBuildingTypes);
        //console.log('filterBuildingTypes',updatedFilterBuildingTypes);
    };

    //const { {id: number, checked: boolean} } = setStateFilterBuildingType; 
    //const error = [residential, commercial].filter((v) => v).length >= 2;

    const handleUnitOfMeasure = (e: any) => {
        var newUnitOfMeasure: string;
        if(unitOfMeasure === 'sqm'){
          newUnitOfMeasure = 'sqft';
        }
        else{
          newUnitOfMeasure = 'sqm';
        }
        setUnitOfMeasure(newUnitOfMeasure);
        const newMarks: {value: number, size: number, label: string}[] = marks.map((element: any) => {
            return {
                value: element.value,
                size: element.size,
                label: `${formatNumber(element.size)} ${newUnitOfMeasure}`
            };
        });
        setMarks(newMarks);
    }

    const handleResetFilters = () => {
        setLocations([]);
        setFilterBuildingTypes([{
            ID: 1,
        },{
            ID: 2,
        },{
            ID: 3,
        },{
            ID: 4,
        },{
            ID: 5,
        },{
            ID: 6,
        },{
            ID: 7,
        },]);
        setUnitOfMeasure('sqm');
        setBuildingSizeFilter([0, 100]);
        calculateMarks([0, 100]);
    }

    const handleApplyFilters = () => {
        const filters: {
            locations: string[],
            building_types: { ID: number }[],
            building_size: { from: number, to: number | undefined }
        } = {
            locations: [],
            building_types: [],
            building_size: { from: 0, to: undefined }
        };

        filters.locations = locations;
        filters.building_types = filterBuildingTypes;
        filters.building_size = {
            from: unitOfMeasure === 'sqm' ? marks[0].size : parseFloat(convertSQM_SQFT(unitOfMeasure, marks[0].size.toString())),
            to: unitOfMeasure === 'sqm' ? marks[1].size : parseFloat(convertSQM_SQFT(unitOfMeasure, marks[1].size.toString()))
        };

        setFilters(filters);
        handleCloseFilterModal();
    }

    return (
        <>
            <Stack
                direction={isMobile ? 'column' : 'row'}
                sx={{ justifyContent: 'flex-end', marginTop: "-4px" }}>

                {/* // OPEN MODAL*/}

                {/* // Add modal to on load page or wherever suitable */}
                <FlexSpacer minWidth={1} />

                {/* // FILTERS MODAL */}
                {/* {!isTablet && <FlexSpacer minHeight={1.5} />} */}

                <Button
                    variant="outlined"
                    size='small'
                    sx={{
                        borderColor: theme.colors.neutral4,
                        color: theme.colors.neutral6,
                        textTransform: 'inherit',
                        "&:hover": {
                            borderColor: theme.colors.neutral4,
                        }
                    }}
                    onClick={handleOpenFilterModal}
                >

                    <img alt="sliders" src="/svg/icons/Sliders.svg" height="18px" width="18px" />
                    <FlexSpacer minWidth={.5} />
                    <Typography size="body" weight="medium">Filters</Typography>
                </Button>

                {isMobile && <FlexSpacer minHeight={1.875} />}

                {!isMobile && <Box sx={{ width: `8px` }} />}

                {/* // BUTTON MENU */}

                <Button
                    size='small'
                    id="basic-button"
                    aria-controls={selectOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={selectOpen ? 'true' : undefined}
                    onClick={handleSelectClick}
                    variant="outlined"
                    sx={{
                        borderColor: theme.colors.neutral4,
                        color: theme.colors.neutral6,
                        textTransform: 'inherit',
                        fontWeight: '500',
                        "&:hover": {
                            borderColor: theme.colors.neutral4,
                        }
                    }}
                >
                    <ExpandMoreIcon />
                    <Typography
                        color={theme.colors.black}
                    >
                        {optionsSortMenu.find(sort => sort.ID === selectedIndex)?.desc}
                    </Typography>

                </Button>

                <CustomMenu
                    id="filter-menu"
                    anchorEl={anchorEl}
                    open={selectOpen}
                    disablePortal={true}
                    onClose={handleSelectClose}
                >
                    {optionsSortMenu.map((item, index) => {
                        return (
                            <MenuItem
                                key={item.ID}
                                selected={item.ID === selectedIndex}
                                onClick={(event) =>
                                    handleOptionsSortMenuItemClick(event, item.ID)
                                }
                            >
                                {item.desc}
                            </MenuItem>
                        )
                    })}
                </CustomMenu>
            </Stack>

            {/* MODALS */}

            <Modal
                open={openFilterModal}
                onClose={handleCloseFilterModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper
                    component="form"
                    sx={{ p: '1px 4px', display: 'flex', alignItems: 'center', maxHeight: 40, width: '100%' }}
                >
                    <StyledBox p={4}>
                        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                            <Typography size="h6" weight="medium">
                                Add filters
                            </Typography>

                            <CloseIcon onClick={handleCloseFilterModal} sx={{ cursor: 'pointer' }} />
                        </Stack>

                        <FlexSpacer minHeight={2} />

                        <Typography id="modal-modal-local" size="body" weight="regular">
                            Location
                        </Typography>

                        <FlexSpacer minHeight={.5} />

                        <SearchTags
                            locations={locations}
                            setLocations={setLocations}
                        />

                        <FlexSpacer minHeight={1.5} />

                        <Typography id="filter-modal-type" size="body" weight="regular">
                            Building type
                        </Typography>

                        <FlexSpacer minHeight={0.5} />

                        <FormControl
                            required
                            error={false}
                            component="fieldset"
                            variant="standard"
                        >
                            <FormGroup>
                                {buildingTypes.map((buildingType: IBuildingType) => {
                                    return (
                                        <FormControlLabel
                                            key={buildingType.ID}
                                            control={
                                                <StyledCheckbox
                                                    name={buildingType.ID.toString()}
                                                    checked={filterBuildingTypes.find(fbt => fbt.ID === buildingType.ID) ? true : false}
                                                    onChange={handleFilterBuildingTypeChange}
                                                />}
                                            label={
                                                <Typography id="filter-modal-title" size="body" weight="medium">
                                                    {buildingType.description}
                                                </Typography>
                                            }
                                        />
                                    )
                                })}
                            </FormGroup>

                            {false && <FormHelperText>Please choose only one option</FormHelperText>}

                        </FormControl>
                        <FlexSpacer minHeight={2} />


                        <label htmlFor="size">
                            <Stack direction="row" sx={{
                                justifyContent: 'space-between'
                            }}>
                                <Typography id="filter-modal-size" size="body" weight="regular">
                                    Building size
                                </Typography>

                                <Stack direction="row">

                                    <Typography weight="regular" size="body" sx={{ marginRight: 1 }}>sqm</Typography>
                                    
                                    <FormControlLabel
                                        name='unitOfMeasure'
                                        control={<IOSSwitch
                                            checked={unitOfMeasure !== 'sqm'}
                                            onChange={handleUnitOfMeasure}
                                            sx={{ m: 1 }}
                                        />}
                                        label="sqft"
                                    />
                                </Stack>
                            </Stack>
                            <FlexSpacer minHeight={.5} />
                        </label>

                        <Box sx={{ width: '100%', height: 100 }}>

                            <StyledSlider
                                value={buildingSizeFilter}
                                scale={calculateBuildingSize}
                                size="small"
                                sx={{ margin: '.5rem .5rem 0 0' }}
                                marks={marks}
                                color="primary"
                                onChange={handleBuildingSizeChangeRange}
                            // step={10}
                            // valueLabelDisplay="auto"
                            />

                            <FlexSpacer minHeight={2} />

                        </Box>

                        <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
                            <Button
                                variant="outlined"
                                sx={{ marginRight: 2 }}
                                onClick={handleResetFilters}
                            >
                                Reset filters
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleApplyFilters}
                            >
                                Save
                            </Button>
                        </Stack>
                    </StyledBox>
                </Paper>
            </Modal >
        </>
    );
}

export default Filters;

const StyledBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    background: white;
    border: none;
    box-shadow: 4px 4px 0 0 #001AFF;
    margin-bottom: 2rem;

    @media (max-width: 900px) {
    max-height: 90vh;
    overflow: scroll;
    }
`;

const StyledCheckbox = styled(Checkbox)`
    padding: 2px 8px 4px 9px;
    font-size: 14px;
`;

const StyledSlider = styled(Slider)`
    .MuiSlider-markLabel{
        font-size: 12px;
    }
`;