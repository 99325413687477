import React from 'react'
import Typography from '../../atoms/Typography'

const TooltipContent = () => {
    return (
        <>
            <Typography color="inherit">
                There're different stakeholders types on Magma:
            </Typography>
            <ul
                style={{
                    listStyle: "disc",
                    paddingLeft: 3,
                    marginLeft: 16,
                }}
            >
                <li>{"Property / Facility manager"}</li>
                <li>{"Service provider"}</li>
                <li>{"Tenant"}</li>
                <li>{"Observer"}</li>
                <li>{"Financial Institution"}</li>
                <li>{"Users / Visitors"}</li>
                {" "}
            </ul>

            {
                "Each role has specific rights that can be edited to match your needs."
            }
        </>
    )
}

export default TooltipContent