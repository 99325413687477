import React, { FC, useEffect, useState } from "react";
import { Box, Button, Grid, Link, Paper, Popover, Skeleton, Stack, useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import axios, { AxiosRequestConfig } from "axios";
import Typography from "../../design-system/atoms/Typography";
import { FlexSpacer } from "../../design-system/atoms/FlexSpacer";
import { Progress } from "../../design-system/atoms/Progress";
import { BUILDING_STATUS_ACTIVE, BUILDING_STATUS_DRAFT, BUILDING_STATUS_NOT_MINTED, IBuilding, IBuildingType, ISearchBuilding } from "../../interfaces/building";
import { IUserInfo, IUserType } from "../../interfaces/userInfo";
import { theme } from "../../utils/theme";
import Filters from "../../design-system/molecules/Filters";
import OptionsButton from "../../design-system/atoms/Buttons/OptionsButton";
import { toast } from 'react-toastify';
import Welcome from "../../design-system/molecules/Cards/Welcome";
import SearchListBuildings from "../../design-system/molecules/Searches/SearchListBuildings";
import UserService from "../../services/userService";
import BuildingService from "../../services/buildingService";
import { MAX_BUILDING_SIZE } from "../../utils/constants";
import { tierData } from "../../interfaces/dtt";
//import 'react-toastify/dist/ReactToastify.css';

interface BuildingListProps {
  open?: boolean,
  messengerOpen?: boolean,
  handleOpenModalRole?: any,
  handleOpenModalStakeholders?: any,
  handleOpenModalStakeholdersDetailed?: any,
  handleOpenModalBuildingAdded?: any,
  handleOpenModalNftLevel?: any,
  handleOpenModalNftProgress?: any,
  handleOpenModalAddStakeholder?: any,
  handleOpenModalEstimate?: any,
  handleOpenModalBuyMUT?: any,
  handleOpenModalNft?: any,
  handleOpenModalInvitation?: any,
  handleOpenModalWalletCreated?: any,
  handleOpenModalDocViewerInfo?: any,
  handleOpenModalGiveAccess?: any,
  handleCloseModalDocChat?: any,
  handleOpenModalDocChat?: any,
  handleOpenModalTour?: any,
  handleOpenModalReminderSent?: any,
  handleOpenModalNda?: any,
  handleOpenModalAllowPopup?: any,
  handleOpenModalWalletSelector?: any,
  setOpen?: Function,
  bothNavsOpen?: boolean,
  setBuildingWallet?: any,
  setUserInfo:any,
  userInfo?:any,
  setBuildingID?: any,
  setMyCallBack?: any,
  setUserTypes?: any,
  setUserTypeDocs?: any,
  setBuilding: any,
  blockchain: any,
}


const BuildingListPage: FC<BuildingListProps> = ({
  open,
  messengerOpen,
  handleOpenModalRole,
  handleOpenModalStakeholders,
  handleOpenModalWalletCreated,
  handleOpenModalStakeholdersDetailed,
  handleOpenModalInvitation,
  handleOpenModalNft,
  handleCloseModalDocChat,
  handleOpenModalDocChat,
  handleOpenModalGiveAccess,
  handleOpenModalDocViewerInfo,
  handleOpenModalBuildingAdded,
  handleOpenModalAddStakeholder,
  handleOpenModalEstimate,
  handleOpenModalNftLevel,
  handleOpenModalNftProgress,
  handleOpenModalAllowPopup,
  handleOpenModalWalletSelector,
  bothNavsOpen,
  handleOpenModalBuyMUT,
  handleOpenModalTour,
  handleOpenModalReminderSent,
  handleOpenModalNda,
  setBuildingWallet,
  setUserInfo,
  userInfo,
  setBuildingID,
  setMyCallBack,
  setUserTypes,
  setUserTypeDocs,
  setBuilding,
  blockchain,
}) => {

  console.log("BuildingsPage");

  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const isSmMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  //const [userInfo, setUserInfo] = useState<IUserInfo | undefined>(undefined);
  const [buildings, setBuildings] = useState<IBuilding[] | undefined>(undefined);
  const [matchedBuildings, setMatchedBuildings] = useState<IBuilding[] | undefined>(undefined);

  const [buildingTypes, setBuildingTypes] = useState<IBuildingType[]>([]);

  const [filters, setFilters] = useState<{
    locations: string[] | undefined,
    building_types: {
      ID: number
    }[] | undefined,
    building_size: {
      from: number,
      to: number | undefined
    } | undefined
  }>();
  const [sort, setSort] = useState<number>(4);

  const [isDraft, setIsDraft] = useState(true);
  const [isValidating, setValidating] = useState(false);
  const [style, setStyle] = useState({ display: 'none' });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentSelectedBuildingID, setCurrentSelectedBuildingID] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(1);
  const openOptions = Boolean(anchorEl);
  const [value, setValue] = useState<number[]>([20, 70]);

  const [searchBuildingInput, setSearchBuildingInput] = useState("");
  const [buildingSearchList, setBuildingSearchList] = useState<ISearchBuilding[] | undefined>([]);
  const [searchBuilding, setSearchBuilding] = useState<ISearchBuilding>();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const elementID = event.currentTarget.id;
    console.log('click delete', elementID);
    const buildingID = elementID.split('-')[1];
    setAnchorEl(event.currentTarget);
    setCurrentSelectedBuildingID(buildingID);
  };

  const [active, setActive] = useState(-1);
  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;



  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // TODO: remove unused functions

  // const handleChangeRange = (event: Event, newValue: number | number[]) => {
  //   setValue(newValue as number[]);
  // };

  // const imageOnLoadHandler = (
  //   event: React.SyntheticEvent<HTMLImageElement, Event>
  // ) => {
  //   console.log(
  //     `The image with url of ${event.currentTarget.src} has been loaded`
  //   );
  //   if (event.currentTarget.className !== "error") {
  //     event.currentTarget.className = "success";
  //   }
  // };



  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = FALLBACK_IMAGE;
    event.currentTarget.className = "error";
  };

  useEffect(() => {
    /* fix if the user is already logged in and coming from loginpage directly */
    if (window.location.pathname === '/') {
      console.log("change url to buildings");
      navigate('/buildings', { replace: true });
    }

    setBuildingWallet(undefined);
    
    fetchBuildingTypes();
    fetchUserInfo();
    if(setMyCallBack) setMyCallBack(() => fetchUserInfo);

    console.log("buildings", buildings);
    /*if (buildings !== undefined) {
      const buildingSearchResult = buildings.filter(building => building.building_name.includes(searchBuildingInput));
      console.log('buildingSearchResult', buildingSearchResult);
      const buildingSearchList = buildingSearchResult.map(building => ({ key: building.ID, value: `${building.building_name} (${building.ID})` }));
      setBuildingSearchList(buildingSearchList);
    }*/

  }, [setMyCallBack]);

  useEffect(() => {
    //apply filters
    console.log('FILTERS',filters);
    if(filters === undefined){
      //add default filters
      console.log('FILTERS add default');
      setFilters({
        locations: undefined,
        building_types: [{
          ID: 1
        },{
          ID: 2
        },{
          ID: 3
        },{
          ID: 4
        },{
          ID: 5
        },{
          ID: 6
        },{
          ID: 7
        }],
        building_size: { from: 0, to: Math.min(250000, MAX_BUILDING_SIZE) }
      });
    }

    if(buildings !== undefined){
      const matchingBuildings: IBuilding[] = [];
      buildings.forEach(building => {
        //const searchResult = searchMatch(searchBuildingInput, [building.building_name, building.address, building.owner, building.owner_address])
        let searchResult = true;
        searchResult = searchMatch(searchBuildingInput, [building.building_name, building.address, building.owner, building.owner_address]);

        if(searchResult && filters !== undefined){
          console.log('BUILDINGFILTERS', filters);
          console.log("searchResult",searchResult,"filters.locations",filters.locations);
          //check for location
          if(searchResult && filters.locations !== undefined && filters.locations.length > 0){
            console.log("CHECK LOCATION",filters.locations,building.address);
            searchResult = filters.locations.some(location => building.address.includes(location));
          }

          //check for building type
          if(searchResult && filters.building_types !== undefined){
            const checkedBuildingTypeIDs = filters.building_types
              .map(building => building.ID);
            
            console.log('checkedBuildingTypeIDs',checkedBuildingTypeIDs);

            searchResult = checkedBuildingTypeIDs.includes(building.building_type_ID);
          }          

          //check for building size
          if(searchResult && filters.building_size !== undefined){
            if(filters.building_size.from !== undefined &&
              building.size < filters.building_size.from){
                console.log(building.size, filters.building_size.from);
                searchResult = false;
            }

            if(searchResult && filters.building_size.to !== undefined &&
              building.size > filters.building_size.to){
                console.log(building.size, filters.building_size.to);
                searchResult = false;
            }
          }
        }

        if (searchResult) {
          matchingBuildings.push(building);
        }
      });

      const sortedBuildings = sortBuildings(matchingBuildings);
      setMatchedBuildings(sortedBuildings);
    }
    else{
      const sortedBuildings = sortBuildings(buildings || []);
      setMatchedBuildings(sortedBuildings);
    }
  }, [searchBuildingInput, filters, sort]);

  function sortBuildings(matchedBuildings: IBuilding[]){
    let sortedBuildings: IBuilding[] = matchedBuildings;
    if(sortedBuildings !== undefined){
      switch(sort){
        case 0: //Alphabetical (A-Z)
          sortedBuildings.sort((a: IBuilding, b: IBuilding) => a.building_name.localeCompare(b.building_name));
          break;
        case 1: //Alphabetical (Z-A)
          sortedBuildings.sort((a: IBuilding, b: IBuilding) => b.building_name.localeCompare(a.building_name));
          break;
        case 2: //Newest to oldest
          sortedBuildings.sort((a: IBuilding, b: IBuilding) => parseInt(a.ID) - parseInt(b.ID));
          break;
        case 3: //Oldest to newest
          sortedBuildings.sort((a: IBuilding, b: IBuilding) => parseInt(b.ID) - parseInt(a.ID));
          break;
        case 4: //Last modification
          sortedBuildings.sort((a: IBuilding, b: IBuilding) => new Date(b.updated!).getTime() - new Date(a.updated!).getTime());
          break;
      }
      
      return sortedBuildings;
    }
  }

  function searchMatch(_searchTerm: string, _wordList: string[]){
    let wordList: string[] = [];

    if(_searchTerm === undefined || _searchTerm === '')
      return true;

    _wordList.forEach(_word => {
      wordList.push(_word);
      wordList.push(_word.replace(/[^a-zA-Z0-9]/g,''));

      _word.split(' ').forEach(word => {
        wordList.push(word);
        wordList.push(word.replace(/[^a-zA-Z0-9]/g,''));
      });
    })
    
    wordList.forEach(word => {
      wordList.push(word.toUpperCase());
      wordList.push(word.toLowerCase());
    })

    wordList = removeDuplicates(wordList);

    console.log('wordList', wordList);

    const escapedSearchTerm = _searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regEx = new RegExp(`\\b${escapedSearchTerm}\\b`, 'gi');

    const filteredList = wordList.filter(word => {
      if(word.includes(_searchTerm))
      return word;

      const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      return escapedWord.match(regEx);
    });

    return filteredList.length > 0;
  }

  function removeDuplicates(arr: string[]) {
    const uniqueArr: string[] = [];
    for (let i = 0; i < arr.length; i++) {
      if (!uniqueArr.includes(arr[i])) {
        uniqueArr.push(arr[i]);
      }
    }
    return uniqueArr;
  }

  const fetchBuildingTypes = () => {
    const buildingService = new BuildingService();
    buildingService.getBuildingTypes(keycloak)
      .then((response) => {
        const buildingTypes: IBuildingType[] = response;
        setBuildingTypes(buildingTypes);
        console.log('buildingTypes', buildingTypes);
      })
      .catch(error => {
        console.log('fetchBuildingTypes error', error);
        if (error.response.status === 401) {
          navigate('/');
          keycloak.logout();
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.error(`Error: ${error}`)
      });
    //setLoading(false);
  }

  const fetchUserInfo = () => {
    const config = {
      headers: { Authorization: `Bearer ${keycloak.token}` }
    };

    axios.get(`${process.env.REACT_APP_API_URL}/user`, config)
      .then((response) => {
        const userInfo: IUserInfo = response.data.result;
        console.log("userInfo", userInfo);
        setUserInfo(userInfo);

        fetchBuildingInfo(userInfo);

        if (userInfo !== undefined &&
            userInfo.permissions !== undefined &&
            userInfo.permissions.length > 0 &&
            !window.localStorage.getItem("hideModalRole")) {
          handleOpenModalRole()
        }

        if (userInfo !== undefined &&
            userInfo.permissions !== undefined &&
            userInfo.permissions.length > 0){
            fetchUserTypes(userInfo.permissions![0].user_type_ID);
        }
      })
      .catch(error => {
        console.log('fetchUserInfo error', error);
        if (error.response?.status === 401) {
          navigate('/');
          keycloak.logout();
        }
        console.log(error.response?.data);
        console.log(error.response?.status);
        console.log(error.response?.headers);
        console.error(`Error: ${error}`)
      });
    //setLoading(false);
  }

  const fetchBuildingInfo = (userInfo: IUserInfo) => {
    if (userInfo === undefined) {
      console.log("userInfo undefined");
      return
    }
    if (userInfo.permissions === undefined) {
      console.log("permissions undefined");
      const buildings: IBuilding[] = [];
      setBuildings(buildings);
      return;
    }

    var permissions = userInfo.permissions;
    var values = permissions.map(permission => permission.building_ID);

    const data = {
      'load_documents': false,
      'building_ID': values
    }

    const url = `${process.env.REACT_APP_API_URL}/building`;

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${keycloak.token}`
      },
      params: data
    };

    console.log(config);
    axios.get(url, config)
      .then(async(response) => {
        const buildings: IBuilding[] = response.data.result;
        console.log('buildings',buildings);

        for (var i = 0; i < buildings.length; i++) {
          const building: IBuilding = buildings[i];
          if (building.media) {
            const building_imgObj = building.media.find(media => media.mediatype === 'BUILDING_IMG');
            if (building_imgObj && building_imgObj.versions !== undefined) {
              const building_img_version = building_imgObj.versions[0];
              building.building_img_data = building_img_version.mediabase64!;
              building.building_img_mimetype = building_img_version.mimetype;
            }
          }

          //reset level and magma index
          //building.level.fill(0);
          //building.magma_index = 0;
          //building levels based on DTT
          building.level = Array(5).fill(0);
          console.log('building.level', building.level);
          building.magma_index = 0;
          if (building.dtt_address?.trim()) {
            const dtt = await blockchain.getInstanceOfDigitalTwinToken(building.dtt_address);
            const tmpDTTMetaData: any = await dtt.getMetaData();
            building.level[0] = tmpDTTMetaData.level;
            building.level[1] = 0;
            building.level[2] = 0;
            building.level[3] = 0;
            building.level[4] = 0;
            
            for (let i = 0; i < tierData.length; i++) {
              building.magma_index += building.level[i] * tierData.sort((a, b) => a.tier_id - b.tier_id)[i].magma_index_weight;
            }
          }
        }
        setBuildings(buildings);
        setMatchedBuildings(sortBuildings(buildings));
      })
      .catch(error => {
        console.log('fetchBuildingInfo error', error);

        if (error.response?.status === 401) {
          navigate('/');
          keycloak.logout();
        }
        console.log(error.response?.data);
        console.log(error.response?.status);
        console.log(error.response?.headers);
        console.error(`Error: ${error}`)
      });
    //setLoading(false);
  }

  const fetchUserTypes = (userTypeID: number) => {
    const userService = new UserService();
    userService.getUserTypes(keycloak)
      .then((response) => {
        const userTypes: IUserType[] = response;
        console.log("userTypes", userTypes);
        setUserTypes(userTypes);
        
        const userType = userTypes.find(userType => userType.ID === userTypeID);
        if(userType !== undefined && userType.user_type_docs !== undefined) {
            console.log('userTypeDocs', userType.user_type_docs);
            setUserTypeDocs(userType.user_type_docs);
        }

        console.log("userType", userType);
      })
      .catch(error => {
        if(error.response){
          if (error.response.status === 401) {
            navigate('/');
            keycloak.logout();
          }
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);

          console.error(`Error: ${error}`)
        }
      });
    //setLoading(false);
  }

  function deleteBuilding(building_ID: any) {
    console.log("DELETE BUILDING", building_ID);

    console.log(buildings);
    if (buildings !== undefined) {
      const data = {
        'building_ID': building_ID
      }

      const url = `${process.env.REACT_APP_API_URL}/building`;

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${keycloak.token}`
        },
        params: data
      };

      axios.delete(url, config)
        .then((response) => {
          //const userInfo: IUserInfo = response.data.result;
          console.log(response);

          if(response.data.status){
            /*const index = buildings.findIndex(building => building.ID.toString() === building_ID);

            if (index > -1){
              buildings.splice(index, 1);
              setBuildings(buildings);
            }*/
          }
          else{
            toast.error("Error removing building, try again!")
          }
        })
        .catch(error => {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          console.error(`Error: ${error}`)
        });

      //if result ok
      const index = buildings.findIndex(building => building.ID.toString() === building_ID);

      if (index > -1)
        buildings.splice(index, 1);

      setAnchorEl(null);
    }
  }

  function isLocked(item: any){
    const building_ID = item.ID;
    const user_permission = userInfo?.permissions?.find((permission: any) => permission.building_ID === building_ID);

    if(!user_permission){
      return undefined;
    }

    // if owner, then no NDA needed
    //if (user_permission?.user_type_ID === 1){
    //  return false;
    //}

    //DISABLE CHECKING NDA, ALWAYS ALLOW ACCESS TO BUILDING BECAUSE THEY SIGN NDA WHEN CREATING MAGMAID
    //return false;
    //return !user_permission!.nda_validated;

    const building = buildings?.find(building => building.ID === building_ID);
    const status = building?.status;

    if([1,2].includes(user_permission.user_type_ID)){
      return false;
    }
    else{
      if(status === BUILDING_STATUS_DRAFT){
        return true;
      }

      return false;
    }
  }

  const handleClickBuilding = (_building: IBuilding) => {
    setBuilding(buildings?.find(b => b.ID === _building.ID));
    if(!isLocked(_building)) {
      if(_building.status === 1){
        navigate(`/building/${_building.ID}/add`);
      }
      else{
        navigate(`/building/${_building.ID}`);
      }
    }
  }

  const handleClickNDA = (item: IBuilding) => {
    setBuildingID(item.ID);
    handleOpenModalNda();
  }

  function canCreateNewBuilding() {
    if(!userInfo || !userInfo.permissions){
      return true;
    }

    const user_type_ID = userInfo.permissions ? userInfo.permissions![0].user_type_ID : 0;

    if([0,1].includes(user_type_ID)){
      return true;
    }
    else{
      return false;
    }
  }

  return (
    <Box className="Buildings">
      <Stack direction={isTablet ? 'column' : 'row'} sx={{ justifyContent: 'space-between' }}>
        <Typography size="h2" weight='regular'>My buildings</Typography>

        <Stack direction={bothNavsOpen ? 'column' : 'row'}
          sx={{
            aligSelf: 'self-end',
            justifyContent: isTablet ? 'flex-start' : 'flex-end'
          }}
        >
          {!isMobile &&
            <Button
              variant="contained"
              color="primary"
              sx={{
                maxHeight: 37,
                maxWidth: 180,
                width: isMobile ? '100%' : 'auto',
                marginRight: '.5rem',
                marginTop: '2px'
              }}
              onClick={() => navigate('/building/add')}
              disabled={!canCreateNewBuilding()}
            >
              <img
                alt="+"
                color="white"
                src="/svg/icons/Plus.svg"
                width={20}
                height={20}
              />
              <Box ml={isMobile ? 0 : '10px'} style={{ width: 'auto', textTransform: 'none' }}>
                <Typography weight="medium">
                  New building
                </Typography>
              </Box>
            </Button>
          }


          {!isMobile &&
            <SearchListBuildings
              placeholder='Search through buildings'
              list={buildingSearchList}
              value={searchBuilding?.value}
              handleSearch={setSearchBuildingInput}
              setSearchBuilding={setSearchBuilding}
            />
          }
        </Stack>
      </Stack>

      <Stack direction={isMobile ? 'column' : 'row'}
        sx={{
          justifyContent: isTablet ? 'flex-start' : 'flex-end',
          marginTop: 2
        }}>
        {isMobile &&
          <Button
            variant="contained"
            color="primary"
            sx={{
              maxHeight: 40,
              marginBottom: isMobile ? 3 : '0',
              width: isMobile ? '100%' : '220px',
              marginRight: '1rem'
            }}
            onClick={() => navigate('/building/add')}
            disabled={!canCreateNewBuilding()}
          >
            <img
              alt="+"
              color="white"
              src="/svg/icons/Plus.svg"
              width={20}
              height={20}
            />
            <span style={{ marginLeft: 10, textTransform: 'none' }}>
              New building
            </span>
          </Button>
        }

        {!isMobile && <FlexSpacer minHeight={.45} />}

        <Filters
          setFilters={setFilters}
          setSort={setSort}
          filters={filters}
          buildingTypes={buildingTypes}
        />

        {/* <ButtonSetModals /> */}

      </Stack>


      <Stack direction="row" sx={{ justifyContent: 'space-between', paddingTop: '2rem', }}>
        <Grid container rowSpacing={2} spacing={2}>
          {matchedBuildings !== undefined && matchedBuildings.length > 0 ? matchedBuildings.map((building, index) => {

            const getCardClassName = (index: number) => {
              if (active > -1 && index === active) return "showProgress";
              return "hideProgress";
            };

            return (
              <Grid
                item
                key={index}
                xs={12} sm={12} md={messengerOpen ? 12 : 6} lg={6}
                className="zoom"
                sx={{ maxWidth: isTablet ? 'auto !important' : '328px !important', maxHeight: '253px', pointerEvents: isValidating ? 'none' : 'all' }}>
                <StyledWrapperBox>
                  <StyledCardLink
                    style={{
                      position: 'relative'
                    }}
                    onClick={() => {
                      handleClickBuilding(building);                      
                    }}
                  >

                    <img
                      alt={building.building_name}
                      color="white"
                      className={`building-img`+(isLocked(building) ? 'not' : '')}
                      src={building.building_img_data !== undefined && building.building_img_data !== '' ? `data:${building.building_img_mimetype};base64,${building.building_img_data}` : '/images/building/Draft.png'}
                      onError={imageOnErrorHandler}
                      width={340}
                      height={200}
                      style={{
                        filter: isLocked(building) ? 'brightness(0.5)' : 'brightness(1)',
                        cursor: isLocked(building) ? 'default' : 'pointer',
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%'
                      }}
                    />

                    {false && isLocked(building) && (
                      <StyledLockedBox
                        onClick={() => handleClickNDA(building)}
                      >
                        <img
                          className="lock"
                          alt={building.building_name}
                          color="white"
                          src="/svg/icons/Lock.svg"
                          width={56}
                          height={56}
                          style={{marginTop: '10px'}}
                        />

                        <Typography size="16px" weight="medium" sx={{ margin: '8px auto 0', maxWidth: 200 }}>
                          Sign the NDA to unlock the building
                        </Typography>
                      </StyledLockedBox>
                    )}
                  </StyledCardLink>

                  {/* Todo: Add validating status inside each card */}
                  <Stack direction="row"
                    sx={{
                      maxWidth: isTablet ? 'inherit' : '290px',
                      right: 0,
                      justifyContent: isSmMobile ? 'space-between' : 'flex-end',
                      position: 'absolute',
                      top: 0,
                      padding: 1,
                      width: '100%'
                    }}>

                    {(() => {
                      switch (building.status) {
                        case BUILDING_STATUS_DRAFT:
                          return (
                            <StyledButton
                              size="small"
                              variant="contained"
                              style={{ backgroundColor: '#F7F7F8' }}
                              sx={{
                                color: theme.colors.black,
                                maxHeight: '25px',
                                minWidth: '50px',
                                cursor: 'auto'
                              }}>{' '}
                              Draft
                            </StyledButton>
                          );
                        case BUILDING_STATUS_NOT_MINTED:
                          return (
                            <>
                              <StyledButton
                                size="small"
                                variant="contained"
                                style={{ backgroundColor: '#FFA800' }}
                                sx={{
                                  color: theme.colors.white,
                                  maxHeight: '25px',
                                  minWidth: '50px',
                                  cursor: 'auto'
                                }}>{' '}
                                Not minted
                              </StyledButton>

                              {/*
                            <Box sx={{
                              position: 'absolute',
                              top: '5px',
                              right: '11px'
                            }}>
                              <Stack direction="row" sx={{ justifyContent: 'end' }}>
                                <Button variant="contained" sx={{
                                  backgroundColor: '#FFA800',
                                  maxHeight: '26px',
                                  marginRight: '10px'
                                }}>
                                  Not minted
                                </Button>
        
                                <OptionsButton
                                  top={4}
                                  right={-1}
                                  size="small"
                                  id={"buildingOptionsMenu-" + item.ID}
                                  aria-controls={open ? 'demo-positioned-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? 'true' : undefined}
                                  onClick={handleClick}
                                  sx={{
                                    position: "relative !important",
                                    backgroundColor: 'white',
                                    width: 25,
                                    height: 25,
                                    marginLeft: '.1rem'
                                  }}
                                />
        
                                {
                                <Menu
                                  id="options-menu"
                                  anchorEl={anchorEl}
                                  open={openOptions}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    'aria-labelledby': 'lock-button',
                                    role: 'listbox',
                                  }}
                                >
                                  {options.map((option, index) => (
                                    <MenuItem
                                      key={option}
                                      disabled={index === 0}
                                      selected={index === selectedIndex}
                                      onClick={(event) => handleMenuItemClick(event, index)}
                                    >
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Menu>
                                }                                magma_index
                              </Stack>
                            </Box>
                            */}
                            </>
                          );
                        case BUILDING_STATUS_ACTIVE:
                          return (
                            <>
                              {/* NOTIFICATION BELL TODO
                              <StyledButton
                                size="small"
                                color='inherit'
                                variant="contained"
                                sx={{
                                  color: theme.colors.dark,
                                  backgroundColor: theme.colors.neutral1,
                                  maxHeight: '25px',
                                  minWidth: '44px',
                                  padding: '5px',
                                  marginRight: '.5rem'
                                }}>
                                {' '}
                                <Typography size="small">{notifications.length}</Typography>
                                <img src='/svg/icons/BellRinging.svg' width="15" height="15" alt="bell icon" style={{ marginLeft: '4px' }} />
                              </StyledButton>
                              */}
                              <Box
                                sx={{ cursor: 'pointer' }}
                                onMouseOver={() => {
                                  setActive(index);
                                }}
                                onMouseOut={() => {
                                  setActive(-1);
                                }}>
                                
                                <Progress
                                  done={building.magma_index}
                                  label="Magma index: "
                                  sx={{ marginRight: '.5rem', marginBottom: '0' }}
                                />
                              </Box>

                              <StyledButton
                                size="small"
                                variant="contained"
                                background-color="#001AFF"
                                sx={{
                                  color: theme.colors.white,
                                  maxHeight: '25px',
                                  minWidth: '50px',
                                  cursor: 'auto'
                                }}>{' '}
                                Active
                              </StyledButton>
                            </>
                          );
                      }
                    })()}

                    { /*BuildingOptionsMenu*/
                      userInfo && 
                      userInfo.permissions &&
                      [1,2].includes(userInfo.permissions[0].user_type_ID) &&
                      <OptionsButton
                        aria-describedby={id}
                        top={2}
                        right={-1}
                        size="small"
                        id={"buildingOptionsMenu-" + building.ID}
                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        maxHeightImg={19}
                        maxWidthImg={21}
                        sx={{
                          position: "relative !important",
                          backgroundColor: 'white',
                          width: 25,
                          height: 25,
                          marginLeft: '.5rem'
                        }}
                      />
                    }

                    <StyledPaper
                      key={building.ID}
                      data-id={building.ID}
                      style={style}
                      sx={{ position: 'absolute', top: '44px', right: '13%' }}
                      className={`${getCardClassName(index)}`}
                    >
                      <Stack
                        direction="column"
                        p={1}
                      >
                        {tierData && tierData
                        .sort((a, b) => a.tier_id - b.tier_id)
                        .map((item, index) => (
                          <Grid
                            container
                            key={index}
                            sx={{
                              gridGap: 10, marginBottom: .5,
                              '&:last-child': { marginBottom: 0 }
                            }}>
                            <Grid item sx={{ width: '65px' }}>
                              <Typography size="10px" sx={{float: 'right'}}>{item.tier}</Typography>
                            </Grid>
                            <Grid item sx={{ width: '98px' }} >
                              <Progress
                                type='level'
                                color={building.level[item.tier_id] === 100 && 'green'}
                                done={building.level[item.tier_id]}
                              />
                            </Grid>
                            <Grid item sx={{ width: '32px', marginLeft: 'auto', marginBottom: 0 }}>
                              <Typography size="10px" color='#B4B5C1'>{Math.round(building.level[item.tier_id] * 100) / 100}%</Typography>
                            </Grid>
                          </Grid>
                        ))}

                      </Stack>
                    </StyledPaper>
                  </Stack>

                </StyledWrapperBox>

                <StyledTextLink
                  onClick={() => handleClickBuilding(building)}
                  sx={{cursor: isLocked(building) ? 'default' : 'pointer'}}
                >
                  <Typography size="h4" my={1}>{building.building_name}</Typography>
                </StyledTextLink>
              </Grid>
            )
          }
          ) :
            (buildings === undefined ? ([{}].map((item,index) =>
              <Grid
                container
                key={`tempbuildings-${index}-${new Date().getTime() + Math.random()}`}              
                sx={{ maxWidth: isTablet ? 'auto !important' : '328px !important', maxHeight: '253px' }}
              >
                <Grid
                  item
                  xs={12} sm={12} md={messengerOpen ? 12 : 6} lg={12}
                  sx={{ maxHeight: '250px', padding: '0 .5rem', width: '100% !important' }}
                >
                  <StyledWrapperBox>
                    <Skeleton
                      animation="wave"
                      width="100%"
                      height={200}
                      sx={{ borderRadius: 2 }}
                    />
                    <Skeleton
                      animation="wave"
                      height={40}
                      width="50%"
                      sx={{ borderRadius: 2 }}
                    />
                  </StyledWrapperBox>
                </Grid>
              </Grid>
            ))
              :
              (
                <Box sx={{ margin: '0 0 0 1rem' }}>
                  <Welcome />
                </Box>
              )
            )
          }

          <Popover
            id={id}
            open={openPop}
            anchorEl={anchorEl}
            disableScrollLock={false}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            PaperProps={{
              elevation: 1
            }}
            sx={{
              ml: 1.7,
              mt: -1.2
            }}
          >
            <Button
              style={{ textTransform: 'inherit' }}
              sx={{ border: 1, borderColor: 'lightgrey' }}
              onClick={() => deleteBuilding(currentSelectedBuildingID)}
            >
              <Stack direction="row"                          >
                <img src="/svg/icons/Trash.svg" className={`icon-error`} alt="delete icon" />
                <Typography color={theme.colors.error} sx={{ ml: 1 }}> Delete building</Typography>
              </Stack>
            </Button>
          </Popover>
        </Grid>
      </Stack>

      {false && (
        <>
          <Stack direction={isMobile ? 'column' : 'row'} sx={{ justifyContent: 'flex-end', marginTop: 2 }}>
            <Typography>Old - finalized Modals:  (needs backend check to be removed)</Typography>
            <Button onClick={() => toast.success("Your account has been created!")}>success!</Button>
            <Button onClick={() => toast.info("Changes saved.")}>info!</Button>
            <Button onClick={() => toast.error("Oops... Something happened. Please try again.")}>error!</Button>
            <Button onClick={handleOpenModalGiveAccess}>GiveAccess</Button>
          </Stack>

          <Stack direction={isMobile ? 'column' : 'row'} sx={{ justifyContent: 'flex-end', marginTop: 1 }}>
            <Button onClick={handleOpenModalAddStakeholder}>AddStakeholder</Button>
            <Button onClick={() => navigate('/privacy-policy')}>policy page</Button>
            <Button onClick={() => navigate('/terms-of-use')}>Terms of use page</Button>
          </Stack>

          <Stack direction={isMobile ? 'column' : 'row'} sx={{ justifyContent: 'flex-end', marginTop: 2 }}>
            <Button onClick={handleOpenModalRole}>Your role </Button>
            <Button onClick={handleOpenModalStakeholders}>Stakeholders</Button>
            <Button onClick={handleOpenModalStakeholdersDetailed}>Stakeholders Detailed</Button>
            <Button onClick={handleOpenModalEstimate}>handleOpenModalEstimate</Button>
          </Stack>

          <Stack direction={isMobile ? 'column' : 'row'} sx={{ justifyContent: 'flex-end', marginTop: 2 }}>
            <Button onClick={handleOpenModalAllowPopup}>Allow popup </Button>
            <Button onClick={handleOpenModalWalletSelector}>Wallet selector </Button>
          </Stack>

          <Stack direction={isMobile ? 'column' : 'row'} sx={{ justifyContent: 'flex-end', marginTop: 2 }}>
            <Typography>NEW modals - Aug-Sept: </Typography>

            <Button onClick={handleOpenModalNft}>DTT® </Button>
            <Button onClick={handleOpenModalNftLevel}>DTT® Level </Button>
            <Button onClick={handleOpenModalNftProgress}>DTT® Progress</Button>
          </Stack>

          <Stack direction={isMobile ? 'column' : 'row'} sx={{ justifyContent: 'flex-end', marginTop: 2 }}>
            <Button onClick={handleOpenModalBuyMUT}>Buy MUT</Button>
            <Button onClick={handleOpenModalWalletCreated}> WalletCreated </Button>
            <Button onClick={handleOpenModalDocViewerInfo}>DocViewerInfo</Button>
            <Button onClick={handleOpenModalDocChat}> Modal Doc Chat</Button>
          </Stack>

          <Stack direction={isMobile ? 'column' : 'row'} sx={{ justifyContent: 'flex-end', marginTop: 2 }}>
            <Button onClick={handleOpenModalTour}>Modal Tour - tutorial </Button>
            <Button onClick={handleOpenModalReminderSent}>Modal Reminder sent </Button>

          </Stack>

          <Stack direction={isMobile ? 'column' : 'row'} sx={{ justifyContent: 'flex-end', marginTop: 2 }}>

            <Typography>Rework - added extra layout: </Typography>

            <Button onClick={handleOpenModalBuildingAdded}>BuildingAdded</Button>
            <Button onClick={handleOpenModalInvitation}>Invitation</Button>
          </Stack>
        </>
      )}
    </Box>
  );
}

export default BuildingListPage;


const StyledPaper = styled(Paper)`
  .progress {
    height: 13px;
    min-width: 80px;
    margin-top: 3px;
    max-width: 98px;

    span {
      display: none;
    }
  }
`;
const StyledButton = styled(Button)`
  font-size: 12px!important
`;

const StyledTextLink = styled(Link)`
  color: black;
`;

const StyledCardLink = styled(Link)`
  overflow: hidden;
  border-radius: 4px;
  height: 200px;
  display: block;

  :hover {
    text-decoration: none;
  }
`;
const StyledWrapperBox = styled(Box)`
  position: relative;
  overflow: hidden;

  .showProgress {
    display: block !important;
  }
`;

const StyledLockedBox = styled(Box)`
  position: absolute;
  color: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0,0,0,0.5);
  padding: 2rem;
  z-index: 2;
`;

const notifications = [
  {
    id: 1,
    title: "Deloitte Amsterdam",
    link: "/activity/id-1"
  },
  {
    id: 2,
    title: "Magma Tower",
    link: "/activity/id-2"
  }
]

const FALLBACK_IMAGE =
  "/images/Logo_Transparent_White.png";

const options = [
  'building-list',
  'Show some love to MUI',
  'Show all notification content',
  'Hide sensitive notification content',
  'Hide all notification content',
];