import { theme } from "../utils/theme";
import { IUserPayment } from "./userInfo";

export interface IMedia {
    ID?: number;
    keyval: string;
    mediatype: string;
    level?: number;
    magma_index_weight?: number;
    index_weight?: number;
    versions: IMediaVersion [];
}

export interface IMediaVersion {
    ID?: number;
    media_ID?: number;
    originalname: string;
    mimetype: string;
    mediadestination: string;
    mediafilename: string;
    mediapath: string;
    mediasize: number;
    mediabase64?: string;
    mediabase64thumbnail?: string;
    hash?: string;
    //validated?: boolean;
    status?: number;
    activity?: IMediaActivity[];
    payment?: IUserPayment[];
    access: boolean;
  }

export interface IMediaActivity {
    ID?: number;
    media_ID: number;
    user_ID: number;
    user_type_ID: number;
    action: number;
    info: string;
    comments: string;
    timestamp?: string;
    firstname?: string;
    lastname?: string;
    wallet_chain?: string;
    wallet_address?: string;
    user_type_name?: string;
}

export const DOCUMENT_STATUS_ADDED = 1;
export const DOCUMENT_STATUS_PENDING = 2;
export const DOCUMENT_STATUS_VALIDATED = 3;
export const DOCUMENT_STATUS_REJECTED = 4;
export const DOCUMENT_STATUS_OUTOFDATE = 5;
export const DOCUMENT_STATUS_UPDATED = 6;

export const documentStatus = [{
    ID: DOCUMENT_STATUS_ADDED,
    desc: 'To do',
    borderColor: theme.colors.primary,
    color: theme.colors.primary,
    bgcolor: theme.colors.white,
    icon: 'Unchecked.svg'
},{
    ID: DOCUMENT_STATUS_PENDING,
    desc: 'Pending',
    borderColor: theme.colors.warning,
    color: theme.colors.white,
    bgcolor: theme.colors.warning,
    icon: 'CheckYellow.svg'
},{
    ID: DOCUMENT_STATUS_VALIDATED,
    desc: 'Validated',
    borderColor: theme.colors.tertiary,
    color: theme.colors.white,
    bgcolor: theme.colors.tertiary,
    icon: 'CheckGreenCircle.svg',
},{
    ID: DOCUMENT_STATUS_REJECTED,
    desc: 'Rejected',
    borderColor: theme.colors.error,
    color: theme.colors.white,
    bgcolor: theme.colors.error,
    icon: 'WarningRed.svg'
},{
    ID: DOCUMENT_STATUS_OUTOFDATE,
    desc: 'Out of Date',
    borderColor: theme.colors.error,
    color: theme.colors.white,
    bgcolor: theme.colors.white,
    icon: 'WarningRed.svg'
},{
    ID: DOCUMENT_STATUS_UPDATED,
    desc: 'Updated',
    borderColor: theme.colors.warning,
    color: theme.colors.white,
    bgcolor: theme.colors.warning,
    icon: 'CheckYellow.svg'
}]