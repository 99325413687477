import {  Box, Button,  Stack,  useMediaQuery } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakTokenParsed } from "keycloak-js";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../../utils/theme";
import { FlexSpacer } from "../../../atoms/FlexSpacer";
import Typography from "../../../atoms/Typography";


type ParsedToken = KeycloakTokenParsed & {
    email?: string
    preferred_username?: string
    given_name?: string
    family_name?: string
}

const Welcome: FC = () => {
    const { keycloak } = useKeycloak();
    const parsedToken: ParsedToken | undefined = keycloak?.tokenParsed;
    const Navigate = useNavigate();

    console.log(parsedToken);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [inputList, setInputList] = useState([
        { name: "", email: "", type: "", company: "" },
    ]);

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClick = () => {
        Navigate('/building/add')
    };


    return (
        <Stack
            direction={isMobile ? 'column' : 'row'}
            bgcolor={theme.colors.lighter}
            sx={{ boxShadow: '4px 4px 0 0 #001AFF', borderRadius: '4px', }}
            p="48px 32px"
            width="100%"
        >
            <Stack
                direction="column"
                mr={{ xs: 0, md: 2 }}
            >
                <Typography size="h3" weight="bold">Welcome to Magma, <br />{parsedToken?.given_name ?? ''}</Typography>

                <FlexSpacer minHeight={1} />

                <Typography size="paragraph" paragraph>
                    You’re just a few steps away from seeing your digital twin!
                </Typography>
                {/* <Typography>{parsedToken?.email ?? ''}</Typography> */}

                <FlexSpacer minHeight={1} />

                <Button
                    variant="contained"
                    sx={{ height: 38, maxWidth: 161 }}
                    onClick={handleClick}
                >
                    <img
                        alt="+"
                        color="white"
                        src="/svg/icons/Plus.svg"
                        width={20}
                        height={20}
                    />
                    <Box
                        ml={isMobile ? 0 : "10px"}
                        style={{
                            fontSize: '14px',
                            fontFamily: 'magma regular',
                            width: "auto",
                            textTransform: "none"
                        }}
                    >
                        New building
                    </Box>
                </Button>
            </Stack>

            <Stack direction="column" style={{ marginTop: isMobile ? 24 : 0 }}>
                <img src="/images/building/building04.jpg" alt="building" />
            </Stack>
        </Stack>
    );
};

export default Welcome;