import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { FC } from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';

interface SearchMapProps {
  register?:any
  id?:any
  name?:any
  error?: any
  handleSearch?: any
  locationList?: any
  locationSearchList?: any
  newValue?: any
  value?: any
  setMapLocation?: any
  field?: any
}

const SearchMap: FC<SearchMapProps> = ({
  register,
  id,
  name,
  error,
  handleSearch,
  locationList,
  locationSearchList,
  setMapLocation,
  value,
  ...otherProps}) => {

  console.log('searchMap value', value);
  console.log('otherProps', otherProps);

  return (
    <div className="searchMap">
      <Autocomplete
        disablePortal
        sx={{padding: 0}}
        fullWidth
        options={locationSearchList}
        filterOptions={(x) => x}
        autoComplete
        filterSelectedOptions
        value={otherProps.field.value.label ? otherProps.field.value.label : ''}
        onChange={(event: any, newSearchLocation: any | null, reason: string) => {
          console.log("REASON", reason);
          if(reason === 'clear'){
            setMapLocation(undefined);
          }
          else{
            if(newSearchLocation === undefined || newSearchLocation === null || newSearchLocation === "") return;
   
            const newMapLocation = locationList.find((location: { place_id: any; }) => location.place_id === newSearchLocation.place_id);
            
            console.log(newMapLocation);

            setMapLocation({
              label: newMapLocation.display_name,
              coords: {
                lat: newMapLocation.lat,
                lng: newMapLocation.lon
              }
            })
          }
        }}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.place_id}>
              <Grid container alignItems="center">
                <Grid item>
                  {option.icon !== undefined && option.icon !== '' ?
                  <Box
                    sx={{ color: 'text.secondary', mr: 2 }}
                  >
                    <img src={option.icon} alt='' />
                  </Box>
                  :
                  <Box
                    component={LocationOnIcon}
                    sx={{ color: 'text.secondary', mr: 2 }}
                  />
                  }
                </Grid>
                <Grid item xs>
                  {option.label}
                </Grid>
              </Grid>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id="searchInput"
            className="map__searcher"
            label=""
            name={name}
            error={error}
            sx={{ 
              "input": {
                padding: '0 7px !important'
              }
            }}
            fullWidth
            onChange={(event: any) => {
              handleSearch(event.target.value);
            }}
          />)
        }
      />
    </div>
  );
};

export default SearchMap;
