import styled from '@emotion/styled';
import { Stack, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { theme } from '../../utils/theme';
import { FlexSpacer } from '../atoms/FlexSpacer';
import Typography from '../atoms/Typography';
import { Loading } from './Loading';

const DivLoading = styled(Stack)`
    background-color: #1930FF; /* For browsers that do not support gradients */
    background-image: linear-gradient(#1930FF, #0017E5); 
    height: 100vh;
    align-items: center;
    padding: 2rem 10rem;
    color: white;
`;

const CenterVertical = styled(Stack)`
    margin: auto 0;
`;

const CenterTitle = styled(Stack)`
    align-items: center;
    justify-content: space-between;
`;

const OneLine = styled(Stack)`
    display: inline;
`;

export const Loader: FC = ({ ...props }) => {
    console.log("Loader");
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <DivLoading direction="column">
            <CenterVertical>
                <img src="/svg/3DLogo.svg" alt="magma brand home" width={isMobile ? '100%' : 360} height={isMobile ? '100%' : 360}/>

                <FlexSpacer minHeight={1.5} />

                <CenterTitle>
                    <img color="white" src="/svg/magma_text_large.svg" alt="magma brand text" width={235} height={50} /><br></br>

                    <Loading />
                </CenterTitle>
            </CenterVertical>
        </DivLoading>
    );
};