import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FlexSpacer } from '../design-system/atoms/FlexSpacer';
import { Typography } from '../design-system/atoms/Typography/Typography'
import FilterActivity from '../design-system/molecules/Filters/FilterActivity';
import GridActivity from '../design-system/organisms/Grids/GridSliderActivity';
import {ActivityTable} from '../design-system/organisms/Tables/ActivityTable';
import { theme } from '../utils/theme';
import BackArrowIcon from "../design-system/atoms/Buttons/BackArrowIcon"
import UserService from '../services/userService';
import { useKeycloak } from '@react-keycloak/web';
import BuildingService from '../services/buildingService';
import { IBuilding } from '../interfaces/building';
import { IUserInfo } from '../interfaces/userInfo';

interface ActivityPageProps {
  messengerOpen?: any
  props?: any
  handleOpenModalReminderSent?: any
  setBuildingWallet?: any;
  setUserInfo: any,
  userInfo: any,
  blockchain: any
}
const ActivityPage: FC<ActivityPageProps> = ({
  handleOpenModalReminderSent,
  messengerOpen,
  setBuildingWallet,
  setUserInfo,
  userInfo,
  blockchain
}) => {
  console.log("Activity");
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const [buildings, setBuildings] = useState<IBuilding[] | undefined>(undefined);
  const [selectedBuildings, setSelectedBuildings] = useState();

  useEffect(() => {
    if (!keycloak.authenticated) {
      navigate('/');
      keycloak.logout();
    }

    setBuildingWallet(undefined);

    fetchUserInfo();
  }, []);

  const fetchUserInfo = () => {
    const userService = new UserService();
    userService.getUser(keycloak)
      .then(async(response) => {
        const userInfo = response;
        console.log("userInfo", userInfo);
        setUserInfo(userInfo);
        fetchBuildingInfo(userInfo);
      })
      .catch(error => {
        console.log('error', error);
        if (error.response?.status === 401) {
          navigate('/');
          keycloak.logout();
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.error(`Error: ${error}`)
      });
  }

  const fetchBuildingInfo = (userInfo: IUserInfo) => {
    if (userInfo === undefined) {
        return;
    }

    const buildingService = new BuildingService();
    buildingService.getBuildings(keycloak, blockchain, userInfo)
    .then(async(response) => {
        setBuildings(response);
        console.log(response);
    })
    .catch((error) => {
        if (error.response.status === 401) {
            navigate('/');
            keycloak.logout();
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.error(`Error: ${error}`)
    });                
  }

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '744px'
      }}
    >
      <Stack direction="row" sx={{ paddingLeft: '.5rem' }}>
        <Link to="/buildings"
          style={{
            margin: '5px 0px -6px -10px',
            color: theme.colors.text,
            fontWeight: 'regular'
          }}
        >
          <BackArrowIcon />
        </Link>
        <Typography sx={{ paddingLeft: '.5rem' }} size='h2'>
          Activity history
        </Typography>
      </Stack>

      <FlexSpacer minHeight={3} />

      <Typography size="h3" weight='regular'>Show activity for</Typography>
      <FlexSpacer minHeight={2} />

      <Box sx={{
        overflow: 'hidden'
      }}>
        <GridActivity
          messengerOpen={messengerOpen}
          buildings={buildings}
          setSelectedBuildings={setSelectedBuildings}
          selectedBuildings={selectedBuildings}
        />
      </Box>

      <FlexSpacer minHeight={2} />

      <Box
        sx={{
          maxWidth: '752px'
        }}
      >
        <FilterActivity />

        <FlexSpacer minHeight={1.5} />

        <ActivityTable
          handleOpenModalReminderSent={handleOpenModalReminderSent}
          selectedBuildings={selectedBuildings}
        />
      </Box>
    </Box>
  )
}

export default ActivityPage;