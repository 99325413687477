import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import { FlexSpacer } from '../atoms/FlexSpacer';
import Typography from '../atoms/Typography';

const OneLine = styled(Stack)`
    display: inline;
`;

export const Loading = () => {
    return (
        <OneLine>
            <Typography  size='h5' >
                LOADING                
                <FlexSpacer minWidth={1} /><img src="/images/loader.gif" alt="magma loader" width={20} height={20} />
            </Typography>
        </OneLine>
    )
}
