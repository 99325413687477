import { FC, useEffect, useState } from "react";
import { Box, Grid, Skeleton, Stack, useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";
import Typography from "../../../../atoms/Typography";
import { theme } from "../../../../../utils/theme";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { useSwiper } from 'swiper/react';
import "swiper/css/navigation";
import "swiper/css";
import 'react-toastify/dist/ReactToastify.css';
import CircularBar from 'react-multicolor-circular-progress-bar';
import { IUserTypeDoc } from "../../../../../interfaces/userInfo";
import { IMedia } from "../../../../../interfaces/media";
import { FlexSpacer } from "../../../../atoms/FlexSpacer";
import { IDTTMetaData, tierData } from "../../../../../interfaces/dtt";

interface DttSliderLevelsProps {
  open?: boolean,
  messengerOpen?: boolean,
  handleOpenModal?: any,
  handleOpenModalStakeholdersDetailed?: any,
  handleOpenModalStakeholders?: any,
  handleOpenModalGiveAccess?: any,
  handleOpenModalBuildingAdded?: any,
  handleOpenModalAddStakeholder?: any,
  handleOpenModalNft?: any,
  handleOpenModalInvitation?: any,
  setOpen?: Function,
  bothNavsOpen?: boolean,
  userTypeDocs: IUserTypeDoc[],
  buildingDocs: IMedia[] | undefined,
  dttMetaData: IDTTMetaData | undefined,
  indexLevels: {
    percentageValidate: number,
    percentageValidated: number
  }[],
}

const DttSliderLevels: FC<DttSliderLevelsProps> = ({
  userTypeDocs,
  buildingDocs,
  dttMetaData,
  indexLevels
}) => {
  console.log("DttSliderLevels");
  const swiperImgControl = useSwiper();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [lastIndex, setLastIndex] = useState(false)
  //const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {

  }, [indexLevels])

  const getIndex = (e: any) => {
    console.log('HERE INDEX', e.realIndex)

    if (e.realIndex >= 0) {
      setLastIndex(true)

      setTimeout(() => {
        setLastIndex(false)

      }, 1000);

    }

    if (e.realIndex >= 12) {
      setLastIndex(false)

    }
    console.log('TRUE INDEX', lastIndex)
  }
  

  return (
    <SwiperWrapper>
      <Swiper
        className="mySwiper"
        onSlideChange={getIndex}
        modules={[Navigation, Pagination]}
        slidesPerView={isMobile ? 1 : 5}
        slidesPerGroup={isMobile ? 1 : 5}
        spaceBetween={10}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        navigation={{
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        }}
      >
        {tierData && tierData
        .sort((a, b) => a.tier_id - b.tier_id)
        .map((tier, index) => {
          return (
            <div key={index}
            >
              <SwiperSlide
                data-caption={tier.tier_id}
                key={tier.tier_id}
              >
                <StyledBox
                  //onClick={() => setSelectedIndex(index)}
                  style={{ position: 'relative' }}
                >
                  <StyledWrapperBox>

                    <Stack direction="column" key={index} sx={{ width: 80 }} >
                      {/* <CircularProgressWithLabel value={item.percentage} />*/

                      <div
                            style={{
                                width: '450px',
                                wordWrap: 'break-word',
                                fontFamily: 'Courier New',
                                fontSize: '10px'
                            }}
                        >
                        {
                        /*(indexLevels[tier.tier_id])?.percentageValidated}
                        angle: {Math.max(0,Math.round((indexLevels[tier.tier_id])?.percentageValidated + Math.min((indexLevels[tier.tier_id])?.percentageValidate, 0)) / 100 * 360 - 0.001)
                        */
                        }
                      </div>
                      }

                      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularBar
                          // title={{
                          // name: `${item.percentage} %`,
                          // position: { X: 10, Y: 1 }
                          // }}
                          // angleTransition={[180]}
                          fontFamily={'magma regular'}
                          scale={0.75}
                          //angleTransition={[Math.round(getPercentage(tier.tier_id).percentageValidated + 1) / 100 * 360]}
                          angleTransition={[Math.max(0.001,Math.round((indexLevels[tier.tier_id])?.percentageValidated + Math.min((indexLevels[tier.tier_id])?.percentageValidate, 0)) / 100 * 360 - 0.001)]}
                          colors={['#001AFF', '#FFA800']} //colors={['#001AFF', '#83ff00']}
                          stroke={{ color: '#BFC6FF', width: 7 }}
                          percent={{
                            showValue: false,
                            value: Math.round((indexLevels[tier.tier_id])?.percentageValidated + (indexLevels[tier.tier_id])?.percentageValidate + Math.max((indexLevels[tier.tier_id])?.percentageValidate * -1, 0)),
                            fontSize: 16,
                            fontWeight: 'bold',
                            align: 'middle',
                            position: {
                              X: 0,
                              Y: 0,
                              /*item: {
                                  tier: item.tier,
                                  percentage: item.percentage,
                                  percentage2: item.percentage2
                              }*/
                            }
                            // color: ['#001AFF', '#83ff00'],
                          }}
                        />
                        {<Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography weight="bold" size="body" >
                                {Math.round((indexLevels[tier.tier_id])?.percentageValidated * 1) / 1}%
                            </Typography>
                            {(indexLevels[tier.tier_id])?.percentageValidate !== 0 &&
                                <Typography weight="regular" size="10px" >
                                    &nbsp; (
                                    {
                                    // add '+' if the amount is higher then 0
                                    (indexLevels[tier.tier_id])?.percentageValidate > 0 && '+'}
                                    {Math.round((indexLevels[tier.tier_id])?.percentageValidate * 1) / 1}%)
                                </Typography>
                            }
                        </Box>}
                      </Box>

                      <Typography size={'13px'} sx={{ margin: '.5rem auto' }}>
                        {tier.tier}
                      </Typography>
                      {tier.tier_id > 0 && 
                        <Typography size={'10px'} sx={{ margin: '0rem auto' }}>
                            (coming soon)
                        </Typography>
                      }
                    </Stack>


                  </StyledWrapperBox>

                </StyledBox>

              </SwiperSlide>
            </div>
          )
        })}
        {/** :
          <Grid
            container
            key={`wallet-${new Date().getTime() + Math.random()}`}
            xs={12}
            sx={{ width: '100% !important', maxHeight: '253px' }}
            direction="row"
          >
            {[{}, {}, {}, {}].map(() =>
              <Grid
                item
                sx={{ maxHeight: '250px', padding: '0 .5rem', width: '184px !important' }}
              >
                <>
                  <Skeleton
                    animation="wave"
                    width="100%"
                    height={167}
                    sx={{ borderRadius: 2 }}
                  />
                  <Skeleton
                    animation="wave"
                    height={40}
                    width="50%"
                    sx={{ borderRadius: 2 }}
                  />
                </>
              </Grid>
            )}
          </Grid>
        }
      */}


        {lastIndex ? '' :
          <div className="with-overlay"></div>
        }
        <StyledImgStack>

          <div
            onClick={() => swiperImgControl.slidePrev()}
            className="swiper-button-prev"
          >
          </div>

          <div
            onClick={() => swiperImgControl.slideNext()}
            className="swiper-button-next"
          >
          </div>
        </StyledImgStack>

      </Swiper>
    </SwiperWrapper>
  );
}



export default DttSliderLevels;

const SwiperWrapper = styled(Box)`
  .swiper-slide {
    height: auto;
    cursor: pointer;
  }
`;



const StyledImgStack = styled(Box)`    
  z-index: 3;

    .swiper-slide { 
      width: auto;
      flex-shrink: 0;
      display: block;
      height: 100%;
      max-height: 100%;
     }
    
    .swiper-button-prev,
    .swiper-button-next {
        margin-top: -36px;
        height: 24px;        
        background: #001AFF;
        border-radius: 5px;
        color: white;
        
        &:after {
            font-size: 10px;
        }
    }
`;


const StyledBox = styled(Box)`
  img {
    border-radius: 4px;
    object-fit: cover;
  }

  &.active {
    background: #F2F4FF;
    border-radius: 4px;
    height: 100%;
    margin: 0 -10px;
    padding: 0 10px;
  } 

  > .MuiBox-root {
    pointer-events: none; 
    overflow: hidden;
    border-radius: 4px;
    height: 140px;
  }
`;

const StyledWrapperBox = styled(Box)`
  margin-top: 10px;
  position: relative;
  overflow: hidden;

  .showProgress {
    display: block !important;
  }
`;

const StyledLockedBox = styled(Box)`
      position: absolute;
      color: white;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      background: #00000080;
      padding: 2rem;
      z-index: 2;
      `;


const FALLBACK_IMAGE = "/images/Logo_Transparent_White.png"; 