import { FC, useState } from 'react'
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Avatar, Box, ListItemButton, Stack, useMediaQuery } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Typography from '../../../../atoms/Typography';
import { theme } from '../../../../../utils/theme';
import { DOCUMENT_STATUS_ADDED, DOCUMENT_STATUS_UPDATED, IMedia, IMediaActivity } from '../../../../../interfaces/media';
import { IUserType, IUserTypeDoc } from '../../../../../interfaces/userInfo';
import { formatNumber, limitChars, removeTrailingZeros } from '../../../../../utils/functions';
import { tierData } from '../../../../../interfaces/dtt';

interface DttAccordionProps {
    userTypeDocs: IUserTypeDoc[],
    buildingDocs: IMedia[] | undefined,
    userTypes: IUserType[],
}

export const DttAccordion: FC<DttAccordionProps> = ({
    userTypeDocs,
    buildingDocs,
    userTypes,
}) => {
    //console.log('tierData', tierData);

    const [expanded, setExpanded] = useState<string | false>('panel0');
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const Accordion = styled((props: AccordionProps) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));

    const AccordionSummary = styled((props: AccordionSummaryProps) => (
        <MuiAccordionSummary
            expandIcon={<ExpandMore />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: 'transparent',
        flexDirection: 'row',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(0),
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(0),
    }));

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const getOwnerImage = (activity: IMediaActivity | undefined) => {
        if(activity === undefined)
            return '';

        const user_type_ID = activity.user_type_ID;
        const user_type_img = userTypes?.find((userType: IUserType) => userType.ID === user_type_ID)?.img;

        return user_type_img;
    }

    const getMediaData = (_buildingDoc: IMedia) => {
        const user_type_doc_ID = parseInt(_buildingDoc.mediatype.substring(_buildingDoc.mediatype.lastIndexOf('_') + 1));
        const user_type_doc_description = userTypeDocs?.find(userTypeDoc => userTypeDoc.ID === user_type_doc_ID)?.description;
        
        const activity = _buildingDoc === undefined ? undefined : _buildingDoc.versions[0].activity?.find(a => [DOCUMENT_STATUS_ADDED, DOCUMENT_STATUS_UPDATED].includes(a.action));

        let paid;
        if(_buildingDoc.versions[0].payment !== undefined && _buildingDoc.versions[0].payment.length > 0){
            paid = `${formatNumber(removeTrailingZeros(_buildingDoc.versions[0].payment[0].amount))} MUT`
        }
        else{
            paid = '/';
        }

        const response = {
            user_img: getOwnerImage(activity),
            user_type_doc_description: user_type_doc_description || '',
            status: '', //'Update',
            user_long: activity?.firstname + ' ' + activity?.lastname,
            user_short: limitChars(activity?.firstname || '', 6) + ' ' + activity?.lastname?.substring(0,1) + '.',
            paid: paid
        }
        return response;
    }

    return (
        <Box mt={2} >
            {tierData && tierData
            .sort((a, b) => a.tier_id - b.tier_id)
            .map((item: any, index: number) => {
                return (
                    <Accordion
                        sx={{
                            backgroundColor: 'transparent',
                            borderRadius: '4px',
                            overflow: 'hidden',
                            marginBottom: '8px',
                            width: '100%'
                        }}
                        expanded={expanded === `panel${item.tier_id}`}
                        onChange={handleChange(`panel${item.tier_id}`)}
                        key={index}
                    >
                        <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            sx={{
                                backgroundColor: '#FCFCFD', width: '100%'
                            }}
                        >
                            <Typography size="h5" weight="medium" width="100%">
                                {item.tier}
                            </Typography>
                        </AccordionSummary>

                        {item.tier_id === 0
                        ? (
                            <AccordionDetails>
                                {buildingDocs !== undefined && buildingDocs.map((buildingDoc: IMedia, index: number) => {
                                    return (
                                        <ListItemButton
                                            key={index}
                                            style={{
                                                display: isMobile ? 'block' : 'flex',
                                                paddingLeft: '10px',
                                                background: '#FCFCFD',
                                                borderRadius: '5px',
                                                margin: ' .5rem 0 ',
                                                width: '100%'
                                            }}>

                                            <Stack direction="column"
                                                justifyContent="space-between"
                                                width="100%"
                                            >
                                                <Stack direction="row" >
                                                    <Typography sx={{
                                                        fontSize: '12px !important',
                                                    }} > {limitChars(getMediaData(buildingDoc).user_type_doc_description, 15)}
                                                    </Typography>

                                                    <Typography sx={{
                                                        fontSize: '12px !important',
                                                        marginLeft: 'auto'
                                                    }}> {getMediaData(buildingDoc).status}
                                                    </Typography>
                                                </Stack>

                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    mt={.5}
                                                    sx={{ alignItems: 'right' }}
                                                >
                                                    <Stack
                                                        direction="row"
                                                    >
                                                        {getMediaData(buildingDoc).status !== undefined && 
                                                            <Avatar
                                                                sx={{ width: 32, height: 32 }}
                                                                alt={`${getMediaData(buildingDoc).user_long}`}
                                                                title={`${getMediaData(buildingDoc).user_long}`}
                                                                src={`/../images/stakeholders/${getMediaData(buildingDoc).user_img}`}
                                                            />
                                                        }

                                                        <Typography ml={1} sx={{
                                                            fontSize: '12px !important',
                                                        }}>
                                                            {getMediaData(buildingDoc).user_short}
                                                        </Typography>
                                                    </Stack>


                                                    <Typography ml="auto" sx={{
                                                        fontSize: '12px !important',
                                                    }}>
                                                        {getMediaData(buildingDoc).paid}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </ListItemButton>
                                    )
                                })
                                }
                            </AccordionDetails>
                        )
                        : (
                            <AccordionDetails>
                                <ListItemButton
                                    style={{
                                        display: isMobile ? 'block' : 'flex',
                                        paddingLeft: '10px',
                                        background: '#FCFCFD',
                                        borderRadius: '5px',
                                        margin: ' .5rem 0 ',
                                        width: '100%'
                                    }}
                                >
                                    Coming soon
                                </ListItemButton>
                            </AccordionDetails>
                        )}
                    </Accordion>

                )
            })
            }

        </Box>
    );
}

