import styled from "styled-components"

export const FileUploadContainer = styled.section`
    position: relative;
    /*margin: 0 0 15px;*/
    border: 1px solid lightgray;
    padding: 35px 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
`;

export const FileUploadContainerDocument = styled.section`
    position: relative;
    /*margin: 0 0 15px;*/
    border: 1px dashed lightgray;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 12px;
`;

export const FormField = styled.input`
    font-size: 18px;
    display: block;
    width: 100%;
    cursor: pointer;
    border: none;
    text-transform: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;

    &:focus {
    outline: none;
    }
`;

export const InputLabel = styled.label`
    top: -30px;
    font-size: 13px;
    color: black;
    left: 0;
    position: absolute;
`;

export const DragDropText = styled.p`
    font-weight: bold;
    letter-spacing: 2.2px;
    margin-top: 0;
    text-align: center;
`;

export const UploadFileBtn = styled.button`
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 1px solid #D9D9D9;
    cursor: pointer;
    font-family: 'magma regular';
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    padding: 7px 12px;
    text-align: center;
    //text-transform: uppercase;
    border-radius: 6px;
    color: #000;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: color 250ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98px;

    img.upload-icon{
        margin-right: 6px;
    }

    &:after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 100%;
        background: #001AFF;
        z-index: -1;
        /*transition: width 250ms ease-in-out;*/
    }

    i {
        display: block;
        font-size: 22px;
        margin-right: 5px;
        border-right: 2px solid;
        position: absolute;  
        left: 0;
        width: max-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @media only screen and (max-width: 500px) {
        width: 70%;
    }

    @media only screen and (max-width: 350px) {
        width: 100%;
    }

    &:hover {
        color: #fff;
        outline: 0;
        background: transparent;

        img {
            filter: invert(1);
        }

        &:after {
            width: 110%;
        }
    }

    &:focus {
        outline: 0;
        background: transparent;
    }

    &:disabled {
        opacity: 0.4;
        filter: grayscale(100%);
        pointer-events: none;
    }
`;

export const FilePreviewContainer = styled.article`

    span {
        font-size: 14px;
    }
`;

export const PreviewList = styled.section`
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    //margin-bottom: 40px;

    &:empty {
        display: none;
    }

    @media only screen and (max-width: 400px) {
        flex-direction: column;
    }
`;

export const FileMetaData = styled.div`
    display: ${(props: any) => (props.isImageFile ? "none" : "flex")};
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    border-radius: 6px;
    color: white;
    font-weight: bold;
    // background-color: rgba(5, 5, 5, 0.55);

    aside.Uploaded-files-aside {
        margin-top: 6px;
        display: flex;
        justify-content: space-between;
    }

    aside.Uploaded-files-aside-removebutton{
        position: absolute;
        right: 12px;
        top: 20px;
        width: 24px;
        height: 24px;
        background: #F7F7F8;
        border-radius: 4px;
        cursor: pointer;

        img {
            position: absolute;
            top: 7px;
            left: 7px;
            width: 10px;
            height: 10px;
            filter: invert(1);
        }
    }

    
`;

export const RemoveFileButton = styled.div`
    width: 24px;
    height: 24px;
    background: #F7F7F8;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 10px;
        height: 10px;
        filter: invert(1);
    }
`

export const RemoveFileIcon = styled.i`
    cursor: pointer;

    &:hover {
        transform: scale(1.3);
    }
`;

export const PreviewContainer = styled.section`
    /*padding: 0.25rem;*/
    width: max-content;
    border-radius: 6px;
    box-sizing: border-box;
    height: 64px;
    
    &:hover {
        //opacity: 0.55;
        background: #f1f1f1;
    }

    & > div:first-of-type {
    height: auto;
    position: relative;
    }

    @media only screen and (max-width: 750px) {
    width: 25%;
    }

    @media only screen and (max-width: 500px) {
    width: 50%;
    }

    @media only screen and (max-width: 400px) {
    width: 100%;
    padding: 0 0 0.4em;
    }

    img.remove{
        cursor: pointer;
    }
`;

export const ImagePreview = styled.img`
    border-radius: 4px;    
`;