import { Stack } from '@mui/material'
import { FC } from 'react'
import Typography from '../Typography'

interface ErrorMessageProps {
    message?: any
}

const ErrorMessage: FC<ErrorMessageProps> = ({message}) => {
    return (
        <Stack component={'span'} direction="row">
            <img src="/svg/icons/WarningRed.svg" alt="warning" />
            <Typography color='#d32f2f' size='14px' weight='regular' ml={'5.5px'}>{' '} {message ? message : 'You have to fill this field.'}</Typography>
        </Stack>
    )
}

export default ErrorMessage