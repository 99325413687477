const urlHealthCheck = `${process.env.REACT_APP_API_URL}/health-check`;

export const checkApiHealth = async () => {
    try {
      const response = await fetch(urlHealthCheck);
      const data = await response.json();
      return data.status === 'ok';
    } catch (error) {
      return false;
    }
  }