import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Typography from "../../design-system/atoms/Typography";
import styled from "@emotion/styled";
import { Box, Button, FormControl, FormControlLabel, FormHelperText, Stack, TextField, useMediaQuery } from "@mui/material";
import { IOSSwitch } from "../../design-system/atoms/IOSSwitch/IOSSwitch";
import { FlexSpacer } from "../../design-system/atoms/FlexSpacer";
import { theme } from "../../utils/theme";
import LightTooltip from "../../design-system/atoms/LightTooltip";
import { FC } from 'react';
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { useForm } from "react-hook-form";
import { exampleAssetManagerInvitee } from "../../__mocks__/exampleStakeholder";
import BuildingService from "../../services/buildingService";
import { IBuilding, IStakeholderDetail } from "../../interfaces/building";
import ErrorMessage from "../../design-system/atoms/Warnings/ErrorMessage";
import UserService from "../../services/userService";
import { getWalletBalance } from "../../utils/functions";
// import ApiService from "../../services/apiServices";

interface BuildingAssetManagerPageProps {
    handleOpenModalDelegate?: any,
    handleOpenModalAddStakeholder?: any,
    setBuildingID?: any,
    setIncludeUserTypeID: any,
    setExcludeUserTypeID: any,
    setMaxStakeholders?: any,
    setMyCallBack?: any,
    setBuildingWallet?: any,
    blockchain: any,
}

const BuildingAssetManagerPage: FC<BuildingAssetManagerPageProps> = ({
    handleOpenModalDelegate,
    handleOpenModalAddStakeholder,
    setBuildingID,
    setIncludeUserTypeID,
    setExcludeUserTypeID,
    setMaxStakeholders,
    setMyCallBack,
    setBuildingWallet,
    blockchain
}) => {
    console.log("BuildingAssetManagerPage");
    let { id } = useParams();
    const { keycloak } = useKeycloak();

    useEffect(() => {
        console.log("setBuildingID");
        setBuildingID(id);
        setIncludeUserTypeID([2]);
        setExcludeUserTypeID();
        setMaxStakeholders(1);
        setMyCallBack(() => fetchBuildingInfo);
        fetchBuildingInfo();
    }, [setBuildingID, id, setIncludeUserTypeID, setExcludeUserTypeID, setMaxStakeholders, setMyCallBack]);

    //, [setBuildingID, setIncludeUserTypeID, setExcludeUserTypeID, setMaxStakeholders, setMyCallBack]

    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    const navigate = useNavigate();
    const [assetManager, setAssetManager] = useState<IStakeholderDetail | undefined>();
    const [isSaved, setIsSaved] = useState(false);
    const [showAssetManager, setShowAssetManager] = useState(false);
    const [formDisabled, setFormDisabled] = useState(false);

    function fetchBuildingInfo() {
        const buildingService = new BuildingService();
        buildingService.getBuildingInfo(keycloak, id)
            .then(async(response) => {
                if (!response){
                    navigate('/');
                    return;
                }

                const building: IBuilding = response;

                if (building.stakeholders) {
                    const assetManagerObj = building.stakeholders.find(stakeholder => stakeholder.user_type_ID === 2);
                    if (assetManagerObj !== undefined) {
                        console.log("assetManagerObj FOUND",assetManagerObj);
                        setAssetManager(assetManagerObj);  
                        
                        reset();
                        register('firstname');
                        register('lastname');
                        register('company');
                        register('email');

                        setValue('user_ID', assetManagerObj.user_ID.toString());
                        setValue('firstname', assetManagerObj.firstname);
                        setValue('lastname', assetManagerObj.lastname);
                        setValue('company', assetManagerObj.company);
                        setValue('email', assetManagerObj.email);
                        
                        setShowAssetManager(true);

                        setFormDisabled(true);
                        clearErrors();
                    }
                }

                if(building.wallet_address !== undefined &&
                    building.wallet_address !== null &&
                    building.wallet_address !== '')
                {
                    setBuildingWallet({
                        name: building.building_name,
                        address: building.wallet_address,
                        balance: {
                            gasBalance: await getWalletBalance(blockchain, building.wallet_address)
                        }
                    });
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    navigate('/');
                    keycloak.logout();
                }
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                console.error(`Error: ${error}`)
            });
    }

    const {
        setValue,
        getValues,
        register,
        handleSubmit,
        clearErrors,
        reset,
        formState: { errors }
    } = useForm({
        mode: "onChange",
        defaultValues: {
            user_ID: '',
            firstname: '',
            lastname: '',
            company: '',
            email: ''
        }
    });

    const handleOpenModalAddStakeholder_Click = () => {
        handleOpenModalAddStakeholder();
    }    

    const isAssetManager = () => {
        setShowAssetManager(!showAssetManager)
    }

    const fillTestData = () => {
        setValue('firstname', exampleAssetManagerInvitee.firstname[Math.floor(Math.random() * (exampleAssetManagerInvitee.firstname.length) + 0)]);
        setValue('lastname', exampleAssetManagerInvitee.lastname[Math.floor(Math.random() * (exampleAssetManagerInvitee.lastname.length) + 0)]);
        setValue('company', exampleAssetManagerInvitee.company[Math.floor(Math.random() * (exampleAssetManagerInvitee.company.length) + 0)]);
        setValue('email', Math.floor(Math.random() * (20) + 0) + '_' + exampleAssetManagerInvitee.email[Math.floor(Math.random() * (exampleAssetManagerInvitee.email.length) + 0)]);
    }

    const onSubmit = () => {
        addAssetManager();        
    }

    const addAssetManager = () => {
        if(!showAssetManager){
            navigate(`/building/${id}/stakeholders`);
            return;
        }

        if (assetManager !== undefined) {
            console.log("not saving an asset manager",assetManager);
            //handleOpenModalDelegate();
            navigate(`/building/${id}/stakeholders`);
            return;
        }

        console.log("add Asset Manager / type: invitee", getValues());

        const url = `${process.env.REACT_APP_API_URL}/building/stakeholder`;

        const data = {
            "building_ID": id,
            "stakeholders": [{
                "firstname": getValues('firstname'),
                "lastname": getValues('lastname'),
                "company": getValues('company'),
                "email": getValues('email'),
                "user_type_ID": "2" //asset manager
            }]
        }

        const config: AxiosRequestConfig = {
            method: 'post',
            url: url,
            data: data,
            headers: {
                'Authorization': `Bearer ${keycloak.token}`,
                'Content-Type': 'application/json'
            }
        };

        console.log("api config", config);

        axios(config)
            .then((response) => {
                //const userInfo: IUserInfo = response.data.result;
                console.log(response);

                if (!response.data.status) {
                    console.log(response.data.msg + "\n" + response.data.error);
                    toast.error("Error inviting asset manager, try again!");
                }
                else {
                    setTimeout(() => {
                        setIsSaved(true);
                    }, 2000);

                    toast.success("Asset manager has been invited!");
                    //handleOpenModalDelegate();
                    navigate(`/building/${id}/stakeholders`);
                }
                //getUserInfo(userInfo);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    navigate('/');
                    keycloak.logout();
                }
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                console.error(`Error: ${error}`)
            });
    }

    const handleRemoveClick = (user_ID: string) => {
        console.log("remove asset manager", user_ID);
        /*const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);*/
        removeAssetManager(user_ID);
    };

    function removeAssetManager (user_ID: string) {
        if(user_ID === ''){
            return;
        }

        const url = `${process.env.REACT_APP_API_URL}/building/stakeholder`;

        const data = {
            "building_ID": id,
            "stakeholders": [{
                "user_ID": user_ID
            }]
        }

        const config: AxiosRequestConfig = {
            method: 'delete',
            url: url,
            data: data,
            headers: {
                'Authorization': `Bearer ${keycloak.token}`,
                'Content-Type': 'application/json'
            }
        };

        console.log("api config", config);

        axios(config)
            .then((response) => {
                //const userInfo: IUserInfo = response.data.result;
                console.log(response);

                if (!response.data.status) {
                    console.log(response.data.msg + "\n" + response.data.error);
                    toast.error("Error removing asset manager, try again!");
                }
                else {
                    toast.success("Asset manager has been removed!");
                    setValue('user_ID', '');
                    setValue('firstname', '');
                    setValue('lastname', '');
                    setValue('company', '');
                    setValue('email', '');
                    setAssetManager(undefined);
                    setFormDisabled(false);
                }
                //getUserInfo(userInfo);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    navigate('/');
                    keycloak.logout();
                }
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                console.error(`Error: ${error}`)
            });
    }

    async function checkDuplicateUser(email: string): Promise<any>{

        const userServiceObj = new UserService();
        const result = await userServiceObj.checkDuplicatedUserByEmail(keycloak, email);
        console.log(result);
        if (result) {
            // if user already exist
            return false;
        }
        else{
            // if user not exist
            return true;
        }
    }

    console.log('errors',errors);

    return (
        <Box sx={{ width: isTablet ? '100%' : '100%', maxWidth: 420 }}>
            <Stack direction={isDesktop ? 'row' : 'column'} sx={{ paddingLeft: '.5rem' }}>
                <Stack direction="row" sx={{ paddingLeft: '.5rem' }}>
                    <Link to={`/building/${id}/add`}
                        style={{
                            margin: '5px 0px -6px -10px',
                            color: theme.colors.text,
                            fontWeight: 'regular'
                        }}
                    >
                        <img src="/svg/icons/ArrowLeft.svg" alt="go back" width="32px" height="32px" />
                    </Link>
                    <Typography sx={{ paddingLeft: '.5rem' }} size='h2'>
                        Add a new building
                    </Typography>
                </Stack>
            </Stack>

            <FlexSpacer minHeight={1} />

            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    display: 'grid',
                    gridTemplateColumns: { xs: '1fr', md: '100%' },
                    gap: 2,
                }}
            >
                <Typography size='h3' weight='regular'>
                    Asset manager
                </Typography>

                <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                    <Typography size='body'>
                        Do you have an asset manager?
                    </Typography>

                    <Stack direction="row">
                        <Typography size="body" weight="regular" sx={{ margin: '0 1rem', }}>
                            No
                        </Typography>

                        <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label="Yes"
                            checked={showAssetManager}
                            onChange={() => {
                                if(showAssetManager === true){
                                    handleRemoveClick(getValues('user_ID'));
                                }
                                isAssetManager();
                            }}
                        />
                    </Stack>

                </Stack>

                <FlexSpacer minHeight={.625} />

                {showAssetManager ?
                    <FormControl>
                        <Stack direction="column">
                            <input type="hidden" name="user_ID" />
                            {process.env.REACT_APP_SHOW_ADD_DUMMY_DATA === "true" &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    type="button"
                                    sx={{ width: '140px', 
                                    padding: 0,
                                    fontSize: '0.6rem',
                                    maxHeight: 40
                                }}
                                    onClick={fillTestData}
                                >
                                    Apply testdata
                                </Button>
                            }
                            <Stack direction="row" sx={{ lineHeight: '1.125', justifyContent: "space-between" }}>
                                <label htmlFor="firstname">
                                    <Typography size="body" weight="regular">
                                        First name
                                    </Typography>
                                </label>

                                {!formDisabled ? (
                                <Typography
                                    onClick={handleOpenModalAddStakeholder_Click}
                                    size="body"
                                    weight="regular"
                                    color={theme.colors.primary}
                                    link="true"
                                >
                                    Already using Magma? Quick add
                                    <img
                                        width={16}
                                        height={16}
                                        src="/svg/icons/ExternalBlue.svg"
                                        alt="icon"
                                        style={{
                                            margin: " 0 0 0 .388rem",
                                            height: '16px'
                                        }}
                                    />
                                </Typography>
                                ) : ''}
                            </Stack>

                            <FlexSpacer minHeight={.5} />

                            <LightTooltip title="Type the first name of the stakeholder" placement={isTablet ? "top" : 'right'} arrow >
                                <StyledInput
                                    {
                                    ...!formDisabled
                                    ? register("firstname", { required: true })
                                    : register("firstname")
                                    }
                                    id="firstname"
                                    name="firstname"
                                    disabled={formDisabled}
                                    autoComplete="firstname"
                                    error={!!errors.firstname}
                                />
                            </LightTooltip>
                            {errors.firstname && errors.firstname.type === 'required' && (<FormHelperText><ErrorMessage /></FormHelperText>)}

                            <FlexSpacer minHeight={1.5} />

                            <label htmlFor="lastname">
                                <Typography size="body" weight="regular">
                                    Last name
                                </Typography>
                            </label>
                            <LightTooltip title="Type the last name of the stakeholder" placement={isTablet ? "top" : 'right'} arrow >
                                <StyledInput
                                    {
                                    ...!formDisabled
                                    ? register("lastname", { required: true })
                                    : register("lastname")
                                    }
                                    id="lastname"
                                    name="lastname"
                                    disabled={formDisabled}
                                    autoComplete="lastname"
                                    error={!!errors.lastname}
                                />
                            </LightTooltip>
                            {errors.lastname && errors.lastname.type === 'required' && (<FormHelperText><ErrorMessage /></FormHelperText>)}

                            <FlexSpacer minHeight={1.5} />

                            <label htmlFor="company">
                                <Typography size="body" weight="regular">
                                    Company
                                </Typography>
                            </label>
                            <FlexSpacer minHeight={.5} />
                            <LightTooltip title="Type the name of the company" placement={isTablet ? "top" : 'right'} arrow >
                                <StyledInput
                                    {
                                    ...!formDisabled
                                    ? register("company", { required: true })
                                    : register("company")
                                    }
                                    id="company"
                                    name="company"
                                    disabled={formDisabled}
                                    error={!!errors.company}
                                />
                            </LightTooltip>
                            {errors.company && errors.company.type === 'required' && (<FormHelperText><ErrorMessage /></FormHelperText>)}

                            <FlexSpacer minHeight={1.5} />

                            <label htmlFor="email">
                                <Typography size="body" weight="regular">
                                    Email address
                                </Typography>
                            </label>
                            <FlexSpacer minHeight={.5} />

                            <LightTooltip title="Type the email address" placement={isTablet ? "top" : 'right'} arrow >
                                <StyledInput
                                    {
                                    ...!formDisabled
                                    ? register("email", {
                                        required: true,
                                        pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                        validate: {
                                            duplicate: async(v) => await checkDuplicateUser(v)
                                        }
                                    })
                                    : register("email")
                                    }
                                    id="email"
                                    name="email"
                                    disabled={formDisabled}
                                    error={!!errors.email}
                                />
                            </LightTooltip>
                            {errors.email && errors.email.type === 'required' && (<FormHelperText><ErrorMessage /></FormHelperText>)}
                            {errors.email && errors.email.type === 'pattern' && (<FormHelperText><ErrorMessage message='Must be a valid email address' /></FormHelperText>)}
                            {errors.email && errors.email.type === 'duplicate' && (<FormHelperText><ErrorMessage message='Asset manager already uses Magma' /></FormHelperText>)}

                            <FlexSpacer minHeight={1.5} />

                            {formDisabled ? (
                            <Button
                                variant="outlined"
                                color="inherit"
                                size="medium"
                                style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                textTransform: "inherit",
                                border: "1px solid #D9D9D9",
                                height: "40px",
                                marginTop: "auto",
                                fontSize: ".7rem",
                                maxWidth: "fit-content",
                                marginLeft: "auto",
                                }}
                                onClick={() => handleRemoveClick(getValues('user_ID')) }
                            >
                                <img
                                width={16}
                                height={16}
                                src="/svg/icons/Cross.svg"
                                style={{ filter: "invert(1)" }}
                                alt="remove icon"
                                />
                                <Typography weight="medium" ml="6.21px">
                                    Remove asset manager
                                </Typography>
                            </Button>
                            ) : ''}
                        </Stack>
                    </FormControl>
                    :
                    ''
                }

                <FlexSpacer minHeight={2} />

                {/* Footer */}
                <Stack
                    direction="row"
                    sx={{
                        justifyContent: 'space-between',
                        width: !isMobile ? '-webkit-fill-available' : '100% !important'
                    }}>
                    {!isMobile && <FlexSpacer minWidth={2} />}

                    <Stack direction={isMobile ? 'column' : 'row'} sx={{
                        alignItems: 'center',
                        justifyContent: 'end',
                        width: !isMobile ? '-webkit-fill-available' : '100% !important'
                    }}>
                        {false && 
                            <Box
                                color="inherit"
                                style={{
                                    textTransform: 'inherit',
                                    justifyContent: 'space-evenly',
                                    marginRight: "1rem",
                                    minHeight: '46px',
                                    lineHeight: '17.71px',
                                    padding: '14px 9px',
                                    fontSize: "8757rem",
                                    minWidth: '130px',
                                    maxWidth: '170px'
                                }}
                            >
                                <Stack direction="row">
                                    <img
                                        width={16}
                                        height={16}
                                        src="/svg/icons/Save.svg"
                                        alt="save icon"
                                    // style={{ margin: '.1rem 0' }}
                                    />
                                    <Typography ml={1}>Draft Saved.</Typography>
                                </Stack>
                            </Box>
                        }

                        {isMobile && <FlexSpacer minHeight={1} />}

                        <Button
                            variant="outlined"
                            color="inherit"
                            fullWidth
                            size="large"
                            sx={{ textTransform: 'inherit', fontSize: ".7rem", minWidth: isMobile ? '100%' : 150, marginLeft: 'auto', marginRight: '.75rem' }}
                            onClick={() => navigate(`/building/${id}/add`)}
                        >
                            Previous Step
                        </Button>

                        {isMobile && <FlexSpacer minHeight={1} />}

                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            sx={{ fontSize: ".7rem", minWidth: isMobile ? 'auto' : "auto", marginLeft: 'auto' }}
                            type="submit"
                        >
                            Next step
                        </Button>
                    </Stack>
                </Stack>
            </Box>

        </Box>
    );

}

export default BuildingAssetManagerPage;

const StyledInput = styled(TextField)`
  padding: 0;

  .MuiOutlinedInput-input {
    padding: 10px 14px;
  }
`;