import axios, { AxiosRequestConfig } from "axios";
import { ISettings } from "../interfaces/settings";
import { checkApiHealth } from "./healthCheckService";

const url = `${process.env.REACT_APP_API_URL}/settings`;

export default class SettingsService {

    async getSettings() {
        const urlSettings = url;

        const isApiHealthy = await checkApiHealth();
        if(!isApiHealthy){
            throw Error('API not healthy');
        }

        return axios.get(urlSettings)
        .then((response) => {
            const settings: ISettings[] = response.data.result;

            return settings;
        })
        .catch(error => {
            throw error; 
            //return error;
        });
    };
}