import { useState, useEffect, FC } from "react";
import { Appearance, PaymentIntent } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { Box, Dialog, Grid, Stack } from "@mui/material";
import getStripe from "../../utils/stripe/get-stripejs";
import { fetchGetJSON, fetchPostJSON } from "../../utils/stripe/api-helpers";
import ElementsForm from "./ElementsForm";
import { Loader } from "../../design-system/_common/Loader";
import { FlexSpacer } from "../../design-system/atoms/FlexSpacer";
import Typography from "../../design-system/atoms/Typography";
import { theme } from "../../utils/theme";
import { Link, useNavigate, useParams } from "react-router-dom";
import BuildingService from "../../services/buildingService";
import { IBuilding } from "../../interfaces/building";
import { useKeycloak } from "@react-keycloak/web";
import { IMedia } from "../../interfaces/media";
import { toast } from "react-toastify";
import { formatNumber } from "../../utils/functions";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

interface CheckoutProps {
  handleOpenModalBuildingAdded?: any;
  setBuildingID?: any;
}

const CheckoutPage: FC<CheckoutProps> = ({
  setBuildingID,
  handleOpenModalBuildingAdded
}) => {
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | null>(null)
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const [building, setBuilding] = useState<IBuilding | undefined>(undefined);
  const [buildingImage, setBuildingImage] = useState<IMedia>();
  const [productPrice, setProductPrice] = useState<{
    amount_excl_vat: number,
    vat_percentage: number,
    amount_vat: number,
    amount_incl_vat: number
  }>();
  
  let { id } = useParams();

  useEffect(() => {
    const stripe_api_url = `${process.env.REACT_APP_STRIPE_API_URL}`;

    setBuildingID(id);

    fetchBuildingInfo()
      .then((response) => {
        if (!response){
          navigate('/');
          return;
        }

        if(response.status){
          console.log('response',response);

          let buildingPaymentResult: any;
          if(response.building.payments !== undefined){
            buildingPaymentResult = response.building.payments.find((payment: any) => payment.product_id === 'magma-setup-fee');
          }
          
          console.log('buildingPaymentResult',buildingPaymentResult);
          const paymentData = {
            product: {
              id: "magma-setup-fee",
              description: `Magma Setup Fee Building #${response.building.ID} (${response.building.building_name})` 
            },
            payment_intent_id: buildingPaymentResult !== undefined ? buildingPaymentResult.payment_intent_id : ''            
          }

          fetchGetJSON(`${stripe_api_url}/health-check`)
          .then((data) => {
            console.log(`${stripe_api_url}/health-check`,data);
          })

          fetchPostJSON(`${stripe_api_url}/payment-intent`, {
            data: paymentData
          }).then((data) => {
            console.log('paymentIntent result',data);

            setPaymentIntent(data.paymentIntent);
            if(buildingPaymentResult === undefined){
              createBuildingPayment(paymentData.product.id, data.paymentIntent.id);
            }            
            setProductPrice(data.productPrice);
          });      
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
            navigate('/');
            keycloak.logout();
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.error(`Error: ${error}`)

        return false;
      });
  }, [setBuildingID,setPaymentIntent]);

  function fetchBuildingInfo(): Promise<any> {
    const buildingService = new BuildingService();
    return buildingService.getBuildingInfo(keycloak, id)
        .then((response) => {
            if (!response){
              navigate('/');
              return;
            }

            const building: IBuilding = response;
            console.log(building);

            setBuilding(building);

            if (building.media) {
                const building_imgObj = building.media.find(media => media.mediatype === 'BUILDING_IMG');
                if (building_imgObj !== undefined) {
                    setBuildingImage(building_imgObj);
                }
            }

            return {
              status: true,
              building: building
            };
        })
        .catch(error => {
            if (error.response.status === 401) {
                navigate('/');
                keycloak.logout();
            }
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.error(`Error: ${error}`)

            return {
              status: false
            };
        });
  }

  function createBuildingPayment(product_id: string, payment_intent_id: string){
    const data = {
      "building_ID": id,
      "product_id": product_id,
      "payment_intent_id": payment_intent_id
    }

    const buildingService = new BuildingService();
    buildingService.createBuildingPayment(keycloak, data)
      .then((response) => {
        console.log(response);
        if(response.data.status){
          //setBuilding(building);
          //toast.success("Building payment has been created!");
        }
        else {
          //toast.error("Building payment not created, try again");
        }          
      })
      .catch((error) => {
        //toast.error("Error creating building payment, try again!")

        if (error.response.status === 401) {
              navigate('/');
              keycloak.logout();
          }            
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          console.error(`Error: ${error}`)
      });
  }
        

  const appearance: Appearance = {
    theme: "stripe",
  };

  const handleCloseModal = () => {};

  // const handleClick = () => {
  //   navigate('/')
  // }

  return (
    <>
      <Dialog
        open={true}
        fullScreen
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        id="stripe-wrapper"
      >
        {paymentIntent && paymentIntent.client_secret ? (
          <>
            <Grid
              container
              height='100%'
            >
              <Grid
                item
                xs={12} sm={12} md={6} lg={6}
                sx={{
                  backgroundColor: 'white'
                }}
              >
                <Box
                  className="checkoutLeftPane"
                  margin={'auto'}
                  paddingTop={'120px'}
                  maxWidth={'477px'}
                >
                  <Stack direction="row" sx={{ paddingLeft: '.5rem' }}>
                    <Link to={`/building/${id}/review`}
                      style={{
                        margin: '15px 0px -6px -40px',
                        color: theme.colors.text,
                        fontWeight: 'regular'
                      }}
                    >
                      <img src="/svg/icons/ArrowLeft.svg" alt="go back" width="20px" height="20px" />
                    </Link>
                    <img
                      src="/svg/ComposedLogo_V2.svg"
                      width="162"
                      height="48"
                      alt="Magma Logo"                    
                    />
                  </Stack>

                  <FlexSpacer minHeight={2} />

                  <Stack>
                    <Typography weight="regular" size="h5" color={theme.colors.neutral5}>Pay Magma</Typography>
                    <FlexSpacer minHeight={.25} />
                    <Typography weight="medium" size="h2">{formatNumber(productPrice!.amount_excl_vat)} €</Typography>
                  </Stack>

                  <FlexSpacer minHeight={2} />

                  <Stack
                    direction="row"
                    sx={{ justifyContent: 'space-between' }}
                  >
                    <Stack
                      direction="row"
                      sx={{ alignItems: "flex-start" }}
                    >
                      <img
                        alt={'building_img_name'}
                        color="white"
                        className="building-img"
                        src={buildingImage ? `data:${buildingImage.versions[0].mimetype};base64,${buildingImage.versions[0].mediabase64}` : "/images/building/Draft.png"}
                        style={{
                          width: '50px',
                          height: '50px',
                          objectFit: 'cover',
                          borderRadius: '4px',
                          marginRight: '10px'
                        }}
                      />
                      <Typography
                        weight="bold"
                        size="body"
                      >
                        {building?.building_name} - Setup Fees
                      </Typography>
                    </Stack>

                    <Stack
                      direction="row"
                      sx={{ alignItems: "flex-start" }}
                    >
                      <Typography
                        weight="bold"
                        size="body"
                      >
                        {formatNumber(productPrice!.amount_excl_vat)} €
                      </Typography>
                    </Stack>
                  </Stack>

                  <FlexSpacer minHeight={2} />

                  <Stack
                    width={'260px'}
                    marginLeft={'auto'}
                  >
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: 'space-between',
                        borderBottom: '1px solid rgba(60, 66, 87, 0.12)'
                      }}
                      paddingBottom={'10px'}
                    >
                      <Typography weight="bold" size="body">Subtotal</Typography>
                      <Typography weight="bold" size="body">{formatNumber(productPrice!.amount_excl_vat)} €</Typography>
                    </Stack>
                    
                    <FlexSpacer minHeight={1} />
                    
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: 'space-between',
                        borderBottom: '1px solid rgba(60, 66, 87, 0.12)'
                      }}
                      paddingBottom={'10px'}
                    >
                      <Typography weight="regular" size="body" color={theme.colors.neutral5}>{`VAT (${productPrice!.vat_percentage}%)`}</Typography>
                      <Typography weight="medium" size="body" color={theme.colors.neutral5}>{formatNumber(productPrice!.amount_vat)} €</Typography>
                    </Stack>

                    <FlexSpacer minHeight={1} />
                    
                    <Stack
                      direction="row"
                      sx={{ justifyContent: 'space-between' }}
                    >
                      <Typography weight="bold" size="body">Total due</Typography>
                      <Typography weight="bold" size="body">{formatNumber(productPrice!.amount_incl_vat)} €</Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
              <Grid
                item
                xs={12} sm={12} md={6} lg={6}
                sx={{
                  backgroundColor: 'white'
                }}
              >
                <Box
                  height='100%'
                  sx={{
                    boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.08)'
                  }}
                >
                  <Box
                    className="checkoutRightPane"
                    margin={'auto'}
                    paddingTop={'120px'}
                    maxWidth={'477px'}
                  >
                    <Elements
                      stripe={getStripe()}
                      options={{
                        appearance: appearance,
                        clientSecret: paymentIntent.client_secret,
                      }}>
                      <ElementsForm
                        building_ID={id}
                        paymentIntent={paymentIntent}
                        handleOpenModalBuildingAdded={handleOpenModalBuildingAdded}
                      />
                    </Elements>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <Loader />
        )        
        }
      </Dialog>
    </>
  )
}

export default CheckoutPage;
