import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { Box, width } from '@mui/system'
import { FC, useEffect, useState } from 'react';

type ProgressSpinnerProps = {
    stroke?: any
    size?: any
}
const StyledCircularProgress = styled(CircularProgress)`
max-width: 15px;
position: absolute;
top: 4px;
`;

const ProgressSpinner: FC<ProgressSpinnerProps> = ({size, stroke }) => {

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 5 : prevProgress + 40));
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <>
            <Box ml={3}>
                <StyledCircularProgress
                    size={size}
                    sx={{
                        "&  svg": {
                            color: !stroke ? 'white' : stroke,
                            width: size
                        }
                    }}
                    color="info" variant="determinate" value={progress} />
            </Box>
        </>
    )
}

export default ProgressSpinner