import { FC, useEffect, useState } from "react";
import { Box, Button, FormControl, FormControlLabel, FormHelperText, MenuItem, Select, Stack, TextField, useMediaQuery } from "@mui/material";
import { Typography } from '../../design-system/atoms/Typography/Typography';
import { Link, useNavigate, useParams } from "react-router-dom";
import { FlexSpacer } from "../../design-system/atoms/FlexSpacer";
import { IOSSwitch } from "../../design-system/atoms/IOSSwitch/IOSSwitch";
import { FileUpload } from "../../design-system/molecules/FileUpload";
import { useKeycloak } from "@react-keycloak/web";
import { styled, useTheme } from "@mui/material/styles";
import LightTooltip from "../../design-system/atoms/LightTooltip";
import SearchMap from "../../design-system/molecules/Searches/SearchMap/SearchMap";
import MapBlock from "../../design-system/molecules/Searches/SearchMap/MapBlock";
import ApiService from "../../services/apiServices";
import { useForm, Controller } from "react-hook-form";
import ErrorMessage from '../../design-system/atoms/Warnings/ErrorMessage';
import { IBuilding, IBuildingType } from "../../interfaces/building";
import { IMedia } from "../../interfaces/media";
import { ILocation, IMapLocation } from "../../interfaces/location";
import { exampleBuildings } from "../../__mocks__/exampleBuildings";
import { toast } from "react-toastify";
import BuildingService from "../../services/buildingService";
import { FileMetaData, PreviewContainer } from "../../design-system/molecules/FileUpload/file-upload.styles";
import { getWalletBalance, niceBytes, convertSQM_SQFT, removeTrailingZeros } from "../../utils/functions";

interface BuildingAddProps {
  handleOpenModalStakeholdersDetailed?: any,
  setBuildingWallet?: any,
  blockchain: any
}

const BuildingAddPage: FC<BuildingAddProps> = ({
  handleOpenModalStakeholdersDetailed,
  setBuildingWallet,
  blockchain,
}) => {
  console.log("AddNewBuilding");
  const navigate = useNavigate();
  const theme = useTheme();
  let { id } = useParams();

  const { keycloak } = useKeycloak();

  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const {
    setValue,
    getValues,
    register,
    handleSubmit,
    clearErrors,
    setError,
    control,
    formState: { isSubmitted, isSubmitting, errors }
  } = useForm({
    mode: "onChange"
  });

  const [buildingImageList, updateBuildingImageList] = useState({
    images: []
  });
  const [buildingImage, setBuildingImage] = useState<IMedia>();
  const [buildingTypes, setBuildingTypes] = useState<IBuildingType[]>([]);
  //const [isMapActive, setMapActive] = useState(false);
  const [unitOfMeasure, setUnitOfMeasure] = useState('sqm');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  //const selectOpen = Boolean(anchorEl);
  /*const handleSelectClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };*/

  //const [selectedIndex, setSelectedIndex] = useState(4);

  /*const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };*/

  useEffect(() => {
    /* fix if the user is already logged in and coming from loginpage directly */
    if (!keycloak.authenticated) {
      navigate('/');
      keycloak.logout();
    }

    fetchBuildingTypes();
  }, []);

  const fetchBuildingTypes = () => {
    const buildingService = new BuildingService();
    buildingService.getBuildingTypes(keycloak)
      .then((response) => {
        const buildingTypes: IBuildingType[] = response;
        setBuildingTypes(buildingTypes);

        if (id !== undefined && parseInt(id) > 0) {
          fetchBuildingInfo();
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          navigate('/');
          keycloak.logout();
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.error(`Error: ${error}`)
      });
    //setLoading(false);
  }

  function fetchBuildingInfo() {
    const buildingService = new BuildingService();
    buildingService.getBuildingInfo(keycloak, id)
      .then(async(response) => {
        if (!response){
          navigate('/');
          return;
        }
        
        const building: IBuilding = response;
        const mapLocation = {
          label: building.address,
          coords: {
            lat: building.lat,
            lng: building.lng,
          }
        };
        const mapLocationOwnerAddress = {
          label: building.owner_address
        }

        setValue('building_name', building.building_name);
        setValue('building_type_ID', building.building_type_ID);
        setValue('construction_year', building.construction_year !== undefined ? building.construction_year.toString() : '');
        setValue('size', removeTrailingZeros(building.size));
        setValue('price', removeTrailingZeros(parseFloat(building.price)));
        setValue('mapLocation', mapLocation);
        setValue('owner', building.owner);
        /*setValue('owner_address', building.owner_address);*/
        setValue('mapLocationOwnerAddress', mapLocationOwnerAddress);
        
        if (building.media) {
          const building_imgObj = building.media.find(media => media.mediatype === 'BUILDING_IMG');
          if (building_imgObj !== undefined) {
            setBuildingImage(building_imgObj);
            setValue('building_img', 'loaded');
          }
        }

        setMapLocation(mapLocation);
        SetSearchInput(mapLocation.label);

        setMapLocationOwnerAddress(mapLocationOwnerAddress);
        SetSearchInputOwnerAddress(mapLocationOwnerAddress.label);

        if(building.wallet_address !== undefined &&
          building.wallet_address !== null &&
          building.wallet_address !== '')
        {
          setBuildingWallet({
            name: building.building_name,
            address: building.wallet_address,
            balance: {
                gasBalance: await getWalletBalance(blockchain, building.wallet_address)
            }
          });
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          navigate('/');
          keycloak.logout();
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.error(`Error: ${error}`)
      });
  }

  const updateUploadedFiles = (files: any) => {
    updateBuildingImageList({ ...buildingImageList, images: files })
  };

  const onSubmit = async (data: any) => {
    /*setTimeout(() => {
      setIsSaved(true);
    }, 500);*/

    setIsSaving(true);

    await saveBuilding(data);

    setIsSaving(false);
  };

  const saveBuilding = async (data: any) => new Promise((resolve) => {
    if(id !== undefined && parseInt(id) > 0){
      data.ID = id;
    }

    //const file = buildingImageList.images[0];
    console.log("saveBuilding buildingImageList.images[0]", buildingImageList.images[0]);
    console.log("saveBuilding data.building_img", data.building_img[0]);

    if(data.price === ''){
      data.price = 0;
    }

    if(mapLocation !== undefined){
      data.address = mapLocation.label;
      data.lat = mapLocation.coords!.lat;
      data.lng = mapLocation.coords!.lng;
    }

    if(mapLocationOwnerAddress !== undefined){
      data.owner_address = mapLocationOwnerAddress.label;
    }

    var bodyFormData = new FormData();
    bodyFormData.append('building', JSON.stringify(data));
    bodyFormData.append('file', data.building_img[0]);

    /*console.log('buildingImageList', buildingImageList);
    console.log('mapLocation',mapLocation);*/

    console.log("saveBuilding bodyFormData", bodyFormData);

    const buildingService = new BuildingService();
    buildingService.saveBuildingInfo(keycloak, bodyFormData)
      .then((response) => {
        console.log("saveBuilding RESPONSE",response);
        if (!response.data.status) {
          console.log(response.data.msg + "\n" + response.data.error);
          if(id !== undefined){
            toast.error("Error updating building, try again!")
          }
          else{
            toast.error("Error building creation, try again!")
          }
          resolve(false);
        }
        else {
          resolve(true);

          const building_ID = id !== undefined ? id : response.data.result;
          if(id !== undefined){
            toast.success("Building has been updated!");
          }
          else{
            toast.success("Building has been created!");
            handleOpenModalStakeholdersDetailed();
          }

          setTimeout(() => {
            setIsSaved(true);
          }, 2000);

          navigate(`/building/${building_ID}/asset-manager`);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate('/');
          keycloak.logout();
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.error(`Error: ${error}`)
      })
  });

  const removeBuildingImg = (buildingImg_ID: number | undefined) => {
    const data = {
      'media_ID': buildingImg_ID
    }

    const buildingService = new BuildingService();
    buildingService.deleteMedia(keycloak, data)
      .then((response) => {
        console.log("RESPONSE",response);
        if (!response.data.status) {
          console.log(response.data.msg + "\n" + response.data.error);
        }
        else {
          setTimeout(() => {
            setIsSaved(true);
          }, 2000);

          setBuildingImage(undefined);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate('/');
          keycloak.logout();
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        console.error(`Error: ${error}`)
      })
  }

  const fillTestData = () => {
    const mapLocation = exampleBuildings.address[Math.floor(Math.random() * (exampleBuildings.address.length) + 0)];
    const mapLocationOwnerAddress = exampleBuildings.address[Math.floor(Math.random() * (exampleBuildings.address.length) + 0)];

    setValue('building_name', exampleBuildings.building_name[Math.floor(Math.random() * (exampleBuildings.building_name.length) + 0)]);
    setValue('building_type_ID', exampleBuildings.building_type_ID[Math.floor(Math.random() * (exampleBuildings.building_type_ID.length) + 0)]);
    setValue('construction_year', exampleBuildings.construction_year[Math.floor(Math.random() * (exampleBuildings.construction_year.length) + 0)]);
    setValue('size', exampleBuildings.size[Math.floor(Math.random() * (exampleBuildings.size.length) + 0)]);
    setValue('price', exampleBuildings.price[Math.floor(Math.random() * (exampleBuildings.price.length) + 0)]);
    setValue('mapLocation', mapLocation);
    setValue('owner', exampleBuildings.owner[Math.floor(Math.random() * (exampleBuildings.owner.length) + 0)]);
    //setValue('owner_address', exampleBuildings.owner_address[Math.floor(Math.random() * (exampleBuildings.owner_address.length) + 0)]);
    setValue('mapLocationOwnerAddress', mapLocationOwnerAddress);
    
    setMapLocation(mapLocation);
    SetSearchInput(mapLocation.label);
    setMapLocationOwnerAddress(mapLocationOwnerAddress);
    SetSearchInputOwnerAddress(mapLocationOwnerAddress.label);
  }

  //const ref = React.createRef();

  // SEARCH INPUT MAP
  const [searchInput, SetSearchInput] = useState("");
  const [locationList, setLocationList] = useState<ILocation[]>([]);
  const [locationSearchList, setLocationSearchList] = useState<ILocation[]>([]);
  const [mapLocation, setMapLocation] = useState<IMapLocation>();
  
  const [searchInputOwnerAddress, SetSearchInputOwnerAddress] = useState("");
  const [locationListOwnerAddress, setLocationListOwnerAddress] = useState<ILocation[]>([]);
  const [locationSearchListOwnerAddress, setLocationSearchListOwnerAddress] = useState<ILocation[]>([]);
  const [mapLocationOwnerAddress, setMapLocationOwnerAddress] = useState<IMapLocation>();

  const apiService = new ApiService();

  function useDebounce(value: string, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
  
      // Cleanup the timeout on every value change.
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
  
    return debouncedValue;
  }

  const debounceDelay = 500; // Set the debounce delay time in milliseconds (adjust as needed).

  const debouncedSearchInput = useDebounce(searchInput, debounceDelay);
  const debouncedSearchInputOwnerAddress = useDebounce(searchInputOwnerAddress, debounceDelay);

  useEffect(() => {
    if (debouncedSearchInput !== '') {
      apiService.getData(debouncedSearchInput).then(res => {
        console.log('locationList', res);
        const searchlist: any = []
        const list: any = []

        for (var i = 0; i < res.length; i++) {
          searchlist.push({
            label: res[i].display_name,
            place_id: res[i].place_id,
            icon: res[i].icon,
          });
          list.push(res[i]);
          /*list.push({
            label: res[i].display_name,
            coords: JSON.stringify({ lat: res[i].lat, lng: res[i].lon })
          })*/
        }

        setLocationList(list);
        setLocationSearchList(searchlist)
      });
    } else {
      // Handle the case when the searchInput is empty (optional)
      setLocationList([]);
      setLocationSearchList([]);
    }
  }, [debouncedSearchInput]);

  useEffect(() => {
    if (debouncedSearchInputOwnerAddress !== '') {
      apiService.getData(debouncedSearchInputOwnerAddress).then(res => {
        console.log('locationListOwnerAddress', res);
        const searchlist: any = []
        const list: any = []

        for (var i = 0; i < res.length; i++) {
          searchlist.push({
            label: res[i].display_name,
            place_id: res[i].place_id,
            icon: res[i].icon,
          });
          list.push(res[i]);
          /*list.push({
            label: res[i].display_name,
            coords: JSON.stringify({ lat: res[i].lat, lng: res[i].lon })
          })*/
        }

        setLocationListOwnerAddress(list);
        setLocationSearchListOwnerAddress(searchlist)
      });
    } else {
      // Handle the case when the searchInput is empty (optional)
      setLocationListOwnerAddress([]);
      setLocationSearchListOwnerAddress([]);
    }

  }, [debouncedSearchInputOwnerAddress]);

  const handleUnitOfMeasure = (e: any) => {
    var newUnitOfMeasure;
    if(unitOfMeasure === 'sqm'){
      newUnitOfMeasure = 'sqft';
    }
    else{
      newUnitOfMeasure = 'sqm';
    }
    setUnitOfMeasure(newUnitOfMeasure);
    setValue('size', convertSQM_SQFT(newUnitOfMeasure, getValues('size')));
  }

  console.log("errors", errors);

  return (
    <StyledContainer sx={{ width: '100%', maxWidth: 420 }} className="add-building-page">
      <Stack direction={isDesktop ? 'row' : 'column'} sx={{ paddingLeft: '.5rem' }}>
        <Stack direction="row" sx={{ paddingLeft: '.5rem' }}>
          <Link to="/buildings"
            style={{
              margin: '5px 0px -6px -10px',
              color: theme.colors.text,
              fontWeight: 'regular'
            }}
          >
            <img src="/svg/icons/ArrowLeft.svg" alt="go back" width="32px" height="32px" />
          </Link>
          <Typography sx={{ paddingLeft: '.5rem' }} size='h2'>
            Add a new building
          </Typography>
        </Stack>
      </Stack>

      <FlexSpacer minHeight={2} />

      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '100%' },
          gap: 2,
        }}
      >
        <FormControl sx={{ width: isTablet ? '100%' : '100%', maxWidth: 420 }}>
          <Typography size='h3' weight='regular'>Building information</Typography>

          <FlexSpacer minHeight={1.5} />

          {process.env.REACT_APP_SHOW_ADD_DUMMY_DATA === "true" && 
            <Button
              variant="contained"
              color="primary"
              size="small"
              type="button"
              sx={{
                maxWidth: '90px',
                padding: 0,
                fontSize: '0.6rem',
                maxHeight: 40
              }}
              onClick={fillTestData}
            >
              Fill data
            </Button>
          }

          <label htmlFor="name">
            <Typography weight="regular" size="body">Name</Typography>
            <FlexSpacer minHeight={.625} />
          </label>

          <LightTooltip
            title="Type the name of building"
            placement={isTablet ? "top" : 'right'} arrow
          >
            <StyledInput
              {...register("building_name", { required: true, maxLength: 30 })}
              variant="outlined"
              id="building_name"
              name="building_name"
              error={!!errors.building_name}
              autoComplete="name"
            />
          </LightTooltip>
          {errors.building_name && errors.building_name.type === 'required' && (<FormHelperText><ErrorMessage /></FormHelperText>)}
          {errors.building_name && errors.building_name.type === 'maxLength' && (<FormHelperText><ErrorMessage message='Can have max 30 characters' /></FormHelperText>)}

          <FlexSpacer minHeight={1.5} />

          <label htmlFor="building_img">
            <Typography weight="regular" size="body">Picture of the building</Typography>
            <FlexSpacer minHeight={.5} />
          </label>

          <Controller
            control={control}
            name={'building_img'}
            defaultValue={''}
            render={({ field, fieldState }) => {
              return (
                <LightTooltip
                  title="Upload the image of your building"
                  placement={isTablet ? "top" : 'right'}
                  arrow
                >
                  <div>
                    {buildingImage === undefined ?
                      <FileUpload
                        // {...register("building_img", { required: true })}
                        register={
                          register("building_img", { required: true})
                        }
                        id="building_img"
                        building_type_doc={'building_image'}
                        error={!!fieldState.error}
                        accept=".png,.jpg,.jpeg"
                        acceptText="Supports: .png, .jpg or .jpeg (max. 2Mb)"
                        label="" //Upload Document(s)
                        updateFilesCb={(files: any) => {
                          updateUploadedFiles(files);
                          setValue('building_img', files)
                          if(files.length > 0){
                            clearErrors('building_img');
                          }
                          else{
                            setError('building_img', { type: 'required' });
                          }
                        }}
                        maxFileSizeInBytes={10 * 1000 * 1000}
                        field={field}
                      />
                      :
                      <>
                        <PreviewContainer
                          className="Uploaded-files-container"
                          style={{ width: "100%" }}
                        >
                          <Stack direction="row" sx={{ position: 'relative', width: '100%', borderRadius: 1, border: '1px solid #D9D9D9' }}>
                            <img
                              alt={getValues('building_name')}
                              color="white"
                              className="building-img"
                              src={`data:${buildingImage.versions[0].mimetype};base64,${buildingImage.versions[0].mediabase64}`}
                              style={{ width: '40px', height: '40px', margin: '12px 0 12px 12px', objectFit: 'cover' }}
                            />

                            <FileMetaData className="Uploaded-files-metadata">
                              {/* <span>{file.name}</span> */}
                              <aside className="Uploaded-files-aside">
                                <Stack direction="column" sx={{ color: "black" }}>
                                  {/* <IconButton onClick={() => removeFile(fileName)} >
                                        <CloseIcon />
                                      </IconButton>  */}
                                  <Typography color={theme.colors.black} weight="medium" size="12px">
                                    {buildingImage.versions[0].originalname}
                                  </Typography>
                                  <Typography color={theme.colors.neutral5} weight="regular" size="10px">
                                    {niceBytes(buildingImage.versions[0].mediasize.toString())}
                                  </Typography>
                                  {/*<Typography>{isImageFile}</Typography>*/}

                                  {/* <span>{convertBytesToKB(file.description)} kb</span> */}
                                </Stack>
                              </aside>
                              <aside className="Uploaded-files-aside-removebutton"
                                onClick={() => {
                                  removeBuildingImg(buildingImage.ID);
                                  setValue('building_img', undefined);
                                  setError('building_img', { type: 'required' });
                                }}
                              >
                                <img
                                  src="/svg/icons/Cross.svg"
                                  className="Uploaded-files-img remove"
                                  alt="delete icon"
                                />
                              </aside>
                            </FileMetaData>
                          </Stack>
                        </PreviewContainer>
                      </>
                    }
                  </div>
                </LightTooltip>
              )
            }}
          />
          {errors.building_img && errors.building_img.type === 'required' && (<FormHelperText><ErrorMessage message='You have to pick an image' /></FormHelperText>)}

          <FlexSpacer minHeight={1.5} />

          <label htmlFor="building_type_ID">
            <Typography weight="regular" size="body">Type</Typography>
            <FlexSpacer minHeight={.625} />
          </label>

          <Controller
            control={control}
            name={'building_type_ID'}
            defaultValue={''}
            render={({ field, fieldState }) => {
              return (
                <LightTooltip
                  title="Select the type of building"
                  placement={isTablet ? "top" : 'right'}
                  arrow
                >
                  <StyledSelect
                    {...register('building_type_ID', { required: true })}
                    labelId="building_type_ID"
                    id="building_type_ID"
                    error={!!fieldState.error}
                    {...field}
                  >
                    {
                    buildingTypes.map((item, index) => (
                      <MenuItem key={index} value={item.ID}>
                        {item.description}
                      </MenuItem>
                    ))
                    }
                  </StyledSelect>
                </LightTooltip>
              )
            }}
          />
          {errors.building_type_ID && errors.building_type_ID.type === 'required' && (<FormHelperText><ErrorMessage message='You have to pick a type' /></FormHelperText>)}

          <FlexSpacer minHeight={1.5} />

          <label htmlFor="construction_year">
            <Typography weight="regular" size="body"> Construction year (optional)</Typography>
            <FlexSpacer minHeight={.5} />
          </label>

          <LightTooltip title="Type the year of construction of your building" placement={isTablet ? "top" : 'right'} arrow >
            <StyledInput
              {...register("construction_year", {
                pattern: /^\d{4}$/,
                min: 1500,
                max: (new Date().getFullYear()) + 3
              })}
              id="construction_year"
              name="construction_year"
              error={!!errors.construction_year}
              autoComplete="construction_year"
            ></StyledInput>
          </LightTooltip>
          {errors.construction_year && errors.construction_year.type === 'pattern' && (<FormHelperText><ErrorMessage message='Must be 4 digits' /></FormHelperText>)}
          {errors.construction_year && errors.construction_year.type === 'min' && (<FormHelperText><ErrorMessage message='Must be greater then 1500' /></FormHelperText>)}
          {errors.construction_year && errors.construction_year.type === 'max' && (<FormHelperText><ErrorMessage message={'Must be lower then ' + ((new Date().getFullYear()) + 3)} /></FormHelperText>)}

          <FlexSpacer minHeight={1.5} />

          <label htmlFor="size">
            <Stack direction="row" sx={{
              justifyContent: 'space-between'
            }}>
              <Typography weight="regular" size="body">Net usable area</Typography>

              <Stack direction="row">

                <Typography weight="regular" size="body" sx={{ marginRight: 1 }}>sqm</Typography>
                <FormControlLabel
                  name='unitOfMeasure'
                  control={<IOSSwitch
                    checked={unitOfMeasure !== 'sqm'}
                    onChange={handleUnitOfMeasure}
                    sx={{ m: 1 }}
                    />}
                  label="sqft"
                />
              </Stack>
            </Stack>
            <FlexSpacer minHeight={.5} />
          </label>

          <LightTooltip title="This will help us to determine the plan that fits the best your needs." placement={isTablet ? "top" : 'right'} arrow >
            {
                <StyledInput
                {...register("size", {
                  required: true,
                  pattern: /^(\d)*(\.)?([0-9]+)?$/,
                  min: 0
                })}
                id="size"
                name="size"
                error={!!errors.size}
              ></StyledInput>
            }
          </LightTooltip>
          {errors.size && errors.size.type === 'required' && (<FormHelperText><ErrorMessage /></FormHelperText>)}
          {errors.size && errors.size.type === 'min' && (<FormHelperText><ErrorMessage message='Must be greater then 0' /></FormHelperText>)}
          {errors.size && errors.size.type === 'pattern' && (<FormHelperText><ErrorMessage message='Must be digits' /></FormHelperText>)}

          <FlexSpacer minHeight={1.5} />

          <label htmlFor="price">
            <Typography weight="regular" size="body"> Building value (€) (Optional)</Typography>
            <FlexSpacer minHeight={.5} />
          </label>

          <LightTooltip title="Type the value of your building" placement={isTablet ? "top" : 'right'} arrow >
            <StyledInput
              {...register("price", {
                pattern: /^(\d)*(\.)?([0-9]+)?$/,
                min: 0
              })}
              id="price"
              name="price"
              error={!!errors.price}
              autoComplete="price"
            ></StyledInput>
          </LightTooltip>
          {errors.price && errors.price.type === 'required' && (<FormHelperText><ErrorMessage /></FormHelperText>)}
          {errors.price && errors.price.type === 'min' && (<FormHelperText><ErrorMessage message='Must be greater then 0' /></FormHelperText>)}
          {errors.price && errors.price.type === 'pattern' && (<FormHelperText><ErrorMessage message={'Must be digits'} /></FormHelperText>)}

          <FlexSpacer minHeight={1.5} />

          <label htmlFor="address">
            <Typography weight="regular" size="body">Address</Typography>
            <FlexSpacer minHeight={.5} />
          </label>

          <Controller
            control={control}
            name={'mapLocation'}
            defaultValue={''}
            render={({ field, fieldState }) => {
              return (
                <LightTooltip
                  title="Type the address of your building"
                  placement={isTablet ? "top" : 'right'}
                  arrow
                >
                  <div>
                    <SearchMap
                      //{...register("mapLocation", { required: true })}
                      register={
                        register("mapLocation", {
                          validate: {
                            empty_address: value => mapLocation !== undefined
                          }
                        })
                      }
                      id="mapLocation"
                      error={!!fieldState.error}
                      locationList={locationList}
                      locationSearchList={locationSearchList}
                      setMapLocation={(mapLocation: any) => {
                        setMapLocation(mapLocation);
                        setValue('mapLocation', mapLocation);
                        if(mapLocation !== undefined){
                          clearErrors('mapLocation');
                        }
                        else{
                          setError('mapLocation', { type: 'empty_address'});
                        }
                      }}
                      handleSearch={SetSearchInput}
                      field={field}
                    />
                  </div>
                </LightTooltip>
              )
            }}
          />
          {errors.mapLocation && errors.mapLocation.type === 'empty_address' && (<FormHelperText><ErrorMessage message="You have to choose an address"/></FormHelperText>)}

          {mapLocation !== undefined ? (
          <Box className="map">
            <MapBlock
              position={
                (mapLocation === undefined ||
                  mapLocation.coords!.lat === undefined || mapLocation.coords!.lat === null ||
                  mapLocation.coords!.lng === undefined || mapLocation.coords!.lng === null)
                  ? [48.8583701, 2.2944813]
                  : [mapLocation.coords!.lat, mapLocation.coords!.lng]}
              markerText={
                mapLocation === undefined
                  ? 'Tour Eiffel, Champ de Mars, 5 Av. Anatole France, 75007 Paris, Frankrijk'
                  : mapLocation.label}
            />
          </Box>
          ) : ''}

          <FlexSpacer minHeight={1.5} />

          <label htmlFor="owner">
            <Typography weight="regular" size="body">Owner</Typography>
            <FlexSpacer minHeight={.5} />
          </label>

          <LightTooltip
            title="The company which owns the building"
            placement={isTablet ? "top" : 'right'}
            arrow >
            <StyledInput
              {...register("owner", { required: true })}
              id="owner"
              name="owner"
              error={!!errors.owner}
            ></StyledInput>
          </LightTooltip>
          {errors.owner && errors.owner.type === 'required' &&(<FormHelperText><ErrorMessage /></FormHelperText>)}

          <FlexSpacer minHeight={1.5} />

          <label htmlFor="owner_address">
            <Typography weight="regular" size="body">Owner address</Typography>
            <FlexSpacer minHeight={.5} />
          </label>

          {/*
          <LightTooltip title="Type the owner's address" placement={isTablet ? "top" : 'right'} arrow>
            <StyledInput
              {...register("owner_address", { required: true })}
              id="owner_address"
              name="owner_address"
              error={!!errors.owner_address}
            />
          </LightTooltip>
          {errors.owner_address && errors.owner_address.type === 'required' && (<FormHelperText><ErrorMessage /></FormHelperText>)}
          */}

          <Controller
            control={control}
            name={'mapLocationOwnerAddress'}
            defaultValue={''}
            render={({ field, fieldState }) => {
              return (
                <LightTooltip
                  title="Type the owner's address"
                  placement={isTablet ? "top" : 'right'}
                  arrow
                >
                  <div>
                    <SearchMap
                      //{...register("mapLocationOwnerAddress", { required: true })}
                      register={
                        register("mapLocationOwnerAddress", {
                          validate: {
                            empty_address: value => mapLocationOwnerAddress !== undefined
                          }
                        })
                      }
                      id="mapLocationOwnerAddress"
                      error={!!fieldState.error}
                      locationList={locationListOwnerAddress}
                      locationSearchList={locationSearchListOwnerAddress}
                      setMapLocation={(mapLocationOwnerAddress: any) => {
                        setMapLocationOwnerAddress(mapLocationOwnerAddress);
                        setValue('mapLocationOwnerAddress', mapLocationOwnerAddress);
                        if(mapLocationOwnerAddress !== undefined){
                          clearErrors('mapLocationOwnerAddress');
                        }
                        else{
                          setError('mapLocationOwnerAddress', { type: 'empty_address'});
                        }
                      }}
                      handleSearch={SetSearchInputOwnerAddress}
                      field={field}
                    />
                  </div>
                </LightTooltip>
              )
            }}
          />
          {errors.mapLocationOwnerAddress && errors.mapLocationOwnerAddress.type === 'empty_address' && (<FormHelperText><ErrorMessage message="You have to choose an address"/></FormHelperText>)}

          <FlexSpacer minHeight={2} />

        </FormControl>

        <Stack
          direction={isMobile ? 'column' : 'row'}
          sx={{
            alignItems: 'center',
            justifyContent: 'end',
            width: !isMobile ? '-webkit-fill-available' : '100% !important'
          }}>

          {isMobile && <FlexSpacer minHeight={1} />}

          {false && isSaved ?
            <Box
              color="inherit"
              style={{
                display: 'flex',
                width: '250px',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                height: '31px', marginLeft: 'auto', fontSize: ".875rem", maxWidth: 150
              }}
            >
              <img width={16} height={16} src="/svg/icons/Save.svg" alt="save icon" style={{ margin: '.1rem 0' }} />
              Draft Saved.
            </Box>
            : ''
          }

          {isMobile && <FlexSpacer minHeight={1} />}

          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            sx={{ marginTop: "auto", fontSize: ".7rem", maxWidth: 'max-content', marginLeft: 'auto' }}
            type="submit"
            disabled={isSubmitting || isSaving}
          >
            {/*disabled={submitCount >= 1}*/}
            {isSaving ? 'Saving...' : 'Next step'}
          </Button>
        </Stack>
      </Box>

      <FlexSpacer minHeight={3} />
    </StyledContainer >
  );
}

export default BuildingAddPage;

const StyledSelect = styled(Select)`
    font-size: 14px;
    height: 38px;
`;

const StyledInput = styled(TextField)`
  padding: 0;
  .MuiOutlinedInput-input {
    padding: 10px 14px;
  }
`;

const StyledContainer = styled(Box)`
  &.add-building-page {

    .upload-container {
      padding: 17px 20px
    }

    .error {
      border: 1px solid #d32f2f;
    }

    .upload-spacer-control {
      display: none;
    }

    .upload-icon {
      margin-right: .8rem;
    }

    .upload-container {
      flex-direction: row;
    }

    .upload-btn {
      width: 112px;
      height: 32px;
    }

    .upload-legend {
      display: none;
    }

    .upload-sublegend {
      margin-left: .8rem;
    }
  }
`;