import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import OptionsButton from "../../../atoms/Buttons/OptionsButton";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true
});


const StyledBox = styled(Box)`
& .MuiPaper-root{  
  border-radius: 0;
  box-shadow: none;
  background: transparent;
}

& .MuiTableCell-head {
  background: rgb(252, 252, 253);
  color: #B4B5C1;
}

`;


export function WalletTable() {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("400px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [searchBtn, setSearchBtn] = useState(true);
  const [downloadBtn, setDownloadBtn] = useState(false);
  const [printBtn, setPrintBtn] = useState(false);
  const [viewColumnBtn, setViewColumnBtn] = useState(false);
  const [filterBtn, setFilterBtn] = useState(true);

  const columns = [
    { name: "Building", options: { filterOptions: { fullWidth: true } } },
    "Amount",
    "Vested since",
    "Release date"
  ];

  const options = {
    search: searchBtn,
    download: downloadBtn,
    print: printBtn,    
    viewColumns: viewColumnBtn,
    filter: filterBtn,
    filterType: "dropdown",
    selectableRows: false,
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    textLabels: {
      toolbar: {
        filter: "Search",
      },       
  },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    }
  };

  const data = [
    ["Jaden Collins", "55 555 555 MUT", "02/04/2022" , "02/04/2023", <OptionsButton />],
    ["Franky Rees", "55 555 555 MUT",  "02/04/2022" ,   "02/04/2023",  <OptionsButton />],
    ["Aaren Rose", "55 555 555 MUT",  "02/04/2022" ,   "02/04/2023",  <OptionsButton />],
    ["Johnny Jones", "55 555 555 MUT",  "02/04/2022" ,   "02/04/2023", <OptionsButton />],
    ["Jimmy Johns", "55 555 555 MUT", "02/04/2022" , "02/04/2023", <OptionsButton />],
    ["Jack Jackson", "55 555 555 MUT",  "02/04/2022",   "02/04/2023",  <OptionsButton />],
    ["Joe Jones", "55 555 555 MUT", "02/04/2022" ,  "02/04/2023", <OptionsButton />],
    ["Jacky Jackson", "55 555 555 MUT Consultant", "02/04/2022",  "02/04/2023", <OptionsButton />],
    ["Jo Jo", "55 555 555 MUT", "02/04/2022",  "02/04/2023",  <OptionsButton />],
    ["Donna Marie", "55 555 555 MUT", "02/04/2022" ,  "02/04/2023", <OptionsButton />]
  ];

  
  return (
    <StyledBox>
      <CacheProvider value={muiCache}>
         
          <MUIDataTable
            paper={0}        
            data={data}
            columns={columns}
            options={options}
          />
      </CacheProvider>
    </StyledBox>
  );
}
 