import React, { FC, useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { PaymentIntent } from "@stripe/stripe-js";
import { Button, Stack } from "@mui/material";
import { FlexSpacer } from "../../design-system/atoms/FlexSpacer";
import { useNavigate } from "react-router-dom";
import { theme } from "../../utils/theme";
import BuildingService from "../../services/buildingService";
import { useKeycloak } from "@react-keycloak/web";
import { formatNumber } from "../../utils/functions";

interface IElementsForm {
  building_ID?: any,
  paymentIntent?: PaymentIntent | null,
  handleOpenModalBuildingAdded?: any
}

const ElementsForm: FC<IElementsForm> = ({
  building_ID,
  paymentIntent = null,
  handleOpenModalBuildingAdded
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const [payment, setPayment] = useState({ status: 'initial' });
  const [errorMessage, setErrorMessage] = useState('');
  const [timeLeft, setTimeLeft] = useState<number>(5);

  //const [message, setMessage] = useState<string | null>(null);
  //const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPayment({status: paymentIntent!.status});
    
    /*if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if( paymentIntent === undefined){
        setMessage("Something went wrong");
        return;
      }
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });*/

    if(payment.status === 'succeeded'){
      if(timeLeft===0){
          console.log("TIME LEFT IS 0");
          setTimeLeft(0);
          redirectToHome();
      }

      // exit early when we reach 0
      if (!timeLeft) return;

      // save intervalId to clear the interval when the
      // component re-renders
      const intervalId = setInterval(() => {

        setTimeLeft(timeLeft - 1);
      }, 1000);

      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
      // add timeLeft as a dependency to re-rerun the effect
      // when we update it
    }

  }, [stripe, timeLeft]);

  const PaymentStatus = ({ status }: { status: string }) => {
    //case 'requires_payment_method':
      
    switch (status) {
      case 'processing':
      case 'requires_confirmation':
        return (
          <>
            <Stack
              direction="row"
              justifyContent={'center'}
            >
              <h2>Processing...</h2>
            </Stack> 
          </>
        )

      case 'requires_action':
        return (
          <>
            <Stack
              direction="row"
              justifyContent={'center'}
            >
              <h2>Authenticating...</h2>
            </Stack> 
          </>
        )

      case 'succeeded':
        return (
          <>
            <Stack
              direction="row"
              justifyContent={'center'}
            >
              <h2>Payment Succeeded 🥳</h2>
            </Stack>
            <Stack
              direction="row"
              justifyContent={'center'}
            >
              {`Page will redirect after ${timeLeft} seconds`}
            </Stack>

            <FlexSpacer minHeight={1} />

            <Stack
              direction="row"
              justifyContent={'center'}
            >
              Click
              <span style={{
                  margin: '0px 5px',
                  color: theme.colors.primary,
                  cursor: 'pointer'
                }} onClick={redirectToHome}>here</span>
              to redirect directly
            </Stack>
          </>
        )

      case 'error':
        return (
          <>
            <Stack
              direction="row"
              justifyContent={'center'}
            >
              <h2>Error 😭</h2>
            </Stack>
            <Stack
              direction="row"
              justifyContent={'center'}
            >
              <p className="error-message">{errorMessage}</p>
            </Stack>            
          </>
        )

      default:
        return null
    }
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    
    if (!e.currentTarget.reportValidity()) return
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    //setIsLoading(true);
    setPayment({ status: 'processing' })

    const currentProtocol = window.location.protocol;
    const currentDomain = window.location.hostname;
    const currentPort = window.location.port;
    const returnURL =
      currentPort
      ? `${currentProtocol}//${currentDomain}:${currentPort}/building/${building_ID}/checkout`
      : `${currentProtocol}//${currentDomain}/building/${building_ID}/checkout`;

    const { error } = await stripe!.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: returnURL,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    /*if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error!.message!);
    } else {
      setMessage("An unexpected error occurred.");
    }*/

    if (error) {
      setPayment({ status: 'error' })
      setErrorMessage(error.message ?? 'An unknown error occurred')
    } else if (paymentIntent) {
      setPayment(paymentIntent)
    }

    //setIsLoading(false);
  };

  function redirectToHome(){
    const BUILDING_STATUS_NOTMINTED = 2;
    const data = {
      "building_ID": building_ID,
      "status": BUILDING_STATUS_NOTMINTED
    }

    const buildingService = new BuildingService();
    buildingService.updateBuildingFields(keycloak, data)
      .then((response) => {
        console.log(response);
        if(response.data.status){

          handleOpenModalBuildingAdded();
          navigate('/');
          //toast.success("Building payment has been created!");
        }
        else {
          //toast.error("Building payment not created, try again");
        }          
      })
      .catch((error) => {
        //toast.error("Error creating building payment, try again!")

        if (error.response.status === 401) {
              navigate('/');
              keycloak.logout();
          }            
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          console.error(`Error: ${error}`)
      });
  }

  return (
    <>
      <form
        id="payment-form"
        className="stripeForm"
        onSubmit={handleSubmit}
      >
        {!['succeeded'].includes(payment.status)
          && (
            <>
              <PaymentElement id="payment-element" />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                sx={{ fontSize: ".7rem", minWidth: 'auto' }}
                disabled={
                  !['initial', 'requires_payment_method', 'error'].includes(payment.status) ||
                  !stripe || !elements
                }
              >
                {['processing'].includes(payment.status)
                    ? <div className="spinner" id="spinner"></div>
                    : `Pay now (${formatNumber(paymentIntent!.amount / 100)} €)`}
              </Button>

              {/*
              <button
                type="submit"
                disabled={
                  !['initial', 'requires_payment_method', 'error'].includes(payment.status) ||
                  !stripe || !elements
                }
              >
                <span id="button-text">
                  {['processing'].includes(payment.status)
                    ? <div className="spinner" id="spinner"></div>
                    : `Pay now (${formatNumber(paymentIntent!.amount / 100)} €)`}
                </span>
              </button>
              */} 
            </>
          )
        }

        { false && (
          <Stack
            direction="row"
            justifyContent={'center'}
          >
            {JSON.stringify(paymentIntent)}
          </Stack>
        )}

        <PaymentStatus status={payment.status} />
      </form>
      {/*JSON.stringify(payment)*/}
    </>
  );
}

export default ElementsForm