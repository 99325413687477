import { FC, useEffect, useState } from "react";
import { Box, Button, Grid, MenuItem, Popover, Stack, useMediaQuery } from "@mui/material";
import Typography from "../../design-system/atoms/Typography";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Progress } from "../../design-system/atoms/Progress";
import { FileUpload } from "../../design-system/molecules/FileUpload";
import { FlexSpacer } from "../../design-system/atoms/FlexSpacer";
import { theme } from "../../utils/theme";
import { IBuilding, IStakeholderDetail } from "../../interfaces/building";
import { useKeycloak } from "@react-keycloak/web";
import { MagmaLevel } from "../../design-system/molecules/MagmaLevel";
import { Stakeholders } from "../../design-system/molecules/Stakeholders";
import Documents from "../../design-system/molecules/Documents";
import OptionsButton from "../../design-system/atoms/Buttons/OptionsButton";
import { DttMintReview } from "../../design-system/molecules/Modals/DttMintReview";
import styled from "@emotion/styled";
import MapBlock from "../../design-system/molecules/Searches/SearchMap/MapBlock";
import { DOCUMENT_STATUS_ADDED, DOCUMENT_STATUS_OUTOFDATE, DOCUMENT_STATUS_PENDING, DOCUMENT_STATUS_REJECTED, DOCUMENT_STATUS_UPDATED, DOCUMENT_STATUS_VALIDATED, IMedia, IMediaVersion } from "../../interfaces/media";
import { IMapLocation } from "../../interfaces/location";
import BuildingService from "../../services/buildingService";
import { getWalletBalance, limitChars } from "../../utils/functions";
import UserService from "../../services/userService";
import { IUserRoleMin, IUserType, IUserTypeDoc } from "../../interfaces/userInfo";
import { toast } from "react-toastify";
import { tierData } from "../../interfaces/dtt";

interface BuildingEditProps {
    setMessengerOpen?: any,
    messengerOpen: any,
    bothNavsOpen: any,
    handleOpenModalAddStakeholder?: any,
    handleOpenModalEstimate?: any,
    openModalEstimate?: any,
    handleCloseModalEstimate?: any,
    handleOpenModalDocChat?: any,
    openModalDocChat?: any,
    handleCloseModalDocChat?: any,
    handleOpenModalNft?: any,
    handleOpenModalNftLevel? :any,
    handleOpenModalTour?: any,
    open: any,
    position?: any,
    setBuildingWallet?: any,
    blockchain: any,
    setDocument: any,
    setUserInfo: any,
    userInfo: any,
    setMyCallBack?: any,
    myCallBack: any,
    setUserTypes?: any,
    setUserTypeDocs?: any,
    userTypes?: any,
    userTypeDocs?: any,
    setBuildingID?: any,
    setBuilding: any,
    building: IBuilding | undefined,
    buildingWallet: any,
    setIncludeUserTypeID?: any,
    setExcludeUserTypeID?: any,
    setMaxStakeholders?: any,
}

const BuildingEditPage: FC<BuildingEditProps> = ({
    open,
    handleOpenModalAddStakeholder,
    handleOpenModalNft,
    handleOpenModalNftLevel,
    handleOpenModalEstimate,
    openModalEstimate,
    handleCloseModalEstimate,
    handleOpenModalTour,
    bothNavsOpen,
    messengerOpen,
    setMessengerOpen,
    setBuildingWallet,
    blockchain,
    setDocument,
    setUserInfo,
    userInfo,
    setMyCallBack,
    myCallBack,
    setUserTypes,
    setUserTypeDocs,
    userTypes,
    userTypeDocs,
    setBuildingID,
    setBuilding,
    building,
    buildingWallet,
    setIncludeUserTypeID,
    setExcludeUserTypeID,
    setMaxStakeholders,
}) => {
    let { id } = useParams();
    const isMobileSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isRetina = useMediaQuery(theme.breakpoints.up('xl'));
    //const [building, setBuilding] = useState<IBuilding | undefined>(undefined);
    const [buildingImage, setBuildingImage] = useState<IMediaVersion>();
    const [buildingDocs, setBuildingDocs] = useState<IMedia[]>();
    /*const [userInfo, setUserInfo] = useState<IUserInfo | undefined>(undefined);*/
    const [userRoles, setUserRoles] = useState<IUserRoleMin[]>();
    /*const [newUserInfo, setNewUserInfo] = useState({
        profileImages: []
    });*/
    const [mapLocation, setMapLocation] = useState<IMapLocation>();
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    const [isMinted, setIsMinted] = useState(false)
    const [isUpgraded, setIsUpgraded] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const dropdownOpen = Boolean(anchorEl);
    const [selectedIndex, setSelectedIndex] = useState(1);

    //const [searchInput, SetSearchInput] = useState();
    //const [resultsList, setResultsList] = useState([]);
    //const apiService = new ApiService();
    const openPop = Boolean(anchorEl);
    const popId = open ? 'simple-popover' : undefined;

    const popoverList = [
        {
            label: 'Edit building information',
            src: '/svg/icons/Pencil.svg',
            color: 'inherit',
            onClick: handleOpenModalAddStakeholder,
            disabled: true
        },
        {
            label: 'Invite a stakeholder',
            src: '/svg/icons/PlusDark.svg', 
            color: 'inherit',
            onClick: () => {
                setIncludeUserTypeID();
                setExcludeUserTypeID([1]);
                setMaxStakeholders(-1);
                handleOpenModalAddStakeholder();
            },
            disabled: false
        },
        {
            label: 'Change picture',
            src: '/svg/icons/Image.svg',
            color: 'inherit',
            onClick: handleOpenModalAddStakeholder,
            disabled: true
        },
        //{ label: 'Delete', src: '/svg/icons/trash.svg', color: 'error', onCLick: handleOpenModalAddStakeholder }
    ]


    useEffect(() => {
        // function getCook(cookiename: any) {
        //     // Get name followed by anything except a semicolon
        //     var cookiestring = RegExp(cookiename + "=[^;]+").exec(document.cookie);
        //     // Return everything after the equal sign, or an empty string if the cookie name not found
        //     return decodeURIComponent(!!cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : "");
        // }

        if (!window.localStorage.getItem("hideModalTour")) {
            handleOpenModalTour()
        }

        fetchUserInfo();

        setMyCallBack(() => fetchBuildingInfo);
        fetchBuildingInfo();
        
    }, []);

    const updateUploadedFiles = (element_id: string, doc_type: number, files: any) => {
        console.log('upload docs', doc_type, files);

        var bodyFormData = new FormData();
        bodyFormData.append('data', JSON.stringify({
            keyval: `user#${userInfo?.ID}_building#${id}`,
            mediatype: `BUILDING_DOC_${doc_type}` 
        }));
        bodyFormData.append('file', files.file);

        /*console.log('buildingImageList', buildingImageList);
        console.log('mapLocation',mapLocation);*/

        const buildingService = new BuildingService();
        buildingService.addMedia(keycloak, bodyFormData)
        .then((response) => {
            console.log("RESPONSE",response);
            //error
            if (!response.data.status) {
                console.log(response.data.msg + "\n" + response.data.error);
                toast.error("Error uploading document, try again!")
                if(id !== undefined){
                    //toast.error("Error updating building, try again!")
                }
                else{
                    //toast.error("Error building creation, try again!")
                }
            }
            //success
            else {
                //const building_ID = id !== undefined ? id : response.data.result;
                
                //don't hide the document box
                /*const element = document.getElementById('FILE_' + element_id);
                if(element) element.style.display = "none";
                console.log('element',element_id,'hidden')*/
                
                if (myCallBack){
                    myCallBack();
                }

                toast.success("Document has been uploaded!");
                

                /*
                if(id !== undefined){
                    //toast.success("Building has been updated!");
                }
                else{
                    //toast.success("Building has been created!");
                    //handleOpenModalStakeholdersDetailed();
                }
                */
            }
        })
        .catch((error) => {
            if (error.response.status === 401) {
            navigate('/');
            keycloak.logout();
            }
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.error(`Error: ${error}`)
        })
        //setNewUserInfo({ ...newUserInfo, profileImages: files });
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        //logic to create new user...
    };

    function fetchBuildingInfo() {
        const buildingService = new BuildingService();
        buildingService.getBuildingInfo(keycloak, id)
            .then(async (response) => {
                if (!response){
                    navigate('/');
                    return;
                }

                const building: IBuilding = response;
                console.log('building', building);

                const mapLocation = {
                    label: building.address,
                    coords: {
                        lat: building.lat,
                        lng: building.lng,
                    }
                };

                building.level = Array(5).fill(0);
                console.log('building.level', building.level);
                building.magma_index = 0;
                if (building.dtt_address?.trim()) {
                    const dtt = await blockchain.getInstanceOfDigitalTwinToken(building.dtt_address);
                    const tmpDTTMetaData: any = await dtt.getMetaData();
                    building.level[0] = tmpDTTMetaData.level;
                    building.level[1] = 0;
                    building.level[2] = 0;
                    building.level[3] = 0;
                    building.level[4] = 0;
                    
                    for (let i = 0; i < tierData.length; i++) {
                        building.magma_index += building.level[i] * tierData.sort((a, b) => a.tier_id - b.tier_id)[i].magma_index_weight;
                    }
                }

                setMapLocation(mapLocation);
                setBuilding(building);
                setBuildingID(building.ID);

                if (building.media) {
                    const building_imgObj = building.media.find(media => media.mediatype === 'BUILDING_IMG');
                    if (building_imgObj && building_imgObj.versions !== undefined) {
                        const building_img_version = building_imgObj.versions[0];
                        setBuildingImage(building_img_version);
                    }

                    const building_docObj = building.media.filter(media => media.mediatype.includes('BUILDING_DOC_'));
                    if (building_docObj && building_docObj !== undefined) {
                        console.log('building_docObj',building_docObj);
                        setBuildingDocs(building_docObj);
                    }
                }

                if(building.wallet_address !== undefined &&
                    building.wallet_address !== null &&
                    building.wallet_address !== '')
                {
                    setBuildingWallet({
                      name: building.building_name,
                      address: building.wallet_address,
                      balance: {
                          gasBalance: await getWalletBalance(blockchain, building.wallet_address)
                      }                      
                    });
                }

                if(building.dtt_address !== undefined &&
                    building.dtt_address !== null &&
                    building.dtt_address !== '')
                {
                    setIsMinted(true);
                }
            })
            .catch(error => {
                if (error.response.status === 401) {
                    navigate('/');
                    keycloak.logout();
                }
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                console.error(`Error: ${error}`)
            });
    }

    const fetchUserInfo = () => {
        const userService = new UserService();
        userService.getUser(keycloak)
          .then((response) => {
            const userInfo = response;
            console.log("userInfo", userInfo);
            setUserInfo(userInfo);

            if (userInfo !== undefined &&
                userInfo.permissions !== undefined &&
                userInfo.permissions.length > 0){
                fetchUserTypes(userInfo.permissions![0].user_type_ID);

                const userRoles = userInfo.permissions.find(p => id !== undefined && parseInt(p.building_ID) === parseInt(id))?.roles;
                const userRolesMin = userRoles?.map(obj => ({ user_role_name: obj.user_role_name, access: obj.access }));
                setUserRoles(userRolesMin);
            }
          })
          .catch(error => {
            console.log('error', error);
            if (error.response?.status === 401) {
              navigate('/');
              keycloak.logout();
            }
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.error(`Error: ${error}`)
          });
        //setLoading(false);
    }

    const fetchUserTypes = (userTypeID: number) => {
        const userService = new UserService();
        userService.getUserTypes(keycloak)
          .then((response) => {
            const userTypes: IUserType[] = response;
            console.log("userTypes", userTypes);
            setUserTypes(userTypes);
            
            const userType = userTypes.find(userType => userType.ID === userTypeID);
            if(userType !== undefined && userType.user_type_docs !== undefined) {
                console.log('userTypeDocs', userType.user_type_docs);
                setUserTypeDocs(userType.user_type_docs);
            }

            console.log("userType", userType);
          })
          .catch(error => {
            if (error.response.status === 401) {
              navigate('/');
              keycloak.logout();
            }
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.error(`Error: ${error}`)
          });
        //setLoading(false);
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setSelectedIndex(index);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const documentExist = (user_type_doc_ID: number) => {
        return false; //always allow to upload multiple documents from the same type
        //return buildingDocs?.find(buildingDoc => buildingDoc.mediatype === `BUILDING_DOC_${user_type_doc_ID}`);
    }

    const getDocDescription = (mediatype: string) => {
        const user_type_doc_ID = parseInt(mediatype.substring(mediatype.lastIndexOf('_') + 1));
        return userTypeDocs?.find((userTypeDoc: IUserTypeDoc) => userTypeDoc.ID === user_type_doc_ID)?.description;
    }

    const getUserRole = (key: string) => {
        if(userRoles === undefined){
            return false;
        }
        
        const permission = userRoles.find(r => r.user_role_name === key);
        return permission === undefined || permission.access === 0 ? false : true;
    }

    const getStatus = (media_ID: number) => {
        const buildingDoc = buildingDocs?.find(buildingDoc => buildingDoc.ID !== undefined && buildingDoc.ID === media_ID);
        
        return buildingDoc === undefined ? 0 : buildingDoc.versions[0].status;
    }

    const getDocumentsToUpload = () => {
        console.log('userInfo', userInfo);
        if(!userInfo) return;

        console.log('userInfo.permissions', userInfo.permissions);

        if(!userInfo.permissions) return;

        const user_type_ID = userInfo.permissions![0].user_type_ID;
        const assetManager = building?.stakeholders?.find((stakeholder: IStakeholderDetail) => stakeholder.user_type_ID === 2);
        
        let documentsToUpload;
        /*if(assetManager === undefined){
            documentsToUpload = userTypeDocs?.filter((userTypeDoc: IUserTypeDoc) => userTypeDoc.user_type_ID === 2);
        }
        else{
            documentsToUpload = userTypeDocs?.filter((userTypeDoc: IUserTypeDoc) => userTypeDoc.show === true);
        }*/  
        
        if (user_type_ID === 1 && assetManager === undefined) {
            documentsToUpload = userTypeDocs?.filter((userTypeDoc: IUserTypeDoc) => userTypeDoc.user_type_ID === 2 || userTypeDoc.show === true);
        }
        else{
            documentsToUpload = userTypeDocs?.filter((userTypeDoc: IUserTypeDoc) => userTypeDoc.show === true);
        }
                        
        return documentsToUpload;
    }

    const getPaidAmount = (doc: IMedia) => {
        if(doc.versions[0].payment === undefined || doc.versions[0].payment.length === 0 ){
            return undefined;
        }
        else{
            return doc.versions[0].payment[0].amount;
        }
    }

    const getOwnerImage = (doc: IMedia) => {
        const user_type_ID = doc?.versions[0].activity?.find(a => [DOCUMENT_STATUS_ADDED, DOCUMENT_STATUS_UPDATED].includes(a.action))?.user_type_ID;
        const user_type_img = userTypes?.find((userType: IUserType) => userType.ID === user_type_ID)?.img;

        return user_type_img;
    }

    const getAccess = (document: IMedia) => {
        console.log('document', document);
        console.log('userInfo', userInfo);
        console.log('userTypeDocs', userTypeDocs);
        
        const document_type = parseInt(document.mediatype.substring(document.mediatype.lastIndexOf('_') + 1))
        //console.log('document_type', document_type);
        
        const user_type_ID = userInfo.permissions![0].user_type_ID;
        //console.log('user_type_ID', user_type_ID);

        /*
        if(user_type_ID === 1){
            //user type 'Owner' always have access to validate a document
            return true;
        }
        */

        /*
        //check in usertypedocs if the user type have validate/reject/update rights
        const validator_user_type_ID_arr = userTypeDoc.validator_user_type_ID.split(',');
        //console.log('validator_user_type_ID_arr', validator_user_type_ID_arr);
        //check if the current user is part of the validators
        if (validator_user_type_ID_arr.indexOf(user_type_ID.toString()) !== -1) {
            return true;
        }

        //check if current user is owner and one of the validators is an asset manager
        if (user_type_ID === 1 && validator_user_type_ID_arr.indexOf('2') !== -1) {
            return true;
        }*/

        /********************** */

        if(!userTypeDocs){
            return false;
        }

        //find the specific building doc type
        const userTypeDoc = userTypeDocs.find((userTypeDoc: IUserTypeDoc) => userTypeDoc.ID === document_type);
        if(!userTypeDoc){
            return false;
        }

        if([DOCUMENT_STATUS_VALIDATED, DOCUMENT_STATUS_REJECTED, DOCUMENT_STATUS_OUTOFDATE].includes(document.versions[0].status!)){
            console.log('user_type_ID',user_type_ID,'userTypeDoc.user_type_ID',userTypeDoc.user_type_ID);
            if(user_type_ID === userTypeDoc.user_type_ID){
                return true;
            }

            //check if current user is owner and one of the validators is an asset manager
            if (user_type_ID === 1 && userTypeDoc.user_type_ID === 2) {
                return true;
            }
        }
        else{
            console.log('user_type_ID',user_type_ID,'userTypeDoc.validator_user_type_ID',userTypeDoc.validator_user_type_ID);
            //check in usertypedocs if the user type have validate/reject/update rights
            const validator_user_type_ID_arr = userTypeDoc.validator_user_type_ID.split(',');
            //console.log('validator_user_type_ID_arr', validator_user_type_ID_arr);
            //check if the current user is part of the validators
            if (validator_user_type_ID_arr.indexOf(user_type_ID.toString()) !== -1) {
                return true;
            }

            //check if current user is owner and one of the validators is an asset manager
            if (user_type_ID === 1 && validator_user_type_ID_arr.indexOf('2') !== -1) {
                return true;
            }
        }


        return false;
    }

    return (
        <StyledBoxWrapper sx={{ maxWidth: isTablet ? 670 : 760 }}>
            <Typography sx={{ padding: '.5rem', marginBottom: '1.5rem' }} size='h6'>
                <Link to="/buildings"
                    style={{
                        margin: '0px 0 -6px -10px',
                        color: theme.colors.text,
                        fontWeight: 'medium',
                    }}
                >
                    <ArrowBackIcon style={{ margin: '0 .2rem -0.3rem 0' }} />
                    Back to dashboard
                </Link>
            </Typography>

            <Typography size='h2'>{building?.building_name}</Typography>

            <Stack direction="row" my={2} style={{ width: '100%', fontSize: '.8rem'}}>
                <Grid style={{display: 'flex'}}>
                    <Grid
                        style={{
                            flex: '0 0 auto',
                        }}>
                        <Stack direction={'row'}>
                            <img src="/svg/icons/BuildingsRed.svg" alt="building icon" height={14} width={14} style={{ margin: '3px' }} />{' '}
                            <Typography>{building?.building_type}</Typography>
                        </Stack>
                    </Grid>
                    <Grid
                        style={{
                            flex: '1 1 auto',
                        }}>
                        <Stack direction={'row'}>
                            <Typography style={{
                                opacity: .2,
                                margin: '0 .5rem',
                                display: 'inline-block',
                                verticalAlign: 'top'
                            }}>{' • '}</Typography>
                            <img src="/svg/icons/MapPinOrange.svg" alt="map icon" height={14} width={14} style={{ margin: '3px' }} />{' '}
                            <Typography>{building?.address}</Typography>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>


            { /* MAP */}
            <Stack direction="column" sx={{ minHeight: '400px', maxHeight: '420px', position: 'relative', borderRadius: '8px', overflow: 'hidden' }}>
                <img
                    className="building-img"
                    src={buildingImage ? `data:${buildingImage!.mimetype};base64,${buildingImage!.mediabase64}` : "/images/building/Draft.png"}
                    width="100%"
                    height="auto"
                    alt="building preview"
                    style={{ objectFit: 'cover', height: '420px'}}
                />

                <Box sx={{ width: '100%', position: 'absolute', top: 0, left: 0, padding: '16px 16px' }}>
                    <Stack direction={isMobileSm ? 'column' : 'row'} sx={{ justifyContent: 'space-between' }} >
                        <Button variant='contained' sx={{ maxWidth: 200, height: 40, marginBottom: isMobileSm ? 2 : 0 }}>
                            <img src="/svg/icons/3DCube_white.svg"
                                alt="3d icon"
                                height={20}
                                width={20}
                            />
                            <Typography style={{ marginLeft: 10 }}>
                                Enter 3D Mode
                            </Typography>
                        </Button>

                        <Stack direction="row" sx={{ alignItems: 'center', position: 'relative' }}>
                            <Progress done={(building && building.magma_index) || 0} label="Magma index: " sx={{ marginBottom: '10px' }} />
                            
                            <OptionsButton
                                top={0}
                                right={0}
                                aria-describedby={popId}
                                sx={{
                                    position: "relative !important",
                                    marginTop: '-10px',
                                    backgroundColor: 'white',
                                    padding: '5px',
                                    width: 30,
                                    height: 26,
                                    marginLeft: '1rem'
                                }}
                                id="options-menu-button"
                                aria-controls={open ? 'demo-positioned-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            />
                            {/* 
                            <Popover
                                id={popId}
                                open={openPop}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                PaperProps={{
                                    elevation: 1
                                }}
                                sx={{
                                    mt: 1.5
                                }}
                            >
                                <Stack direction="column" sx={{ alignItems: 'self-start' }}>
                                    {popoverList.map((item, index) => {
                                        return (
                                            <Button
                                                onClick={item.onClick}
                                                color="inherit"
                                                key={index}
                                                style={{ textTransform: 'inherit', width: '100%', justifyContent: "flex-start" }}
                                                disabled={item.disabled}
                                            >
                                                <Stack direction="row">
                                                    <img
                                                        src={item.src}
                                                        alt={item.label}
                                                        style={{
                                                            filter: item.disabled ? 'grayscale(100%)' : 'none',
                                                            opacity: item.disabled ? '0.25' : '1'
                                                        }}
                                                    />
                                                    <Typography color={item.color} sx={{ ml: 1 }}>{item.label}</Typography>
                                                </Stack>
                                            </Button>
                                        )
                                    })
                                    }
                                </Stack>
                            </Popover>
                            */}
                            <Popover
                                id={popId}
                                open={openPop}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                PaperProps={{
                                    elevation: 1
                                }}
                                sx={{
                                    mt: 1.5
                                }}
                            >
                                {popoverList.map((item, index) => {
                                    return (
                                        <StyledMenuItem
                                            key={index}
                                            onClick={item.onClick}
                                            disabled={item.disabled}
                                        >
                                            <Stack direction="row">
                                                <img src={item.src} className={`icon-${item.color}`} alt="icon" />
                                                <Typography color={item.color} sx={{ ml: 1 }}>{item.label}</Typography>
                                            </Stack>
                                        </StyledMenuItem>
                                    )
                                })}
                            </Popover>
                        </Stack>
                    </Stack>
                </Box>

                <Box className="map__edit">
                    <MapBlock
                        position={
                            (mapLocation === undefined ||
                                mapLocation!.coords!.lat === undefined || mapLocation!.coords!.lat === null ||
                                mapLocation!.coords!.lng === undefined || mapLocation!.coords!.lng === null)
                                ? [48.8583701, 2.2944813]
                                : [mapLocation!.coords!.lat, mapLocation!.coords!.lng]}
                        markerText={
                            mapLocation === undefined
                                ? 'Tour Eiffel, Champ de Mars, 5 Av. Anatole France, 75007 Paris, Frankrijk'
                                : mapLocation!.label}
                    />
                </Box>
            </Stack>

            <FlexSpacer minHeight={3} />

            {/* DOCUMENTS TO UPLOAD */}
            <Typography size="h3" weight='medium'>Documents you need to upload</Typography>

            <FlexSpacer minHeight={1.5} />

            {getDocumentsToUpload() !== undefined && getDocumentsToUpload()?.length === 0 &&
                <Stack>
                    No documents to upload
                </Stack>
            }

            <form onSubmit={handleSubmit}>
                <Grid container
                    spacing={3}
                >
                    {getDocumentsToUpload() !== undefined && getDocumentsToUpload()!.map((userTypeDoc: IUserTypeDoc, index: number) => {
                        return (
                            <Grid item
                                key={`building-doc-${index}`}
                                xs={12} md={4}
                                id={`FILE_${userTypeDoc.ID}`}
                                style={{ display: documentExist(userTypeDoc.ID) ? 'none' : '' }}                                
                            >
                                <FileUpload
                                    id={`${userTypeDoc.ID}`}
                                    building_type_doc={'document'}
                                    accept={userTypeDoc.extensions.split(',').map((element, index) => {
                                        return `.${element}`;
                                    })}
                                    acceptText={`Supports: ${userTypeDoc.extensions.split(',').map((element, index, item) => {
                                        let prefix = '';
                                        if(index === 0){
                                            prefix = ' ';
                                        }
                                        if(item.length > 1 && index === item.length - 1){
                                            prefix = ' or ';
                                        }
                                        return `${prefix} .${element}`;
                                    })}`}
                                    label=""
                                    doc_type={userTypeDoc.ID}
                                    updateFilesCb={updateUploadedFiles}
                                />
                                <Stack
                                    direction='row'
                                    mt={2}
                                >
                                    <Typography size="14px" weight="medium">
                                        {limitChars(userTypeDoc.description, 50)}
                                    </Typography>                                    
                                </Stack>
                                {false && 
                                <Stack
                                    direction='row'
                                    mt={2}
                                >
                                    <Typography size="14px" weight="medium">
                                        {'mandatory:' + userTypeDoc.mandatory + ' weight:' + userTypeDoc.index_weight + ' validator_user_type_ID:' + userTypeDoc.validator_user_type_ID + ' validator_time:' + userTypeDoc.validation_time}
                                    </Typography>
                                </Stack>
                                }
                            </Grid>
                        )
                    })}
                </Grid>                
            </form>

            <FlexSpacer minHeight={4.5} />

            {/* DOCUMENTS IN NEED OF UPDATING */}
            <Typography size="h3" weight='medium'>Documents in need of updating</Typography>

            <FlexSpacer minHeight={1.5} />

            {buildingDocs !== undefined && (
                <Documents
                    keyprefix={'updating_docs'}
                    documentData={
                        buildingDocs?.map(((doc: IMedia) => {
                            return {
                                name: getDocDescription(doc.mediatype),
                                thumbnail: doc.versions[0].mediabase64thumbnail ? doc.versions[0].mediabase64thumbnail : '',
                                base64: doc.versions[0].mediabase64,
                                filename: doc.versions[0].originalname,
                                mimetype: doc.versions[0].mimetype,
                                doc: doc,
                                show: [DOCUMENT_STATUS_REJECTED, DOCUMENT_STATUS_OUTOFDATE, DOCUMENT_STATUS_UPDATED].includes(doc.versions[0].status!) && getAccess(doc),
                            }
                        }))
                    }
                    setDocument={setDocument}
                    handleOpenModalEstimate={handleOpenModalEstimate}
                />
            )}

            <FlexSpacer minHeight={3} />

            {/* DOCUMENTS PENDING VALIDATION */}
            <Typography size="h3" weight='medium'>
                Documents pending validation
            </Typography>

            <FlexSpacer minHeight={1.5} />

            {buildingDocs !== undefined && (
                <Documents
                    keyprefix={'pending_docs'}
                    documentData={
                        buildingDocs?.map(((doc: IMedia) => {
                            return {
                                name: getDocDescription(doc.mediatype),
                                thumbnail: doc.versions[0].mediabase64thumbnail ? doc.versions[0].mediabase64thumbnail : '',
                                base64: doc.versions[0].mediabase64,
                                filename: doc.versions[0].originalname,
                                mimetype: doc.versions[0].mimetype,
                                doc: doc,
                                show: [DOCUMENT_STATUS_PENDING, DOCUMENT_STATUS_UPDATED].includes(doc.versions[0].status!) && getAccess(doc),
                            }
                        }))
                    }
                    setDocument={setDocument}
                    handleOpenModalEstimate={handleOpenModalEstimate}
                />
            )}

            <FlexSpacer minHeight={3} />

            {/* MAGMA LEVEL */}
            <MagmaLevel 
                userTypeDocs={userTypeDocs}
                buildingDocs={buildingDocs}
                userTypes={userTypes}
            />

            <FlexSpacer minHeight={3} />

            {/* STAKEHOLDERS */}
            <Stakeholders
                handleOpenModalAddStakeholder={handleOpenModalAddStakeholder}
                stakeholdersData={building?.stakeholders}
                building={building}
                userInfo={userInfo}
                setIncludeUserTypeID={setIncludeUserTypeID}
                setExcludeUserTypeID={setExcludeUserTypeID}
                setMaxStakeholders={setMaxStakeholders}
            />

            <FlexSpacer minHeight={3} />

            {/* DOCUMENTS */}
            <Typography size="h3" weight="medium">
                Documents
            </Typography>

            <FlexSpacer minHeight={1.5} />

            <Typography size="h4" weight="medium">
                Quick Access
            </Typography>

            <FlexSpacer minHeight={1} />

            {buildingDocs !== undefined && (
                <Documents
                    keyprefix={'docs'}
                    documentData={
                        buildingDocs?.map((doc => {
                            return {
                                name: getDocDescription(doc.mediatype),
                                thumbnail: doc.versions[0].mediabase64thumbnail ? doc.versions[0].mediabase64thumbnail : '',
                                base64: doc.versions[0].mediabase64,
                                filename: doc.versions[0].originalname,
                                mimetype: doc.versions[0].mimetype,
                                doc: doc,
                                show: doc.versions[0].status === 3 && doc.versions[0].access,
                                paid: getPaidAmount(doc),
                                owner: getOwnerImage(doc),
                                status: (doc.ID !== undefined ? getStatus(doc.ID) : 0),
                                access: doc.versions[0].access
                            }
                        }))
                    }
                    setDocument={setDocument}
                    handleOpenModalEstimate={handleOpenModalEstimate}
                    showDetails={true}
                />
            )}

            {
                getUserRole('building_update') && (
                    <>

                    <FlexSpacer minHeight={3} />
                    
                    {/* DTT MINTING REVIEW */}
                    <DttMintReview
                        handleOpenModalNft={handleOpenModalNft}
                        handleOpenModalNftLevel={handleOpenModalNftLevel}
                        setIsMinted={setIsMinted}
                        isMinted={isMinted}
                        isUpgraded={isUpgraded}
                        setIsUpgraded={setIsUpgraded}
                        blockchain={blockchain}               
                        building={building}
                        keycloak={keycloak}
                        userTypeDocs={userTypeDocs}
                        buildingDocs={buildingDocs}
                        userInfo={userInfo}
                        myCallBack={myCallBack}
                        userTypes={userTypes}
                        setBuildingID={setBuildingID}
                        setBuilding={setBuilding}
                        buildingWallet={buildingWallet}
                        setBuildingWallet={setBuildingWallet}
                    />

                    <FlexSpacer minHeight={3} />

                    {/* 
                    <ModalEstimate
                        openModalEstimate={openModalEstimate}
                        handleCloseModalEstimate={handleCloseModalEstimate}
                    />*/}
                    </>
                )
            }
        </StyledBoxWrapper>
    );
}

export default BuildingEditPage;

const StyledBoxWrapper = styled(Box)`

    @media (min-width: 600px) {
        width: calc(100vw - 183px);
    }

    @media (min-width: 760px) {
        max-width: 760px;
    }

    @media (min-width: 1900px) {
        max-width: 55%;
    }
`;

const StyledMenuItem = styled(MenuItem)`
    :hover{
        div{
            transform: translateX(-4px);
        }

        img{
            filter: invert(1);
        }
    }
`;