import styled from "@emotion/styled";
import clsx from "clsx";
import { FC, useState } from "react";

interface ProgressProps {
    type?: any,
    done?: any,
    color?: any,
    children?: any,
    label?: any,
    className?: any,
    sx?: any
}

export const Progress: FC<ProgressProps> = ({ className, type, color, children, sx, done, label }) => {
    const [style, setStyle] = useState({});

    done = Math.min(done, 100);

    setTimeout(() => {
        const newStyle = {
            opacity: 1,
            width: `${done}%`
        }
        setStyle(newStyle);
    }, 0);

    return (
        <div style={sx} className={clsx(`progress${type === 'level' ? ' progress-level' : ''}`)}>
            <TextPercentage className="progress_text">
                {label}
                {!children ? Math.round(done * 10) / 10 : ''}
                {!children ? '%' : ''}
            </TextPercentage>
            <div className={clsx(`progress-done progress-${color}`)} style={style}></div>
            {children}
        </div>
    )
}

const TextPercentage = styled.span`
    font-size: 0.7rem;
    color: yellow;
    font-weight: bold;
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    z-index: 1;
`;