import { FC, useState } from 'react'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Avatar, Box, ListItemButton, ListItemIcon, Stack, useMediaQuery } from '@mui/material';
import { FlexSpacer } from '../../atoms/FlexSpacer';
import { ExpandMore } from '@mui/icons-material';
import Typography from '../../atoms/Typography';
import { theme } from '../../../utils/theme';
import { IUserType, IUserTypeDoc } from '../../../interfaces/userInfo';
import { DOCUMENT_STATUS_ADDED, DOCUMENT_STATUS_UPDATED, IMedia, documentStatus } from '../../../interfaces/media';
import { tierData } from '../../../interfaces/dtt';

interface CustomizedAccordionsProps {
    userTypeDocs: IUserTypeDoc[],
    buildingDocs: IMedia[],
    userTypes: IUserType[],
}

export const CustomizedAccordions: FC<CustomizedAccordionsProps> = ({
    userTypeDocs,
    buildingDocs,
    userTypes,
}) => {
    const [expanded, setExpanded] = useState<string | false>(`panel${tierData[0].tier_id}`);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const Accordion = styled((props: AccordionProps) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));

    const AccordionSummary = styled((props: AccordionSummaryProps) => (
        <MuiAccordionSummary
            expandIcon={<ExpandMore />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: 'white',
        flexDirection: 'row',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
    }));
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const getStatus = (user_type_doc_ID: number) => {
        const buildingDoc = buildingDocs?.find(buildingDoc => buildingDoc.mediatype === `BUILDING_DOC_${user_type_doc_ID}`);
        
        const response = {
            status: buildingDoc === undefined ? 1 : buildingDoc.versions[0].status,
            statusIcon: buildingDoc === undefined ? documentStatus.find(stat => stat.ID === DOCUMENT_STATUS_ADDED)!.icon : documentStatus.find(stat => stat.ID === buildingDoc.versions[0].status)!.icon,
            reminder: buildingDoc === undefined ? true: false,
            user: buildingDoc === undefined ? undefined :
                buildingDoc.versions[0].activity![0].firstname + ' ' +
                buildingDoc.versions[0].activity![0].lastname /*+ ' - ' +
                buildingDoc.versions[0].activity![0].info*/,
            user_type_img: buildingDoc === undefined ? undefined :
                getUserTypeImg(buildingDoc.versions[0].activity![0].user_type_ID),
        }

        return response;
    }

    const getUserTypeImg = (user_type_ID: number | undefined) => {
        if(!user_type_ID)
            return undefined;
        return userTypes?.find((userType: IUserType) => userType.ID === user_type_ID)?.img;
    }

    /*const getMaxLevelWeight = (tier: number) => {
        return userTypeDocs.filter(utd => utd.level === tier).reduce((maxIndexWeight: number, utd: IUserTypeDoc) => {
            return maxIndexWeight + utd.magma_index_weight * 100;
        }, 0);
    }*/

    return (
        <Box mt={2} >
            <>
                {tierData && tierData
                .sort((a, b) => a.tier_id - b.tier_id)
                .map((item: any, index: number) => {
                    return (
                        <Accordion
                            sx={{ backgroundColor: '#F7F7F8', borderRadius: '4px', overflow: 'hidden', marginBottom: '8px' }}
                            expanded={expanded === `panel${item.tier_id}`}
                            onChange={handleChange(`panel${item.tier_id}`)}
                            key={index}
                        >
                            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" sx={{ backgroundColor: '#F7F7F8' }}>
                                <Typography size="h5" weight="medium">{`${item.tier}`}</Typography>
                                {/*<Typography size="h5" weight="medium">{`${item.tier} (${getMaxLevelWeight(item.tier_id)}% of total DTT value)`}</Typography>*/}
                            </AccordionSummary>

                            {item.tier_id === 0
                            ? (
                                <AccordionDetails>
                                    {userTypeDocs.filter(utd => utd.level === item.tier_id).map((userTypeDoc: IUserTypeDoc, index: number) => {
                                        return (
                                            <ListItemButton
                                                key={index}
                                                style={{ display: isMobile ? 'block' : 'flex', paddingLeft: '10px', marginRight: 0, background: '#EBECEF', borderRadius: '5px', margin: ' .5rem 0 ', justifyContent: 'space-between' }}>

                                                <Stack direction="row" sx={{maxWidth: '60%'}}>
                                                    <ListItemIcon>
                                                        <img src={`/svg/icons/${getStatus(userTypeDoc.ID).statusIcon}`} alt="status icon" height="24px" width="24px" />
                                                    </ListItemIcon>
                                                        
                                                    <Typography>
                                                        {
                                                            (getStatus(userTypeDoc.ID)!.status === 1 ? 'Upload the ' : '') + userTypeDoc.description + ' (' + Math.round(userTypeDoc.index_weight * 100 * 1000) / 1000 + '%)'
                                                        }
                                                    </Typography>
                                                </Stack>

                                                <Stack direction="row" sx={{ alignItems: 'center' }}>

                                                    {getStatus(userTypeDoc.ID).reminder && (
                                                        <Stack direction="row" style={{ color: theme.colors.secondary, alignItems: 'center' }}>
                                                            <img src="/svg/icons/BellRingingOrange.svg" height="18px" width="18px" alt='bell riging icon' />
                                                            <FlexSpacer minWidth={1} />

                                                            {isMobile ? '' : <Typography>Set reminder</Typography>}
                                                        </Stack>)
                                                    }

                                                    <FlexSpacer minWidth={1} />

                                                    {getStatus(userTypeDoc.ID).user !== undefined && 
                                                        <Avatar
                                                            sx={{ width: 32, height: 32 }}
                                                            alt={`${getStatus(userTypeDoc.ID).user}`}
                                                            title={`${getStatus(userTypeDoc.ID).user}`}
                                                            src={`${getStatus(userTypeDoc.ID).user_type_img ? `/../images/stakeholders/${getStatus(userTypeDoc.ID).user_type_img}` : ''}`}
                                                        />
                                                    }

                                                    <FlexSpacer minWidth={1} />

                                                    <Typography>
                                                        {getStatus(userTypeDoc.ID).status === 1 && ('Waiting for upload')}
                                                        {getStatus(userTypeDoc.ID).status === 2 && (
                                                            <>
                                                                Uploaded, pending validation by
                                                                <img
                                                                    src="/svg/icons/Info.svg"
                                                                    alt="Document validators"
                                                                    width="20px"
                                                                    height="20px"
                                                                    style={{marginLeft: '5px'}}
                                                                    title={
                                                                        `Below you find a list of all possible validator types:\n\n` +
                                                                        userTypeDoc.validator_user_type_ID.split(',').map((id) => {
                                                                            // Convert the id to a number
                                                                            const userTypeID = parseInt(id);
                                                                      
                                                                            // Find the userType with the matching ID
                                                                            const userType = userTypes.find((userType) => userType.ID === userTypeID);
                                                                      
                                                                            // Return a list item with the user type name if userType is found, or the original id if not found
                                                                            return userType ? userType.name : id;
                                                                        }).join('\n')
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                        {getStatus(userTypeDoc.ID).status === 3 && ('Validated')}
                                                        {getStatus(userTypeDoc.ID).status === 4 && ('Validated')}
                                                        {getStatus(userTypeDoc.ID).status === 5 && ('Out of date')}
                                                        </Typography>
                                                </Stack>
                                            </ListItemButton>
                                        )
                                    })
                                    }
                                </AccordionDetails>
                            )
                            : (
                                <AccordionDetails>
                                    <ListItemButton
                                        style={{ display: isMobile ? 'block' : 'flex', paddingLeft: '10px', marginRight: 0, background: '#EBECEF', borderRadius: '5px', margin: ' .5rem 0 ', justifyContent: 'space-between' }}>

                                        Coming soon
                                    </ListItemButton>
                                </AccordionDetails>
                            )}
                        </Accordion>

                    )
                })
                }
            </>
        </Box>
    );
}

