import { FC } from 'react'
import { Autocomplete, Box, Grid, IconButton, TextField, Typography, useMediaQuery } from '@mui/material';
import { theme } from '../../../../utils/theme';

interface SearchListProps {
    placeholder?: any,
    list?: any,
    value?: any,
    handleSearch?: any,
    setSearchBuilding?: any,
}

const SearchListBuildings: FC<SearchListProps> = ({
    placeholder,
    list,
    value,
    handleSearch,
    setSearchBuilding }) => {

    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    console.log('SearchListBuildings', list);

    return (
        <Box
            sx={{
                position: 'relative',
                p: '0 0 0 4px',
                display: 'flex',
                alignItems: 'center',
                width: !isTablet ? 305 : 180
            }}
            id="searchBuildings"
        >
            <Autocomplete
                sx={{
                    ml: 0, flex: 1, padding: 0,
                    "& .MuiInputBase-inputAdornedEnd": {
                        padding: '0 !important'
                    },
                    '& fieldset.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D9D9D9',
                    },
                    "& .MuiAutocomplete-endAdornment": {
                        paddingRight: '30px !important'
                    }
                }}
                freeSolo
                fullWidth
                options={list}
                filterOptions={(x) => x}
                forcePopupIcon={true}
                popupIcon=""
                filterSelectedOptions
                value={value ? value : ''}
                /*onChange={(event: any, newSearchItem: any | null) => {
                    if (newSearchItem === undefined || newSearchItem === null || newSearchItem === "") return;

                    console.log('newSearchItem', newSearchItem);

                    setSearchBuilding(newSearchItem)
                }}*/
                renderInput={(params) => (
                    <TextField
                        {...params}
                        id="searchInputList"
                        placeholder={placeholder}
                        sx={{
                            fontSize: '14px',
                            maxHeight: 38,
                            "& input::placeholder": {
                                opacity: 1,                               
                                color: "#D9D9D9 !important",
                            }                           
                        }}
                        
                        label=""
                        fullWidth
                        onChange={(event: any) => {
                            handleSearch(event.target.value);
                        }}
                    />)
                }
                /*renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.key}>
                            <Grid container alignItems="center">
                                <Grid item>
                                    {option !== undefined && option.key !== '' &&
                                        <Typography sx={{ color: 'text.secondary', mr: 2 }}>
                                            {option.value}
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                        </li>
                    )
                }}*/
            />

            <IconButton type="submit" sx={{ position: 'absolute', right: 0, p: '10px' }} aria-label="search">
               <img src="/svg/icons/Search.svg" alt="search" />
            </IconButton>
        </Box>
    )
}

export default SearchListBuildings