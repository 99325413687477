import { lazy, Suspense, FC, useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { ToastContainer } from 'react-toastify';
import { useMediaQuery } from "@mui/material";
import { theme } from "./utils/theme";
import { NoMatch } from "./pages/nomatch";
import { Maintenance } from './pages/maintenance';
import ActivityPage from "./pages/activity";
import Settings from "./pages/settings";
import BuildingListPage from "./pages/building/building-list";
import BuildingEditPage from "./pages/building/building-edit";
import BuildingAddPage from "./pages/building/building-add";
import BuildingAssetManagerPage from "./pages/building/building-asset-manager";
import BuildingStakeholdersPage from "./pages/building/building-stakeholders";
import BuildingReviewPage from "./pages/building/building-review";
import CheckoutPage from './pages/checkout';
import ScrollToTop from './utils/ScrollToTop';
import TermsAndConditions from "./pages/legal/terms-and-conditions";
import PrivacyPolicy from "./pages/legal/privacy-policy";
import Wallet from "./pages/wallet";
import renderLoader from './utils/renderLoader.tsx';
import clsx from "clsx";
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from './design-system/_common/Loader';
import { Blockchain, getBlockchain } from 'magma-sdk';
import { IUserInfo, IUserType, IUserTypeDoc } from './interfaces/userInfo';
import SettingsService from './services/settingsService';
import { ISettings } from './interfaces/settings';

const Layout = lazy(() => import('./design-system/_common/Layout')
    .then(({ Layout }) => ({ default: Layout })));

const AllModals = lazy(() => import('./design-system/molecules/Modals/AllModals')
    .then(({ AllModals }) => ({ default: AllModals })));

export const Router: FC = () => {
    useEffect(() => {
        console.log('API_URL', process.env.REACT_APP_API_URL);
    }, []);

    // Base consts
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const { keycloak, initialized } = useKeycloak();
    const isLoggedIn = keycloak.authenticated;
    const [open, setOpen] = useState(!isMobile)
    const [messengerOpen, setMessengerOpen] = useState(isMobile ? false : true);
    const [bothNavsOpen, setBothNavsOpen] = useState(false);
    const [userInfo, setUserInfo] = useState<IUserInfo | undefined>(undefined);
    const [building_ID, setBuildingID] = useState(0);
    const [building, setBuilding] = useState();
    const [include_user_type_ID, setIncludeUserTypeID] = useState<number[]>([]);
    const [exclude_user_type_ID, setExcludeUserTypeID] = useState<number[]>([]);
    const [max_stakeholders, setMaxStakeholders] = useState(0);
    const [document, setDocument] = useState('');
    const [userTypes, setUserTypes] = useState<IUserType[] | undefined>();
    const [userTypeDocs, setUserTypeDocs] = useState<IUserTypeDoc[] | undefined>();
    const location = useLocation();
    const path = location.pathname;

    const [myCallBack, setMyCallBack] = useState();

    const [settings, setSettings] = useState<ISettings[]>();
    const [isMaintenance, setMaintenance] = useState<boolean>();
    const [blockchain, setBlockchain] = useState<Blockchain>();
    useEffect(() => {
        console.log('get blockchain')
        const venlyNetwork = process.env.REACT_APP_VENLY_NETWORK;
        const venlyChain = process.env.REACT_APP_VENLY_CHAIN;
        const venlyClientID = process.env.REACT_APP_VENLY_CLIENT_ID;
        const venlyEnvironment = process.env.REACT_APP_VENLY_ENVIRONMENT;
        const venlyNodeUrl = process.env.REACT_APP_VENLY_NODEURL;

        console.log("blockchain params", {
            venlyNetwork: venlyNetwork,
            venlyChain: venlyChain,
            venlyClientID: venlyClientID,
            venlyEnvironment: venlyEnvironment,
            venlyNodeUrl: venlyNodeUrl}
        );

        if (venlyNetwork !== 'test' && venlyNetwork !== 'main') {
            console.error('Invalid venlyNetwork. Allowed values are "test" or "main".');
            return;
        }

        if (venlyChain !== 'VECHAIN') {
            console.error('Invalid venlyChain. Allowed value is "VECHAIN".');
            return;
        }
        // need to use promise because useEffect cannot take an async function.
        getBlockchain(
            venlyNetwork,
            venlyChain,
            venlyClientID,
            venlyNodeUrl
        ).then((chain: any) => {
            console.log("setChain", chain);
            setBlockchain(chain)
        });
    }, []);

    useEffect(() => {
        const fetchSettings = async () => {
            const settingsObj = new SettingsService();
            const settingsResult = await settingsObj.getSettings();
            console.log('settings',settingsResult);
            setSettings(settingsResult);
        };
    
        fetchSettings();

        /*const intervalId = setInterval(() => {
            if(isMaintenance){
                fetchSettings();
            }
        }, 5000);

        return () => {
            clearInterval(intervalId);
        };*/
    }, [location]);

    useEffect(() => {
        if(settings === undefined)
            return;

        const maintenance: boolean = settings.find(settings => settings.key === 'maintenance')?.keyval === 'true' ? true : false;
        setMaintenance(maintenance); 
    }, [settings]);

    /* BuildingWallet */
    const [buildingWallet, setBuildingWallet] = useState({ name:'', address: '', balance: { gasBalance: 0}});

    /* StakeholderWallet */
    const [stakeholderWallet, setStakeholderWallet] = useState({ name: '', address: '', balance: { gasBalance: 0}});

    const [wallets, setWallets] = useState();
    const [walletType, setWalletType] = useState();

    /* test */
    //const [isConnected, setIsConnected] = useState(false);
    //const [walletConnected, setWalletConnected] = useState({ address: '', balance: { gasBalance: 0}});

    //const walletAddress = walletConnected.address;

    //const walletBalance = !isConnected ? 0 : walletConnected.balance.gasBalance;


    // MODALS
    const [openModalRole, setOpenModalRole] = useState(false);
    const handleOpenModalRole = () => setOpenModalRole(true);
    const handleCloseModalRole = () => {
        window.localStorage.setItem("hideModalRole", "true");
        setOpenModalRole(false);
    }

    const [openModalAddStakeholder, setOpenModalAddStakeholder] = useState(false);
    const handleOpenModalAddStakeholder = () => setOpenModalAddStakeholder(true);
    const handleCloseModalAddStakeholder = () => setOpenModalAddStakeholder(false);

    const [openModalDelegate, setOpenModalDelegate] = useState(false);
    const handleOpenModalDelegate = () => setOpenModalDelegate(true);
    const handleCloseModalDelegate = () => setOpenModalDelegate(false);

    const [openModalBuildingAdded, setOpenModalBuildingAdded] = useState(false);
    const handleOpenModalBuildingAdded = () => setOpenModalBuildingAdded(true);
    const handleCloseModalBuildingAdded = () => setOpenModalBuildingAdded(false);

    const [openModalGiveAccess, setOpenModalGiveAccess] = useState(false);
    const handleOpenModalGiveAccess = () => setOpenModalGiveAccess(true);
    const handleCloseModalGiveAccess = () => setOpenModalGiveAccess(false);

    const [openModalDocChat, setOpenModalDocChat] = useState(false);
    const handleOpenModalDocChat = () => setOpenModalDocChat(true);
    const handleCloseModalDocChat = () => setOpenModalDocChat(false);

    const [openModalDocViewerInfo, setOpenModalDocViewerInfo] = useState(false);
    const handleOpenModalDocViewerInfo = () => setOpenModalDocViewerInfo(true);
    const handleCloseModalDocViewerInfo = () => setOpenModalDocViewerInfo(false);

    const [openModalEstimate, setOpenModalEstimate] = useState(false);
    const handleOpenModalEstimate = () => setOpenModalEstimate(true);
    const handleCloseModalEstimate = () => setOpenModalEstimate(false);

    const [openModalNda, setOpenModalNda] = useState(false);
    const handleOpenModalNda = () => setOpenModalNda(true);
    const handleCloseModalNda = () => setOpenModalNda(false);

    const [openModalNft, setOpenModalNft] = useState(false);
    const handleOpenModalNft = () => setOpenModalNft(true);
    const handleCloseModalNft = () => setOpenModalNft(false);

    const [openModalNftProgress, setOpenModalNftProgress] = useState(false);
    const handleOpenModalNftProgress = () => setOpenModalNftProgress(true);
    const handleCloseModalNftProgress = () => setOpenModalNftProgress(false);

    const [openModalNftLevel, setOpenModalNftLevel] = useState(false);
    const handleOpenModalNftLevel = () => setOpenModalNftLevel(true);
    const handleCloseModalNftLevel = () => setOpenModalNftLevel(false);

    const [openModalInvitation, setOpenModalInvitation] = useState(false);
    const handleOpenModalInvitation = () => setOpenModalInvitation(true);
    const handleCloseModalInvitation = () => setOpenModalInvitation(false);

    const [openModalBuyMUT, setOpenModalBuyMUT] = useState(false);
    const handleOpenModalBuyMUT = () => setOpenModalBuyMUT(true);
    const handleCloseModalBuyMUT = () => setOpenModalBuyMUT(false);

    const [openModalWalletCreated, setOpenModalWalletCreated] = useState(false);
    const handleOpenModalWalletCreated = () => setOpenModalWalletCreated(true);
    const handleCloseModalWalletCreated = () => setOpenModalWalletCreated(false);

    const [openModalStakeholdersDetailed, setOpenModalStakeholdersDetailed] = useState(false);
    const handleOpenModalStakeholdersDetailed = () => setOpenModalStakeholdersDetailed(true);
    const handleCloseModalStakeholdersDetailed = () => setOpenModalStakeholdersDetailed(false);

    const [openModalStakeholders, setOpenModalStakeholders] = useState(false);
    const handleOpenModalStakeholders = () => setOpenModalStakeholders(true);
    const handleCloseModalStakeholders = () => setOpenModalStakeholders(false);

    const [openModalTour, setOpenModalTour] = useState(false);
    const handleOpenModalTour = () => setOpenModalTour(true);
    const handleCloseModalTour = () => setOpenModalTour(false);

    const [openModalReminderSent, setOpenModalReminderSent] = useState(false);
    const handleOpenModalReminderSent = () => setOpenModalReminderSent(true);
    const handleCloseModalReminderSent = () => setOpenModalReminderSent(false);

    const [openModalAllowPopup, setOpenModalAllowPopup] = useState(false);
    const handleOpenModalAllowPopup = () => setOpenModalAllowPopup(true);
    const handleCloseModalAllowPopup = () => setOpenModalAllowPopup(false);

    const [openModalWalletSelector, setOpenModalWalletSelector] = useState(false);
    const handleOpenModalWalletSelector = () => setOpenModalWalletSelector(true);
    const handleCloseModalWalletSelector = () => setOpenModalWalletSelector(false);
    
    if(isMaintenance && path !== '/'){
        navigate('/');
    }

    if (!isLoggedIn && initialized
            && window.location.pathname !== '/'
            && path !== '/terms-and-conditions'
            && path !== '/privacy-policy') {
        console.log("initialized", initialized);
        console.log('isLoggedIn', isLoggedIn);
        keycloak.redirectUri = window.location.origin;
        keycloak.logout();
    }

    if(!isMaintenance && !isLoggedIn && initialized){
        console.log(path);
        if(path !== '/terms-and-conditions' &&
           path !== '/privacy-policy'){
            keycloak.login();
            return null;
        }
        else {
            return <>
                <Routes>
                    <Route path="/privacy-policy" element={<PrivacyPolicy source='public'/>} />
                    <Route path="/terms-and-conditions" element={<TermsAndConditions source='public'/>} />
                </Routes>
            </>;
        }        
    }
    else {
        return (
            <>
                <ScrollToTop />
                <main className={clsx(`${isLoggedIn ? 'magma-main-private' : 'magma-main-public'}`)}>
                    {(!isMaintenance && isLoggedIn) ? (
                        <>
                            <Suspense fallback={renderLoader()}>
                                <Layout
                                    open={open}
                                    setOpen={setOpen}
                                    messengerOpen={messengerOpen}
                                    setMessengerOpen={setMessengerOpen}
                                    bothNavsOpen={bothNavsOpen}
                                    handleOpenModalBuyMUT={handleOpenModalBuyMUT}
                                    buildingWallet={buildingWallet}
                                    userInfo={userInfo}
                                    settings={settings}
                                >
                                    <Routes>

                                        <Route
                                            index
                                            element={
                                                <BuildingListPage
                                                    open={open}
                                                    setOpen={setOpen}
                                                    handleOpenModalRole={handleOpenModalRole}
                                                    handleOpenModalStakeholders={handleOpenModalStakeholders}
                                                    handleOpenModalStakeholdersDetailed={handleOpenModalStakeholdersDetailed}
                                                    setBuildingWallet={setBuildingWallet}
                                                    setUserInfo={setUserInfo}
                                                    userInfo={userInfo}
                                                    setUserTypes={setUserTypes}
                                                    setUserTypeDocs={setUserTypeDocs}
                                                    setBuilding={setBuilding}
                                                    blockchain={blockchain}
                                                />
                                            }
                                        />

                                        <Route
                                            path="/buildings"
                                            element={
                                                <BuildingListPage
                                                    messengerOpen={messengerOpen}
                                                    bothNavsOpen={bothNavsOpen}
                                                    handleOpenModalRole={handleOpenModalRole}
                                                    handleOpenModalAddStakeholder={handleOpenModalAddStakeholder}
                                                    handleOpenModalStakeholders={handleOpenModalStakeholders}
                                                    handleOpenModalStakeholdersDetailed={handleOpenModalStakeholdersDetailed}
                                                    handleOpenModalDocChat={handleOpenModalDocChat}
                                                    handleOpenModalGiveAccess={handleOpenModalGiveAccess}
                                                    handleOpenModalDocViewerInfo={handleOpenModalDocViewerInfo}
                                                    handleOpenModalBuildingAdded={handleOpenModalBuildingAdded}
                                                    handleOpenModalTour={handleOpenModalTour}
                                                    handleOpenModalReminderSent={handleOpenModalReminderSent}
                                                    handleOpenModalNft={handleOpenModalNft}
                                                    handleOpenModalInvitation={handleOpenModalInvitation}
                                                    handleOpenModalBuyMUT={handleOpenModalBuyMUT}
                                                    handleOpenModalWalletCreated={handleOpenModalWalletCreated}
                                                    handleOpenModalEstimate={handleOpenModalEstimate}
                                                    handleOpenModalNftLevel={handleOpenModalNftLevel}
                                                    handleOpenModalNftProgress={handleOpenModalNftProgress}
                                                    handleOpenModalNda={handleOpenModalNda}
                                                    handleOpenModalAllowPopup={handleOpenModalAllowPopup}
                                                    setBuildingWallet={setBuildingWallet}
                                                    setUserInfo={setUserInfo}
                                                    userInfo={userInfo}
                                                    setBuildingID={setBuildingID}
                                                    setMyCallBack={setMyCallBack}
                                                    setBuilding={setBuilding}
                                                    blockchain={blockchain}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/building/:id"
                                            element={
                                                <BuildingEditPage
                                                    open={open}
                                                    openModalEstimate={openModalEstimate}
                                                    handleOpenModalEstimate={handleOpenModalEstimate}
                                                    handleCloseModalEstimate={handleCloseModalEstimate}
                                                    handleOpenModalNft={handleOpenModalNft}
                                                    handleOpenModalNftLevel={handleOpenModalNftLevel}
                                                    handleOpenModalAddStakeholder={handleOpenModalAddStakeholder}
                                                    messengerOpen={messengerOpen}
                                                    setMessengerOpen={setMessengerOpen}
                                                    handleOpenModalTour={handleOpenModalTour}
                                                    bothNavsOpen={bothNavsOpen}
                                                    setBuildingWallet={setBuildingWallet}
                                                    blockchain={blockchain}
                                                    setDocument={setDocument}
                                                    setUserInfo={setUserInfo}
                                                    userInfo={userInfo}
                                                    setMyCallBack={setMyCallBack}
                                                    myCallBack={myCallBack}
                                                    setUserTypes={setUserTypes}
                                                    setUserTypeDocs={setUserTypeDocs}
                                                    userTypes={userTypes}
                                                    userTypeDocs={userTypeDocs}
                                                    setBuildingID={setBuildingID}
                                                    setBuilding={setBuilding}
                                                    building={building}
                                                    buildingWallet={buildingWallet}
                                                    setIncludeUserTypeID={setIncludeUserTypeID}
                                                    setExcludeUserTypeID={setExcludeUserTypeID}
                                                    setMaxStakeholders={setMaxStakeholders}
                                                />
                                            }
                                        />
                                        <Route path="/building/add"
                                            element={
                                                <BuildingAddPage
                                                    handleOpenModalStakeholdersDetailed={handleOpenModalStakeholdersDetailed}
                                                    setBuildingWallet={setBuildingWallet}
                                                    blockchain={blockchain}
                                                />
                                            }
                                        />
                                        <Route path="/building/:id/add"
                                            element={
                                                <BuildingAddPage
                                                    handleOpenModalStakeholdersDetailed={handleOpenModalStakeholdersDetailed}
                                                    setBuildingWallet={setBuildingWallet}
                                                    blockchain={blockchain}
                                                />
                                            }
                                        />
                                        <Route path="/building/:id/asset-manager"
                                            element={
                                                <BuildingAssetManagerPage
                                                    handleOpenModalDelegate={handleOpenModalDelegate}
                                                    handleOpenModalAddStakeholder={handleOpenModalAddStakeholder}
                                                    setBuildingID={setBuildingID}
                                                    setIncludeUserTypeID={setIncludeUserTypeID}
                                                    setExcludeUserTypeID={setExcludeUserTypeID}
                                                    setMaxStakeholders={setMaxStakeholders}
                                                    setMyCallBack={setMyCallBack}
                                                    setBuildingWallet={setBuildingWallet}
                                                    blockchain={blockchain}
                                                />
                                            }
                                        />
                                        <Route path="/building/:id/stakeholders"
                                            element={
                                                <BuildingStakeholdersPage
                                                    handleOpenModalAddStakeholder={handleOpenModalAddStakeholder}
                                                    setBuildingID={setBuildingID}
                                                    setIncludeUserTypeID={setIncludeUserTypeID}
                                                    setExcludeUserTypeID={setExcludeUserTypeID}
                                                    setMaxStakeholders={setMaxStakeholders}
                                                    setMyCallBack={setMyCallBack}
                                                    setBuildingWallet={setBuildingWallet}
                                                    blockchain={blockchain}
                                                />
                                            }
                                        />
                                        <Route path="/building/:id/review"
                                            element={
                                                <BuildingReviewPage
                                                    buildingWallet={buildingWallet}
                                                    setBuildingWallet={setBuildingWallet}
                                                    setWallets={setWallets}
                                                    setWalletType={setWalletType}
                                                    blockchain={blockchain}
                                                    handleOpenModalAllowPopup={handleOpenModalAllowPopup}
                                                    handleOpenModalWalletSelector={handleOpenModalWalletSelector}
                                                    building={building}
                                                    setBuilding={setBuilding}
                                                />
                                            }
                                        />
                                        <Route path="/building/:id/checkout"
                                            element={
                                                <CheckoutPage
                                                    setBuildingID={setBuildingID}
                                                    handleOpenModalBuildingAdded={handleOpenModalBuildingAdded}
                                                />
                                            }
                                        />

                                        <Route path="/wallet"
                                            element={
                                                <Wallet
                                                    stakeholderWallet={stakeholderWallet}
                                                    setStakeholderWallet={setStakeholderWallet}
                                                    setWallets={setWallets}
                                                    messengerOpen={messengerOpen}
                                                    handleOpenModalBuyMUT={handleOpenModalBuyMUT}
                                                    setBuildingWallet={setBuildingWallet}
                                                    setWalletType={setWalletType}
                                                    blockchain={blockchain}
                                                    setUserInfo={setUserInfo}
                                                    userInfo={userInfo}
                                                    handleOpenModalAllowPopup={handleOpenModalAllowPopup}
                                                    handleOpenModalWalletSelector={handleOpenModalWalletSelector}
                                                />}
                                        />

                                        <Route path="/activity"
                                            element={
                                                <ActivityPage
                                                    handleOpenModalReminderSent={handleOpenModalReminderSent}
                                                    messengerOpen={messengerOpen}
                                                    setBuildingWallet={setBuildingWallet}
                                                    setUserInfo={setUserInfo}
                                                    userInfo={userInfo}
                                                    blockchain={blockchain}
                                                />
                                            }
                                        />
                                        <Route path="/settings"
                                            element={
                                                <Settings
                                                    setBuildingWallet={setBuildingWallet}
                                                />
                                            }
                                        />
                                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                                        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

                                        {/* TODO: Activate when necessary - Contact Button route*/}
                                        {/* <Route path="/contacts" element={<Contacts messengerOpen={messengerOpen} />} /> */}

                                        {/*<Route path="/checkout" element={<CheckoutPage />} />*/}

                                        <Route
                                            path="*"
                                            element={<Navigate to="/404" replace />}
                                        />

                                        <Route path="/404" element={<NoMatch />} />
                                    </Routes>
                                </Layout>
                            </Suspense>

                            <Suspense fallback={renderLoader()}>
                                <AllModals
                                    openModalEstimate={openModalEstimate}
                                    handleCloseModalEstimate={handleCloseModalEstimate}
                                    openModalRole={openModalRole}
                                    handleCloseModalRole={handleCloseModalRole}
                                    openModalStakeholdersDetailed={openModalStakeholdersDetailed}
                                    handleCloseModalStakeholdersDetailed={handleCloseModalStakeholdersDetailed}
                                    openModalStakeholders={openModalStakeholders}
                                    handleCloseModalStakeholders={handleCloseModalStakeholders}
                                    openModalAddStakeholder={openModalAddStakeholder}
                                    handleCloseModalAddStakeholder={handleCloseModalAddStakeholder}
                                    myCallBack={myCallBack}
                                    userInfo={userInfo}
                                    building_ID={building_ID}
                                    include_user_type_ID={include_user_type_ID}
                                    exclude_user_type_ID={exclude_user_type_ID}
                                    max_stakeholders={max_stakeholders}
                                    openModalDelegate={openModalDelegate}
                                    handleCloseModalDelegate={handleCloseModalDelegate}
                                    openModalBuildingAdded={openModalBuildingAdded}
                                    handleCloseModalBuildingAdded={handleCloseModalBuildingAdded}
                                    openModalGiveAccess={openModalGiveAccess}
                                    handleCloseModalGiveAccess={handleCloseModalGiveAccess}
                                    openModalDocViewerInfo={openModalDocViewerInfo}
                                    handleCloseModalDocViewerInfo={handleCloseModalDocViewerInfo}
                                    openModalNda={openModalNda}
                                    handleCloseModalNda={handleCloseModalNda}
                                    handleOpenModalNda={handleOpenModalNda}
                                    openModalInvitation={openModalInvitation}
                                    handleCloseModalInvitation={handleCloseModalInvitation}
                                    openModalBuyMUT={openModalBuyMUT}
                                    handleCloseModalBuyMUT={handleCloseModalBuyMUT}
                                    handleCloseModalWalletCreated={handleCloseModalWalletCreated}
                                    openModalWalletCreated={openModalWalletCreated}
                                    openModalNft={openModalNft}
                                    handleCloseModalNft={handleCloseModalNft}
                                    openModalNftProgress={openModalNftProgress}
                                    handleOpenModalNftProgress={handleOpenModalNftProgress}
                                    handleCloseModalNftProgress={handleCloseModalNftProgress}
                                    openModalNftLevel={openModalNftLevel}
                                    handleCloseModalNftLevel={handleCloseModalNftLevel}
                                    openModalDocChat={openModalDocChat}
                                    handleCloseModalDocChat={handleCloseModalDocChat}
                                    openModalTour={openModalTour}
                                    handleCloseModalTour={handleCloseModalTour}
                                    openModalReminderSent={openModalReminderSent}
                                    handleCloseModalReminderSent={handleCloseModalReminderSent}
                                    openModalAllowPopup={openModalAllowPopup}
                                    handleCloseModalAllowPopup={handleCloseModalAllowPopup}
                                    openModalWalletSelector={openModalWalletSelector}
                                    handleCloseModalWalletSelector={handleCloseModalWalletSelector}
                                    blockchain={blockchain}
                                    buildingWallet={buildingWallet}
                                    stakeholderWallet={stakeholderWallet}
                                    wallets={wallets}
                                    setWallets={setWallets}
                                    setSelectedWallet={walletType}
                                    document={document}
                                    userTypeDocs={userTypeDocs}
                                    building={building}
                                />

                                {/* toast modals */}
                                <ToastContainer />
                            </Suspense>
                        </>
                    ) : (
                        isMaintenance
                        ?
                            <Routes>
                                <Route index element={<Maintenance />} />
                                {/* <Route index element={renderLoader()} /> */}
                            </Routes>
                            /*<Routes>
                                <Route index element={!isMaintenance ? <LoginPage /> : <Maintenance />} />
                                //{<Route index element={renderLoader()} />}
                            </Routes>*/
                        :
                        <Loader />
                    )}
                </main>
            </>
        )
    }
}